import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const RoutesPlanningView = lazy(
  () => import(/* webpackChunkName: "routes-planning" */ /* webpackPrefetch: true */ './views/Index')
)

export const RoutesPlanningRoutes = {
  path: '/routes-planning',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <RoutesPlanningView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/routes" replace />
    }
  ]
}
