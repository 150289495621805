import { ReactElement, useState } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'

import styled, { css } from 'styled-components'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message

import { BookingIcon } from '@10xLabs/react-web/components/Icons/Booking'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { BusIcon } from '@10xLabs/react-web/components/Icons/Bus'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { CompaniesIcon } from '@10xLabs/react-web/components/Icons/Companies'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { HeartMedicalIcon } from '@10xLabs/react-web/components/Icons/HeartMedical'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { ImportExportIcon } from '@10xLabs/react-web/components/Icons/ImportExport'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { LinesIcon } from '@10xLabs/react-web/components/Icons/Lines'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { LuggageIcon } from '@10xLabs/react-web/components/Icons/Luggage'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { PlacesIcon } from '@10xLabs/react-web/components/Icons/Places'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { PricingIcon } from '@10xLabs/react-web/components/Icons/Pricing'
import { RewardsIcon } from 'components/Icons/Rewards'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { RoleIcon } from '@10xLabs/react-web/components/Icons/Role'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { PersonIcon } from '@10xLabs/react-web/components/Icons/Person'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { RoutesIcon } from '@10xLabs/react-web/components/Icons/Routes'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { SegmentsIcon } from '@10xLabs/react-web/components/Icons/Segments'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { ShoppingCartIcon } from '@10xLabs/react-web/components/Icons/ShoppingCart'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { SiteIcon } from '@10xLabs/react-web/components/Icons/Site'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { ToggleSwitchIcon } from '@10xLabs/react-web/components/Icons/ToggleSwitch'
// @ts-expect-error TS(2559) FIXME Remove this comment to see the full error message
import { UserTagIcon } from '@10xLabs/react-web/components/Icons/UserTag'
import { VanIcon } from 'components/Icons/VanIcon'
import kolorsLogo from 'assets/images/logo-kolors-white.svg'
import simpleNexbusLogo from 'assets/images/isotipo_kolors.svg'
import { HandshakeIcon } from 'components/Icons/HandshakeIcon'
import { WalletIcon } from 'components/Icons/WalletIcon'
import { NavItems, NavItem, SubNavItems, SubNavItem } from 'features/App/components/NavItems'
import { FEATURES } from 'features/Roles/components/Permissions/constants'
import { useLogin } from 'features/Login/hooks'
import {
  canRead,
  canReadInventory,
  isSuperAdmin,
  canReadCart,
  canWriteCart,
  canShowInsuranceSettings,
  hasAllAccess,
  canReadIncidenceSubcampaigns,
  canReadDbSubcampaigns,
  canWrite,
  canAssignPriceToBusinessClient
} from 'features/Users/utils'
import { device } from 'utils/style'
import { EAppCategories } from './constants'
import { setIsSidebarCollapsed, useIsSidebarCollapsed, saveSidebarStateOnLocalStorage } from 'variables/sidebar'
import sidebarArrow from 'assets/images/sidebar-arrow.svg'
import { SteeringWheelIcon } from 'components/Icons/SteeringWheelIcon'

const IconWrapper = styled.div<TSFixMe>`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 24px;
  width: 24px;
`

const NavItemLabel = styled.span<TSFixMe>`
  display: ${({ isSidebarCollapsed }): TSFixMe => (isSidebarCollapsed ? 'none' : 'inline')};
`

const StyledSidebar = styled.nav<TSFixMe>`
  background-color: #28265d;
  height: 100vh;
  position: absolute;
  transition: left 0.3s ease-in;
  width: ${({ isSidebarCollapsed }): TSFixMe => (isSidebarCollapsed ? '72px' : '200px')};
  z-index: 2;

  @media ${device.laptop} {
    left: 0;
  }

  @media print {
    display: none;
  }

  ${({ isSidebarOnHover }): TSFixMe =>
    isSidebarOnHover &&
    css`
      width: 200px;
      ${NavItemLabel} {
        display: inline;
      }
    `}
`

const LogoWrapper = styled.div<TSFixMe>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 75px;
  position: relative;
  ${({ isSidebarCollapsed }): TSFixMe => {
    if (isSidebarCollapsed) {
      return `
        justify-content: center;
      `
    }
  }};
`

const LogoContainer = styled.div<TSFixMe>`
  ${({ isSidebarCollapsed }): TSFixMe => {
    if (!isSidebarCollapsed) {
      return `
        padding: 0 22px
      `
    }
  }};
`

const Logo = styled.img`
  height: auto;
  width: 103px;
  margin-left: -5px;
`

const SimpleLogo = styled.img`
  height: 39px;
  width: auto;
`

const StyledImportExportIcon = styled(ImportExportIcon)`
  width: 25px;
  height: 23px;
`

const StyledHeartMedicalIcon = styled(HeartMedicalIcon)`
  width: 24px;
  height: 24px;
`

const StyledToggleSwitchIcon = styled(ToggleSwitchIcon)`
  width: 24px;
  height: 24px;
`

const NavBarImage = styled.img<TSFixMe>`
  cursor: pointer;
  width: 23px;
  height: 20px;
  transform: ${({ isSidebarCollapsed }): TSFixMe => (isSidebarCollapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease-in;
`

const ToggleSidebar = styled.div<TSFixMe>`
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(50%, -50%);
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 8px 8px 16px 0px rgba(102, 119, 150, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ItemsWrapper = styled.div<TSFixMe>`
  overflow-y: auto;
  @media ${device.laptop} {
    overflow-y: ${({ isSidebarCollapsed, isSidebarOnHover }): TSFixMe =>
      !isSidebarCollapsed || isSidebarOnHover ? 'auto' : 'hidden'};
  }
  height: calc(100% - 75px);
`

const Sidebar = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const login = useLogin()
  const intl = useIntl()

  const [activeDropdown, setActiveDropdown] = useState<EAppCategories | null>(null)
  const [isSidebarOnHover, setIsSidebarOnHover] = useState(false)
  const isSidebarCollapsed = useIsSidebarCollapsed()

  const handleLinkClick = async (to: TSFixMe, event: TSFixMe): Promise<TSFixMe> => {
    event.preventDefault()

    const mq = window.matchMedia(device.laptop)
    if (!mq.matches) {
      setIsSidebarCollapsed(true)
      saveSidebarStateOnLocalStorage(true)
    }

    const currentLink = `${location.pathname}${location.search}`

    if (currentLink !== to) {
      navigate(to)
    }
  }

  const handleActiveItem = (key: EAppCategories, isActive: boolean): string => {
    if (isActive) {
      setActiveDropdown(key)
      return 'selected'
    }

    return ''
  }

  const policies = login.accessToken.policies
  const showPassengerTypesMenu =
    canRead(FEATURES.PASSENGER_TYPES, policies) || canRead(FEATURES.PASSENGER_PROPERTIES, policies)
  const isToggleChildrenEnabled = !isSidebarCollapsed || isSidebarOnHover
  const handleMouseOver = (): TSFixMe => {
    setIsSidebarOnHover(true)
  }

  const handleMouseLeave = (): TSFixMe => {
    setIsSidebarOnHover(false)
  }

  const messages = defineMessages({
    collapse: {
      id: 'sidebar.collapse.alt',
      defaultMessage: 'collapse'
    }
  })

  return (
    <StyledSidebar
      isSidebarCollapsed={isSidebarCollapsed}
      isSidebarOnHover={isSidebarOnHover}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <LogoWrapper isSidebarCollapsed={isSidebarCollapsed && !isSidebarOnHover}>
        <LogoContainer isSidebarCollapsed={isSidebarCollapsed && !isSidebarOnHover}>
          {isSidebarCollapsed && !isSidebarOnHover ? (
            <SimpleLogo src={simpleNexbusLogo} alt="" />
          ) : (
            <Logo src={kolorsLogo} alt="logo" />
          )}
        </LogoContainer>
        <ToggleSidebar
          onClick={(): void => {
            saveSidebarStateOnLocalStorage(!isSidebarCollapsed)
            setIsSidebarCollapsed(!isSidebarCollapsed)
            setIsSidebarOnHover(false)
          }}
        >
          <NavBarImage
            src={sidebarArrow}
            alt={intl.formatMessage(messages.collapse)}
            isSidebarCollapsed={isSidebarCollapsed}
          />
        </ToggleSidebar>
      </LogoWrapper>
      <ItemsWrapper isSidebarCollapsed={isSidebarCollapsed} isSidebarOnHover={isSidebarOnHover}>
        <NavItems>
          {canRead(FEATURES.ROUTE, policies) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.ROUTES}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <RoutesIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.routes" defaultMessage="Routes" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {hasAllAccess(FEATURES.ROUTE, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/routes"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.ROUTES, isActive)}
                      onClick={handleLinkClick.bind(this, '/routes')}
                    >
                      <FormattedMessage id="sidebar.routes.services" defaultMessage="Services" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.ROUTE, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/routes-planning"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.ROUTES, isActive)}
                      onClick={handleLinkClick.bind(this, '/routes-planning')}
                    >
                      <FormattedMessage id="sidebar.routes.planning" defaultMessage="Planning" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.COMPANY, policies) && (
            <NavItem>
              <NavLink
                to="/companies"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.COMPANIES, isActive)}
                onClick={handleLinkClick.bind(this, '/companies')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <CompaniesIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="dictionary.companies" defaultMessage="Companies" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {(canRead(FEATURES.BUSINESS_CLIENT, policies) ||
            canRead(FEATURES.BUSINESS_CUSTOMER, policies) ||
            canRead(FEATURES.BUSINESS_SITE, policies) ||
            canRead(FEATURES.BUSINESS_CLIENT_USER, policies)) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.CLIENTS}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <VanIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.businessClients" defaultMessage="Business Clients" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.BUSINESS_CLIENT, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/business-clients"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.CLIENTS, isActive)}
                      onClick={handleLinkClick.bind(this, '/business-clients')}
                    >
                      <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                        <FormattedMessage id="dictionary.business_clients" defaultMessage="Clients" />
                      </NavItemLabel>
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.BUSINESS_SITE, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/business-sites"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.CLIENTS, isActive)}
                      onClick={handleLinkClick.bind(this, '/business-sites')}
                    >
                      <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                        <FormattedMessage id="dictionary.business_sites" defaultMessage="Sites" />
                      </NavItemLabel>
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.BUSINESS_CUSTOMER, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/business-customers"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.CLIENTS, isActive)}
                      onClick={handleLinkClick.bind(this, '/business-customers')}
                    >
                      <FormattedMessage id="dictionary.business_customers" defaultMessage="Customers" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.BUSINESS_CLIENT_USER, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/business-client-users"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.CLIENTS, isActive)}
                      onClick={handleLinkClick.bind(this, '/business-client-users')}
                    >
                      <FormattedMessage id="dictionary.businessClientsUsers" defaultMessage="Users" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.COMPANY_LINE, policies) && (
            <NavItem>
              <NavLink
                to="/lines"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.COMPANY_LINES, isActive)}
                onClick={handleLinkClick.bind(this, '/lines')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <LinesIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="dictionary.lines" defaultMessage="Lines" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {canRead(FEATURES.PLACE, policies) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.PLACES}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <PlacesIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.places" defaultMessage="Places" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                <SubNavItem>
                  <NavLink
                    to="/cities"
                    className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PLACES, isActive)}
                    onClick={handleLinkClick.bind(this, '/cities')}
                  >
                    <FormattedMessage id="dictionary.cities" defaultMessage="Cities" />
                  </NavLink>
                </SubNavItem>
                <SubNavItem>
                  <NavLink
                    to="/places"
                    className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PLACES, isActive)}
                    onClick={handleLinkClick.bind(this, '/places')}
                  >
                    <FormattedMessage id="dictionary.placesList" defaultMessage="Places list" />
                  </NavLink>
                </SubNavItem>
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.SEGMENT, policies) && (
            <NavItem>
              <NavLink
                to="/segments"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.SEGMENTS, isActive)}
                onClick={handleLinkClick.bind(this, '/segments')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <SegmentsIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="dictionary.segments" defaultMessage="Segments" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {(canRead(FEATURES.BOOKING_CLASS, policies) ||
            canRead(FEATURES.SEASON_EVENT, policies) ||
            canRead(FEATURES.FARE_FAMILY, policies) ||
            canRead(FEATURES.FARE_RULE, policies) ||
            canRead(FEATURES.INVENTORY_RULE, policies) ||
            canWrite(FEATURES.PARTNER_COST, policies) ||
            canAssignPriceToBusinessClient(policies)) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.PRICING}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <PricingIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="sidebar.pricingv2" defaultMessage="Pricing" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.BOOKING_CLASS, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/booking-classes"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PRICING, isActive)}
                      onClick={handleLinkClick.bind(this, '/booking-classes')}
                    >
                      <FormattedMessage id="dictionary.bookingClasses" defaultMessage="Booking Classes" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.SEASON_EVENT, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/season-events"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PRICING, isActive)}
                      onClick={handleLinkClick.bind(this, '/season-events')}
                    >
                      <FormattedMessage id="dictionary.seasonEvents" defaultMessage="Season Events" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.FARE_FAMILY, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/fare-families"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PRICING, isActive)}
                      onClick={handleLinkClick.bind(this, '/fare-families')}
                    >
                      <FormattedMessage id="dictionary.fareFamiies" defaultMessage="Fare Families" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.FARE_RULE, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/fare-rules"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PRICING, isActive)}
                      onClick={handleLinkClick.bind(this, '/fare-rules')}
                    >
                      <FormattedMessage id="dictionary.fareRules" defaultMessage="Fare Rules" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.INVENTORY_RULE, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/inventory-rules"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PRICING, isActive)}
                      onClick={handleLinkClick.bind(this, '/inventory-rules')}
                    >
                      <FormattedMessage id="dictionary.inventoryRules" defaultMessage="Inventory Rules" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canWrite(FEATURES.PARTNER_COST, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/partner-cost/new"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.PRICING, isActive)}
                      onClick={handleLinkClick.bind(this, '/partner-cost/new')}
                    >
                      <FormattedMessage id="dictionary.partnerCost" defaultMessage="Partner Cost" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canAssignPriceToBusinessClient(policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/business-client-price/new"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.PRICING, isActive)}
                      onClick={handleLinkClick.bind(this, '/business-client-price/new')}
                    >
                      <FormattedMessage id="dictionary.businessClientPrice" defaultMessage="Client Price" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {(canRead(FEATURES.CAMPAIGN, policies) ||
            canReadIncidenceSubcampaigns(policies) ||
            canReadDbSubcampaigns(policies)) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.CAMPAIGNS}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <UserTagIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.campaigns" defaultMessage="Campaigns" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.CAMPAIGN, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/campaigns"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.CAMPAIGNS, isActive)}
                      onClick={handleLinkClick.bind(this, '/campaigns')}
                    >
                      <FormattedMessage id="sidebar.campaignsList" defaultMessage="Campaigns list" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.CAMPAIGN, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/coupons"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.CAMPAIGNS, isActive)}
                      onClick={handleLinkClick.bind(this, '/coupons')}
                    >
                      <FormattedMessage id="dictionary.coupons" defaultMessage="Coupons" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canReadIncidenceSubcampaigns(policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/subcampaigns/incidences"
                      className={({ isActive }): TSFixMe => (isActive ? 'selected' : '')}
                      onClick={handleLinkClick.bind(this, '/subcampaigns/incidences')}
                    >
                      <FormattedMessage id="sidebar.campaignsList" defaultMessage="Subcampaigns" />
                    </NavLink>
                  </SubNavItem>
                )}
                {!canReadIncidenceSubcampaigns(policies) && canReadDbSubcampaigns(policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/subcampaigns/database-coupons"
                      className={({ isActive }): TSFixMe => (isActive ? 'selected' : '')}
                      onClick={handleLinkClick.bind(this, '/subcampaigns/database-coupons')}
                    >
                      <FormattedMessage id="sidebar.campaignsList" defaultMessage="Subcampaigns" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.LOYALTY_CAMPAIGN, policies) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.LOYALTY}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <RewardsIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.campaigns" defaultMessage="Loyalty" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.LOYALTY_CAMPAIGN, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/loyalty/campaigns"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.LOYALTY, isActive)}
                      onClick={handleLinkClick.bind(this, '/loyalty/campaigns')}
                    >
                      <FormattedMessage id="sidebar.loyaltyCampaign" defaultMessage="Campaigns" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.LOYALTY_REWARD, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/loyalty/rewards"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.LOYALTY, isActive)}
                      onClick={handleLinkClick.bind(this, '/loyalty/rewards')}
                    >
                      <FormattedMessage id="dictionary.rewards" defaultMessage="Rewards" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.TRIP, policies) && (
            <NavItem>
              <NavLink
                to="/trips"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.TRIPS, isActive)}
                onClick={handleLinkClick.bind(this, '/trips')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <LuggageIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="dictionary.trips" defaultMessage="Trips" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {canRead(FEATURES.BOOKING, policies) && (
            <NavItem>
              <NavLink
                to="/bookings"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.BOOKINGS, isActive)}
                onClick={handleLinkClick.bind(this, '/bookings')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <BookingIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="dictionary.bookings" defaultMessage="Bookings" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {canRead(FEATURES.KREDIT, policies) && (
            <NavItem>
              <NavLink
                to="/kredits"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.KREDITS, isActive)}
                onClick={handleLinkClick.bind(this, '/kredits')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <WalletIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="sidebar.kredits" defaultMessage="Kredits" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {canRead(FEATURES.BOOKING, policies) && (
            <NavItem>
              <NavLink
                to="/ancillaries/insurance"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.ASSISTANCE, isActive)}
                onClick={handleLinkClick.bind(this, '/ancillaries/insurance')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <StyledHeartMedicalIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="sidebar.assistance" defaultMessage="Assistance" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {canShowInsuranceSettings(policies) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.ANCILLARIES}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <StyledToggleSwitchIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="sidebar.ancillaries" defaultMessage="Ancillaries" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                <SubNavItem>
                  <NavLink
                    to="/ancillaries/settings"
                    className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.ANCILLARIES, isActive)}
                    onClick={handleLinkClick.bind(this, '/ancillaries/settings')}
                  >
                    <FormattedMessage id="dictionary.travelAssistance" defaultMessage="Travel Assistance" />
                  </NavLink>
                </SubNavItem>
                {canRead(FEATURES.ANCILLARIES, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/ancillaries/luggage"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.ANCILLARIES, isActive)}
                      onClick={handleLinkClick.bind(this, '/ancillaries/luggage')}
                    >
                      <FormattedMessage id="sidebar.ancillaries.luggage" defaultMessage="Luggage & Pets" />
                    </NavLink>
                  </SubNavItem>
                )}
                <SubNavItem>
                  <NavLink
                    to="/ancillaries/services"
                    className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.ANCILLARIES, isActive)}
                    onClick={handleLinkClick.bind(this, '/ancillaries/services')}
                    end
                  >
                    <FormattedMessage id="dictionary.service" defaultMessage="Service" />
                  </NavLink>
                </SubNavItem>
                {canRead(FEATURES.ANCILLARIES, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/ancillaries/preferred-seats"
                      className={({ isActive }): string => handleActiveItem(EAppCategories.ANCILLARIES, isActive)}
                      onClick={handleLinkClick.bind(this, '/ancillaries/preferred-seats')}
                    >
                      <FormattedMessage id="sidebar.ancillaries.preferredSeats" defaultMessage="Preferred Seats" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.SITE, policies) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.POS}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <SiteIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="sidebar.pointOfSales" defaultMessage="PoS" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                <SubNavItem>
                  <NavLink
                    to="/sites/sales"
                    className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.POS, isActive)}
                    onClick={handleLinkClick.bind(this, '/sites/sales')}
                    end
                  >
                    <FormattedMessage id="dictionary.sales" defaultMessage="Sales" />
                  </NavLink>
                </SubNavItem>

                <SubNavItem>
                  <NavLink
                    to="/sites"
                    className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.POS, isActive)}
                    onClick={handleLinkClick.bind(this, '/sites')}
                    end
                  >
                    <FormattedMessage id="dictionary.settings" defaultMessage="Settings" />
                  </NavLink>
                </SubNavItem>
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.PARTNER, policies) && (
            <NavItem>
              <NavLink
                to="/partners"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PARTNERS, isActive)}
                onClick={handleLinkClick.bind(this, '/partners')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <HandshakeIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="sidebar.partners" defaultMessage="Partners" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {canRead(FEATURES.DRIVER, policies) && (
            <NavItem>
              <NavLink
                to="/drivers"
                className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PARTNERS, isActive)}
                onClick={handleLinkClick.bind(this, '/drivers')}
              >
                <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                  <SteeringWheelIcon />
                </IconWrapper>
                <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                  <FormattedMessage id="sidebar.drivers" defaultMessage="Drivers" />
                </NavItemLabel>
              </NavLink>
            </NavItem>
          )}
          {(canRead(FEATURES.VEHICLE, policies) || canRead(FEATURES.VEHICLE_TEMPLATE, policies)) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.VEHICLES}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <BusIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.vehicles" defaultMessage="Vehicles" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.VEHICLE_TEMPLATE, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/vehicle-templates"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.VEHICLES, isActive)}
                      onClick={handleLinkClick.bind(this, '/vehicle-templates')}
                    >
                      <FormattedMessage id="dictionary.templates" defaultMessage="Templates" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.VEHICLE, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/vehicles"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.VEHICLES, isActive)}
                      onClick={handleLinkClick.bind(this, '/vehicles')}
                    >
                      <FormattedMessage id="dictionary.vehiclesList" defaultMessage="Vehicles list" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {(canRead(FEATURES.USER, policies) || canRead(FEATURES.POLICY, policies)) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.USERS}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <RoleIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.users" defaultMessage="Users" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.USER, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/users"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.USERS, isActive)}
                      onClick={handleLinkClick.bind(this, '/users')}
                    >
                      <FormattedMessage id="dictionary.usersList" defaultMessage="Users list" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.POLICY, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/roles"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.USERS, isActive)}
                      onClick={handleLinkClick.bind(this, '/roles')}
                    >
                      <FormattedMessage id="dictionary.roles" defaultMessage="Roles" />
                    </NavLink>
                  </SubNavItem>
                )}
                {isSuperAdmin(login.accessToken.conditions) && canRead(FEATURES.POLICY, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/company-roles"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.USERS, isActive)}
                      onClick={handleLinkClick.bind(this, '/company-roles')}
                    >
                      <FormattedMessage id="dictionary.companyRoles" defaultMessage="Company Roles" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.CHANGES, policies) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.CHANGE_MANAGEMENT}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <StyledImportExportIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.changeManagement" defaultMessage="Change management" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.CHANGES, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/changes"
                      className={({ isActive }): TSFixMe =>
                        handleActiveItem(EAppCategories.CHANGE_MANAGEMENT, isActive)
                      }
                      onClick={handleLinkClick.bind(this, '/changes')}
                    >
                      <FormattedMessage id="dictionary.changesList" defaultMessage="Changes list" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {showPassengerTypesMenu && (
            <NavItem
              isActive={activeDropdown === EAppCategories.PASSENGER_TYPES}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <PersonIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.passengerTypes" defaultMessage="Passengers" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.PASSENGER_TYPES, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/passenger-types"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PASSENGER_TYPES, isActive)}
                      onClick={handleLinkClick.bind(this, '/passenger-types')}
                    >
                      <FormattedMessage id="dictionary.passengers" defaultMessage="Types" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.PASSENGER_PROPERTIES, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/passenger-properties"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.PASSENGER_TYPES, isActive)}
                      onClick={handleLinkClick.bind(this, '/passenger-properties')}
                    >
                      <FormattedMessage id="sidebar.passengerProperties" defaultMessage="Properties" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {canRead(FEATURES.ITEM, policies) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.CATALOGS}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <ShoppingCartIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.catalogs" defaultMessage="Catalogs" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canRead(FEATURES.ITEM, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/snacks"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.CATALOGS, isActive)}
                      onClick={handleLinkClick.bind(this, '/snacks/products')}
                    >
                      <FormattedMessage id="dictionary.snacks" defaultMessage="Snacks" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
          {(canReadInventory(policies) || canReadCart(policies) || canRead(FEATURES.ORDER, policies)) && (
            <NavItem
              isActive={activeDropdown === EAppCategories.INVENTORIES}
              isToggleChildrenEnabled={isToggleChildrenEnabled}
              toggle={
                <>
                  <IconWrapper isSidebarCollapsed={isSidebarCollapsed}>
                    <ShoppingCartIcon />
                  </IconWrapper>
                  <NavItemLabel isSidebarCollapsed={isSidebarCollapsed}>
                    <FormattedMessage id="dictionary.inventories" defaultMessage="Inventories" />
                  </NavItemLabel>
                </>
              }
            >
              <SubNavItems>
                {canReadInventory(policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/inventories"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.INVENTORIES, isActive)}
                      onClick={handleLinkClick.bind(this, '/inventories')}
                    >
                      <FormattedMessage id="dictionary.inventories" defaultMessage="Locations list" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canReadCart(policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/carts"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.INVENTORIES, isActive)}
                      onClick={handleLinkClick.bind(this, '/carts')}
                    >
                      <FormattedMessage id="dictionary.carts" defaultMessage="Carts" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canRead(FEATURES.ORDER, policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/orders"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.INVENTORIES, isActive)}
                      onClick={handleLinkClick.bind(this, '/orders')}
                    >
                      <FormattedMessage id="dictionary.orders" defaultMessage="Orders" />
                    </NavLink>
                  </SubNavItem>
                )}
                {canWriteCart(policies) && (
                  <SubNavItem>
                    <NavLink
                      to="/sales"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.INVENTORIES, isActive)}
                      onClick={handleLinkClick.bind(this, '/sales')}
                    >
                      <FormattedMessage id="dictionary.sales" defaultMessage="Sales" />
                    </NavLink>
                  </SubNavItem>
                )}
                {(canReadInventory(policies) || canReadCart(policies)) && (
                  <SubNavItem>
                    <NavLink
                      to="/wastes"
                      className={({ isActive }): TSFixMe => handleActiveItem(EAppCategories.INVENTORIES, isActive)}
                      onClick={handleLinkClick.bind(this, '/wastes')}
                    >
                      <FormattedMessage id="dictionary.wastes" defaultMessage="Wastes" />
                    </NavLink>
                  </SubNavItem>
                )}
              </SubNavItems>
            </NavItem>
          )}
        </NavItems>
      </ItemsWrapper>
    </StyledSidebar>
  )
}

export { Sidebar }
