import { ReactElement, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const NewPartnerCostView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "new-partner-cost" */ /* webpackPrefetch: true */ './views/New')
)
const SelectPartnerCostFormView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "select-partner-cost-form" */ /* webpackPrefetch: true */ './views/SelectForm')
)

export const PartnerCostRoutes = {
  path: '/partner-cost',
  children: [
    {
      index: true,
      element: <Navigate to="/partner-cost/new" replace />
    },
    {
      path: 'new',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <NewPartnerCostView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: 'select-form',
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <SelectPartnerCostFormView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/partner-cost/new" replace />
    }
  ]
}
