import { ReactElement, useState } from 'react'
import styled from 'styled-components'

import { AngleDownIcon } from 'components/Icons/AngleDown'
import { INavItemProps } from './types'

export const NavItems = styled.ul<TSFixMe>`
  padding: 0px;
  margin: 0px;
`

const StyledAngleDownIcon = styled(AngleDownIcon)`
  fill: #b0b0b0;
  min-width: 10px;
  min-height: 6px;
`

const BaseNavItem = styled.li<TSFixMe>`
  color: #e3e3e3;
  display: block;

  > a {
    display: flex;
    align-items: center;
    color: inherit;
    cursor: pointer;
    font-size: 15px;
    gap: 4px;
    padding: 0 24px;
    height: 48px;
    text-decoration: none;

    :hover {
      background-color: #423f9a;
      color: #ffffff;

      ${StyledAngleDownIcon} {
        fill: #ffffff;
      }
    }

    &.selected {
      background-color: #5d59d7;
      color: #ffffff;
      font-weight: 500;

      svg {
        fill: #27edc7;
      }
    }
  }
`

export const SubNavItems = styled(NavItems)<TSFixMe>``

export const SubNavItem = styled(BaseNavItem)<TSFixMe>`
  > a {
    padding: 0 20px 0 60px;
    gap: 0px;
  }
`

const NavItemToggle = styled.div<TSFixMe>`
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 48px;

  :hover {
    background-color: #423f9a;
  }
`

const ToggleWrapper = styled.div<{ isOpen?: boolean; isActive?: boolean; isToggleChildrenEnabled?: boolean }>`
  align-items: center;
  color: ${({ isActive }): string => (isActive ? '#ffffff' : 'inherit')};
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%;

  > svg {
    fill: ${({ isActive }): string => (isActive ? '#ffffff' : '#b0b0b0')};
    transition: transform 0.2s;
    transform: ${({ isOpen }): string => (isOpen ? 'rotate(180deg)' : 'none')};
  }

  > div > div > svg {
    fill: ${({ isActive }): string => (isActive ? '#27edc7' : '#b0b0b0')};
  }
`

const ToggleContent = styled.div<TSFixMe>`
  display: flex;
  gap: 4px;
`

const NavItemContent = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }): string => (isOpen ? 'auto' : '0')};
  overflow: hidden;
`

export const NavItem = ({ children, isActive, toggle, isToggleChildrenEnabled }: INavItemProps): ReactElement => {
  const [isOpen, setOpen] = useState(false)

  if (!toggle) {
    return <BaseNavItem>{children}</BaseNavItem>
  }

  const toggleSubItems = (): void => {
    setOpen(!isOpen)
  }

  return (
    <BaseNavItem>
      <NavItemToggle onClick={toggleSubItems}>
        <ToggleWrapper isActive={isActive} isOpen={isOpen} isToggleChildrenEnabled={isToggleChildrenEnabled}>
          <ToggleContent>{toggle}</ToggleContent>
          {isToggleChildrenEnabled && <StyledAngleDownIcon />}
        </ToggleWrapper>
      </NavItemToggle>
      <NavItemContent isOpen={isOpen && Boolean(isToggleChildrenEnabled)}>{children}</NavItemContent>
    </BaseNavItem>
  )
}
