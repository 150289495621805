import styled, { css } from 'styled-components'

const baseTypography = css`
  color: #28265d;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
`

const Caption = styled.span`
  ${baseTypography}
  font-size: 12px;
`

const Body = styled.span<{ bold?: boolean }>`
  ${baseTypography}
  font-size: 16px;
  font-weight: ${({ bold }): number | string => (bold ? 600 : 'normal')};
`

const Label = styled.span`
  ${baseTypography}
  color: #717171;
  font-size: 14px;
  text-transform: uppercase;
`

const Error = styled.span`
  ${baseTypography}
  color: #ff627b;
  font-size: 16px;
`

const H5 = styled.h5`
  ${baseTypography}
  font-size: 24px;
`

const H4 = styled.h4<{ small?: boolean }>`
  ${baseTypography}
  font-size: ${({ small }): string => (small ? '20px' : '22px')};
  font-weight: 500;
`

const H3 = styled.h3<{ capitalize?: boolean }>`
  ${baseTypography}
  font-size: 24px;
  font-weight: 500;
  text-transform: ${({ capitalize }): string => (capitalize ? 'capitalize' : 'none')};
`

const H2 = styled.h2`
  ${baseTypography}
  font-size: 40px;
  font-weight: 500;
`

const H1 = styled.h1`
  ${baseTypography}
  font-size: 48px;
  font-weight: 700;
`

export default {
  Caption,
  Body,
  Label,
  Error,
  H5,
  H4,
  H3,
  H2,
  H1
}
