import { min, max } from 'lodash'

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
}

/**
 * Generates a unique RGB color string based on the given color index.
 *
 * @param colorIndex - The index of the color to generate.
 * @returns A string representing the RGB color in the format `rgb(r, g, b)`.
 */
export const getUniqueColor = (colorIndex: number): string => {
  const rgb = [0, 0, 0]

  for (let i = 0; i < 24; i++) {
    rgb[i % 3] <<= 1
    rgb[i % 3] |= colorIndex & 0x01
    colorIndex >>= 1
  }

  return hexToRgb('#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, ''))
}

export const parseRgb = (rgb: string): number[] => {
  const regex = /rgb\((\d+), (\d+), (\d+)\)/
  const match = rgb.match(regex)

  if (!match) {
    throw new Error('Invalid RGB string')
  }

  return match.slice(1).map((value) => parseInt(value, 10))
}

export const componentToHex = (c: number): string => {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export const rgbToHex = (rgb: string): string => {
  const [r, g, b] = parseRgb(rgb)
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSL representation
 */
export const rgbToHsl = (rgb: string): number[] => {
  const [r, g, b] = parseRgb(rgb).map((value) => value / 255)

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h = 0,
    s = 0
  const l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  return [h, s, l]
}

/**
 * Adjusts the lightness of an RGB color by converting it to HSL, modifying the lightness,
 * and then returning the color as an HSL string.
 *
 * @param rgbColor - The RGB color string to be adjusted (e.g., "rgb(255, 0, 0)").
 * @param lightness - The amount of lightness to add to the color. The value should be between -1 and 1.
 * @returns The adjusted color as an HSL string (e.g., "hsl(0 100% 50%)").
 */
export const addLightToRgbColor = (rgbColor: string, lightness: number): string => {
  const hslRepresentation = rgbToHsl(rgbColor)
  const h = hslRepresentation[0]
  const s = hslRepresentation[1]
  const l = hslRepresentation[2]

  const lightnessValue = max([0, min([1, l + lightness])]) as number
  return `hsl(${h * 360} ${s * 100}% ${lightnessValue * 100}%)`
}

/**
 * Generates an RGBA color string by appending the specified opacity to a given RGB color string.
 *
 * @param rgbColor - The original color in RGB format (e.g. "rgb(255, 0, 0)").
 * @param opacity - A number between 0 (fully transparent) and 1 (fully opaque).
 * @returns The resulting color in RGBA format.
 */
export const addOpacityToRgbColor = (rgbColor: string, opacity: number): string => {
  const [r, g, b] = parseRgb(rgbColor)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const hexToRgb = (hex: string): string => {
  const hexValue = hex.replace('#', '')
  const r = parseInt(hexValue.substring(0, 2), 16)
  const g = parseInt(hexValue.substring(2, 4), 16)
  const b = parseInt(hexValue.substring(4, 6), 16)

  return `rgb(${r}, ${g}, ${b})`
}
