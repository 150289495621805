export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: any; output: any }
  Long: { input: any; output: any }
  Time: { input: any; output: any }
  Timezone: { input: any; output: any }
  UUID: { input: any; output: any }
  Upload: { input: any; output: any }
}

export type GTAccountInput = {
  kreditID: Scalars['UUID']['input']
  userAccountID: Scalars['UUID']['input']
}

export type GTActivateBusinessClientUserInput = {
  id: Scalars['UUID']['input']
}

export type GTActivateCampaignCouponInput = {
  coupon: GTActivateCouponInput
  /** campaign id */
  id: Scalars['ID']['input']
}

export type GTActivateCampaignInput = {
  id: Scalars['ID']['input']
}

export type GTActivateCouponInput = {
  /** coupon id */
  id: Scalars['ID']['input']
}

export type GTActivePeriod = {
  __typename?: 'ActivePeriod'
  endsAt: Scalars['String']['output']
  startsAt: Scalars['String']['output']
}

export type GTActivePeriodInput = {
  endsAt: Scalars['String']['input']
  startsAt: Scalars['String']['input']
}

export type GTAddBookingTicketsInsuranceInput = {
  /** booking id */
  id: Scalars['ID']['input']
  /** ticket IDs */
  ticketIDs: Array<Scalars['ID']['input']>
}

/** add campaign snack coupon input */
export type GTAddCampaignSnacksCouponInput = {
  /** campaign id */
  campaignID: Scalars['ID']['input']
  /** coupon code */
  code: Scalars['String']['input']
  /** constraints */
  constraints: GTSnackConstraintsInput
  /** discount type */
  discountType: GTDiscountType
  /** endsAt */
  endsAt: Scalars['String']['input']
  /** startsAt */
  startsAt: Scalars['String']['input']
  /** timezone */
  timezone: Scalars['String']['input']
  /** coupon value */
  value: Scalars['Int']['input']
}

/** add coupon input */
export type GTAddCouponInput = {
  /** campaign id */
  campaignID: Scalars['ID']['input']
  /** coupon promotion channels */
  channels: Array<GTCouponChannel>
  /** coupon code */
  code: Scalars['String']['input']
  /** constraints */
  constraints: GTConstraintsInput
  /** discount type */
  discountType: GTDiscountType
  /** endsAt */
  endsAt: Scalars['String']['input']
  /** startsAt */
  startsAt: Scalars['String']['input']
  /** timezone */
  timezone: Scalars['String']['input']
  /** coupon value */
  value: Scalars['Int']['input']
}

export type GTAddFareFamilyFaresInput = {
  fares: Array<GTFareFamilyFareInput>
  id: Scalars['ID']['input']
}

export type GTAddLoyaltyCampaignRuleConditionsCitySegmentsConditionsInput = {
  citySegments?: InputMaybe<Array<InputMaybe<GTChangeLoyaltyCampaignRuleConditionsCitySegmentInput>>>
}

export type GTAddLoyaltyCampaignRuleConditionsCitySegmentsInput = {
  id: Scalars['ID']['input']
  rule: GTAddLoyaltyCampaignRuleConditionsCitySegmentsRuleInput
}

export type GTAddLoyaltyCampaignRuleConditionsCitySegmentsRuleInput = {
  conditions: GTAddLoyaltyCampaignRuleConditionsCitySegmentsConditionsInput
  id: Scalars['ID']['input']
}

export type GTAddLoyaltyCampaignRuleConditionsRouteServicesConditionsInput = {
  routeIDs: Array<Scalars['UUID']['input']>
  routeServiceIDs: Array<Scalars['UUID']['input']>
}

export type GTAddLoyaltyCampaignRuleConditionsRouteServicesInput = {
  id: Scalars['UUID']['input']
  rule: GTAddLoyaltyCampaignRuleConditionsRouteServicesRuleInput
}

export type GTAddLoyaltyCampaignRuleConditionsRouteServicesRuleInput = {
  conditions: GTAddLoyaltyCampaignRuleConditionsRouteServicesConditionsInput
  id: Scalars['UUID']['input']
}

export type GTAddLoyaltyCampaignRuleConditionsSalesChannelsConditionsInput = {
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTAddLoyaltyCampaignRuleConditionsSalesChannelsInput = {
  id: Scalars['ID']['input']
  rule: GTAddLoyaltyCampaignRuleConditionsSalesChannelsRuleInput
}

export type GTAddLoyaltyCampaignRuleConditionsSalesChannelsRuleInput = {
  conditions: GTAddLoyaltyCampaignRuleConditionsSalesChannelsConditionsInput
  id: Scalars['ID']['input']
}

export type GTAddPartnerDocumentInput = {
  category: GTPartnerDocumentCategoryInput
  filePath: Scalars['String']['input']
}

export type GTAddPartnerDocumentsInput = {
  documents: Array<GTAddPartnerDocumentInput>
}

export type GTAddRouteServicePeaksInput = {
  id: Scalars['ID']['input']
  peaks: Array<GTRouteServicePeakInput>
}

export type GTAddServiceFeeAddonRuleFeeInput = {
  amount: Scalars['Int']['input']
  currencyCode: Scalars['String']['input']
}

export type GTAddServiceFeeAddonRuleInput = {
  companyID: Scalars['ID']['input']
  companyLineID: Scalars['ID']['input']
  confirmationModalities: Array<GTConfirmationModality>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  segmentIDs: Array<Scalars['ID']['input']>
  ticketFees: Array<GTAddServiceFeeAddonRuleTicketFeeInput>
}

export type GTAddServiceFeeAddonRuleTicketFeeInput = {
  fees: Array<GTAddServiceFeeAddonRuleFeeInput>
  quantity: Scalars['Int']['input']
}

export type GTAddTripNoteInput = {
  cashFlow?: InputMaybe<GTCashFlowInput>
  category: GTTripNoteCategoryInput
  filePaths?: InputMaybe<Array<Scalars['String']['input']>>
  files?: InputMaybe<Array<Scalars['Upload']['input']>>
  id: Scalars['UUID']['input']
  message: Scalars['String']['input']
}

/** address */
export type GTAddress = {
  __typename?: 'Address'
  /** city */
  city: Scalars['String']['output']
  /** country code in ISO_3166-1_alpha-2 format. */
  countryCode: Scalars['String']['output']
  /** line 1 */
  line1: Scalars['String']['output']
  /** line 2 */
  line2?: Maybe<Scalars['String']['output']>
  /** state code in ISO_3166-2:MX format. */
  stateCode: Scalars['String']['output']
  /** zip code */
  zipCode: Scalars['String']['output']
}

export const GTAmenity = {
  Ac: 'AC',
  Chair: 'CHAIR',
  Eticket: 'ETICKET',
  Excursion: 'EXCURSION',
  Guide: 'GUIDE',
  Light: 'LIGHT',
  Lunchbox: 'LUNCHBOX',
  Plug: 'PLUG',
  Tv: 'TV',
  Wc: 'WC',
  Wifi: 'WIFI'
} as const

export type GTAmenity = (typeof GTAmenity)[keyof typeof GTAmenity]
export type GTAmounts = {
  __typename?: 'Amounts'
  card: Scalars['Int']['output']
  cash: Scalars['Int']['output']
}

export type GTAncillariesInput = {
  types?: InputMaybe<Array<GTAncillaryTypeInput>>
}

export type GTAncillariesResponse = {
  __typename?: 'AncillariesResponse'
  items: Array<GTAncillary>
  total: Scalars['Int']['output']
}

export type GTAncillary = {
  __typename?: 'Ancillary'
  company: GTAncillaryCompany
  conditions?: Maybe<GTAncillaryConditions>
  id: Scalars['ID']['output']
  isEnabled: Scalars['Boolean']['output']
  label?: Maybe<Array<GTAncillaryTranslation>>
  name?: Maybe<Scalars['String']['output']>
  price?: Maybe<Scalars['Int']['output']>
  /** @deprecated use conditions.salesChannels */
  salesChannels: Array<GTSalesChannel>
  /** @deprecated use conditions.salesChannels */
  salesChannelsV2: Array<GTAncillarySalesChannel>
  type: GTAncillaryType
}

export type GTAncillaryCitySegment = {
  __typename?: 'AncillaryCitySegment'
  destination: GTAncillaryPlace
  origin: GTAncillaryPlace
  routeServices?: Maybe<Array<GTAncillaryRouteService>>
  segments: Array<GTAncillarySegment>
}

export type GTAncillaryCompany = {
  __typename?: 'AncillaryCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTAncillaryConditions = {
  __typename?: 'AncillaryConditions'
  citySegments?: Maybe<Array<GTAncillaryCitySegment>>
  salesChannels?: Maybe<Array<GTAncillarySalesChannel>>
  seatsPrice?: Maybe<GTAncillarySeatsPrice>
}

export type GTAncillaryConditionsCitySegmentInput = {
  destinationID: Scalars['ID']['input']
  originID: Scalars['ID']['input']
  routeServiceIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  segmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTAncillaryConditionsInput = {
  citySegments?: InputMaybe<Array<GTAncillaryConditionsCitySegmentInput>>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  seatsPrice?: InputMaybe<GTAncillarySeatsPriceInput>
}

export type GTAncillaryCountry = {
  __typename?: 'AncillaryCountry'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTAncillaryInput = {
  id: Scalars['ID']['input']
}

export type GTAncillaryPlace = {
  __typename?: 'AncillaryPlace'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTAncillaryRouteService = {
  __typename?: 'AncillaryRouteService'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type GTAncillarySalesChannel = {
  __typename?: 'AncillarySalesChannel'
  country: GTAncillaryCountry
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTAncillarySeatRangePrice = {
  __typename?: 'AncillarySeatRangePrice'
  fromLabel: Scalars['String']['output']
  price: Scalars['Int']['output']
  toLabel: Scalars['String']['output']
}

export type GTAncillarySeatRangePriceInput = {
  fromLabel: Scalars['String']['input']
  price: Scalars['Int']['input']
  toLabel: Scalars['String']['input']
}

export type GTAncillarySeatsPrice = {
  __typename?: 'AncillarySeatsPrice'
  doubleDecker?: Maybe<Array<GTAncillarySeatRangePrice>>
  standard?: Maybe<Array<GTAncillarySeatRangePrice>>
}

export type GTAncillarySeatsPriceInput = {
  doubleDecker?: InputMaybe<Array<GTAncillarySeatRangePriceInput>>
  standard?: InputMaybe<Array<GTAncillarySeatRangePriceInput>>
}

export type GTAncillarySegment = {
  __typename?: 'AncillarySegment'
  destination: GTAncillaryPlace
  id: Scalars['ID']['output']
  origin: GTAncillaryPlace
}

export const GTAncillaryStatus = {
  CancellationFailed: 'cancellationFailed',
  Cancelled: 'cancelled',
  Confirmed: 'confirmed',
  CreationFailed: 'creationFailed',
  EditFailed: 'editFailed',
  Expired: 'expired',
  Failed: 'failed',
  Reserved: 'reserved',
  UpdateFailed: 'updateFailed'
} as const

export type GTAncillaryStatus = (typeof GTAncillaryStatus)[keyof typeof GTAncillaryStatus]
export const GTAncillaryStatusInput = {
  Cancellationfailed: 'CANCELLATIONFAILED',
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED',
  Creationfailed: 'CREATIONFAILED',
  Expired: 'EXPIRED',
  Failed: 'FAILED',
  Reserved: 'RESERVED',
  Updatefailed: 'UPDATEFAILED'
} as const

export type GTAncillaryStatusInput = (typeof GTAncillaryStatusInput)[keyof typeof GTAncillaryStatusInput]
export const GTAncillaryStatusV2 = {
  Cancellationfailed: 'CANCELLATIONFAILED',
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED',
  Creationfailed: 'CREATIONFAILED',
  Expired: 'EXPIRED',
  Failed: 'FAILED',
  Reserved: 'RESERVED',
  Updatefailed: 'UPDATEFAILED'
} as const

export type GTAncillaryStatusV2 = (typeof GTAncillaryStatusV2)[keyof typeof GTAncillaryStatusV2]
export type GTAncillaryTranslation = {
  __typename?: 'AncillaryTranslation'
  language: GTLanguage
  text: Scalars['String']['output']
}

export type GTAncillaryTranslationInput = {
  language: GTLanguage
  text: Scalars['String']['input']
}

export const GTAncillaryType = {
  CarryOn: 'CARRY_ON',
  Insurance: 'INSURANCE',
  Luggage: 'LUGGAGE',
  Seat: 'SEAT',
  ServiceFee: 'SERVICE_FEE'
} as const

export type GTAncillaryType = (typeof GTAncillaryType)[keyof typeof GTAncillaryType]
export const GTAncillaryTypeInput = {
  CarryOn: 'carryOn',
  Insurance: 'insurance',
  Luggage: 'luggage',
  Seat: 'seat',
  ServiceFee: 'serviceFee'
} as const

export type GTAncillaryTypeInput = (typeof GTAncillaryTypeInput)[keyof typeof GTAncillaryTypeInput]
export type GTApplyBookingCouponCouponResponse = {
  __typename?: 'ApplyBookingCouponCouponResponse'
  discountType: Scalars['String']['output']
  id: Scalars['ID']['output']
  tickets: Array<GTApplyBookingCouponCouponResponseTicket>
  value: Scalars['Int']['output']
}

export type GTApplyBookingCouponCouponResponseTicket = {
  __typename?: 'ApplyBookingCouponCouponResponseTicket'
  discount: Scalars['Int']['output']
  id: Scalars['ID']['output']
}

export type GTApplyBookingCouponInput = {
  /** coupon code */
  code: Scalars['String']['input']
  /** booking id */
  id: Scalars['ID']['input']
}

export type GTApplyBookingCouponResponse = {
  __typename?: 'ApplyBookingCouponResponse'
  coupon: GTApplyBookingCouponCouponResponse
  discount: Scalars['Int']['output']
  subtotal: Scalars['Int']['output']
  vat: Scalars['Int']['output']
}

export type GTArchiveInventoryInput = {
  id: Scalars['ID']['input']
}

export type GTArchiveRouteServiceItineraryScheduleInput = {
  /** route service id */
  id: Scalars['ID']['input']
  /** itinerary schedule ids */
  itineraryScheduleID: Scalars['ID']['input']
}

export type GTAsset = {
  __typename?: 'Asset'
  name: Scalars['String']['output']
  path: Scalars['String']['output']
}

export type GTAssignBusinessCustomerKreditCategoryInput = {
  category: Scalars['Int']['input']
  id: Scalars['UUID']['input']
}

export type GTAssignBusinessCustomersKreditCategoryInput = {
  category: Scalars['Int']['input']
  ids: Array<Scalars['UUID']['input']>
}

export type GTAvailableSeats = {
  __typename?: 'AvailableSeats'
  adult: Scalars['Int']['output']
  child: Scalars['Int']['output']
  senior: Scalars['Int']['output']
}

/** booking */
export type GTBooking = {
  __typename?: 'Booking'
  /** business client */
  businessClient?: Maybe<GTBookingBusinessClient>
  /** buyer */
  buyer?: Maybe<GTBookingBuyer>
  /** booking trips check in scores */
  checkInScores: Array<GTBookingTripCheckInScore>
  /** client */
  client?: Maybe<GTClient>
  /** time booking was paid */
  confirmedAt?: Maybe<Scalars['Time']['output']>
  /** country */
  country: GTBookingCountry
  /** coupon */
  coupon?: Maybe<GTBookingCoupon>
  /** time booking was created */
  createdAt: Scalars['Time']['output']
  /** currency */
  currency: GTBookingCurrency
  /** ISO 4217 currency code */
  currencyCode: GTCurrencyCode
  /** departure trips */
  departureTrips: Array<GTBookingTrip>
  /** emails */
  emails: Array<GTEmail>
  /** booking id */
  id: Scalars['ID']['output']
  /** is home pickup */
  isHomePickup: Scalars['Boolean']['output']
  /** is tax invoiced (is true if all the payments are tax invoiced) */
  isTaxInvoiced: Scalars['Boolean']['output']
  /** is invoiced by user account (only present if isTaxInvoiced is true) */
  isTaxInvoicedByUserAccount?: Maybe<Scalars['Boolean']['output']>
  /** passengers */
  passengers: Array<GTBookingPassenger>
  /** payments */
  payments: Array<GTBookingPayment>
  /** refunds */
  refunds: Array<GTBookingRefund>
  /** return trips */
  returnTrips: Array<GTBookingTrip>
  /**
   * sales channel
   * @deprecated Use salesChannelV2 instead
   */
  salesChannel?: Maybe<GTSalesChannel>
  /** sales channel type */
  salesChannelType?: Maybe<GTSalesChannelType>
  /** sales channel v2 */
  salesChannelV2?: Maybe<GTBookingSalesChannel>
  /** status */
  status: Scalars['String']['output']
  /** support interactions */
  supportInteractions: Array<GTSupportInteraction>
  /** tickets */
  tickets: Array<GTBookingTicket>
  /** vertical */
  vertical: GTBookingVertical
}

export type GTBookingAncillary = {
  __typename?: 'BookingAncillary'
  externalID?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** @deprecated use type instead */
  label: Scalars['String']['output']
  /** @deprecated not used */
  paid: Scalars['Boolean']['output']
  /** @deprecated use total instead */
  price: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
  status: GTAncillaryStatus
  /** @deprecated Use total instead */
  subtotal: Scalars['Int']['output']
  total: Scalars['Int']['output']
  type: GTAncillaryType
  unitPrice: Scalars['Int']['output']
  /** @deprecated total includes VAT */
  vat: Scalars['Int']['output']
}

export type GTBookingBusinessClient = {
  __typename?: 'BookingBusinessClient'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** booking buyer */
export type GTBookingBuyer = {
  __typename?: 'BookingBuyer'
  /** buyer's email */
  email: Scalars['String']['output']
  /** buyer's first name */
  firstName: Scalars['String']['output']
  /** indicates wether the buyer is also a passenger or not */
  isPassenger: Scalars['Boolean']['output']
  /** buyer's last name */
  lastName: Scalars['String']['output']
  /** buyer's phone number */
  phone: Scalars['String']['output']
}

export type GTBookingClass = {
  __typename?: 'BookingClass'
  activatedAt?: Maybe<Scalars['Time']['output']>
  company: GTBookingClassCompany
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  priority: Scalars['Int']['output']
  promo: Scalars['Boolean']['output']
  updatedAt: Scalars['Time']['output']
}

export type GTBookingClassCompany = {
  __typename?: 'BookingClassCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTBookingCountry = {
  __typename?: 'BookingCountry'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type GTBookingCoupon = {
  __typename?: 'BookingCoupon'
  /** code */
  code: Scalars['String']['output']
  /** discount type */
  discountType: GTDiscountType
  /** coupon id */
  id: Scalars['ID']['output']
  /** coupon value */
  value: Scalars['Int']['output']
}

export type GTBookingCurrency = {
  __typename?: 'BookingCurrency'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export const GTBookingEmailStatus = {
  Blocked: 'BLOCKED',
  Bounce: 'BOUNCE',
  Deferred: 'DEFERRED',
  Delivered: 'DELIVERED',
  Dropped: 'DROPPED',
  Processed: 'PROCESSED',
  Sent: 'SENT'
} as const

export type GTBookingEmailStatus = (typeof GTBookingEmailStatus)[keyof typeof GTBookingEmailStatus]
export const GTBookingInvoicedBy = {
  Automatic: 'AUTOMATIC',
  Finance: 'FINANCE',
  User: 'USER'
} as const

export type GTBookingInvoicedBy = (typeof GTBookingInvoicedBy)[keyof typeof GTBookingInvoicedBy]
/** booking passenger */
export type GTBookingPassenger = {
  __typename?: 'BookingPassenger'
  /** passenger's first name */
  firstName: Scalars['String']['output']
  /** id */
  id: Scalars['ID']['output']
  /** passenger's last name */
  lastName: Scalars['String']['output']
  /** properties */
  properties: Array<GTBookingPassengerProperty>
  /**
   * passenger type
   * @deprecated Use typeV2 instead
   */
  type?: Maybe<GTPassengerType>
  /** passenger type */
  typeV2: GTBookingPassengerType
}

export type GTBookingPassengerProperty = {
  __typename?: 'BookingPassengerProperty'
  abbreviation: Scalars['String']['output']
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type GTBookingPassengerType = {
  __typename?: 'BookingPassengerType'
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
}

/** booking payment */
export type GTBookingPayment = {
  __typename?: 'BookingPayment'
  /** amount */
  amount: Scalars['Int']['output']
  /** auth code */
  authCode?: Maybe<Scalars['String']['output']>
  /** charge id */
  chargeID?: Maybe<Scalars['String']['output']>
  /** id */
  id: Scalars['String']['output']
  /** last 4 */
  last4?: Maybe<Scalars['String']['output']>
  /** payment method */
  method: Scalars['String']['output']
  /** payment list id */
  paymentListID: Scalars['ID']['output']
  /** payment processor */
  processor?: Maybe<Scalars['String']['output']>
  /** reason */
  reason: Scalars['String']['output']
  /** status */
  status: Scalars['String']['output']
  /** tickets */
  tickets: Array<GTPaymentTicket>
  /** user */
  user?: Maybe<GTPaymentUser>
}

export type GTBookingRefund = {
  __typename?: 'BookingRefund'
  amount: Scalars['Int']['output']
  method: Scalars['String']['output']
  paymentID: Scalars['ID']['output']
}

export type GTBookingSalesChannel = {
  __typename?: 'BookingSalesChannel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** seat */
export type GTBookingSeat = {
  __typename?: 'BookingSeat'
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
}

/** booking ticket */
export type GTBookingTicket = {
  __typename?: 'BookingTicket'
  /** ancillaries */
  ancillaries?: Maybe<Array<GTBookingAncillary>>
  /** cancellation source */
  cancellationSource?: Maybe<GTCancellationSource>
  /** checked in */
  checkedIn: Scalars['Boolean']['output']
  /** discount */
  discount: Scalars['Int']['output']
  /** ticket id */
  id: Scalars['ID']['output']
  /** passenger */
  passenger?: Maybe<GTBookingPassenger>
  /** payment */
  payment?: Maybe<GTTicketPayment>
  /** indicates if a reward was applied to this ticket */
  rewardApplied: Scalars['Boolean']['output']
  /** seat */
  seat?: Maybe<GTBookingSeat>
  /** service fee */
  serviceFee?: Maybe<GTServiceFee>
  /** status */
  status: GTTicketStatus
  /** subtotal */
  subtotal: Scalars['Int']['output']
  /** trip */
  trip?: Maybe<GTBookingTrip>
  /** VAT */
  vat: Scalars['Int']['output']
}

export const GTBookingTicketCancelationReason = {
  Fraud: 'FRAUD',
  NoShow: 'NO_SHOW',
  Operational: 'OPERATIONAL',
  Personal: 'PERSONAL',
  Test: 'TEST',
  TripChange: 'TRIP_CHANGE'
} as const

export type GTBookingTicketCancelationReason =
  (typeof GTBookingTicketCancelationReason)[keyof typeof GTBookingTicketCancelationReason]
/** booking trip */
export type GTBookingTrip = {
  __typename?: 'BookingTrip'
  /** company line */
  companyLine?: Maybe<GTBookingTripCompanyLine>
  /** departure at in UTC */
  departureAt: Scalars['Time']['output']
  /** trip id */
  id: Scalars['ID']['output']
  /** is active */
  isActive: Scalars['Boolean']['output']
  /** segment */
  segment: GTBookingTripSegment
  /** trip's origin timezone */
  timezone: Scalars['String']['output']
}

export type GTBookingTripCheckInScore = {
  __typename?: 'BookingTripCheckInScore'
  score: Scalars['Float']['output']
  tripID: Scalars['ID']['output']
}

/** booking trip city */
export type GTBookingTripCity = {
  __typename?: 'BookingTripCity'
  /** id */
  id: Scalars['ID']['output']
  /** city name */
  name: Scalars['String']['output']
  /** timezone */
  timezone: Scalars['String']['output']
}

/** booking trip company line */
export type GTBookingTripCompanyLine = {
  __typename?: 'BookingTripCompanyLine'
  /** company line name */
  name: Scalars['String']['output']
}

/** booking trip place */
export type GTBookingTripPlace = {
  __typename?: 'BookingTripPlace'
  /** city */
  city: GTBookingTripCity
  /** id */
  id: Scalars['ID']['output']
  /** place name */
  name: Scalars['String']['output']
}

export type GTBookingTripSegment = {
  __typename?: 'BookingTripSegment'
  /** arrival time in UTC */
  arrivalAt: Scalars['Time']['output']
  /** departure time in UTC */
  departureAt: Scalars['Time']['output']
  /** destination place */
  destination?: Maybe<GTBookingTripPlace>
  /** trip's fare */
  fare?: Maybe<GTBookingTripSegmentFare>
  /** segment id */
  id: Scalars['ID']['output']
  /** origin place */
  origin?: Maybe<GTBookingTripPlace>
}

/** booking trip place */
export type GTBookingTripSegmentFare = {
  __typename?: 'BookingTripSegmentFare'
  /** base price */
  basePrice: Scalars['Int']['output']
  /** id */
  id: Scalars['ID']['output']
}

export const GTBookingType = {
  OneWay: 'ONE_WAY',
  RoundTrip: 'ROUND_TRIP'
} as const

export type GTBookingType = (typeof GTBookingType)[keyof typeof GTBookingType]
export type GTBookingV2 = {
  __typename?: 'BookingV2'
  businessClient?: Maybe<GTBookingV2BusinessClient>
  buyer?: Maybe<GTBookingV2Buyer>
  client?: Maybe<GTBookingV2Client>
  confirmedAt?: Maybe<Scalars['Time']['output']>
  country: GTBookingV2Country
  coupon?: Maybe<GTBookingV2Coupon>
  createdAt: Scalars['Time']['output']
  currency: GTBookingV2Currency
  departureTrips: Array<GTBookingV2Trip>
  emailsSent: Array<GTBookingV2EmailSent>
  id: Scalars['UUID']['output']
  invoicedBy?: Maybe<GTBookingInvoicedBy>
  isHomePickup: Scalars['Boolean']['output']
  /** @deprecated Use invoicedBy instead */
  isInvoicedByUserAccount?: Maybe<Scalars['Boolean']['output']>
  isTaxInvoiceable: Scalars['Boolean']['output']
  isTaxInvoiced: Scalars['Boolean']['output']
  passengers: Array<GTBookingV2Passenger>
  payments: Array<GTBookingV2Payment>
  refunds: Array<GTBookingV2Refund>
  returnTrips: Array<GTBookingV2Trip>
  salesChannel: GTBookingV2SalesChannel
  status: GTBookingV2Status
  supportInteractions: Array<GTBookingV2SupportInteraction>
  tickets: Array<GTBookingV2Ticket>
  userAccount?: Maybe<GTBookingV2UserAccount>
  vertical: GTBookingV2Vertical
}

export type GTBookingV2BusinessClient = {
  __typename?: 'BookingV2BusinessClient'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBookingV2Buyer = {
  __typename?: 'BookingV2Buyer'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  isPassenger: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  phone: Scalars['String']['output']
}

export type GTBookingV2Client = {
  __typename?: 'BookingV2Client'
  os?: Maybe<Scalars['String']['output']>
  source: GTBookingV2ClientSource
  userAgent?: Maybe<Scalars['String']['output']>
}

export const GTBookingV2ClientSource = {
  Android: 'ANDROID',
  Ios: 'IOS',
  UrbvanAndroid: 'URBVAN_ANDROID',
  UrbvanIos: 'URBVAN_IOS',
  Web: 'WEB'
} as const

export type GTBookingV2ClientSource = (typeof GTBookingV2ClientSource)[keyof typeof GTBookingV2ClientSource]
export type GTBookingV2Country = {
  __typename?: 'BookingV2Country'
  code: Scalars['String']['output']
  id: Scalars['UUID']['output']
}

export type GTBookingV2Coupon = {
  __typename?: 'BookingV2Coupon'
  code: Scalars['String']['output']
  discountType: GTBookingV2CouponDiscountType
  id: Scalars['UUID']['output']
  value: Scalars['Int']['output']
}

export const GTBookingV2CouponDiscountType = {
  Amount: 'AMOUNT',
  Percentage: 'PERCENTAGE'
} as const

export type GTBookingV2CouponDiscountType =
  (typeof GTBookingV2CouponDiscountType)[keyof typeof GTBookingV2CouponDiscountType]
export type GTBookingV2Currency = {
  __typename?: 'BookingV2Currency'
  code: Scalars['String']['output']
  id: Scalars['UUID']['output']
}

export type GTBookingV2EmailSent = {
  __typename?: 'BookingV2EmailSent'
  address: Scalars['String']['output']
  id: Scalars['UUID']['output']
  reason?: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
  updatedAt: Scalars['Time']['output']
}

export type GTBookingV2Passenger = {
  __typename?: 'BookingV2Passenger'
  firstName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  lastName: Scalars['String']['output']
  properties: Array<GTBookingV2PassengerProperty>
  type: GTBookingV2PassengerType
}

export type GTBookingV2PassengerProperty = {
  __typename?: 'BookingV2PassengerProperty'
  abbreviation: Scalars['String']['output']
  id: Scalars['UUID']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type GTBookingV2PassengerType = {
  __typename?: 'BookingV2PassengerType'
  id: Scalars['UUID']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type GTBookingV2Payment = {
  __typename?: 'BookingV2Payment'
  amount: Scalars['Int']['output']
  authCode?: Maybe<Scalars['String']['output']>
  chargeID?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  last4?: Maybe<Scalars['String']['output']>
  method: GTBookingV2PaymentMethod
  paymentListID?: Maybe<Scalars['UUID']['output']>
  processor?: Maybe<GTBookingV2PaymentProcessor>
  reason: Scalars['String']['output']
  status: GTBookingV2PaymentStatus
  tickets: Array<GTBookingV2PaymentTicket>
  user?: Maybe<GTBookingV2PaymentUser>
}

export const GTBookingV2PaymentMethod = {
  AccountMoney: 'ACCOUNT_MONEY',
  Card: 'CARD',
  Cash: 'CASH',
  Coupon: 'COUPON',
  CreditCard: 'CREDIT_CARD',
  DebitCard: 'DEBIT_CARD',
  Kredit: 'KREDIT'
} as const

export type GTBookingV2PaymentMethod = (typeof GTBookingV2PaymentMethod)[keyof typeof GTBookingV2PaymentMethod]
export const GTBookingV2PaymentProcessor = {
  Clip: 'CLIP',
  Conekta: 'CONEKTA',
  Kredit: 'KREDIT',
  MercadoPago: 'MERCADO_PAGO',
  Stripe: 'STRIPE'
} as const

export type GTBookingV2PaymentProcessor = (typeof GTBookingV2PaymentProcessor)[keyof typeof GTBookingV2PaymentProcessor]
export const GTBookingV2PaymentStatus = {
  ChargedBack: 'CHARGED_BACK',
  Paid: 'PAID',
  PaymentDeclined: 'PAYMENT_DECLINED',
  PaymentFailed: 'PAYMENT_FAILED',
  Refunded: 'REFUNDED'
} as const

export type GTBookingV2PaymentStatus = (typeof GTBookingV2PaymentStatus)[keyof typeof GTBookingV2PaymentStatus]
export type GTBookingV2PaymentTicket = {
  __typename?: 'BookingV2PaymentTicket'
  discount: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  subtotal: Scalars['Int']['output']
  vat: Scalars['Int']['output']
}

export type GTBookingV2PaymentUser = {
  __typename?: 'BookingV2PaymentUser'
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

export type GTBookingV2Refund = {
  __typename?: 'BookingV2Refund'
  amount: Scalars['Int']['output']
  method: GTBookingV2RefundMethod
  paymentID: Scalars['UUID']['output']
}

export const GTBookingV2RefundMethod = {
  Card: 'CARD',
  Cash: 'CASH',
  Coupon: 'COUPON',
  CreditCard: 'CREDIT_CARD',
  DebitCard: 'DEBIT_CARD',
  Kredit: 'KREDIT'
} as const

export type GTBookingV2RefundMethod = (typeof GTBookingV2RefundMethod)[keyof typeof GTBookingV2RefundMethod]
export type GTBookingV2SalesChannel = {
  __typename?: 'BookingV2SalesChannel'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  type: GTBookingV2SalesChannelType
}

export const GTBookingV2SalesChannelType = {
  Offline: 'OFFLINE',
  Online: 'ONLINE'
} as const

export type GTBookingV2SalesChannelType = (typeof GTBookingV2SalesChannelType)[keyof typeof GTBookingV2SalesChannelType]
export const GTBookingV2Status = {
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED',
  Failed: 'FAILED',
  Incomplete: 'INCOMPLETE',
  Paid: 'PAID',
  PartiallyConfirmed: 'PARTIALLY_CONFIRMED',
  Reserved: 'RESERVED'
} as const

export type GTBookingV2Status = (typeof GTBookingV2Status)[keyof typeof GTBookingV2Status]
export type GTBookingV2SupportInteraction = {
  __typename?: 'BookingV2SupportInteraction'
  callStatus?: Maybe<GTBookingV2SupportInteractionCallStatus>
  createdAt: Scalars['Time']['output']
  notes?: Maybe<Scalars['String']['output']>
  notificationChannel?: Maybe<GTBookingV2SupportInteractionNotificationChannel>
  user: GTBookingV2SupportInteractionUser
}

export const GTBookingV2SupportInteractionCallStatus = {
  AttendanceConfirmed: 'ATTENDANCE_CONFIRMED',
  BusyNumber: 'BUSY_NUMBER',
  Cancellation: 'CANCELLATION',
  HungUp: 'HUNG_UP',
  NonExistentNumber: 'NON_EXISTENT_NUMBER',
  ServiceChange: 'SERVICE_CHANGE',
  UnavailableNumber: 'UNAVAILABLE_NUMBER',
  Voicemail: 'VOICEMAIL',
  WrongNumber: 'WRONG_NUMBER'
} as const

export type GTBookingV2SupportInteractionCallStatus =
  (typeof GTBookingV2SupportInteractionCallStatus)[keyof typeof GTBookingV2SupportInteractionCallStatus]
export const GTBookingV2SupportInteractionNotificationChannel = {
  Email: 'EMAIL',
  Phone: 'PHONE',
  SocialMedia: 'SOCIAL_MEDIA',
  Whatsapp: 'WHATSAPP'
} as const

export type GTBookingV2SupportInteractionNotificationChannel =
  (typeof GTBookingV2SupportInteractionNotificationChannel)[keyof typeof GTBookingV2SupportInteractionNotificationChannel]
export type GTBookingV2SupportInteractionUser = {
  __typename?: 'BookingV2SupportInteractionUser'
  username: Scalars['String']['output']
}

export type GTBookingV2Ticket = {
  __typename?: 'BookingV2Ticket'
  ancillaries?: Maybe<Array<GTBookingV2TicketAncillary>>
  cancellationSource?: Maybe<GTBookingV2TicketCancellationSource>
  discount: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  isCheckedIn: Scalars['Boolean']['output']
  isRewardApplied: Scalars['Boolean']['output']
  passenger?: Maybe<GTBookingV2Passenger>
  seat?: Maybe<GTBookingV2TicketSeat>
  serviceFee?: Maybe<GTBookingV2TicketServiceFee>
  status: GTBookingV2TicketStatus
  subtotal: Scalars['Int']['output']
  trip?: Maybe<GTBookingV2Trip>
  vat: Scalars['Int']['output']
}

export type GTBookingV2TicketAncillary = {
  __typename?: 'BookingV2TicketAncillary'
  externalID?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  quantity: Scalars['Int']['output']
  status: GTBookingV2TicketAncillaryStatus
  total: Scalars['Int']['output']
  type: GTBookingV2TicketAncillaryType
  unitPrice: Scalars['Int']['output']
}

export const GTBookingV2TicketAncillaryStatus = {
  CancellationFailed: 'CANCELLATION_FAILED',
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED',
  CreationFailed: 'CREATION_FAILED',
  Reserved: 'RESERVED',
  UpdateFailed: 'UPDATE_FAILED'
} as const

export type GTBookingV2TicketAncillaryStatus =
  (typeof GTBookingV2TicketAncillaryStatus)[keyof typeof GTBookingV2TicketAncillaryStatus]
export const GTBookingV2TicketAncillaryType = {
  CarryOn: 'CARRY_ON',
  Insurance: 'INSURANCE',
  Luggage: 'LUGGAGE',
  Seat: 'SEAT',
  ServiceFee: 'SERVICE_FEE'
} as const

export type GTBookingV2TicketAncillaryType =
  (typeof GTBookingV2TicketAncillaryType)[keyof typeof GTBookingV2TicketAncillaryType]
export const GTBookingV2TicketCancellationSource = {
  Admin: 'ADMIN',
  UserAccount: 'USER_ACCOUNT'
} as const

export type GTBookingV2TicketCancellationSource =
  (typeof GTBookingV2TicketCancellationSource)[keyof typeof GTBookingV2TicketCancellationSource]
export type GTBookingV2TicketSeat = {
  __typename?: 'BookingV2TicketSeat'
  id: Scalars['UUID']['output']
  label: Scalars['String']['output']
}

export type GTBookingV2TicketServiceFee = {
  __typename?: 'BookingV2TicketServiceFee'
  id: Scalars['UUID']['output']
  price: Scalars['Int']['output']
}

export const GTBookingV2TicketStatus = {
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED',
  Pending: 'PENDING'
} as const

export type GTBookingV2TicketStatus = (typeof GTBookingV2TicketStatus)[keyof typeof GTBookingV2TicketStatus]
export type GTBookingV2Trip = {
  __typename?: 'BookingV2Trip'
  checkInScore?: Maybe<Scalars['Float']['output']>
  companyLine: GTBookingV2TripCompanyLine
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
  segment: GTBookingV2TripSegment
}

export type GTBookingV2TripCompanyLine = {
  __typename?: 'BookingV2TripCompanyLine'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBookingV2TripSegment = {
  __typename?: 'BookingV2TripSegment'
  arrivalAt: Scalars['Time']['output']
  departureAt: Scalars['Time']['output']
  destination: GTBookingV2TripSegmentPlace
  fare?: Maybe<GTBookingV2TripSegmentFare>
  id: Scalars['UUID']['output']
  origin: GTBookingV2TripSegmentPlace
}

export type GTBookingV2TripSegmentFare = {
  __typename?: 'BookingV2TripSegmentFare'
  basePrice: Scalars['Int']['output']
  id: Scalars['UUID']['output']
}

export type GTBookingV2TripSegmentPlace = {
  __typename?: 'BookingV2TripSegmentPlace'
  city: GTBookingV2TripSegmentPlaceCity
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBookingV2TripSegmentPlaceCity = {
  __typename?: 'BookingV2TripSegmentPlaceCity'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  timezone: Scalars['Timezone']['output']
}

export type GTBookingV2UserAccount = {
  __typename?: 'BookingV2UserAccount'
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<GTPhoneNumber>
}

export const GTBookingV2Vertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTBookingV2Vertical = (typeof GTBookingV2Vertical)[keyof typeof GTBookingV2Vertical]
export const GTBookingVertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTBookingVertical = (typeof GTBookingVertical)[keyof typeof GTBookingVertical]
/** bookings dashboard data */
export type GTBookingsDashboardData = {
  __typename?: 'BookingsDashboardData'
  /** cancelled bookings */
  cancelled: GTBookingsDashboardDataMethodInfo
  /** confirmed bookings */
  confirmed: GTBookingsDashboardDataMethodInfo
  /** failed bookings */
  failed: GTBookingsDashboardDataMethodInfo
  /** partially confirmed bookings */
  partiallyConfirmed: GTBookingsDashboardDataMethodInfo
  /** reserved bookings */
  reserved: GTBookingsDashboardDataMethodInfo
}

export type GTBookingsDashboardDataInfo = {
  __typename?: 'BookingsDashboardDataInfo'
  /** total in cents */
  amount: Scalars['Long']['output']
  /** number of bookings */
  count: Scalars['Int']['output']
}

export type GTBookingsDashboardDataMethodInfo = {
  __typename?: 'BookingsDashboardDataMethodInfo'
  /** bookings made offline */
  offline: GTBookingsDashboardDataInfo
  /** bookings made online */
  online: GTBookingsDashboardDataInfo
}

/** bookings dashboard response */
export type GTBookingsDashboardResponse = {
  __typename?: 'BookingsDashboardResponse'
  /** data for the requested day */
  day: GTBookingsDashboardData
  /** data for the requested month */
  month: GTBookingsDashboardData
}

/** paginated bookings response */
export type GTBookingsResponse = {
  __typename?: 'BookingsResponse'
  /** bookings */
  items: Array<GTBooking>
  /** total of bookings */
  total: Scalars['Int']['output']
}

export const GTBookingsV2BuyerEmailStatusInput = {
  Bounce: 'BOUNCE',
  Deferred: 'DEFERRED',
  Delivered: 'DELIVERED',
  Dropped: 'DROPPED',
  Open: 'OPEN',
  Processed: 'PROCESSED',
  Sent: 'SENT'
} as const

export type GTBookingsV2BuyerEmailStatusInput =
  (typeof GTBookingsV2BuyerEmailStatusInput)[keyof typeof GTBookingsV2BuyerEmailStatusInput]
export type GTBookingsV2Input = {
  buyerEmail?: InputMaybe<Scalars['String']['input']>
  buyerEmailStatus?: InputMaybe<GTBookingsV2BuyerEmailStatusInput>
  buyerPhone?: InputMaybe<Scalars['String']['input']>
  companyLineID?: InputMaybe<Scalars['UUID']['input']>
  confirmedAtRange?: InputMaybe<GTBookingsV2TimeRangeInput>
  couponCode?: InputMaybe<Scalars['String']['input']>
  createdAtRange?: InputMaybe<GTBookingsV2TimeRangeInput>
  departureAtRange?: InputMaybe<GTBookingsV2TimeRangeInput>
  destinationID?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  originID?: InputMaybe<Scalars['UUID']['input']>
  routeServiceIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  salesChannelID?: InputMaybe<Scalars['UUID']['input']>
  shortIDs?: InputMaybe<Array<Scalars['String']['input']>>
  siteID?: InputMaybe<Scalars['UUID']['input']>
  statuses?: InputMaybe<Array<GTBookingV2Status>>
}

export type GTBookingsV2Response = {
  __typename?: 'BookingsV2Response'
  items: Array<GTBookingV2>
  total: Scalars['Int']['output']
}

export type GTBookingsV2TimeRangeInput = {
  endAt?: InputMaybe<Scalars['Time']['input']>
  startAt?: InputMaybe<Scalars['Time']['input']>
}

export type GTBusinessClient = {
  __typename?: 'BusinessClient'
  companies: Array<GTBusinessClientCompany>
  contacts: Array<GTBusinessClientContact>
  createdAt: Scalars['Time']['output']
  emailDomains: Array<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
  kamUser?: Maybe<GTBusinessClientKamUser>
  kreditsSettings?: Maybe<GTKreditsSettings>
  logo?: Maybe<GTAsset>
  name: Scalars['String']['output']
  numberOfCustomers: Scalars['Int']['output']
  numberOfSites: Scalars['Int']['output']
  shareCustomersData: Scalars['Boolean']['output']
  updatedAt: Scalars['Time']['output']
}

export type GTBusinessClientAutocomplete = {
  __typename?: 'BusinessClientAutocomplete'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  verticals: Array<GTBusinessClientVertical>
}

export type GTBusinessClientCompany = {
  __typename?: 'BusinessClientCompany'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  vertical: GTBusinessClientVertical
}

export type GTBusinessClientContact = {
  __typename?: 'BusinessClientContact'
  company?: Maybe<GTBusinessClientCompany>
  email: Scalars['String']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  phone: Scalars['String']['output']
  secondaryEmail?: Maybe<Scalars['String']['output']>
  secondaryPhone?: Maybe<Scalars['String']['output']>
}

export type GTBusinessClientKamUser = {
  __typename?: 'BusinessClientKamUser'
  firstName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  lastName: Scalars['String']['output']
}

export type GTBusinessClientSuggestion = {
  __typename?: 'BusinessClientSuggestion'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  verticals: Array<Scalars['String']['output']>
}

export type GTBusinessClientSuggestionsInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTBusinessClientSuggestionsResponse = {
  __typename?: 'BusinessClientSuggestionsResponse'
  items: Array<GTBusinessClientSuggestion>
  total: Scalars['Long']['output']
}

export type GTBusinessClientUser = {
  __typename?: 'BusinessClientUser'
  businessClient: GTBusinessClientUserBusinessClient
  businessSite?: Maybe<GTBusinessClientUserBusinessSite>
  createdAt: Scalars['Time']['output']
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
  title: Scalars['String']['output']
  updatedAt: Scalars['Time']['output']
  userAccount: GTBusinessClientUserUserAccount
}

export type GTBusinessClientUserBusinessClient = {
  __typename?: 'BusinessClientUserBusinessClient'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessClientUserBusinessSite = {
  __typename?: 'BusinessClientUserBusinessSite'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessClientUserUserAccount = {
  __typename?: 'BusinessClientUserUserAccount'
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<GTBusinessClientUserUserAccountPhoneNumber>
}

export type GTBusinessClientUserUserAccountPhoneNumber = {
  __typename?: 'BusinessClientUserUserAccountPhoneNumber'
  countryCode: Scalars['String']['output']
  number: Scalars['String']['output']
}

export type GTBusinessClientUsersInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
}

export type GTBusinessClientUsersResponse = {
  __typename?: 'BusinessClientUsersResponse'
  items: Array<GTBusinessClientUser>
  total: Scalars['Int']['output']
}

export const GTBusinessClientVertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTBusinessClientVertical = (typeof GTBusinessClientVertical)[keyof typeof GTBusinessClientVertical]
export const GTBusinessClientVerticalInput = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTBusinessClientVerticalInput =
  (typeof GTBusinessClientVerticalInput)[keyof typeof GTBusinessClientVerticalInput]
export type GTBusinessClientsAutocompleteInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTBusinessClientsAutocompleteResponse = {
  __typename?: 'BusinessClientsAutocompleteResponse'
  items: Array<GTBusinessClientAutocomplete>
  total: Scalars['Int']['output']
}

export type GTBusinessClientsInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  vertical?: InputMaybe<GTBusinessClientVerticalInput>
}

export type GTBusinessClientsResponse = {
  __typename?: 'BusinessClientsResponse'
  items: Array<GTBusinessClient>
  total: Scalars['Int']['output']
}

export const GTBusinessClientsRouteServicesSchedulePriceDow = {
  Friday: 'FRIDAY',
  Monday: 'MONDAY',
  Saturday: 'SATURDAY',
  Sunday: 'SUNDAY',
  Thursday: 'THURSDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY'
} as const

export type GTBusinessClientsRouteServicesSchedulePriceDow =
  (typeof GTBusinessClientsRouteServicesSchedulePriceDow)[keyof typeof GTBusinessClientsRouteServicesSchedulePriceDow]
export type GTBusinessCustomer = {
  __typename?: 'BusinessCustomer'
  businessClient: GTBusinessCustomerClient
  businessSite?: Maybe<GTBusinessCustomerSite>
  hasHomePickupRoute: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  isEnabled: Scalars['Boolean']['output']
  kreditsSettings?: Maybe<GTBusinessCustomerKreditsSettings>
  place?: Maybe<GTBusinessCustomerPlace>
  qrCode: Scalars['String']['output']
  routeIDs?: Maybe<Array<Scalars['UUID']['output']>>
  type: GTBusinessCustomerType
  userAccount: GTBusinessCustomerUserAccount
}

export type GTBusinessCustomerCity = {
  __typename?: 'BusinessCustomerCity'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessCustomerClient = {
  __typename?: 'BusinessCustomerClient'
  id: Scalars['UUID']['output']
  isKreditEnabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  verticals: Array<GTBusinessClientVertical>
}

export type GTBusinessCustomerInput = {
  id: Scalars['UUID']['input']
}

export type GTBusinessCustomerKreditsSettings = {
  __typename?: 'BusinessCustomerKreditsSettings'
  amount: Scalars['Int']['output']
  category?: Maybe<Scalars['Int']['output']>
}

export type GTBusinessCustomerPlace = {
  __typename?: 'BusinessCustomerPlace'
  address?: Maybe<GTBusinessCustomerPlaceAddress>
  id: Scalars['UUID']['output']
  parent: GTBusinessCustomerCity
}

export type GTBusinessCustomerPlaceAddress = {
  __typename?: 'BusinessCustomerPlaceAddress'
  line1: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export type GTBusinessCustomerPlaceInput = {
  /** The business customer address */
  address: Scalars['String']['input']
  /** Coordinates for the place. If not provided, the coordinates will be fetched from the address */
  location?: InputMaybe<GTLocationInput>
  /** The ID of the city where this place is located */
  parentID: Scalars['UUID']['input']
  /** The business customer zip code */
  zipCode: Scalars['String']['input']
}

export type GTBusinessCustomerSite = {
  __typename?: 'BusinessCustomerSite'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessCustomerSuggestion = {
  __typename?: 'BusinessCustomerSuggestion'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessCustomerSuggestionsInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTBusinessCustomerSuggestionsResponse = {
  __typename?: 'BusinessCustomerSuggestionsResponse'
  items: Array<GTBusinessCustomerSuggestion>
  total: Scalars['Long']['output']
}

export const GTBusinessCustomerType = {
  Associate: 'ASSOCIATE'
} as const

export type GTBusinessCustomerType = (typeof GTBusinessCustomerType)[keyof typeof GTBusinessCustomerType]
export const GTBusinessCustomerTypeInput = {
  Associate: 'ASSOCIATE'
} as const

export type GTBusinessCustomerTypeInput = (typeof GTBusinessCustomerTypeInput)[keyof typeof GTBusinessCustomerTypeInput]
export type GTBusinessCustomerUserAccount = {
  __typename?: 'BusinessCustomerUserAccount'
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  /** @deprecated use FirstName and LastName */
  name: Scalars['String']['output']
  /** @deprecated use phoneNumber */
  phone?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<GTPhoneNumber>
}

export type GTBusinessCustomersInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessCustomerID?: InputMaybe<Scalars['String']['input']>
  businessSiteID?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<Scalars['Int']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>
  phoneNumber?: InputMaybe<GTBusinessCustomersPhoneNumberInput>
  userAccountID?: InputMaybe<Scalars['String']['input']>
}

export type GTBusinessCustomersPhoneNumberInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>
  number: Scalars['String']['input']
}

export type GTBusinessCustomersResponse = {
  __typename?: 'BusinessCustomersResponse'
  items: Array<GTBusinessCustomer>
  total: Scalars['Int']['output']
}

export type GTBusinessPartner = {
  __typename?: 'BusinessPartner'
  bankDetails?: Maybe<GTBusinessPartnerBankDetails>
  /** @deprecated Use `bankDetails` instead */
  bankName: Scalars['String']['output']
  /** @deprecated Use `taxInfo` instead */
  businessName?: Maybe<Scalars['String']['output']>
  city?: Maybe<GTBusinessPartnerCity>
  /** @deprecated Use `city` instead */
  cityName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use `bankDetails` instead */
  clabe: Scalars['String']['output']
  /** @deprecated Use `taxInfo` instead */
  companyName?: Maybe<Scalars['String']['output']>
  contacts: Array<GTBusinessPartnerContact>
  createdAt: Scalars['Time']['output']
  /** @deprecated Use `taxInfo` instead */
  curp?: Maybe<Scalars['String']['output']>
  documents: Array<GTBusinessPartnerDocument>
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
  kamUser?: Maybe<GTBusinessPartnerKamUser>
  name: Scalars['String']['output']
  numberOfVehicles: Scalars['Int']['output']
  operationalStateCodes?: Maybe<Array<Scalars['String']['output']>>
  /** @deprecated Use `taxInfo` instead */
  personType?: Maybe<GTBusinessPartnerTaxPersonType>
  /** @deprecated Use `taxInfo` instead */
  rfc: Scalars['String']['output']
  stateCode?: Maybe<Scalars['String']['output']>
  /** @deprecated Use `operationalStateCodes` instead */
  stateCodes?: Maybe<Array<Scalars['String']['output']>>
  taxInfo?: Maybe<GTBusinessPartnerTaxInformation>
  type: GTBusinessPartnerType
  updatedAt: Scalars['Time']['output']
  verticals: Array<GTBusinessPartnerVertical>
}

export type GTBusinessPartnerAutocomplete = {
  __typename?: 'BusinessPartnerAutocomplete'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  verticals: Array<GTBusinessPartnerVertical>
}

export type GTBusinessPartnerBankDetails = {
  __typename?: 'BusinessPartnerBankDetails'
  bankCoverLink?: Maybe<Scalars['String']['output']>
  bankName: Scalars['String']['output']
  clabe: Scalars['String']['output']
  paymentEmail: Scalars['String']['output']
  paymentType: GTBusinessPartnerBankPaymentType
}

export const GTBusinessPartnerBankPaymentType = {
  InterbankTransfer: 'INTERBANK_TRANSFER',
  Spei: 'SPEI',
  TransferWithinBank: 'TRANSFER_WITHIN_BANK'
} as const

export type GTBusinessPartnerBankPaymentType =
  (typeof GTBusinessPartnerBankPaymentType)[keyof typeof GTBusinessPartnerBankPaymentType]
export type GTBusinessPartnerCity = {
  __typename?: 'BusinessPartnerCity'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  stateCode: Scalars['String']['output']
}

export type GTBusinessPartnerContact = {
  __typename?: 'BusinessPartnerContact'
  email: Scalars['String']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  phone: Scalars['String']['output']
  secondaryEmail?: Maybe<Scalars['String']['output']>
  secondaryPhone?: Maybe<Scalars['String']['output']>
}

export type GTBusinessPartnerDocument = {
  __typename?: 'BusinessPartnerDocument'
  category: GTBusinessPartnerDocumentCategory
  filename: Scalars['String']['output']
  id: Scalars['UUID']['output']
  url: Scalars['String']['output']
}

export const GTBusinessPartnerDocumentCategory = {
  AccountStatementCover: 'ACCOUNT_STATEMENT_COVER',
  ComplianceOpinion: 'COMPLIANCE_OPINION',
  ConstitutiveAct: 'CONSTITUTIVE_ACT',
  Curp: 'CURP',
  OfficialId: 'OFFICIAL_ID',
  PowerOfAttorney: 'POWER_OF_ATTORNEY',
  ProofOfAddress: 'PROOF_OF_ADDRESS',
  Repse: 'REPSE',
  Rfc: 'RFC'
} as const

export type GTBusinessPartnerDocumentCategory =
  (typeof GTBusinessPartnerDocumentCategory)[keyof typeof GTBusinessPartnerDocumentCategory]
export type GTBusinessPartnerKamUser = {
  __typename?: 'BusinessPartnerKamUser'
  firstName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  lastName: Scalars['String']['output']
}

export type GTBusinessPartnerTaxInformation = {
  __typename?: 'BusinessPartnerTaxInformation'
  businessName?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  curp?: Maybe<Scalars['String']['output']>
  personType: GTBusinessPartnerTaxPersonType
  proofOfTaxSituation?: Maybe<Scalars['String']['output']>
  repseID?: Maybe<Scalars['String']['output']>
  repseLink?: Maybe<Scalars['String']['output']>
  rfc: Scalars['String']['output']
  taxRegime?: Maybe<Scalars['String']['output']>
}

export const GTBusinessPartnerTaxPersonType = {
  Individual: 'INDIVIDUAL',
  LegalEntity: 'LEGAL_ENTITY'
} as const

export type GTBusinessPartnerTaxPersonType =
  (typeof GTBusinessPartnerTaxPersonType)[keyof typeof GTBusinessPartnerTaxPersonType]
export const GTBusinessPartnerType = {
  Fleet: 'FLEET',
  Host: 'HOST'
} as const

export type GTBusinessPartnerType = (typeof GTBusinessPartnerType)[keyof typeof GTBusinessPartnerType]
export const GTBusinessPartnerVertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTBusinessPartnerVertical = (typeof GTBusinessPartnerVertical)[keyof typeof GTBusinessPartnerVertical]
export type GTBusinessPartnersAutocompleteInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTBusinessPartnersAutocompleteResponse = {
  __typename?: 'BusinessPartnersAutocompleteResponse'
  items: Array<GTBusinessPartnerAutocomplete>
  total: Scalars['Int']['output']
}

export type GTBusinessPartnersInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  kamUserId?: InputMaybe<Scalars['UUID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  paymentEmail?: InputMaybe<Scalars['String']['input']>
  rfc?: InputMaybe<Scalars['String']['input']>
  stateCodes?: InputMaybe<Array<Scalars['String']['input']>>
  taxPersonType?: InputMaybe<GTBusinessPartnerTaxPersonType>
}

export type GTBusinessPartnersResponse = {
  __typename?: 'BusinessPartnersResponse'
  items: Array<GTBusinessPartner>
  total: Scalars['Int']['output']
}

export type GTBusinessSite = {
  __typename?: 'BusinessSite'
  city?: Maybe<GTBusinessSiteCity>
  client: GTBusinessSiteClient
  contacts: Array<GTBusinessSiteContact>
  createdAt: Scalars['Time']['output']
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
  kreditsSettings?: Maybe<Array<GTKreditCategory>>
  name: Scalars['String']['output']
  updatedAt: Scalars['Time']['output']
}

export type GTBusinessSiteAutocomplete = {
  __typename?: 'BusinessSiteAutocomplete'
  clientID: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessSiteCity = {
  __typename?: 'BusinessSiteCity'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessSiteClient = {
  __typename?: 'BusinessSiteClient'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessSiteContact = {
  __typename?: 'BusinessSiteContact'
  email: Scalars['String']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  phone: Scalars['String']['output']
  secondaryEmail?: Maybe<Scalars['String']['output']>
  secondaryPhone?: Maybe<Scalars['String']['output']>
}

export type GTBusinessSiteSuggestion = {
  __typename?: 'BusinessSiteSuggestion'
  businessClient: GTBusinessSiteSuggestionBusinessClient
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessSiteSuggestionBusinessClient = {
  __typename?: 'BusinessSiteSuggestionBusinessClient'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTBusinessSiteSuggestionsInput = {
  businessClientIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTBusinessSiteSuggestionsResponse = {
  __typename?: 'BusinessSiteSuggestionsResponse'
  items: Array<GTBusinessSiteSuggestion>
  total: Scalars['Long']['output']
}

export type GTBusinessSitesAutocompleteInput = {
  clientID?: InputMaybe<Scalars['UUID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTBusinessSitesAutocompleteResponse = {
  __typename?: 'BusinessSitesAutocompleteResponse'
  items: Array<GTBusinessSiteAutocomplete>
  total: Scalars['Int']['output']
}

export type GTBusinessSitesInput = {
  clientID?: InputMaybe<Scalars['UUID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTBusinessSitesResponse = {
  __typename?: 'BusinessSitesResponse'
  items: Array<GTBusinessSite>
  total: Scalars['Int']['output']
}

export type GTBuyer = {
  __typename?: 'Buyer'
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
}

export type GTCampaign = {
  __typename?: 'Campaign'
  area?: Maybe<GTCampaignArea>
  company: GTCampaignCompany
  coupons: Array<GTCoupon>
  description: Scalars['String']['output']
  endsAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  startsAt: Scalars['String']['output']
  subCampaigns: Array<GTSubCampaign>
  template?: Maybe<GTCampaignTemplate>
  timezone: Scalars['String']['output']
  type: GTCampaignType
}

export const GTCampaignArea = {
  CustomerSupport: 'CUSTOMER_SUPPORT',
  Marketing: 'MARKETING',
  Mobile: 'MOBILE',
  Partners: 'PARTNERS',
  Sales: 'SALES',
  Snacks: 'SNACKS',
  Travel: 'TRAVEL'
} as const

export type GTCampaignArea = (typeof GTCampaignArea)[keyof typeof GTCampaignArea]
export type GTCampaignAutocomplete = {
  __typename?: 'CampaignAutocomplete'
  /** campaign id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

export type GTCampaignCompany = {
  __typename?: 'CampaignCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTCampaignCouponSupportInteractionInput = {
  callStatus?: InputMaybe<Scalars['String']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  notificationChannel?: InputMaybe<Scalars['String']['input']>
}

export type GTCampaignPlace = {
  __typename?: 'CampaignPlace'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTCampaignQueryInput = {
  id: Scalars['ID']['input']
}

export type GTCampaignSegment = {
  __typename?: 'CampaignSegment'
  destination: GTCampaignPlace
  id: Scalars['ID']['output']
  origin: GTCampaignPlace
}

export type GTCampaignTemplate = {
  __typename?: 'CampaignTemplate'
  confirmationModalities: GTCouponConfirmationModalities
  expirationTime: GTDuration
  prefix?: Maybe<Scalars['String']['output']>
  /** @deprecated Use salesChannelsV2 instead */
  salesChannels: Array<GTSalesChannel>
  salesChannelsV2: Array<GTCampaignTemplateSalesChannel>
  value: Scalars['Int']['output']
}

export type GTCampaignTemplateSalesChannel = {
  __typename?: 'CampaignTemplateSalesChannel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GTCampaignType = {
  GeneralCoupon: 'GENERAL_COUPON',
  Incidence: 'INCIDENCE',
  UniqueCouponForDatabase: 'UNIQUE_COUPON_FOR_DATABASE',
  CustomerSupport: 'customer_support',
  Marketing: 'marketing',
  Snacks: 'snacks'
} as const

export type GTCampaignType = (typeof GTCampaignType)[keyof typeof GTCampaignType]
export type GTCampaignsAutocompleteInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<GTCampaignType>>
}

export type GTCampaignsInput = {
  areas?: InputMaybe<Array<GTCampaignArea>>
  companyID?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  types?: InputMaybe<Array<GTCampaignType>>
}

export type GTCancelBookingTicketsInput = {
  /** comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** booking ID */
  id: Scalars['UUID']['input']
  /** payments, only required if cannot refund automatically because it was paid with cash or clip. */
  payments?: InputMaybe<Array<GTPaymentRefundInputV2>>
  /** cancelation reason */
  reason: GTBookingTicketCancelationReason
  /** refund destination. */
  refundDestination?: InputMaybe<GTRefundDestinationInput>
  /** ticket IDs */
  ticketIDs: Array<Scalars['UUID']['input']>
}

export type GTCancelBookingTicketsInsuranceInput = {
  /** booking id */
  id: Scalars['ID']['input']
  /** ticket IDs */
  ticketIDs: Array<Scalars['ID']['input']>
}

export type GTCancelBookingTripChangeProposalInput = {
  id: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
  tripChangeProposalID: Scalars['ID']['input']
}

export type GTCancelOrderInput = {
  id: Scalars['ID']['input']
}

export type GTCancelSaleItemsInput = {
  id: Scalars['ID']['input']
  lineItems: Array<GTCancelSaleItemsLineItemInput>
}

export type GTCancelSaleItemsLineItemInput = {
  id: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export const GTCancellationCategory = {
  BusinessClient: 'BUSINESS_CLIENT',
  Driver: 'DRIVER',
  ExternalConditions: 'EXTERNAL_CONDITIONS',
  Other: 'OTHER',
  Partner: 'PARTNER',
  Planning: 'PLANNING',
  Vehicle: 'VEHICLE'
} as const

export type GTCancellationCategory = (typeof GTCancellationCategory)[keyof typeof GTCancellationCategory]
export const GTCancellationReason = {
  Accident: 'accident',
  Maintenance: 'maintenance',
  MechanicalFailure: 'mechanicalFailure',
  Other: 'other',
  Planning: 'planning',
  RoadBlock: 'roadBlock'
} as const

export type GTCancellationReason = (typeof GTCancellationReason)[keyof typeof GTCancellationReason]
export const GTCancellationSource = {
  Admin: 'ADMIN',
  UserAccount: 'USER_ACCOUNT'
} as const

export type GTCancellationSource = (typeof GTCancellationSource)[keyof typeof GTCancellationSource]
export const GTCancellationSubcategory = {
  Absence: 'ABSENCE',
  Accident: 'ACCIDENT',
  Disability: 'DISABILITY',
  MechanicalFailure: 'MECHANICAL_FAILURE',
  Other: 'OTHER',
  Protest: 'PROTEST',
  Roadblock: 'ROADBLOCK',
  ScheduledMaintenance: 'SCHEDULED_MAINTENANCE',
  Security: 'SECURITY',
  Traffic: 'TRAFFIC',
  UnitStoppage: 'UNIT_STOPPAGE',
  Weather: 'WEATHER'
} as const

export type GTCancellationSubcategory = (typeof GTCancellationSubcategory)[keyof typeof GTCancellationSubcategory]
export type GTCart = {
  __typename?: 'Cart'
  companies: Array<GTCartCompany>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: Scalars['String']['output']
}

export type GTCartActivity = {
  __typename?: 'CartActivity'
  amount: Scalars['Int']['output']
  borrowedAt: Scalars['Time']['output']
  conciliationStatus?: Maybe<GTCartActivityConciliationStatus>
  id: Scalars['ID']['output']
  returnedAt?: Maybe<Scalars['Time']['output']>
  sales: Array<GTCartActivitySale>
  user: GTCartActivityUser
}

export const GTCartActivityConciliationStatus = {
  Completed: 'completed',
  Conflicting: 'conflicting'
} as const

export type GTCartActivityConciliationStatus =
  (typeof GTCartActivityConciliationStatus)[keyof typeof GTCartActivityConciliationStatus]
export type GTCartActivityResponse = {
  __typename?: 'CartActivityResponse'
  items: Array<GTCartActivity>
  total: Scalars['Int']['output']
}

export type GTCartActivitySale = {
  __typename?: 'CartActivitySale'
  amount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  status: GTSaleStatus
}

export type GTCartActivityUser = {
  __typename?: 'CartActivityUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type GTCartCompany = {
  __typename?: 'CartCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTCartInput = {
  name: Scalars['String']['input']
}

export type GTCartQueryInput = {
  id: Scalars['ID']['input']
}

export const GTCartStatus = {
  Available: 'AVAILABLE',
  Occupied: 'OCCUPIED'
} as const

export type GTCartStatus = (typeof GTCartStatus)[keyof typeof GTCartStatus]
export type GTCartsQueryInput = {
  name?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<GTCartStatus>
}

export type GTCartsResponse = {
  __typename?: 'CartsResponse'
  items: Array<GTCart>
  total: Scalars['Int']['output']
}

export type GTCashFlow = {
  __typename?: 'CashFlow'
  amount: Scalars['Int']['output']
  type: Scalars['String']['output']
}

export type GTCashFlowInput = {
  payments?: InputMaybe<Array<GTCashFlowPaymentInput>>
  type: GTCashFlowTypeInput
}

export type GTCashFlowPaymentInput = {
  amount: Scalars['Int']['input']
  last4?: InputMaybe<Scalars['String']['input']>
  method: GTPaymentIncidencePaymentMethodInput
}

export const GTCashFlowTypeInput = {
  Expense: 'EXPENSE',
  Income: 'INCOME'
} as const

export type GTCashFlowTypeInput = (typeof GTCashFlowTypeInput)[keyof typeof GTCashFlowTypeInput]
/** vehicle template cell component */
export const GTCellComponent = {
  Door: 'door',
  Driver: 'driver',
  Empty: 'empty',
  Seat: 'seat',
  Stair: 'stair',
  Wc: 'wc'
} as const

export type GTCellComponent = (typeof GTCellComponent)[keyof typeof GTCellComponent]
/** vehicle template cell component properties */
export const GTCellComponentProperty = {
  Handicap: 'handicap',
  Men: 'men',
  Plug: 'plug',
  Tv: 'tv',
  Women: 'women'
} as const

export type GTCellComponentProperty = (typeof GTCellComponentProperty)[keyof typeof GTCellComponentProperty]
export type GTChange = {
  __typename?: 'Change'
  bookings: Array<GTChangeBooking>
  difference: Array<GTChangeDifference>
  executeAt?: Maybe<Scalars['Time']['output']>
  id: Scalars['ID']['output']
  movedBookings: Array<GTChangeBooking>
  status?: Maybe<GTChangeStatus>
  statusChanges: Array<GTStatusChange>
  trips: Array<GTChangeTrip>
  type: GTChangeType
  user: GTChangeUser
}

export type GTChangeAncillaryInput = {
  conditions?: InputMaybe<GTAncillaryConditionsInput>
  id: Scalars['ID']['input']
  label?: InputMaybe<Array<GTAncillaryTranslationInput>>
  name?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Int']['input']>
}

export type GTChangeBooking = {
  __typename?: 'ChangeBooking'
  id: Scalars['ID']['output']
}

/** change passengers names input */
export type GTChangeBookingPassengersNamesInput = {
  /** booking id */
  bookingID: Scalars['ID']['input']
  /** ticket id */
  passengers: Array<GTChangeBookingPassengersNamesPassengerInput>
}

export type GTChangeBookingPassengersNamesPassengerInput = {
  /** first name */
  firstName: Scalars['String']['input']
  /** passenger id */
  id: Scalars['ID']['input']
  /** last name */
  lastName: Scalars['String']['input']
}

export type GTChangeBookingTicketsAncillariesInput = {
  id: Scalars['ID']['input']
  tickets: Array<GTChangeBookingTicketsAncillariesTicketInput>
}

export type GTChangeBookingTicketsAncillariesTicketAncillaryInput = {
  id: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type GTChangeBookingTicketsAncillariesTicketInput = {
  ancillaries: Array<GTChangeBookingTicketsAncillariesTicketAncillaryInput>
  id: Scalars['ID']['input']
}

export type GTChangeBusinessClientContactInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  email: Scalars['String']['input']
  id: Scalars['UUID']['input']
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  secondaryPhone?: InputMaybe<Scalars['String']['input']>
}

export type GTChangeBusinessClientContactsInput = {
  contacts: Array<GTChangeBusinessClientContactInput>
}

export type GTChangeBusinessClientCustomerKreditsAmountInput = {
  amount: Scalars['Int']['input']
  customerID: Scalars['UUID']['input']
  id: Scalars['UUID']['input']
}

export type GTChangeBusinessClientCustomersKreditsAmountInput = {
  amount: Scalars['Int']['input']
  customerIDs: Array<Scalars['UUID']['input']>
  id: Scalars['UUID']['input']
}

export type GTChangeBusinessClientInput = {
  companyIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  emailDomains?: InputMaybe<Array<Scalars['String']['input']>>
  kamUserID?: InputMaybe<Scalars['UUID']['input']>
  logoPath?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  shareCustomersData?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTChangeBusinessClientKreditSettingsInput = {
  categories: Array<GTKreditCategoryInput>
  id: Scalars['UUID']['input']
  siteIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type GTChangeBusinessClientSiteKreditSettingsInput = {
  categories: Array<GTKreditCategoryInput>
  id: Scalars['UUID']['input']
  siteID: Scalars['UUID']['input']
}

export type GTChangeBusinessClientUserInput = {
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  userAccountID: Scalars['UUID']['input']
}

export type GTChangeBusinessCustomerBusinessClientInput = {
  businessClientID: Scalars['UUID']['input']
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  id: Scalars['UUID']['input']
}

export type GTChangeBusinessCustomerBusinessSiteInput = {
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  id: Scalars['UUID']['input']
}

export type GTChangeBusinessSiteContactInput = {
  email: Scalars['String']['input']
  id: Scalars['UUID']['input']
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  secondaryPhone?: InputMaybe<Scalars['String']['input']>
}

export type GTChangeBusinessSiteContactsInput = {
  contacts: Array<GTChangeBusinessSiteContactInput>
}

export type GTChangeBusinessSiteInput = {
  cityID?: InputMaybe<Scalars['UUID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type GTChangeCartInput = {
  cart: GTCartInput
  id: Scalars['ID']['input']
}

export const GTChangeCategory = {
  Executed: 'executed',
  Scheduled: 'scheduled'
} as const

export type GTChangeCategory = (typeof GTChangeCategory)[keyof typeof GTChangeCategory]
export type GTChangeComboItemInput = {
  id: Scalars['ID']['input']
  item: GTComboItemInput
}

export type GTChangeCompanyInsuranceSettingsInput = {
  /** company id */
  companyID: Scalars['ID']['input']
  /** insurance settings */
  insuranceSettings: GTInsuranceSettingsInput
}

export type GTChangeCurrencyExchangeRateInput = {
  exchangeRate: Scalars['Float']['input']
  id: Scalars['ID']['input']
}

export type GTChangeDifference = {
  __typename?: 'ChangeDifference'
  itinerary?: Maybe<GTItineraryDifference>
  routeService: GTChangeRouteService
  segments?: Maybe<GTSegmentsDifference>
  vehicle?: Maybe<GTVehicleDifference>
}

export type GTChangeDriverDocumentsDocumentInput = {
  category: GTDriverDocumentCategoryInput
  filePath: Scalars['String']['input']
}

export type GTChangeDriverDocumentsInput = {
  documents: Array<GTChangeDriverDocumentsDocumentInput>
  driverID: Scalars['UUID']['input']
}

/** change fare rule */
export type GTChangeFareRuleInput = {
  /** activation at ranges */
  activationAtRanges?: InputMaybe<Array<GTFareRuleDateRangeInput>>
  /** city segments */
  citySegments?: InputMaybe<Array<GTFareRulecitySegmentInput>>
  /** departure at ranges */
  departureAtRanges?: InputMaybe<Array<GTFareRuleDateRangeInput>>
  /** description */
  description?: InputMaybe<Scalars['String']['input']>
  /** exceptions */
  exceptions?: InputMaybe<GTFareRuleExceptionsInput>
  /** fare rule id */
  id: Scalars['ID']['input']
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** price */
  price?: InputMaybe<Scalars['Int']['input']>
}

export type GTChangeInventoryInput = {
  id: Scalars['ID']['input']
  inventory: GTInventoryInput
}

/** change inventory rule cabin settings */
export type GTChangeInventoryRuleCabinStrategiesInput = {
  /** cabin strategies */
  cabinStrategies: Array<GTChangeInventoryRuleCabinStrategyInput>
  /** inventory rule id */
  id: Scalars['ID']['input']
}

/** change inventory rule cabin strategy */
export type GTChangeInventoryRuleCabinStrategyInput = {
  /** activation ranges */
  activationRanges?: InputMaybe<Array<GTInventoryRuleDaysToDepartureRangeInput>>
  /** cabin settings */
  cabinSettings: Array<GTInventoryRuleCabinSettingInput>
  /** id */
  id: Scalars['ID']['input']
}

/** change inventory rule */
export type GTChangeInventoryRuleInput = {
  /** activation at ranges */
  activationAtRanges?: InputMaybe<Array<GTInventoryRuleDateRangeInput>>
  /** city segments */
  citySegments?: InputMaybe<Array<GTInventoryRulecitySegmentInput>>
  /** departure at ranges */
  departureAtRanges?: InputMaybe<Array<GTInventoryRuleDateRangeInput>>
  /** description */
  description?: InputMaybe<Scalars['String']['input']>
  /** exceptions */
  exceptions?: InputMaybe<GTInventoryRuleExceptionsInput>
  /** inventory rule id */
  id: Scalars['ID']['input']
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
}

/** change item input */
export type GTChangeItemInput = {
  /** item id */
  id: Scalars['ID']['input']
  /** item */
  item: GTItemInput
}

export type GTChangeItemKolorsMobilePlanInput = {
  id: Scalars['ID']['input']
  item: GTItemKolorsMobilePlanInput
}

export type GTChangeLoyaltyCampaignInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  timeFrame?: InputMaybe<GTChangeLoyaltyCampaignTimeFrameInput>
}

export type GTChangeLoyaltyCampaignRuleConditionsCitySegmentInput = {
  destinationID: Scalars['ID']['input']
  originID: Scalars['ID']['input']
  segmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTChangeLoyaltyCampaignRuleConditionsInput = {
  citySegments?: InputMaybe<Array<InputMaybe<GTChangeLoyaltyCampaignRuleConditionsCitySegmentInput>>>
  routeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  routeServiceIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  totalBookingsValue?: InputMaybe<Scalars['Int']['input']>
}

export type GTChangeLoyaltyCampaignRuleDetailsInput = {
  description?: InputMaybe<Scalars['String']['input']>
  details?: InputMaybe<Scalars['String']['input']>
  howToGet?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  validAt?: InputMaybe<Scalars['String']['input']>
}

export type GTChangeLoyaltyCampaignRuleInput = {
  id: Scalars['ID']['input']
  rule: GTChangeLoyaltyCampaignRuleRuleInput
}

export type GTChangeLoyaltyCampaignRuleRuleInput = {
  conditions?: InputMaybe<GTChangeLoyaltyCampaignRuleConditionsInput>
  details?: InputMaybe<GTChangeLoyaltyCampaignRuleDetailsInput>
  id: Scalars['ID']['input']
}

export type GTChangeLoyaltyCampaignTimeFrameInput = {
  endAt?: InputMaybe<Scalars['Time']['input']>
  startAt?: InputMaybe<Scalars['Time']['input']>
}

export type GTChangeOrderInput = {
  destinationID?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  lineItems?: InputMaybe<Array<GTOrderLineItemInput>>
  purchaseID?: InputMaybe<Scalars['ID']['input']>
  supplier?: InputMaybe<Scalars['String']['input']>
}

export type GTChangePartnerBankDetailsInput = {
  bankCoverLink?: InputMaybe<Scalars['String']['input']>
  bankName: Scalars['String']['input']
  clabe: Scalars['String']['input']
  paymentEmail: Scalars['String']['input']
  paymentType: GTPartnerBankPaymentTypeInput
}

export type GTChangePartnerContactInput = {
  email: Scalars['String']['input']
  id: Scalars['UUID']['input']
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  secondaryPhone?: InputMaybe<Scalars['String']['input']>
}

export type GTChangePartnerContactsInput = {
  contacts: Array<GTChangePartnerContactInput>
}

export type GTChangePartnerDetailsInput = {
  cityID?: InputMaybe<Scalars['UUID']['input']>
  name: Scalars['String']['input']
  operationalStateCodes: Array<Scalars['String']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  type: GTPartnerTypeInput
  verticals: Array<GTPartnerVerticalInput>
}

export type GTChangePartnerKamUserInput = {
  kamUserID: Scalars['UUID']['input']
}

export type GTChangePartnerTaxInformationInput = {
  businessName?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  curp?: InputMaybe<Scalars['String']['input']>
  personType: GTPartnerTaxInformationPersonTypeInput
  proofOfTaxSituation?: InputMaybe<Scalars['String']['input']>
  repseID?: InputMaybe<Scalars['String']['input']>
  repseLink?: InputMaybe<Scalars['String']['input']>
  rfc: Scalars['String']['input']
  taxRegime?: InputMaybe<Scalars['String']['input']>
}

export type GTChangePassengerPropertyDateRangeInput = {
  endAt: Scalars['Time']['input']
  startAt: Scalars['Time']['input']
}

export type GTChangePassengerPropertyInput = {
  abbreviation?: InputMaybe<Array<GTChangePassengerPropertyTranslationInput>>
  departureDateRanges?: InputMaybe<Array<GTChangePassengerPropertyDateRangeInput>>
  description?: InputMaybe<Array<GTChangePassengerPropertyTranslationInput>>
  id: Scalars['ID']['input']
  label?: InputMaybe<Array<GTChangePassengerPropertyTranslationInput>>
  required?: InputMaybe<Scalars['Boolean']['input']>
  searchDateRanges?: InputMaybe<Array<GTChangePassengerPropertyDateRangeInput>>
  settings?: InputMaybe<Array<GTChangePassengerPropertySettingInput>>
}

export type GTChangePassengerPropertySettingInput = {
  discount: Scalars['Int']['input']
  passengerTypeIDs: Array<Scalars['ID']['input']>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTChangePassengerPropertyTranslationInput = {
  language: GTLanguage
  text: Scalars['String']['input']
}

export type GTChangePassengerTypeInput = {
  abbreviation?: InputMaybe<Array<GTChangePassengerTypeTranslationInput>>
  description?: InputMaybe<Array<GTChangePassengerTypeTranslationInput>>
  discount?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  label?: InputMaybe<Array<GTChangePassengerTypeTranslationInput>>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTChangePassengerTypeTranslationInput = {
  language: GTLanguage
  plural?: Scalars['Boolean']['input']
  text: Scalars['String']['input']
}

export type GTChangePlace = {
  __typename?: 'ChangePlace'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  timezone: Scalars['String']['output']
}

export type GTChangePlaceV2AddressInput = {
  cityName?: InputMaybe<Scalars['String']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  line1?: InputMaybe<Scalars['String']['input']>
  line2?: InputMaybe<Scalars['String']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}

export type GTChangePlaceV2CoordinatesInput = {
  latitude: Scalars['String']['input']
  longitude: Scalars['String']['input']
}

export type GTChangePlaceV2Input = {
  address?: InputMaybe<GTChangePlaceV2AddressInput>
  amenities?: InputMaybe<Array<GTPlaceV2AmenityInput>>
  area?: InputMaybe<Scalars['String']['input']>
  coordinates?: InputMaybe<GTChangePlaceV2CoordinatesInput>
  directions?: InputMaybe<Scalars['String']['input']>
  geofenceRadius?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  param?: InputMaybe<Scalars['String']['input']>
  photoPath?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  type?: InputMaybe<GTPlaceV2TypeInput>
}

export type GTChangeRouteService = {
  __typename?: 'ChangeRouteService'
  code: Scalars['String']['output']
  id: Scalars['String']['output']
}

export type GTChangeRouteServiceBusinessClientInput = {
  businessSiteIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  id: Scalars['ID']['input']
}

export type GTChangeRouteServiceItinerariesScheduleInput = {
  /** route service id */
  id: Scalars['ID']['input']
  /** itineraries schedule */
  itinerariesSchedule: Array<GTChangeRouteServiceItineraryScheduleInput>
}

export type GTChangeRouteServiceItineraryScheduleInput = {
  /** days of week */
  dow: Array<GTWeekday>
  /** end at */
  endAt?: InputMaybe<Scalars['Date']['input']>
  /** itinerary schedule id */
  id: Scalars['ID']['input']
  /** itinerary */
  itinerary: Array<GTChangeRouteServiceItineraryScheduleStopInput>
  /** notes */
  notes?: Array<Scalars['String']['input']>
  /** route id */
  routeID: Scalars['ID']['input']
  /** start at */
  startAt: Scalars['Date']['input']
}

export type GTChangeRouteServiceItineraryScheduleStopInput = {
  arrival?: InputMaybe<GTChangeRouteServiceItineraryScheduleStopTimeInput>
  departure?: InputMaybe<GTChangeRouteServiceItineraryScheduleStopTimeInput>
  placeID: Scalars['ID']['input']
}

export type GTChangeRouteServiceItineraryScheduleStopTimeInput = {
  day: Scalars['Int']['input']
  time: Scalars['String']['input']
}

export type GTChangeRouteServiceVehicleScheduleFrequencyInput = {
  unit: GTRouteServiceScheduleFrequencyUnit
  value: Scalars['Int']['input']
}

export type GTChangeRouteServiceVehicleScheduleInput = {
  /** days of week */
  dow?: InputMaybe<Array<GTWeekday>>
  /** driver id */
  driverID?: InputMaybe<Scalars['ID']['input']>
  /** end at */
  endAt?: InputMaybe<Scalars['Date']['input']>
  /** frequency */
  frequency?: InputMaybe<GTChangeRouteServiceVehicleScheduleFrequencyInput>
  /** vehicle schedule id */
  id: Scalars['ID']['input']
  /** start at */
  startAt: Scalars['Date']['input']
  /** vehicle id */
  vehicleID: Scalars['ID']['input']
}

export type GTChangeRouteServiceVehiclesScheduleInput = {
  /** route service id */
  id: Scalars['ID']['input']
  /** vehicles schedule */
  vehiclesSchedule: Array<GTChangeRouteServiceVehicleScheduleInput>
}

export type GTChangeSegment = {
  __typename?: 'ChangeSegment'
  destination: GTChangePlace
  id: Scalars['ID']['output']
  origin: GTChangePlace
}

export type GTChangeServiceFeeAddonRuleFeeInput = {
  amount: Scalars['Int']['input']
  currencyCode: Scalars['String']['input']
}

export type GTChangeServiceFeeAddonRuleInput = {
  companyID: Scalars['ID']['input']
  confirmationModalities?: InputMaybe<Array<GTConfirmationModality>>
  id: Scalars['ID']['input']
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  segmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  ticketFees?: InputMaybe<Array<GTChangeServiceFeeAddonRuleTicketFeeInput>>
}

export type GTChangeServiceFeeAddonRuleTicketFeeInput = {
  fees: Array<GTChangeServiceFeeAddonRuleFeeInput>
  quantity: Scalars['Int']['input']
}

export const GTChangeStatus = {
  Cancelled: 'cancelled',
  Completed: 'completed',
  Executed: 'executed',
  Failed: 'failed',
  Scheduled: 'scheduled',
  Started: 'started'
} as const

export type GTChangeStatus = (typeof GTChangeStatus)[keyof typeof GTChangeStatus]
export type GTChangeStop = {
  __typename?: 'ChangeStop'
  arrivalAt?: Maybe<Scalars['Time']['output']>
  departureAt?: Maybe<Scalars['Time']['output']>
  origin: GTChangePlace
}

export type GTChangeTDriverDetailsInput = {
  partnerID?: InputMaybe<Scalars['UUID']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
}

export type GTChangeTRouteInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type GTChangeTTripDriverInput = {
  comments?: InputMaybe<Scalars['String']['input']>
  driverID?: InputMaybe<Scalars['UUID']['input']>
  reason?: InputMaybe<GTChangeTripDriverReason>
}

export type GTChangeTVehicleCardsInput = {
  fuelCards?: InputMaybe<Array<Scalars['String']['input']>>
  tagCards?: InputMaybe<Array<Scalars['String']['input']>>
}

export type GTChangeTVehicleDetailsInput = {
  cityName?: InputMaybe<Scalars['String']['input']>
  companyIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  externalID?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  stateCode?: InputMaybe<Scalars['String']['input']>
  vin: Scalars['String']['input']
}

export type GTChangeTVehicleDocumentsDocumentInput = {
  category: GTVehicleDocumentCategoryInput
  filePath: Scalars['String']['input']
}

export type GTChangeTVehicleDocumentsInput = {
  documents: Array<GTChangeTVehicleDocumentsDocumentInput>
}

export type GTChangeTVehicleDriversInput = {
  driverIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type GTChangeTVehicleFeaturesInput = {
  amenities?: InputMaybe<Array<Scalars['String']['input']>>
  brandCoverage?: InputMaybe<Scalars['String']['input']>
  make?: InputMaybe<Scalars['String']['input']>
  model?: InputMaybe<Scalars['String']['input']>
  year?: InputMaybe<Scalars['Int']['input']>
}

export type GTChangeTVehicleInsurancePolicyInput = {
  expirationDate: Scalars['Date']['input']
  filePath?: InputMaybe<Scalars['String']['input']>
}

export type GTChangeTVehicleLicensePlatesInput = {
  licensePlates: Array<GTChangeTVehicleLicensePlatesLicensePlateInput>
}

export type GTChangeTVehicleLicensePlatesLicensePlateInput = {
  number: Scalars['String']['input']
  stateCode?: InputMaybe<Scalars['String']['input']>
  type: Scalars['String']['input']
}

export type GTChangeTVehicleOdometerReadingInput = {
  odometerReading: Scalars['Int']['input']
}

export type GTChangeTVehicleRegistrationDetailsInput = {
  category: GTVehicleRegistrationCategoryInput
  expirationDate?: InputMaybe<Scalars['Date']['input']>
  filePath?: InputMaybe<Scalars['String']['input']>
  isPermanent?: Scalars['Boolean']['input']
  stateCode?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<GTVehicleRegistrationTypeInput>
}

export type GTChangeTrip = {
  __typename?: 'ChangeTrip'
  departureAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
}

export type GTChangeTripConfirmationModalitiesConfirmationModalityExceptionsInput = {
  placeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type GTChangeTripConfirmationModalitiesConfirmationModalityInput = {
  exceptions: GTChangeTripConfirmationModalitiesConfirmationModalityExceptionsInput
  name: Scalars['String']['input']
  salesChannelIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type GTChangeTripConfirmationModalitiesInput = {
  confirmationModalities: Array<GTChangeTripConfirmationModalitiesConfirmationModalityInput>
  id: Scalars['UUID']['input']
}

export const GTChangeTripDriverReason = {
  BusinessClient: 'BUSINESS_CLIENT',
  Partner: 'PARTNER'
} as const

export type GTChangeTripDriverReason = (typeof GTChangeTripDriverReason)[keyof typeof GTChangeTripDriverReason]
/** change trip itinerary departure time input */
export type GTChangeTripItineraryDepartureTimeInput = {
  /** departure date */
  departureDate: Scalars['String']['input']
  /** trip ID */
  id: Scalars['ID']['input']
  /** itinerary */
  itinerary: Array<GTChangeTripItineraryDepartureTimeItineraryInput>
}

/** change trip itinerary departure time itinerary input */
export type GTChangeTripItineraryDepartureTimeItineraryInput = {
  /** arrival time */
  arrival?: InputMaybe<GTChangeTripItineraryDepartureTimeItineraryTimeInput>
  /** departure time */
  departure?: InputMaybe<GTChangeTripItineraryDepartureTimeItineraryTimeInput>
  /** place ID */
  placeID: Scalars['ID']['input']
}

/** change trip itinerary departure time itinerary time input */
export type GTChangeTripItineraryDepartureTimeItineraryTimeInput = {
  /** an integer representing how many days after departure the stop takes place. ex. 0 = same day, 1 = next day */
  day: Scalars['Int']['input']
  /** time in hh:mm format, in the timezone of the place */
  time: Scalars['String']['input']
}

/** change trip route input */
export type GTChangeTripRouteInput = {
  /** departure date */
  departureDate: Scalars['Date']['input']
  /** trip ID */
  id: Scalars['UUID']['input']
  /** itinerary */
  itinerary: Array<GTChangeTripRouteItineraryStopInput>
  /** place replacements */
  placeReplacements: Array<GTChangeTripRoutePlaceReplacementInput>
  /** route ID */
  routeID: Scalars['UUID']['input']
}

/** change trip route itinerary stop input */
export type GTChangeTripRouteItineraryStopInput = {
  /** arrival time */
  arrival?: InputMaybe<GTChangeTripRouteItineraryStopTimeInput>
  /** departure time */
  departure?: InputMaybe<GTChangeTripRouteItineraryStopTimeInput>
  /** place ID */
  placeID: Scalars['UUID']['input']
}

/** change trip route itinerary stop time input */
export type GTChangeTripRouteItineraryStopTimeInput = {
  /** an integer representing how many days after departure the stop takes place. ex. 0 = same day, 1 = next day */
  day: Scalars['Int']['input']
  /** time in hh:mm format, in the timezone of the place */
  time: Scalars['String']['input']
}

export type GTChangeTripRoutePlaceReplacementInput = {
  newPlaceID: Scalars['UUID']['input']
  oldPlaceID: Scalars['UUID']['input']
}

/** change crew users assigned to a trip input */
export type GTChangeTripUsersInput = {
  id: Scalars['ID']['input']
  userIDs: Array<Scalars['ID']['input']>
}

/** change trip vehicle input */
export type GTChangeTripVehicleInput = {
  /** comments for the change */
  comments?: InputMaybe<Scalars['String']['input']>
  /** trip ID */
  id: Scalars['ID']['input']
  /** reason for the change */
  reason: GTChangeTripVehicleReason
  /** vehicle ID */
  vehicleID: Scalars['ID']['input']
}

export const GTChangeTripVehicleReason = {
  BackupFixed: 'BACKUP_FIXED',
  BackupFlexible: 'BACKUP_FLEXIBLE',
  BusinessClient: 'BUSINESS_CLIENT',
  Partner: 'PARTNER',
  Uber: 'UBER'
} as const

export type GTChangeTripVehicleReason = (typeof GTChangeTripVehicleReason)[keyof typeof GTChangeTripVehicleReason]
/** change trips vehicle input */
export type GTChangeTripsVehicleInput = {
  /** comments for the change */
  comments?: InputMaybe<Scalars['String']['input']>
  /** reason for the change */
  reason: GTChangeTripVehicleReason
  /** trips ID */
  tripIDs: Array<Scalars['ID']['input']>
  /** vehicle ID */
  vehicleID: Scalars['ID']['input']
}

export const GTChangeType = {
  ChangeRouteServiceItinerary: 'ChangeRouteServiceItinerary',
  ChangeRouteServicesRoute: 'ChangeRouteServicesRoute',
  RouteServiceActivePeriodChanged: 'RouteServiceActivePeriodChanged',
  RouteServiceDeactivated: 'RouteServiceDeactivated',
  RouteServiceItineraryChanged: 'RouteServiceItineraryChanged',
  RouteServiceRouteChanged: 'RouteServiceRouteChanged',
  RouteServiceVehicleChanged: 'RouteServiceVehicleChanged',
  TripCancelled: 'TripCancelled',
  TripDeactivated: 'TripDeactivated',
  TripItineraryChanged: 'TripItineraryChanged',
  TripRouteChanged: 'TripRouteChanged',
  TripVehicleChanged: 'TripVehicleChanged'
} as const

export type GTChangeType = (typeof GTChangeType)[keyof typeof GTChangeType]
export type GTChangeUser = {
  __typename?: 'ChangeUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type GTChangeUserAccountDetailsInput = {
  /** User Account Email */
  email?: InputMaybe<Scalars['String']['input']>
  /** User Account First Name */
  firstName?: InputMaybe<Scalars['String']['input']>
  /** User Account Gender */
  gender?: InputMaybe<GTUserAccountGenderInput>
  /** User Account ID */
  id: Scalars['UUID']['input']
  /** User Account Last Name */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** User Account Phone Number */
  phoneNumber?: InputMaybe<GTPhoneNumberInput>
}

export type GTChangeUserPasswordInput = {
  password: Scalars['String']['input']
}

export type GTChangeVehicle = {
  __typename?: 'ChangeVehicle'
  capacity: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  partner: GTPartner
}

export type GTChangesResponse = {
  __typename?: 'ChangesResponse'
  items: Array<GTChange>
  total: Scalars['Int']['output']
}

export type GTCitySuggestion = {
  __typename?: 'CitySuggestion'
  countryCode?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  stateCode?: Maybe<Scalars['String']['output']>
}

export type GTCitySuggestionsInput = {
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  pattern?: InputMaybe<Scalars['String']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  vertical?: InputMaybe<Scalars['String']['input']>
}

export type GTCitySuggestionsResponse = {
  __typename?: 'CitySuggestionsResponse'
  items: Array<GTCitySuggestion>
  total: Scalars['Long']['output']
}

export const GTClass = {
  E: 'E',
  L: 'L',
  P: 'P'
} as const

export type GTClass = (typeof GTClass)[keyof typeof GTClass]
export type GTClient = {
  __typename?: 'Client'
  os?: Maybe<Scalars['String']['output']>
  source: GTSource
  userAgent?: Maybe<Scalars['String']['output']>
}

export type GTComboComponent = {
  __typename?: 'ComboComponent'
  eligibleItems: Array<GTItem>
  quantity: Scalars['Int']['output']
}

export type GTComboItem = {
  __typename?: 'ComboItem'
  category: Scalars['String']['output']
  components: Array<GTComboComponent>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  minSellingPrice?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  photoPath?: Maybe<Scalars['String']['output']>
  sellingPrice: Scalars['Int']['output']
  subcategory: Scalars['String']['output']
}

/** combo item autocomplete */
export type GTComboItemAutocomplete = {
  __typename?: 'ComboItemAutocomplete'
  /** category */
  category: Scalars['String']['output']
  /** item ID */
  id: Scalars['ID']['output']
  /** isActive */
  isActive: Scalars['Boolean']['output']
  /** name */
  name: Scalars['String']['output']
  /** subcategory */
  subcategory: Scalars['String']['output']
}

export type GTComboItemInput = {
  category?: InputMaybe<Scalars['String']['input']>
  components?: InputMaybe<Array<GTItemComboComponentInput>>
  minSellingPrice?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  photoPath?: InputMaybe<Scalars['String']['input']>
  sellingPrice?: InputMaybe<Scalars['Int']['input']>
  subcategory?: InputMaybe<Scalars['String']['input']>
}

export type GTComboItemQueryInput = {
  id: Scalars['ID']['input']
}

export type GTComboItemsAutocompleteInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type GTComboItemsQueryInput = {
  category?: InputMaybe<Scalars['String']['input']>
  companyID?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  subcategory?: InputMaybe<Scalars['String']['input']>
}

export type GTComboItemsResponse = {
  __typename?: 'ComboItemsResponse'
  items: Array<GTComboItem>
  total: Scalars['Int']['output']
}

export type GTCompaniesAutocompleteInput = {
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type GTCompaniesV2Autocomplete = {
  __typename?: 'CompaniesV2Autocomplete'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  vertical: GTCompanyV2Vertical
}

export type GTCompaniesV2AutocompleteInput = {
  verticals?: InputMaybe<Array<GTCompanyV2Vertical>>
}

export type GTCompaniesV2AutocompleteResponse = {
  __typename?: 'CompaniesV2AutocompleteResponse'
  items: Array<GTCompaniesV2Autocomplete>
  total: Scalars['Int']['output']
}

export type GTCompaniesV2Input = {
  vertical?: InputMaybe<GTCompanyV2Vertical>
}

export type GTCompaniesV2Response = {
  __typename?: 'CompaniesV2Response'
  items: Array<GTCompanyV2>
  total: Scalars['Int']['output']
}

/** company */
export type GTCompany = {
  __typename?: 'Company'
  /** address */
  address?: Maybe<GTAddress>
  /** comments */
  comments?: Maybe<Scalars['String']['output']>
  /** domains */
  domains: Array<Scalars['String']['output']>
  /** company id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** phone */
  phone: Scalars['String']['output']
  /** company role */
  policyRole?: Maybe<GTCompanyCompanyRole>
}

/** company autocomplete */
export type GTCompanyAutocomplete = {
  __typename?: 'CompanyAutocomplete'
  /** company ID */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** company role */
export type GTCompanyCompanyRole = {
  __typename?: 'CompanyCompanyRole'
  /** role id */
  id: Scalars['ID']['output']
  /** role name */
  name: Scalars['String']['output']
}

export type GTCompanyInsuranceSalesChannel = {
  __typename?: 'CompanyInsuranceSalesChannel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTCompanyInsuranceSettings = {
  __typename?: 'CompanyInsuranceSettings'
  active?: Maybe<Scalars['Boolean']['output']>
  price?: Maybe<Scalars['Int']['output']>
  /** @deprecated Use salesChannelsV2 instead */
  salesChannels?: Maybe<Array<Maybe<GTSalesChannel>>>
  salesChannelsV2?: Maybe<Array<GTCompanyInsuranceSalesChannel>>
}

export type GTCompanyInsuranceSettingsInput = {
  companyID: Scalars['ID']['input']
}

/** company line */
export type GTCompanyLine = {
  __typename?: 'CompanyLine'
  /** amenities */
  amenities: Array<GTAmenity>
  /** class */
  class: GTClass
  /** company line id */
  id: Scalars['ID']['output']
  /** path for the company line's logo */
  logoPath?: Maybe<Scalars['String']['output']>
  /** name */
  name: Scalars['String']['output']
  /** refund rules */
  refundRules: Scalars['String']['output']
  /** type */
  type: GTCompanyLineType
}

/** company line autocomplete */
export type GTCompanyLineAutocomplete = {
  __typename?: 'CompanyLineAutocomplete'
  /** company line ID */
  id: Scalars['ID']['output']
  /** company line name */
  name: Scalars['String']['output']
}

export const GTCompanyLineType = {
  Bus: 'BUS',
  Van: 'VAN'
} as const

export type GTCompanyLineType = (typeof GTCompanyLineType)[keyof typeof GTCompanyLineType]
export type GTCompanyLinesAutocompleteInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}

/** company role */
export type GTCompanyRole = {
  __typename?: 'CompanyRole'
  /** companies that have this role assigned */
  companies: Array<GTRoleCompany>
  /** conditions */
  conditions: Array<Scalars['String']['output']>
  /** company role id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** permissions */
  permissions: Array<GTRolePermission>
}

export type GTCompanySuggestion = {
  __typename?: 'CompanySuggestion'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  vertical: Scalars['String']['output']
}

export type GTCompanySuggestionsInput = {
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  pattern?: InputMaybe<Scalars['String']['input']>
  verticals?: InputMaybe<Array<Scalars['String']['input']>>
}

export type GTCompanySuggestionsResponse = {
  __typename?: 'CompanySuggestionsResponse'
  items: Array<GTCompanySuggestion>
  total: Scalars['Long']['output']
}

export type GTCompanyV2 = {
  __typename?: 'CompanyV2'
  address: GTCompanyV2Address
  comments?: Maybe<Scalars['String']['output']>
  companyLines?: Maybe<Array<GTCompanyV2CompanyLine>>
  createdAt: Scalars['Time']['output']
  id: Scalars['UUID']['output']
  insuranceSettings?: Maybe<GTCompanyV2InsuranceSettings>
  name: Scalars['String']['output']
  numberOfClients: Scalars['Int']['output']
  phone?: Maybe<Scalars['String']['output']>
  policy?: Maybe<GTCompanyV2Policy>
  updatedAt: Scalars['Time']['output']
  vertical: GTCompanyV2Vertical
}

export type GTCompanyV2Address = {
  __typename?: 'CompanyV2Address'
  cityName: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  line1: Scalars['String']['output']
  line2?: Maybe<Scalars['String']['output']>
  stateCode: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export type GTCompanyV2CompanyLine = {
  __typename?: 'CompanyV2CompanyLine'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTCompanyV2InsuranceSettings = {
  __typename?: 'CompanyV2InsuranceSettings'
  isActive?: Maybe<Scalars['Boolean']['output']>
  price?: Maybe<Scalars['Int']['output']>
  salesChannels: Array<GTCompanyV2SalesChannel>
}

export type GTCompanyV2Policy = {
  __typename?: 'CompanyV2Policy'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTCompanyV2SalesChannel = {
  __typename?: 'CompanyV2SalesChannel'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export const GTCompanyV2Vertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTCompanyV2Vertical = (typeof GTCompanyV2Vertical)[keyof typeof GTCompanyV2Vertical]
export const GTCompanyV2VerticalInput = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTCompanyV2VerticalInput = (typeof GTCompanyV2VerticalInput)[keyof typeof GTCompanyV2VerticalInput]
export type GTConfirmBookingTripChangeProposalInput = {
  id: Scalars['ID']['input']
  tripChangeProposalID: Scalars['ID']['input']
}

export type GTConfirmationModalities = {
  __typename?: 'ConfirmationModalities'
  facebook: Scalars['Boolean']['output']
  paid: Scalars['Boolean']['output']
  reserved: Scalars['Boolean']['output']
}

export const GTConfirmationModality = {
  Paid: 'PAID',
  Reserved: 'RESERVED'
} as const

export type GTConfirmationModality = (typeof GTConfirmationModality)[keyof typeof GTConfirmationModality]
export type GTConfirmationMoldalitiesInput = {
  paid?: InputMaybe<Scalars['Boolean']['input']>
  reserved?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTConstraintSegment = {
  __typename?: 'ConstraintSegment'
  destination: GTConstraintSegmentPlace
  id: Scalars['ID']['output']
  origin: GTConstraintSegmentPlace
}

export type GTConstraintSegmentPlace = {
  __typename?: 'ConstraintSegmentPlace'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type GTConstraints = {
  __typename?: 'Constraints'
  bookingTypes: Array<GTBookingType>
  citySegments?: Maybe<Array<GTCouponConstraintCitySegment>>
  confirmationModalities: GTCouponConfirmationModalities
  departureDateRanges: Array<GTDepartureDateRange>
  limit?: Maybe<Scalars['Int']['output']>
  minSale?: Maybe<Scalars['Int']['output']>
  minTicketsCount?: Maybe<Scalars['Int']['output']>
  onlyRegisteredUsers: Scalars['Boolean']['output']
  onlyRoundTrips: Scalars['Boolean']['output']
  passengerTypes?: Maybe<Array<GTCouponConstraintsPassengerType>>
  salesChannels?: Maybe<Array<GTSalesChannel>>
  salesChannelsV2?: Maybe<Array<GTCouponConstraintsSalesChannel>>
  /** @deprecated Use citySegments instead */
  segments: Array<GTConstraintSegment>
  userLimit: Scalars['Int']['output']
}

export type GTConstraintsInput = {
  bookingTypes?: InputMaybe<Array<GTBookingType>>
  citySegments?: InputMaybe<Array<GTCouponConstraintsCitySegmentInput>>
  confirmationModalities?: InputMaybe<GTConfirmationMoldalitiesInput>
  departureDateRanges?: InputMaybe<Array<GTDepartureDateRangeInput>>
  limit?: InputMaybe<Scalars['Int']['input']>
  minSale?: InputMaybe<Scalars['Int']['input']>
  minTicketsCount?: InputMaybe<Scalars['Int']['input']>
  onlyRegisteredUsers?: InputMaybe<Scalars['Boolean']['input']>
  onlyRoundTrips?: InputMaybe<Scalars['Boolean']['input']>
  passengerTypes?: InputMaybe<Array<GTCouponConstraintsPassengerTypeInput>>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  userLimit: Scalars['Int']['input']
}

export type GTCountriesInput = {
  ok?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTCountriesOutput = {
  __typename?: 'CountriesOutput'
  items: Array<GTCountry>
  total: Scalars['Int']['output']
}

export type GTCountry = {
  __typename?: 'Country'
  code: Scalars['String']['output']
  createdAt: Scalars['Time']['output']
  currencyID: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['Time']['output']
}

export type GTCountryInput = {
  id: Scalars['ID']['input']
}

export type GTCoupon = {
  __typename?: 'Coupon'
  campaign: GTCouponCampaign
  channels: Array<GTCouponChannel>
  code: Scalars['String']['output']
  constraints: GTConstraints
  discountType: GTDiscountType
  endsAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  startsAt: Scalars['String']['output']
  supportInteractions: Array<GTCouponSupportInteraction>
  timezone: Scalars['String']['output']
  usedCount: Scalars['Int']['output']
  value: Scalars['Int']['output']
}

export type GTCouponCampaign = {
  __typename?: 'CouponCampaign'
  area: GTCampaignArea
  company: GTCouponCampaignCompany
  endsAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  startsAt: Scalars['String']['output']
  timezone: Scalars['String']['output']
  type: GTCampaignType
}

export type GTCouponCampaignCompany = {
  __typename?: 'CouponCampaignCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GTCouponChannel = {
  Sem: 'SEM',
  Facebook: 'facebook'
} as const

export type GTCouponChannel = (typeof GTCouponChannel)[keyof typeof GTCouponChannel]
export type GTCouponConfirmationModalities = {
  __typename?: 'CouponConfirmationModalities'
  paid: Scalars['Boolean']['output']
  reserved: Scalars['Boolean']['output']
}

export type GTCouponConstraintCitySegment = {
  __typename?: 'CouponConstraintCitySegment'
  destination: GTCampaignPlace
  origin: GTCampaignPlace
  segments: Array<GTCampaignSegment>
}

export type GTCouponConstraintsCitySegmentInput = {
  destinationID: Scalars['ID']['input']
  originID: Scalars['ID']['input']
  segmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTCouponConstraintsPassengerType = {
  __typename?: 'CouponConstraintsPassengerType'
  countryCode: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTCouponConstraintsPassengerTypeInput = {
  id: Scalars['ID']['input']
}

export type GTCouponConstraintsSalesChannel = {
  __typename?: 'CouponConstraintsSalesChannel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** coupon query input */
export type GTCouponInput = {
  id: Scalars['ID']['input']
}

export const GTCouponStatus = {
  Created: 'CREATED',
  Expired: 'EXPIRED',
  Redeemed: 'REDEEMED',
  Sent: 'SENT'
} as const

export type GTCouponStatus = (typeof GTCouponStatus)[keyof typeof GTCouponStatus]
export type GTCouponSupportInteraction = {
  __typename?: 'CouponSupportInteraction'
  callStatus?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Time']['output']
  notes?: Maybe<Scalars['String']['output']>
  notificationChannel?: Maybe<Scalars['String']['output']>
}

export type GTCouponUser = {
  __typename?: 'CouponUser'
  email?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
}

/** coupons query input */
export type GTCouponsInput = {
  campaignID?: InputMaybe<Scalars['ID']['input']>
  campaignTypes?: InputMaybe<Array<GTCampaignType>>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type GTCouponsResponse = {
  __typename?: 'CouponsResponse'
  items: Array<GTCoupon>
  total: Scalars['Int']['output']
}

export type GTCouponsWithPaginationInput = {
  campaignAreas?: InputMaybe<Array<GTCampaignArea>>
  campaignID?: InputMaybe<Scalars['ID']['input']>
  campaignTypes?: InputMaybe<Array<GTCampaignType>>
  code?: InputMaybe<Scalars['String']['input']>
}

export type GTCreateAncillaryInput = {
  companyID: Scalars['ID']['input']
  conditions?: InputMaybe<GTAncillaryConditionsInput>
  label?: InputMaybe<Array<GTAncillaryTranslationInput>>
  name?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Int']['input']>
  type: GTAncillaryTypeInput
}

export type GTCreateBookingTripChangeProposalInput = {
  id: Scalars['ID']['input']
  keepPrice: Scalars['Boolean']['input']
  newTrip: GTCreateBookingTripChangeProposalTripInput
  oldTrip: GTCreateBookingTripChangeProposalTripInput
  seatIDs: Array<Scalars['ID']['input']>
}

export type GTCreateBookingTripChangeProposalResponse = {
  __typename?: 'CreateBookingTripChangeProposalResponse'
  coupon?: Maybe<GTCreateBookingTripChangeProposalResponseCoupon>
  id: Scalars['ID']['output']
  reward?: Maybe<GTCreateBookingTripChangeProposalResponseReward>
  tickets: Array<GTCreateBookingTripChangeProposalResponseTicket>
  trips: Array<GTCreateBookingTripChangeProposalResponseTrip>
}

export type GTCreateBookingTripChangeProposalResponseAncillary = {
  __typename?: 'CreateBookingTripChangeProposalResponseAncillary'
  id: Scalars['ID']['output']
  /** @deprecated use total instead */
  price: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
  status: GTAncillaryStatus
  /** @deprecated Use total instead */
  subtotal: Scalars['Int']['output']
  total: Scalars['Int']['output']
  type: GTAncillaryType
  unitPrice: Scalars['Int']['output']
  /** @deprecated total includes VAT */
  vat: Scalars['Int']['output']
}

export type GTCreateBookingTripChangeProposalResponseCity = {
  __typename?: 'CreateBookingTripChangeProposalResponseCity'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  timezone: Scalars['String']['output']
}

export type GTCreateBookingTripChangeProposalResponseCoupon = {
  __typename?: 'CreateBookingTripChangeProposalResponseCoupon'
  code: Scalars['String']['output']
  discountType: Scalars['String']['output']
  id: Scalars['ID']['output']
  ticketIDs: Array<Scalars['ID']['output']>
  value: Scalars['Int']['output']
}

export type GTCreateBookingTripChangeProposalResponsePassenger = {
  __typename?: 'CreateBookingTripChangeProposalResponsePassenger'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
}

export type GTCreateBookingTripChangeProposalResponsePlace = {
  __typename?: 'CreateBookingTripChangeProposalResponsePlace'
  city: GTCreateBookingTripChangeProposalResponseCity
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTCreateBookingTripChangeProposalResponseReward = {
  __typename?: 'CreateBookingTripChangeProposalResponseReward'
  discount: Scalars['Int']['output']
  ticketID: Scalars['ID']['output']
}

export type GTCreateBookingTripChangeProposalResponseSeat = {
  __typename?: 'CreateBookingTripChangeProposalResponseSeat'
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
}

export type GTCreateBookingTripChangeProposalResponseTicket = {
  __typename?: 'CreateBookingTripChangeProposalResponseTicket'
  ancillaries: Array<GTCreateBookingTripChangeProposalResponseAncillary>
  discount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  passenger: GTCreateBookingTripChangeProposalResponsePassenger
  seat: GTCreateBookingTripChangeProposalResponseSeat
  /** service fee */
  serviceFee?: Maybe<GTCreateBookingTripChangeProposalResponseTicketServiceFee>
  subtotal: Scalars['Int']['output']
  tripID: Scalars['ID']['output']
  vat: Scalars['Int']['output']
}

export type GTCreateBookingTripChangeProposalResponseTicketServiceFee = {
  __typename?: 'CreateBookingTripChangeProposalResponseTicketServiceFee'
  id: Scalars['ID']['output']
  price: Scalars['Int']['output']
}

export type GTCreateBookingTripChangeProposalResponseTrip = {
  __typename?: 'CreateBookingTripChangeProposalResponseTrip'
  id: Scalars['ID']['output']
  segment: GTCreateBookingTripChangeProposalResponseTripSegment
}

export type GTCreateBookingTripChangeProposalResponseTripSegment = {
  __typename?: 'CreateBookingTripChangeProposalResponseTripSegment'
  arrivalAt: Scalars['Time']['output']
  departureAt: Scalars['Time']['output']
  destination: GTCreateBookingTripChangeProposalResponsePlace
  id: Scalars['ID']['output']
  origin: GTCreateBookingTripChangeProposalResponsePlace
}

export type GTCreateBookingTripChangeProposalTripInput = {
  id: Scalars['ID']['input']
  segmentID: Scalars['ID']['input']
}

export type GTCreateBusinessClientContactInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  secondaryPhone?: InputMaybe<Scalars['String']['input']>
}

export type GTCreateBusinessClientContactsInput = {
  contacts: Array<GTCreateBusinessClientContactInput>
}

export type GTCreateBusinessClientInput = {
  companyIDs: Array<Scalars['UUID']['input']>
  contacts: Array<GTCreateBusinessClientContactInput>
  emailDomains?: InputMaybe<Array<Scalars['String']['input']>>
  kamUserID?: InputMaybe<Scalars['UUID']['input']>
  logoPath?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  shareCustomersData?: Scalars['Boolean']['input']
}

export type GTCreateBusinessClientResponse = {
  __typename?: 'CreateBusinessClientResponse'
  id: Scalars['UUID']['output']
}

export type GTCreateBusinessClientUserInput = {
  businessClientID: Scalars['UUID']['input']
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber: GTPhoneNumberInput
  title: Scalars['String']['input']
}

export type GTCreateBusinessClientsRouteServicesSchedulePriceInput = {
  priceSchedule: GTCreateBusinessClientsRouteServicesSchedulePricePriceScheduleInput
  routeServiceIDs: Array<Scalars['UUID']['input']>
}

export type GTCreateBusinessClientsRouteServicesSchedulePricePriceScheduleInput = {
  dow: Array<GTBusinessClientsRouteServicesSchedulePriceDow>
  endAt?: InputMaybe<Scalars['Date']['input']>
  price: Scalars['Int']['input']
  startAt: Scalars['Date']['input']
}

export type GTCreateBusinessCustomerInput = {
  /** The business client ID */
  businessClientID: Scalars['UUID']['input']
  /** The business site ID */
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  /** The business customer email */
  email?: InputMaybe<Scalars['String']['input']>
  /** The business customer first name */
  firstName?: InputMaybe<Scalars['String']['input']>
  /** kredit category */
  kreditCategory?: InputMaybe<Scalars['Int']['input']>
  /** The business customer last name */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** The business customer phone number */
  phoneNumber?: InputMaybe<GTPhoneNumberInput>
  /** If place is present, a place will be created and assigned to the business customer */
  place?: InputMaybe<GTBusinessCustomerPlaceInput>
  /** The business customer type. Default is ASSOCIATE */
  type?: GTBusinessCustomerTypeInput
}

export type GTCreateBusinessCustomersFromCsvInput = {
  /** The business client ID */
  businessClientID: Scalars['UUID']['input']
  /** The business site ID */
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  /** The path of the CSV file */
  path: Scalars['String']['input']
  /** The business customer type. Default is ASSOCIATE */
  type?: GTBusinessCustomerTypeInput
}

export type GTCreateBusinessSiteContactInput = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  secondaryPhone?: InputMaybe<Scalars['String']['input']>
}

export type GTCreateBusinessSiteContactsInput = {
  contacts: Array<GTCreateBusinessSiteContactInput>
}

export type GTCreateBusinessSiteInput = {
  cityID?: InputMaybe<Scalars['UUID']['input']>
  clientID: Scalars['UUID']['input']
  contacts: Array<GTCreateBusinessSiteContactInput>
  name: Scalars['String']['input']
}

export type GTCreateBusinessSiteResponse = {
  __typename?: 'CreateBusinessSiteResponse'
  id: Scalars['UUID']['output']
}

export type GTCreateCampaignInput = {
  area: GTCampaignArea
  companyID: Scalars['ID']['input']
  description: Scalars['String']['input']
  endsAt: Scalars['String']['input']
  name: Scalars['String']['input']
  startsAt: Scalars['String']['input']
  timezone: Scalars['String']['input']
  type: GTCampaignType
}

export type GTCreateCampaignSubcampaignInput = {
  authorizer?: InputMaybe<Scalars['String']['input']>
  filePath?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  reason?: InputMaybe<Scalars['String']['input']>
  trip?: InputMaybe<GTCreateCampaignSubcampaignTripInput>
}

export type GTCreateCampaignSubcampaignResponse = {
  __typename?: 'CreateCampaignSubcampaignResponse'
  id: Scalars['ID']['output']
}

export type GTCreateCampaignSubcampaignTripInput = {
  id: Scalars['ID']['input']
  legSegmentIDs: Array<Scalars['ID']['input']>
}

export type GTCreateCartInput = {
  companyIDs: Array<Scalars['ID']['input']>
  name: Scalars['String']['input']
}

export type GTCreateCompanyV2AddressInput = {
  cityName: Scalars['String']['input']
  countryCode: Scalars['String']['input']
  line1: Scalars['String']['input']
  line2?: InputMaybe<Scalars['String']['input']>
  stateCode: Scalars['String']['input']
  zipCode: Scalars['String']['input']
}

export type GTCreateCompanyV2Input = {
  address: GTCreateCompanyV2AddressInput
  comments?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  policyID?: InputMaybe<Scalars['UUID']['input']>
  vertical: GTCompanyV2VerticalInput
}

export type GTCreateCountryInput = {
  code: Scalars['String']['input']
  currencyID: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type GTCreateCurrencyInput = {
  code: Scalars['String']['input']
  exchangeRate: Scalars['Float']['input']
  name: Scalars['String']['input']
  symbol: Scalars['String']['input']
}

export type GTCreateDriverInput = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<GTUserAccountGenderInput>
  lastName?: InputMaybe<Scalars['String']['input']>
  partnerID: Scalars['UUID']['input']
  phoneNumber?: InputMaybe<GTPhoneNumberInput>
  stateCode?: InputMaybe<Scalars['String']['input']>
}

export type GTCreateDriverResponse = {
  __typename?: 'CreateDriverResponse'
  id: Scalars['UUID']['output']
}

/** create fare rule */
export type GTCreateFareRuleInput = {
  /** activation at ranges */
  activationAtRanges: Array<GTFareRuleDateRangeInput>
  /** city segments */
  citySegments: Array<GTFareRulecitySegmentInput>
  /** company id */
  companyID: Scalars['ID']['input']
  /** departure at ranges */
  departureAtRanges: Array<GTFareRuleDateRangeInput>
  /** description */
  description: Scalars['String']['input']
  /** exceptions */
  exceptions: GTFareRuleExceptionsInput
  /** name */
  name: Scalars['String']['input']
  /** price */
  price: Scalars['Int']['input']
}

export type GTCreateInventoryInput = {
  address?: InputMaybe<GTInventoryAddressInput>
  companyID?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  type: GTInventoryType
  userIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** create inventory rule cabin settings */
export type GTCreateInventoryRuleCabinStrategiesInput = {
  /** cabin strategies */
  cabinStrategies: Array<GTInventoryRuleCabinStrategyInput>
  /** inventory rule id */
  id: Scalars['ID']['input']
}

/** create inventory rule */
export type GTCreateInventoryRuleInput = {
  /** activation at ranges */
  activationAtRanges: Array<GTInventoryRuleDateRangeInput>
  /** cabin strategies */
  cabinStrategies?: InputMaybe<Array<GTInventoryRuleCabinStrategyInput>>
  /** city segments */
  citySegments: Array<GTInventoryRulecitySegmentInput>
  /** company id */
  companyID: Scalars['ID']['input']
  /** departure at ranges */
  departureAtRanges: Array<GTInventoryRuleDateRangeInput>
  /** description */
  description: Scalars['String']['input']
  /** exceptions */
  exceptions: GTInventoryRuleExceptionsInput
  /** name */
  name: Scalars['String']['input']
}

export type GTCreateLoyaltyCampaignInput = {
  companyID: Scalars['ID']['input']
  companyLineIDs: Array<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  timeFrame: GTCreateLoyaltyCampaignTimeFrameInput
}

export type GTCreateLoyaltyCampaignRuleConditionsCitySegmentInput = {
  destinationID: Scalars['ID']['input']
  originID: Scalars['ID']['input']
  segmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTCreateLoyaltyCampaignRuleConditionsInput = {
  citySegments?: InputMaybe<Array<InputMaybe<GTCreateLoyaltyCampaignRuleConditionsCitySegmentInput>>>
  routeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  routeServiceIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  totalBookingsValue?: InputMaybe<Scalars['Int']['input']>
}

export type GTCreateLoyaltyCampaignRuleEffectsInput = {
  rewardID: Scalars['ID']['input']
}

export type GTCreateLoyaltyCampaignRuleInput = {
  conditions: GTCreateLoyaltyCampaignRuleConditionsInput
  description?: InputMaybe<Scalars['String']['input']>
  details: Scalars['String']['input']
  effects: GTCreateLoyaltyCampaignRuleEffectsInput
  howToGet: Scalars['String']['input']
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  validAt: Scalars['String']['input']
}

export type GTCreateLoyaltyCampaignTimeFrameInput = {
  endAt: Scalars['Time']['input']
  startAt: Scalars['Time']['input']
}

export type GTCreateLoyaltyRewardInput = {
  companyID: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  type?: GTLoyaltyRewardType
}

export type GTCreateOrderInput = {
  destinationID: Scalars['ID']['input']
  lineItems: Array<GTOrderLineItemInput>
  originID?: InputMaybe<Scalars['ID']['input']>
  purchaseID?: InputMaybe<Scalars['ID']['input']>
  supplier?: InputMaybe<Scalars['String']['input']>
  type: GTOrderType
}

export type GTCreatePartnerContactInput = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  secondaryPhone?: InputMaybe<Scalars['String']['input']>
}

export type GTCreatePartnerContactsInput = {
  contacts: Array<GTCreatePartnerContactInput>
}

export type GTCreatePartnerInput = {
  cityID?: InputMaybe<Scalars['UUID']['input']>
  name: Scalars['String']['input']
  operationalStateCodes: Array<Scalars['String']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  type: GTPartnerTypeInput
  verticals: Array<GTPartnerVerticalInput>
}

export type GTCreatePartnerResponse = {
  __typename?: 'CreatePartnerResponse'
  id: Scalars['UUID']['output']
}

export type GTCreatePassengerPropertyDateRangeInput = {
  endAt: Scalars['Time']['input']
  startAt: Scalars['Time']['input']
}

export type GTCreatePassengerPropertyInput = {
  abbreviation: Array<GTCreatePassengerPropertyTranslationInput>
  countryCode: Scalars['String']['input']
  departureDateRanges?: InputMaybe<Array<GTCreatePassengerPropertyDateRangeInput>>
  description?: InputMaybe<Array<GTCreatePassengerPropertyTranslationInput>>
  label: Array<GTCreatePassengerPropertyTranslationInput>
  name: Scalars['String']['input']
  required: Scalars['Boolean']['input']
  searchDateRanges?: InputMaybe<Array<GTCreatePassengerPropertyDateRangeInput>>
  settings?: InputMaybe<Array<GTCreatePassengerPropertySettingInput>>
}

export type GTCreatePassengerPropertySettingInput = {
  discount: Scalars['Int']['input']
  passengerTypeIDs: Array<Scalars['ID']['input']>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTCreatePassengerPropertyTranslationInput = {
  language: GTLanguage
  text: Scalars['String']['input']
}

export type GTCreatePassengerTypeInput = {
  abbreviation?: InputMaybe<Array<GTCreatePassengerTypeTranslationInput>>
  countryCode: Scalars['String']['input']
  description?: InputMaybe<Array<GTCreatePassengerTypeTranslationInput>>
  discount: Scalars['Int']['input']
  label: Array<GTCreatePassengerTypeTranslationInput>
  name: Scalars['String']['input']
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTCreatePassengerTypeTranslationInput = {
  language: GTLanguage
  plural?: Scalars['Boolean']['input']
  text: Scalars['String']['input']
}

export type GTCreatePlaceForBusinessCustomerInput = {
  businessClientID: Scalars['UUID']['input']
  businessCustomerID: Scalars['UUID']['input']
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  name: Scalars['String']['input']
  place: GTBusinessCustomerPlaceInput
}

export type GTCreatePlaceV2AddressInput = {
  cityName: Scalars['String']['input']
  countryCode: Scalars['String']['input']
  line1: Scalars['String']['input']
  line2?: InputMaybe<Scalars['String']['input']>
  stateCode: Scalars['String']['input']
  zipCode: Scalars['String']['input']
}

export type GTCreatePlaceV2CoordinatesInput = {
  latitude: Scalars['String']['input']
  longitude: Scalars['String']['input']
}

export type GTCreatePlaceV2Input = {
  address?: InputMaybe<GTCreatePlaceV2AddressInput>
  amenities?: InputMaybe<Array<GTPlaceV2AmenityInput>>
  area?: InputMaybe<Scalars['String']['input']>
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  code: Scalars['String']['input']
  companyID?: InputMaybe<Scalars['UUID']['input']>
  coordinates: GTCreatePlaceV2CoordinatesInput
  directions?: InputMaybe<Scalars['String']['input']>
  geofenceRadius?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  param: Scalars['String']['input']
  parentID: Scalars['UUID']['input']
  photoPath?: InputMaybe<Scalars['String']['input']>
  tags: Array<Scalars['String']['input']>
  type: GTPlaceV2TypeInput
}

export type GTCreatePresignedUrlForFileUploadInput = {
  /** file name */
  name: Scalars['String']['input']
}

export type GTCreatePresignedUrlForFileUploadOutput = {
  __typename?: 'CreatePresignedUrlForFileUploadOutput'
  /** file path */
  path: Scalars['String']['output']
  /** signed url */
  url: Scalars['String']['output']
}

/** create route input */
export type GTCreateRouteInput = {
  /** company id */
  companyID: Scalars['ID']['input']
  /** company lines that use this route */
  companyLineIDs: Array<Scalars['String']['input']>
  /** places this route has stops in */
  placeIDs: Array<Scalars['String']['input']>
}

export type GTCreateRouteResponse = {
  __typename?: 'CreateRouteResponse'
  id: Scalars['ID']['output']
}

export type GTCreateRouteServiceBusinessClientInput = {
  businessSiteIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  id: Scalars['ID']['input']
}

export type GTCreateRouteServiceDriverOverrideInput = {
  /** adiitional comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** date */
  date: Scalars['Date']['input']
  /** driver id */
  driverID: Scalars['UUID']['input']
  /** route service id */
  id: Scalars['UUID']['input']
  /** reason for the change */
  reason: GTCreateRouteServiceDriverOverrideReasonInput
}

export const GTCreateRouteServiceDriverOverrideReasonCategory = {
  BusinessClient: 'BUSINESS_CLIENT',
  Driver: 'DRIVER',
  Other: 'OTHER',
  Partner: 'PARTNER'
} as const

export type GTCreateRouteServiceDriverOverrideReasonCategory =
  (typeof GTCreateRouteServiceDriverOverrideReasonCategory)[keyof typeof GTCreateRouteServiceDriverOverrideReasonCategory]
export type GTCreateRouteServiceDriverOverrideReasonInput = {
  /** reason category */
  category: GTCreateRouteServiceDriverOverrideReasonCategory
  /** reason subcategory */
  subcategory?: InputMaybe<GTCreateRouteServiceDriverOverrideReasonSubcategory>
}

export const GTCreateRouteServiceDriverOverrideReasonSubcategory = {
  Absence: 'ABSENCE',
  Disability: 'DISABILITY',
  Other: 'OTHER',
  Replacement: 'REPLACEMENT'
} as const

export type GTCreateRouteServiceDriverOverrideReasonSubcategory =
  (typeof GTCreateRouteServiceDriverOverrideReasonSubcategory)[keyof typeof GTCreateRouteServiceDriverOverrideReasonSubcategory]
export type GTCreateRouteServiceItinerariesScheduleInput = {
  /** route service id */
  id: Scalars['ID']['input']
  /** itineraries schedule */
  itinerariesSchedule: Array<GTCreateRouteServiceItineraryScheduleInput>
}

export type GTCreateRouteServiceItineraryOverrideInput = {
  /** date */
  date: Scalars['Date']['input']
  /** route service id */
  id: Scalars['UUID']['input']
  /** itinerary */
  itinerary?: InputMaybe<Array<GTCreateRouteServiceItineraryOverrideStopInput>>
}

export type GTCreateRouteServiceItineraryOverrideStopInput = {
  /** arrival */
  arrival?: InputMaybe<GTCreateRouteServiceItineraryOverrideStopTimeInput>
  /** departure */
  departure?: InputMaybe<GTCreateRouteServiceItineraryOverrideStopTimeInput>
  /** place id */
  placeID: Scalars['UUID']['input']
}

export type GTCreateRouteServiceItineraryOverrideStopTimeInput = {
  /** day */
  day: Scalars['Int']['input']
  /** time */
  time: Scalars['String']['input']
}

export type GTCreateRouteServiceItineraryScheduleInput = {
  dow: Array<GTWeekday>
  endAt?: InputMaybe<Scalars['Date']['input']>
  itinerary: Array<GTCreateRouteServiceItineraryScheduleStopInput>
  notes?: Array<Scalars['String']['input']>
  oldRouteID?: InputMaybe<Scalars['ID']['input']>
  placeReplacements?: Array<GTCreateRouteServiceItinerarySchedulePlaceReplacementInput>
  routeID: Scalars['ID']['input']
  startAt: Scalars['Date']['input']
}

export type GTCreateRouteServiceItinerarySchedulePlaceReplacementInput = {
  newPlaceID: Scalars['ID']['input']
  oldPlaceID: Scalars['ID']['input']
}

export type GTCreateRouteServiceItineraryScheduleStopInput = {
  arrival?: InputMaybe<GTCreateRouteServiceItineraryScheduleStopTimeInput>
  departure?: InputMaybe<GTCreateRouteServiceItineraryScheduleStopTimeInput>
  placeID: Scalars['ID']['input']
}

export type GTCreateRouteServiceItineraryScheduleStopTimeInput = {
  day: Scalars['Int']['input']
  time: Scalars['String']['input']
}

export type GTCreateRouteServicePastVehicleOverrideInput = {
  /** additional comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** date */
  date: Scalars['Date']['input']
  /** route service id */
  id: Scalars['UUID']['input']
  /** reason for the change */
  reason: GTCreateRouteServiceVehicleOverrideReasonInput
  /** vehicle id */
  vehicleID: Scalars['UUID']['input']
}

export type GTCreateRouteServiceRouteOverrideInput = {
  /** date */
  date: Scalars['Date']['input']
  /** route service id */
  id: Scalars['UUID']['input']
  /** itinerary */
  itinerary?: InputMaybe<Array<GTCreateRouteServiceRouteOverrideStopInput>>
  /** old route id */
  oldRouteID: Scalars['UUID']['input']
  /** place replacements */
  placeReplacements?: InputMaybe<Array<GTCreateRouteServiceRouteOverridePlaceReplacementInput>>
  /** route id */
  routeID: Scalars['UUID']['input']
}

export type GTCreateRouteServiceRouteOverridePlaceReplacementInput = {
  /** new place id */
  newPlaceID: Scalars['UUID']['input']
  /** old place id */
  oldPlaceID: Scalars['UUID']['input']
}

export type GTCreateRouteServiceRouteOverrideStopInput = {
  /** arrival */
  arrival?: InputMaybe<GTCreateRouteServiceRouteOverrideStopTimeInput>
  /** departure */
  departure?: InputMaybe<GTCreateRouteServiceRouteOverrideStopTimeInput>
  /** place id */
  placeID: Scalars['UUID']['input']
}

export type GTCreateRouteServiceRouteOverrideStopTimeInput = {
  /** day */
  day: Scalars['Int']['input']
  /** time */
  time: Scalars['String']['input']
}

export type GTCreateRouteServiceSingleTripInput = {
  companyLineID: Scalars['ID']['input']
  date: Scalars['Date']['input']
  itinerary: Array<GTCreateRouteServiceSingleTripStopInput>
  reason?: InputMaybe<GTRouteServiceSingleTripReason>
  routeID: Scalars['ID']['input']
  vehicleID: Scalars['ID']['input']
}

export type GTCreateRouteServiceSingleTripStopInput = {
  arrival?: InputMaybe<GTCreateRouteServiceSingleTripStopTimeInput>
  departure?: InputMaybe<GTCreateRouteServiceSingleTripStopTimeInput>
  placeID: Scalars['ID']['input']
}

export type GTCreateRouteServiceSingleTripStopTimeInput = {
  day: Scalars['Int']['input']
  time: Scalars['String']['input']
}

export type GTCreateRouteServiceVehicleOverrideInput = {
  /** additional comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** date */
  date: Scalars['Date']['input']
  /** route service id */
  id: Scalars['UUID']['input']
  /** reason for the change */
  reason: GTCreateRouteServiceVehicleOverrideReasonInput
  /** vehicle id */
  vehicleID: Scalars['UUID']['input']
}

export const GTCreateRouteServiceVehicleOverrideReasonCategory = {
  BusinessClient: 'BUSINESS_CLIENT',
  Other: 'OTHER',
  Partner: 'PARTNER',
  Planning: 'PLANNING',
  Vehicle: 'VEHICLE'
} as const

export type GTCreateRouteServiceVehicleOverrideReasonCategory =
  (typeof GTCreateRouteServiceVehicleOverrideReasonCategory)[keyof typeof GTCreateRouteServiceVehicleOverrideReasonCategory]
export type GTCreateRouteServiceVehicleOverrideReasonInput = {
  /** reason category */
  category: GTCreateRouteServiceVehicleOverrideReasonCategory
  /** reason subcategory */
  subcategory?: InputMaybe<GTCreateRouteServiceVehicleOverrideReasonSubcategory>
}

export const GTCreateRouteServiceVehicleOverrideReasonSubcategory = {
  Accident: 'ACCIDENT',
  MechanicalFailure: 'MECHANICAL_FAILURE',
  Other: 'OTHER',
  ScheduledMaintenance: 'SCHEDULED_MAINTENANCE'
} as const

export type GTCreateRouteServiceVehicleOverrideReasonSubcategory =
  (typeof GTCreateRouteServiceVehicleOverrideReasonSubcategory)[keyof typeof GTCreateRouteServiceVehicleOverrideReasonSubcategory]
export type GTCreateRouteServiceVehicleScheduleFrequencyInput = {
  unit: GTRouteServiceScheduleFrequencyUnit
  value: Scalars['Int']['input']
}

export type GTCreateRouteServiceVehicleScheduleInput = {
  dow?: InputMaybe<Array<GTWeekday>>
  driverID?: InputMaybe<Scalars['ID']['input']>
  endAt?: InputMaybe<Scalars['Date']['input']>
  frequency?: InputMaybe<GTCreateRouteServiceVehicleScheduleFrequencyInput>
  startAt: Scalars['Date']['input']
  vehicleID: Scalars['ID']['input']
}

export type GTCreateRouteServiceVehiclesScheduleInput = {
  /** adiitional comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** route service id */
  id: Scalars['ID']['input']
  /** reason for the change */
  reason?: InputMaybe<GTCreateRouteServiceVehiclesScheduleReason>
  /** vehicles schedule */
  vehiclesSchedule: Array<GTCreateRouteServiceVehicleScheduleInput>
}

export const GTCreateRouteServiceVehiclesScheduleReason = {
  BackupFixed: 'BACKUP_FIXED',
  BackupFlexible: 'BACKUP_FLEXIBLE',
  BusinessClient: 'BUSINESS_CLIENT',
  Partner: 'PARTNER',
  Uber: 'UBER'
} as const

export type GTCreateRouteServiceVehiclesScheduleReason =
  (typeof GTCreateRouteServiceVehiclesScheduleReason)[keyof typeof GTCreateRouteServiceVehiclesScheduleReason]
export type GTCreateSalesChannelInput = {
  countryID: Scalars['ID']['input']
  name: Scalars['String']['input']
  type: GTSalesChannelType
}

export type GTCreateTRouteInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  companyID: Scalars['UUID']['input']
  companyLineIDs: Array<Scalars['UUID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  polyline: GTCreateTRoutePolylineInput
  stopIDs: Array<Scalars['UUID']['input']>
}

export type GTCreateTRoutePolylineInput = {
  distance: Scalars['Int']['input']
  encoded: Scalars['String']['input']
}

export type GTCreateTRouteResponse = {
  __typename?: 'CreateTRouteResponse'
  id: Scalars['UUID']['output']
}

export type GTCreateTVehicleCardsInput = {
  fuelCards?: InputMaybe<Array<Scalars['String']['input']>>
  tagCards?: InputMaybe<Array<Scalars['String']['input']>>
}

export type GTCreateTVehicleInput = {
  cards: GTCreateTVehicleCardsInput
  cityName?: InputMaybe<Scalars['String']['input']>
  companyIDs: Array<Scalars['UUID']['input']>
  driverIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  externalID?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  partnerID: Scalars['UUID']['input']
  stateCode?: InputMaybe<Scalars['String']['input']>
  templateID: Scalars['UUID']['input']
  vin: Scalars['String']['input']
}

export type GTCreateTVehicleResponse = {
  __typename?: 'CreateTVehicleResponse'
  id: Scalars['UUID']['output']
}

export type GTCurrenciesInput = {
  ok?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTCurrenciesOutput = {
  __typename?: 'CurrenciesOutput'
  items: Array<GTCurrency>
  total: Scalars['Int']['output']
}

export type GTCurrency = {
  __typename?: 'Currency'
  code: Scalars['String']['output']
  createdAt: Scalars['Time']['output']
  exchangeRate: Scalars['Float']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  symbol: Scalars['String']['output']
  updatedAt: Scalars['Time']['output']
}

export const GTCurrencyCode = {
  Mxn: 'MXN',
  Usd: 'USD'
} as const

export type GTCurrencyCode = (typeof GTCurrencyCode)[keyof typeof GTCurrencyCode]
export type GTCurrencyInput = {
  id: Scalars['ID']['input']
}

export type GTDatabaseSubCampaign = {
  __typename?: 'DatabaseSubCampaign'
  campaign: GTSubCampaignCampaign
  coupons: Array<GTCoupon>
  endsAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  startsAt: Scalars['String']['output']
}

export type GTDatabaseSubcampaign = {
  __typename?: 'DatabaseSubcampaign'
  campaignID: Scalars['ID']['output']
  campaignType: Scalars['String']['output']
  endsAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  startsAt: Scalars['Time']['output']
  status: GTSubcampaignStatus
}

export type GTDatabaseSubcampaignCoupon = {
  __typename?: 'DatabaseSubcampaignCoupon'
  emails: Array<GTEmail>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  status: GTCouponStatus
  supportInteractions: Array<GTSubcampaignSupportInteraction>
  user: GTCouponUser
}

export type GTDatabaseSubcampaignCouponsInput = {
  email?: InputMaybe<Scalars['String']['input']>
  emailStatus?: InputMaybe<GTSubcampaignCouponEmailStatus>
  phone?: InputMaybe<Scalars['String']['input']>
  subcampaignID: Scalars['ID']['input']
  userName?: InputMaybe<Scalars['String']['input']>
}

export type GTDatabaseSubcampaignCouponsResponse = {
  __typename?: 'DatabaseSubcampaignCouponsResponse'
  coupons: Array<GTDatabaseSubcampaignCoupon>
  total: Scalars['Int']['output']
}

export type GTDatabaseSubcampaignInput = {
  id: Scalars['ID']['input']
}

export type GTDatabaseSubcampaignsInput = {
  campaignID?: InputMaybe<Scalars['ID']['input']>
}

export type GTDatabaseSubcampaignsResponse = {
  __typename?: 'DatabaseSubcampaignsResponse'
  items: Array<GTDatabaseSubCampaign>
  total: Scalars['Int']['output']
}

export type GTDateRange = {
  end?: InputMaybe<Scalars['Time']['input']>
  start?: InputMaybe<Scalars['Time']['input']>
}

/** days of week */
export const GTDayOfWeek = {
  Friday: 'friday',
  Monday: 'monday',
  Saturday: 'saturday',
  Sunday: 'sunday',
  Thursday: 'thursday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday'
} as const

export type GTDayOfWeek = (typeof GTDayOfWeek)[keyof typeof GTDayOfWeek]
export type GTDeactivateBusinessClientUserInput = {
  id: Scalars['UUID']['input']
}

export type GTDeactivateCampaignCouponInput = {
  coupon: GTDeactivateCouponInput
  /** campaign id */
  id: Scalars['ID']['input']
  subcampaignID?: InputMaybe<Scalars['ID']['input']>
}

export type GTDeactivateCampaignInput = {
  id: Scalars['ID']['input']
}

export type GTDeactivateCouponInput = {
  /** coupon id */
  id: Scalars['ID']['input']
}

export type GTDeactivatePartnerInput = {
  comments?: InputMaybe<Scalars['String']['input']>
  reason: GTDeactivatePartnerReasonInput
}

export const GTDeactivatePartnerReasonInput = {
  Other: 'OTHER',
  Partner: 'PARTNER'
} as const

export type GTDeactivatePartnerReasonInput =
  (typeof GTDeactivatePartnerReasonInput)[keyof typeof GTDeactivatePartnerReasonInput]
export type GTDeactivateVehicleInput = {
  reason: GTDeactivateVehicleReasonInput
}

export const GTDeactivateVehicleReasonInput = {
  Operational: 'OPERATIONAL'
} as const

export type GTDeactivateVehicleReasonInput =
  (typeof GTDeactivateVehicleReasonInput)[keyof typeof GTDeactivateVehicleReasonInput]
export const GTDeactivationReason = {
  Accident: 'accident',
  Maintenance: 'maintenance',
  MechanicalFailure: 'mechanicalFailure',
  Other: 'other',
  Planning: 'planning',
  RoadBlock: 'roadBlock'
} as const

export type GTDeactivationReason = (typeof GTDeactivationReason)[keyof typeof GTDeactivationReason]
/** default origin place for a site. */
export type GTDefaultOrigin = {
  __typename?: 'DefaultOrigin'
  /** place id */
  id: Scalars['ID']['output']
  /** place name */
  name: Scalars['String']['output']
}

export type GTDeleteBusinessClientContactsInput = {
  contactIDs: Array<Scalars['UUID']['input']>
}

export type GTDeleteBusinessSiteContactsInput = {
  contactIDs: Array<Scalars['UUID']['input']>
}

/** delete inventory rule cabin settings */
export type GTDeleteInventoryRuleCabinStrategiesInput = {
  /** cabin strategies id */
  cabinStrategyIDs: Array<Scalars['ID']['input']>
  /** inventory rule id */
  id: Scalars['ID']['input']
}

export type GTDeletePartnerContactsInput = {
  contactIDs: Array<Scalars['UUID']['input']>
}

export type GTDeleteRouteServiceVehiclesScheduleInput = {
  /** route service id */
  id: Scalars['ID']['input']
  /** vehicle schedule ids */
  vehiclesScheduleIDs: Array<Scalars['ID']['input']>
}

export type GTDeleteTVehicleDocumentsInput = {
  categories: Array<GTVehicleDocumentCategoryInput>
}

/** demand */
export const GTDemand = {
  /** local no stops */
  D: 'D',
  /** not local no stops */
  I: 'I',
  /** local with stops */
  L: 'L',
  /** N/A */
  N: 'N',
  /** not local with stops */
  T: 'T'
} as const

export type GTDemand = (typeof GTDemand)[keyof typeof GTDemand]
export type GTDepartureDateRange = {
  __typename?: 'DepartureDateRange'
  from: Scalars['String']['output']
  to: Scalars['String']['output']
}

export type GTDepartureDateRangeInput = {
  from: Scalars['String']['input']
  to: Scalars['String']['input']
}

export type GTDepositKreditCorporateFundsInput = {
  account: GTAccountInput
  amount: Scalars['Int']['input']
}

export type GTDepositKreditsBonusFundsInput = {
  accounts: Array<GTAccountInput>
  amount: Scalars['Int']['input']
}

export type GTDepositKreditsCorporateFundsInput = {
  accounts: Array<GTAccountInput>
  amount: Scalars['Int']['input']
}

export type GTDisableAncillaryInput = {
  id: Scalars['ID']['input']
}

export type GTDisableBusinessCustomerInput = {
  id: Scalars['UUID']['input']
}

export type GTDisableDriverInput = {
  id: Scalars['UUID']['input']
}

/** disable fare rule */
export type GTDisableFareRuleInput = {
  /** id */
  id: Scalars['ID']['input']
}

/** disable inventory rule */
export type GTDisableInventoryRuleInput = {
  /** inventory rule id */
  id: Scalars['ID']['input']
}

export type GTDisablePassengerPropertyInput = {
  id: Scalars['ID']['input']
}

export type GTDisablePassengerTypeInput = {
  id: Scalars['ID']['input']
}

export type GTDisableServiceFeeAddonRuleInput = {
  companyID: Scalars['ID']['input']
  id: Scalars['ID']['input']
}

export const GTDiscountType = {
  Amount: 'amount',
  Percentage: 'percentage'
} as const

export type GTDiscountType = (typeof GTDiscountType)[keyof typeof GTDiscountType]
/** document category */
export const GTDocumentCategory = {
  Bills: 'bills',
  Inspections: 'inspections',
  Insurance: 'insurance',
  Legal: 'legal'
} as const

export type GTDocumentCategory = (typeof GTDocumentCategory)[keyof typeof GTDocumentCategory]
export const GTDomain = {
  Kolors: 'kolors',
  Miautobus: 'miautobus'
} as const

export type GTDomain = (typeof GTDomain)[keyof typeof GTDomain]
export type GTDrawerBooking = {
  __typename?: 'DrawerBooking'
  buyer?: Maybe<GTBookingBuyer>
  coupon?: Maybe<GTDrawerBookingCoupon>
  id: Scalars['ID']['output']
  isCancelled: Scalars['Boolean']['output']
  payments: Array<GTDrawerBookingPayment>
  tickets: Array<Maybe<GTDrawerBookingTicket>>
}

export type GTDrawerBookingCoupon = {
  __typename?: 'DrawerBookingCoupon'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type GTDrawerBookingPayment = {
  __typename?: 'DrawerBookingPayment'
  amount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  last4?: Maybe<Scalars['String']['output']>
  method: Scalars['String']['output']
  status: Scalars['String']['output']
  tickets: Array<GTPaymentTicket>
}

export type GTDrawerBookingTicket = {
  __typename?: 'DrawerBookingTicket'
  id: Scalars['ID']['output']
  passengerType: GTDrawerBookingTicketPassengerType
  status: GTDrawerBookingTicketStatus
}

export const GTDrawerBookingTicketPassengerType = {
  Adult: 'adult',
  Child: 'child',
  Senior: 'senior',
  Student: 'student',
  Teacher: 'teacher'
} as const

export type GTDrawerBookingTicketPassengerType =
  (typeof GTDrawerBookingTicketPassengerType)[keyof typeof GTDrawerBookingTicketPassengerType]
export const GTDrawerBookingTicketStatus = {
  Cancelled: 'cancelled',
  Confirmed: 'confirmed',
  Reserved: 'reserved'
} as const

export type GTDrawerBookingTicketStatus = (typeof GTDrawerBookingTicketStatus)[keyof typeof GTDrawerBookingTicketStatus]
export type GTDrawerCompany = {
  __typename?: 'DrawerCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTDrawerData = {
  __typename?: 'DrawerData'
  actualAmount?: Maybe<Scalars['Int']['output']>
  company: GTDrawerCompany
  drawerBookings: Array<GTDrawerBooking>
  drawerKreditSales: Array<GTDrawerSale>
  drawerMobileSales: Array<GTDrawerSale>
  drawerSales: Array<GTDrawerSale>
  endTime?: Maybe<Scalars['Time']['output']>
  expectedAmount: GTExpectedAmount
  receiptData: GTReceiptData
  site: GTDrawerSite
  startTime?: Maybe<Scalars['Time']['output']>
  status: GTDrawerStatus
  user: GTDrawerUser
}

export type GTDrawerSale = {
  __typename?: 'DrawerSale'
  id: Scalars['ID']['output']
  payment: GTDrawerSalePayment
}

export type GTDrawerSalePayment = {
  __typename?: 'DrawerSalePayment'
  amount: Scalars['Int']['output']
  last4?: Maybe<Scalars['String']['output']>
  method: Scalars['String']['output']
}

export type GTDrawerSite = {
  __typename?: 'DrawerSite'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GTDrawerStatus = {
  Closed: 'closed',
  Open: 'open'
} as const

export type GTDrawerStatus = (typeof GTDrawerStatus)[keyof typeof GTDrawerStatus]
export type GTDrawerUser = {
  __typename?: 'DrawerUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type GTDrawersResponse = {
  __typename?: 'DrawersResponse'
  items: Array<GTDrawerData>
  total: Scalars['Int']['output']
}

export type GTDriverDevice = {
  __typename?: 'DriverDevice'
  androidID?: Maybe<Scalars['String']['output']>
  app?: Maybe<GTDriverDeviceApp>
  brand: Scalars['String']['output']
  gpsEnabled: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  idfa?: Maybe<Scalars['String']['output']>
  model: Scalars['String']['output']
  os: Scalars['String']['output']
  osVersion: Scalars['String']['output']
}

export type GTDriverDeviceApp = {
  __typename?: 'DriverDeviceApp'
  androidPermissions?: Maybe<GTDriverDeviceAppAndroidPermissions>
  appID: Scalars['String']['output']
  oneSignalID?: Maybe<Scalars['String']['output']>
  version: Scalars['String']['output']
}

export type GTDriverDeviceAppAndroidPermissions = {
  __typename?: 'DriverDeviceAppAndroidPermissions'
  accessBackgroundLocation: Scalars['Boolean']['output']
  accessCoarseLocation: Scalars['Boolean']['output']
  accessFineLocation: Scalars['Boolean']['output']
  accessNetworkState: Scalars['Boolean']['output']
  camera: Scalars['Boolean']['output']
  foregroundService: Scalars['Boolean']['output']
  internet: Scalars['Boolean']['output']
  locationHardware: Scalars['Boolean']['output']
  readExternalStorage: Scalars['Boolean']['output']
  vibrate: Scalars['Boolean']['output']
  wakeLock: Scalars['Boolean']['output']
  writeExternalStorage: Scalars['Boolean']['output']
}

export const GTDriverDocumentCategoryInput = {
  Application: 'APPLICATION',
  BirthCertificate: 'BIRTH_CERTIFICATE',
  Curp: 'CURP',
  Id: 'ID',
  Licence: 'LICENCE',
  Photo: 'PHOTO',
  ProofOfAddress: 'PROOF_OF_ADDRESS',
  ProofOfLastStudy: 'PROOF_OF_LAST_STUDY'
} as const

export type GTDriverDocumentCategoryInput =
  (typeof GTDriverDocumentCategoryInput)[keyof typeof GTDriverDocumentCategoryInput]
export type GTDriverSuggestion = {
  __typename?: 'DriverSuggestion'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTDriverSuggestionsInput = {
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  partnerID?: InputMaybe<Scalars['UUID']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTDriverSuggestionsResponse = {
  __typename?: 'DriverSuggestionsResponse'
  items: Array<GTDriverSuggestion>
  total: Scalars['Long']['output']
}

export type GTDuration = {
  __typename?: 'Duration'
  unit: GTDurationUnit
  value: Scalars['Int']['output']
}

export type GTDurationInput = {
  unit: GTDurationUnit
  value: Scalars['Int']['input']
}

export const GTDurationUnit = {
  Days: 'DAYS',
  Hours: 'HOURS'
} as const

export type GTDurationUnit = (typeof GTDurationUnit)[keyof typeof GTDurationUnit]
export type GTEmail = {
  __typename?: 'Email'
  address: Scalars['String']['output']
  id: Scalars['ID']['output']
  reason?: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
  updatedAt: Scalars['Time']['output']
}

export type GTEnableAncillaryInput = {
  id: Scalars['ID']['input']
}

export type GTEnableBusinessCustomerInput = {
  id: Scalars['UUID']['input']
}

export type GTEnableDriverInput = {
  id: Scalars['UUID']['input']
}

/** enable fare rule */
export type GTEnableFareRuleInput = {
  /** id */
  id: Scalars['ID']['input']
}

/** enable inventory rule */
export type GTEnableInventoryRuleInput = {
  /** inventory rule id */
  id: Scalars['ID']['input']
}

export type GTEnablePassengerPropertyInput = {
  id: Scalars['ID']['input']
}

export type GTEnablePassengerTypeInput = {
  id: Scalars['ID']['input']
}

export type GTEnableServiceFeeAddonRuleInput = {
  companyID: Scalars['ID']['input']
  id: Scalars['ID']['input']
}

export type GTExpectedAmount = {
  __typename?: 'ExpectedAmount'
  bookings: Scalars['Int']['output']
  kreditSales: Scalars['Int']['output']
  mobileSales: Scalars['Int']['output']
  sales: Scalars['Int']['output']
}

export type GTExpectedAmountByMethod = {
  __typename?: 'ExpectedAmountByMethod'
  bookings: GTAmounts
  kreditSales: GTAmounts
  mobileSales: GTAmounts
  sales: GTAmounts
}

export type GTFareFamiliesResponse = {
  __typename?: 'FareFamiliesResponse'
  /** fare families */
  items: Array<GTFareFamily>
  /** total number of fare families */
  total: Scalars['Int']['output']
}

export type GTFareFamily = {
  __typename?: 'FareFamily'
  company: GTFareFamilyCompany
  familyName: Scalars['String']['output']
  fares: Array<GTFareFamilyFare>
  id: Scalars['ID']['output']
  segment: GTFareFamilySegment
}

export type GTFareFamilyCompany = {
  __typename?: 'FareFamilyCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTFareFamilyFare = {
  __typename?: 'FareFamilyFare'
  basePrice: Scalars['Int']['output']
  bookingClass: GTFareFamilyFareBookingClass
  createdAt: Scalars['Time']['output']
  demand: GTDemand
  /**
   * DOW is an integer representing a day of the week
   *
   * 0 -> N/A
   *
   * 1 -> Monday
   *
   * 2 -> Tuesday
   *
   * 3 -> Wednesday
   *
   * 4 -> Thursday
   *
   * 5 -> Friday
   *
   * 6 -> Saturday
   *
   * 7 -> Sunday
   */
  dow: Scalars['Int']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  /**
   * SalesChannel is an integer representing a sales channel
   *
   * 0 -> N/A
   *
   * 1 -> Offline
   *
   * 2 -> Online
   */
  salesChannel: Scalars['Int']['output']
  seasonType: GTSeasonType
  serviceType: GTServiceType
  timeWindow: Scalars['Int']['output']
  type: GTFareType
  updatedAt: Scalars['Time']['output']
}

export type GTFareFamilyFareBookingClass = {
  __typename?: 'FareFamilyFareBookingClass'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTFareFamilyFareInput = {
  basePrice?: InputMaybe<Scalars['Int']['input']>
  bookingClassID: Scalars['ID']['input']
  demand: GTDemand
  /**
   * DOW is an integer representing a day of the week
   *
   * 0 -> N/A
   *
   * 1 -> Monday
   *
   * 2 -> Tuesday
   *
   * 3 -> Wednesday
   *
   * 4 -> Thursday
   *
   * 5 -> Friday
   *
   * 6 -> Saturday
   *
   * 7 -> Sunday
   */
  dow: Scalars['Int']['input']
  isActive: Scalars['Boolean']['input']
  /**
   * SalesChannel is an integer representing a sales channel
   *
   * 0 -> N/A
   *
   * 1 -> Offline
   *
   * 2 -> Online
   */
  salesChannel: Scalars['Int']['input']
  seasonType: GTSeasonType
  serviceType: GTServiceType
  timeWindow: Scalars['Int']['input']
  /** deprecated in favor of basePrice */
  total?: InputMaybe<Scalars['Int']['input']>
  type: GTFareType
}

export type GTFareFamilySegment = {
  __typename?: 'FareFamilySegment'
  destination: GTFareFamilySegmentPlace
  id: Scalars['ID']['output']
  origin: GTFareFamilySegmentPlace
}

export type GTFareFamilySegmentPlace = {
  __typename?: 'FareFamilySegmentPlace'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

/** fare rule */
export type GTFareRule = {
  __typename?: 'FareRule'
  /** activation at ranges */
  activationAtRanges?: Maybe<Array<GTFareRuleDateRange>>
  /** city segments */
  citySegments: Array<GTFareRuleCitySegment>
  /** company id */
  companyID: Scalars['String']['output']
  /** departure at ranges */
  departureAtRanges: Array<GTFareRuleDateRange>
  /** description */
  description: Scalars['String']['output']
  /** enabled */
  enabled: Scalars['Boolean']['output']
  /** exceptions */
  exceptions: GTFareRuleExceptions
  /** id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** price */
  price: Scalars['Int']['output']
  /** status */
  status: GTFareRuleStatus
  /** trips affected */
  tripsAffected: Scalars['Int']['output']
}

/** fare rule city segment */
export type GTFareRuleCitySegment = {
  __typename?: 'FareRuleCitySegment'
  /** all route services */
  allRouteServices: Scalars['Boolean']['output']
  /** all segments */
  allSegments: Scalars['Boolean']['output']
  /** destination */
  destination: GTFareRulePlace
  /** origin */
  origin: GTFareRulePlace
  /** route services */
  routeServices: Array<GTFareRuleRouteService>
  /** segments */
  segments: Array<GTFareRuleSegment>
}

/** fare rule date range */
export type GTFareRuleDateRange = {
  __typename?: 'FareRuleDateRange'
  /** start end */
  endAt: Scalars['Time']['output']
  /** start at */
  startAt: Scalars['Time']['output']
}

/** date range */
export type GTFareRuleDateRangeInput = {
  /** end at */
  endAt: Scalars['Time']['input']
  /** start at */
  startAt: Scalars['Time']['input']
}

/** fare rule exceptions */
export type GTFareRuleExceptions = {
  __typename?: 'FareRuleExceptions'
  /** days of week */
  dow?: Maybe<Array<Scalars['Int']['output']>>
}

/** exceptions */
export type GTFareRuleExceptionsInput = {
  /** day of week */
  dow?: InputMaybe<Array<Scalars['Int']['input']>>
}

/** fare rule query */
export type GTFareRuleInput = {
  /** id */
  id: Scalars['ID']['input']
}

/** itinerary stop */
export type GTFareRuleItineraryStop = {
  __typename?: 'FareRuleItineraryStop'
  /** day */
  day: Scalars['Int']['output']
  /** departure time in hh:mm format, in the timezone of the place */
  departureTime: Scalars['String']['output']
  /** place id */
  placeID: Scalars['ID']['output']
}

/** fare rule place */
export type GTFareRulePlace = {
  __typename?: 'FareRulePlace'
  /** code */
  code: Scalars['String']['output']
  /** id */
  id: Scalars['ID']['output']
  /** day of week */
  name: Scalars['String']['output']
}

/** fare rule route service */
export type GTFareRuleRouteService = {
  __typename?: 'FareRuleRouteService'
  /** code */
  code: Scalars['String']['output']
  /** id */
  id: Scalars['ID']['output']
}

/** fare rule segment */
export type GTFareRuleSegment = {
  __typename?: 'FareRuleSegment'
  /** destination */
  destination: GTFareRulePlace
  /** id */
  id: Scalars['ID']['output']
  /** origin */
  origin: GTFareRulePlace
}

/** fare rule status */
export const GTFareRuleStatus = {
  /** activated, in an activation range */
  Activated: 'activated',
  /** created, base status */
  Created: 'created',
  /** deactivated, in an activation range but disabled */
  Deactivated: 'deactivated',
  /** disabled, after last activation range */
  Expired: 'expired',
  /** scheduled, previous to an activation range */
  Scheduled: 'scheduled'
} as const

export type GTFareRuleStatus = (typeof GTFareRuleStatus)[keyof typeof GTFareRuleStatus]
/** city segment */
export type GTFareRulecitySegmentInput = {
  /** destination id */
  destinationID: Scalars['ID']['input']
  /** origin id */
  originID: Scalars['ID']['input']
  /** route services id */
  routeServiceIDs: Array<Scalars['ID']['input']>
  /** segments id */
  segmentIDs: Array<Scalars['ID']['input']>
}

/** fare rules query */
export type GTFareRulesInput = {
  /** destination city id */
  destinationCityID?: InputMaybe<Scalars['String']['input']>
  /** destination id */
  destinationID?: InputMaybe<Scalars['String']['input']>
  /** days of week */
  dow?: InputMaybe<Array<Scalars['Int']['input']>>
  /** origin city id */
  originCityID?: InputMaybe<Scalars['String']['input']>
  /** origin id */
  originID?: InputMaybe<Scalars['String']['input']>
  /** route service id */
  routeServiceID?: InputMaybe<Scalars['String']['input']>
  /** status */
  status?: InputMaybe<Scalars['String']['input']>
}

export type GTFareRulesResponse = {
  __typename?: 'FareRulesResponse'
  items: Array<GTFareRule>
  total: Scalars['Int']['output']
}

/** fare type */
export const GTFareType = {
  /** normal, available for all booking classes */
  M: 'M',
  /** promo, available if booking class allows it */
  P: 'P'
} as const

export type GTFareType = (typeof GTFareType)[keyof typeof GTFareType]
export type GTForceChangeUserAccountPasswordInput = {
  /** User Account ID */
  id: Scalars['UUID']['input']
  /** User Account Password */
  password: Scalars['String']['input']
}

export type GTFrequency = {
  __typename?: 'Frequency'
  unit: GTFrequencyUnit
  value: Scalars['Int']['output']
}

export type GTFrequencyInput = {
  unit: GTFrequencyUnitInput
  value: Scalars['Int']['input']
}

export const GTFrequencyUnit = {
  Day: 'DAY',
  Month: 'MONTH'
} as const

export type GTFrequencyUnit = (typeof GTFrequencyUnit)[keyof typeof GTFrequencyUnit]
export const GTFrequencyUnitInput = {
  Day: 'DAY',
  Month: 'MONTH'
} as const

export type GTFrequencyUnitInput = (typeof GTFrequencyUnitInput)[keyof typeof GTFrequencyUnitInput]
export type GTIncidenceSubCampaign = {
  __typename?: 'IncidenceSubCampaign'
  authorizer: Scalars['String']['output']
  campaign: GTSubCampaignCampaign
  coupons: Array<GTCoupon>
  endsAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  reason: Scalars['String']['output']
  startsAt: Scalars['String']['output']
  trip: GTSubCampaignTrip
}

export type GTIncidenceSubcampaign = {
  __typename?: 'IncidenceSubcampaign'
  campaignID: Scalars['ID']['output']
  campaignType: Scalars['String']['output']
  coupons: Array<GTIncidenceSubcampaignCoupon>
  endsAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  startsAt: Scalars['Time']['output']
  status: GTSubcampaignStatus
  trip: GTSubcampaignTrip
}

export type GTIncidenceSubcampaignCoupon = {
  __typename?: 'IncidenceSubcampaignCoupon'
  emails: Array<GTEmail>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  originalBooking: GTSubcampaignBooking
  redeemedBooking?: Maybe<GTSubcampaignBooking>
  status: GTCouponStatus
  supportInteractions: Array<GTSubcampaignSupportInteraction>
}

export type GTIncidenceSubcampaignInput = {
  id: Scalars['ID']['input']
}

export type GTIncidenceSubcampaignsInput = {
  campaignID?: InputMaybe<Scalars['ID']['input']>
  tripDestinationID?: InputMaybe<Scalars['ID']['input']>
  tripOriginID?: InputMaybe<Scalars['ID']['input']>
}

export type GTIncidenceSubcampaignsResponse = {
  __typename?: 'IncidenceSubcampaignsResponse'
  items: Array<GTIncidenceSubCampaign>
  total: Scalars['Int']['output']
}

export type GTInsurance = {
  __typename?: 'Insurance'
  bookingID: Scalars['ID']['output']
  certificateID?: Maybe<Scalars['String']['output']>
  confirmedAt?: Maybe<Scalars['Time']['output']>
  externalID?: Maybe<Scalars['String']['output']>
  hasConflict?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  insuranceCoverage?: Maybe<GTInsuranceCoverage>
  /** @deprecated Use statusV2 instead */
  status: GTAncillaryStatus
  statusV2: GTAncillaryStatusV2
  ticket: GTInsuranceTicket
}

export type GTInsuranceCoverage = {
  __typename?: 'InsuranceCoverage'
  arrivalAt: Scalars['Time']['output']
  departureAt: Scalars['Time']['output']
  passenger?: Maybe<GTInsurancePassenger>
}

export type GTInsurancePassenger = {
  __typename?: 'InsurancePassenger'
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
}

/** insurance settings */
export type GTInsuranceSettingsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  price?: InputMaybe<Scalars['Int']['input']>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTInsuranceTicket = {
  __typename?: 'InsuranceTicket'
  passenger: GTInsurancePassenger
  trip: GTTicketInsuranceTrip
}

export type GTInteractionUser = {
  __typename?: 'InteractionUser'
  username: Scalars['String']['output']
}

export type GTInventoriesAutocompleteInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  types?: InputMaybe<Array<GTInventoryAutocompleteType>>
}

export type GTInventoriesQueryInput = {
  name?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<GTInventoryType>
}

export type GTInventoriesResponse = {
  __typename?: 'InventoriesResponse'
  items: Array<GTInventory>
  total: Scalars['Int']['output']
}

export type GTInventory = {
  __typename?: 'Inventory'
  address?: Maybe<GTInventoryAddress>
  company: GTInventoryCompany
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  type: GTInventoryType
  users: Array<GTInventoryUser>
}

export type GTInventoryAddress = {
  __typename?: 'InventoryAddress'
  city?: Maybe<GTInventoryCity>
  line1?: Maybe<Scalars['String']['output']>
  line2?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type GTInventoryAddressInput = {
  cityID?: InputMaybe<Scalars['ID']['input']>
  line1?: InputMaybe<Scalars['String']['input']>
  line2?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}

/** inventory autocomplete */
export type GTInventoryAutocomplete = {
  __typename?: 'InventoryAutocomplete'
  /** inventory ID */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** type */
  type: GTInventoryType
}

export const GTInventoryAutocompleteType = {
  Base: 'base',
  Cart: 'cart',
  Warehouse: 'warehouse'
} as const

export type GTInventoryAutocompleteType = (typeof GTInventoryAutocompleteType)[keyof typeof GTInventoryAutocompleteType]
export type GTInventoryCity = {
  __typename?: 'InventoryCity'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTInventoryCompany = {
  __typename?: 'InventoryCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTInventoryInput = {
  address?: InputMaybe<GTInventoryAddressInput>
  name?: InputMaybe<Scalars['String']['input']>
  userIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** item */
export type GTInventoryItemResponse = {
  __typename?: 'InventoryItemResponse'
  /** brand */
  brand: Scalars['String']['output']
  /** category */
  category: Scalars['String']['output']
  /** container */
  container: Scalars['String']['output']
  /** item id */
  id: Scalars['ID']['output']
  /** indicates whether the item is active or not */
  isActive: Scalars['Boolean']['output']
  /** lost quantity */
  lostQuantity: Scalars['Int']['output']
  /** minimum selling price */
  minSellingPrice?: Maybe<Scalars['Int']['output']>
  /** name */
  name: Scalars['String']['output']
  /** needs refrigeration */
  needsRefrigeration: Scalars['Boolean']['output']
  /** net content */
  netContent: Scalars['Int']['output']
  /** photo path */
  photoPath: Scalars['String']['output']
  /** selling price */
  sellingPrice: Scalars['Int']['output']
  /** sold quantity */
  soldQuantity: Scalars['Int']['output']
  /** stock quantity */
  stockQuantity: Scalars['Int']['output']
  /** subcategory */
  subcategory?: Maybe<Scalars['String']['output']>
  /** measurement units */
  units: Scalars['String']['output']
}

export type GTInventoryItemsQueryInput = {
  category?: InputMaybe<Scalars['String']['input']>
  companyID?: InputMaybe<Scalars['ID']['input']>
  inStock?: InputMaybe<Scalars['Boolean']['input']>
  inventoryID: Scalars['ID']['input']
  itemID?: InputMaybe<Scalars['ID']['input']>
  subcategory?: InputMaybe<Scalars['String']['input']>
}

/** inventory item */
export type GTInventoryItemsResponse = {
  __typename?: 'InventoryItemsResponse'
  /** items */
  items: Array<GTInventoryItemResponse>
  /** total of inventory items */
  total: Scalars['Int']['output']
}

export type GTInventoryQueryInput = {
  id: Scalars['ID']['input']
}

/** inventory rule */
export type GTInventoryRule = {
  __typename?: 'InventoryRule'
  /** activation at ranges */
  activationAtRanges?: Maybe<Array<GTInventoryRuleDateRange>>
  /** cabin strategies */
  cabinStrategies: Array<GTInventoryRuleCabinStrategy>
  /** city segments */
  citySegments: Array<GTInventoryRuleCitySegment>
  /** company id */
  companyID: Scalars['String']['output']
  /** departure at ranges */
  departureAtRanges: Array<GTInventoryRuleDateRange>
  /** description */
  description: Scalars['String']['output']
  /** enabled */
  enabled: Scalars['Boolean']['output']
  /** exceptions */
  exceptions: GTInventoryRuleExceptions
  /** id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** status */
  status: GTInventoryRuleStatus
  /** trips affected */
  tripsAffected: Scalars['Int']['output']
}

/** inventory rule booking class */
export type GTInventoryRuleBookingClass = {
  __typename?: 'InventoryRuleBookingClass'
  /** id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** priority */
  priority: Scalars['Int']['output']
}

/** inventory rule cabin setting */
export type GTInventoryRuleCabinSetting = {
  __typename?: 'InventoryRuleCabinSetting'
  bookingClass: GTInventoryRuleBookingClass
  /** is active */
  isActive: Scalars['Boolean']['output']
  /** max load factor */
  maxLoadFactor: Scalars['Int']['output']
  /** promo */
  promo: Scalars['Boolean']['output']
}

/** cabin setting */
export type GTInventoryRuleCabinSettingInput = {
  /** booking class id */
  bookingClassID: Scalars['ID']['input']
  /** is active */
  isActive: Scalars['Boolean']['input']
  /** max load factor */
  maxLoadFactor: Scalars['Int']['input']
  /** promo */
  promo?: InputMaybe<Scalars['Boolean']['input']>
}

/** inventory rule cabin strategy */
export type GTInventoryRuleCabinStrategy = {
  __typename?: 'InventoryRuleCabinStrategy'
  /** activation ranges */
  activationRanges: Array<GTInventoryRuleDaysToDepartureRange>
  /** cabin settings */
  cabinSettings: Array<GTInventoryRuleCabinSetting>
  /** id */
  id: Scalars['ID']['output']
  /** is default */
  isDefault: Scalars['Boolean']['output']
  /** name */
  name: Scalars['String']['output']
  /** version */
  version: Scalars['Int']['output']
}

/** inventory rule cabin strategy */
export type GTInventoryRuleCabinStrategyInput = {
  /** activation ranges */
  activationRanges?: InputMaybe<Array<GTInventoryRuleDaysToDepartureRangeInput>>
  /** cabin settings */
  cabinSettings: Array<GTInventoryRuleCabinSettingInput>
}

/** inventory rule city segment */
export type GTInventoryRuleCitySegment = {
  __typename?: 'InventoryRuleCitySegment'
  /** all route services */
  allRouteServices: Scalars['Boolean']['output']
  /** all segments */
  allSegments: Scalars['Boolean']['output']
  /** destination */
  destination: GTInventoryRulePlace
  /** origin */
  origin: GTInventoryRulePlace
  /** route services */
  routeServices: Array<GTInventoryRuleRouteService>
  /** segments */
  segments: Array<GTInventoryRuleSegment>
}

/** inventory rule date range */
export type GTInventoryRuleDateRange = {
  __typename?: 'InventoryRuleDateRange'
  /** start end */
  endAt: Scalars['Time']['output']
  /** start at */
  startAt: Scalars['Time']['output']
}

/** date range */
export type GTInventoryRuleDateRangeInput = {
  /** end at */
  endAt: Scalars['Time']['input']
  /** start at */
  startAt: Scalars['Time']['input']
}

/** days to departure range */
export type GTInventoryRuleDaysToDepartureRange = {
  __typename?: 'InventoryRuleDaysToDepartureRange'
  /** end */
  end: Scalars['Int']['output']
  /** start */
  start: Scalars['Int']['output']
}

/** days to departure range */
export type GTInventoryRuleDaysToDepartureRangeInput = {
  /** end */
  end: Scalars['Int']['input']
  /** start */
  start: Scalars['Int']['input']
}

/** inventory rule exceptions */
export type GTInventoryRuleExceptions = {
  __typename?: 'InventoryRuleExceptions'
  /** days of week */
  dow: Array<Scalars['Int']['output']>
  /** route services */
  routeServices: Array<GTInventoryRuleRouteService>
}

/** exceptions */
export type GTInventoryRuleExceptionsInput = {
  /** day of week */
  dow?: InputMaybe<Array<Scalars['Int']['input']>>
  /** route services id */
  routeServiceIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** inventory rule query */
export type GTInventoryRuleInput = {
  /** id */
  id: Scalars['ID']['input']
}

/** itinerary stop */
export type GTInventoryRuleItineraryStop = {
  __typename?: 'InventoryRuleItineraryStop'
  /** day */
  day: Scalars['Int']['output']
  /** departure time in hh:mm format, in the timezone of the place */
  departureTime: Scalars['String']['output']
  /** place id */
  placeID: Scalars['ID']['output']
}

/** inventory rule place */
export type GTInventoryRulePlace = {
  __typename?: 'InventoryRulePlace'
  /** code */
  code: Scalars['String']['output']
  /** id */
  id: Scalars['ID']['output']
  /** day of week */
  name: Scalars['String']['output']
}

/** inventory rule route service */
export type GTInventoryRuleRouteService = {
  __typename?: 'InventoryRuleRouteService'
  /** code */
  code: Scalars['String']['output']
  /** id */
  id: Scalars['ID']['output']
}

/** inventory rule segment */
export type GTInventoryRuleSegment = {
  __typename?: 'InventoryRuleSegment'
  /** destination */
  destination: GTInventoryRulePlace
  /** id */
  id: Scalars['ID']['output']
  /** origin */
  origin: GTInventoryRulePlace
}

/** inventory rule status */
export const GTInventoryRuleStatus = {
  /** activated, in an activation range */
  Activated: 'activated',
  /** created, base status */
  Created: 'created',
  /** deactivated, in an activation range but disabled */
  Deactivated: 'deactivated',
  /** expired, after last activation range */
  Expired: 'expired',
  /** scheduled, previous to an activation range */
  Scheduled: 'scheduled'
} as const

export type GTInventoryRuleStatus = (typeof GTInventoryRuleStatus)[keyof typeof GTInventoryRuleStatus]
/** city segment */
export type GTInventoryRulecitySegmentInput = {
  /** destination id */
  destinationID: Scalars['ID']['input']
  /** origin id */
  originID: Scalars['ID']['input']
  /** route services id */
  routeServiceIDs: Array<Scalars['ID']['input']>
  /** segments id */
  segmentIDs: Array<Scalars['ID']['input']>
}

/** inventory rules query */
export type GTInventoryRulesInput = {
  /** destination city id */
  destinationCityID?: InputMaybe<Scalars['String']['input']>
  /** destination id */
  destinationID?: InputMaybe<Scalars['String']['input']>
  /** days of week */
  dow?: InputMaybe<Array<Scalars['Int']['input']>>
  /** origin city id */
  originCityID?: InputMaybe<Scalars['String']['input']>
  /** origin id */
  originID?: InputMaybe<Scalars['String']['input']>
  /** route service id */
  routeServiceID?: InputMaybe<Scalars['String']['input']>
  /** status */
  status?: InputMaybe<GTInventoryRuleStatus>
}

/** inventory rules response */
export type GTInventoryRulesResponse = {
  __typename?: 'InventoryRulesResponse'
  items: Array<GTInventoryRule>
  total: Scalars['Int']['output']
}

export const GTInventoryType = {
  Base: 'base',
  Cart: 'cart',
  Warehouse: 'warehouse'
} as const

export type GTInventoryType = (typeof GTInventoryType)[keyof typeof GTInventoryType]
export type GTInventoryUser = {
  __typename?: 'InventoryUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type GTInventoryWaste = {
  __typename?: 'InventoryWaste'
  amount: Scalars['Int']['output']
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  inventory: GTInventoryWasteInventory
  items: Array<GTInventoryWasteItem>
  reason: GTItemWasteReason
  status: GTInventoryWasteStatus
  user: GTInventoryWasteUser
}

export type GTInventoryWasteInventory = {
  __typename?: 'InventoryWasteInventory'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTInventoryWasteItem = {
  __typename?: 'InventoryWasteItem'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  price: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
  subtotal: Scalars['Int']['output']
}

export const GTInventoryWasteStatus = {
  Cancelled: 'cancelled',
  Created: 'created'
} as const

export type GTInventoryWasteStatus = (typeof GTInventoryWasteStatus)[keyof typeof GTInventoryWasteStatus]
export type GTInventoryWasteUser = {
  __typename?: 'InventoryWasteUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
}

export type GTInventoryWastesQueryInput = {
  creationDateRange?: InputMaybe<GTDateRangeInput>
  id?: InputMaybe<Scalars['ID']['input']>
  inventoryID?: InputMaybe<Scalars['ID']['input']>
  itemID?: InputMaybe<Scalars['ID']['input']>
}

export type GTInventoryWastesResponse = {
  __typename?: 'InventoryWastesResponse'
  items: Array<GTInventoryWaste>
  total: Scalars['Int']['output']
}

/** item */
export type GTItem = {
  __typename?: 'Item'
  /** brand */
  brand: Scalars['String']['output']
  /** category */
  category: Scalars['String']['output']
  /** container */
  container: Scalars['String']['output']
  /** item id */
  id: Scalars['ID']['output']
  /** indicates whether the item is active or not */
  isActive: Scalars['Boolean']['output']
  /** minimum selling price */
  minSellingPrice?: Maybe<Scalars['Int']['output']>
  /** name */
  name: Scalars['String']['output']
  /** needs refrigeration */
  needsRefrigeration: Scalars['Boolean']['output']
  /** net content */
  netContent: Scalars['Int']['output']
  /** photo path */
  photoPath?: Maybe<Scalars['String']['output']>
  /** selling price */
  sellingPrice: Scalars['Int']['output']
  /** subcategory */
  subcategory?: Maybe<Scalars['String']['output']>
  /** template name */
  templateName?: Maybe<GTTemplateName>
  /** measurement units */
  units: Scalars['String']['output']
}

/** item autocomplete */
export type GTItemAutocomplete = {
  __typename?: 'ItemAutocomplete'
  /** brand */
  brand: Scalars['String']['output']
  /** category */
  category: Scalars['String']['output']
  /** container */
  container: Scalars['String']['output']
  /** item ID */
  id: Scalars['ID']['output']
  /** isActive */
  isActive: Scalars['Boolean']['output']
  /** name */
  name: Scalars['String']['output']
  /** netContent */
  netContent: Scalars['Int']['output']
  /** subcategory */
  subcategory?: Maybe<Scalars['String']['output']>
  /** measurement units */
  units: Scalars['String']['output']
}

export type GTItemComboComponentInput = {
  eligibleItemIDs: Array<Scalars['ID']['input']>
  quantity: Scalars['Int']['input']
}

export type GTItemInput = {
  /** brand */
  brand?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<Scalars['String']['input']>
  container?: InputMaybe<Scalars['String']['input']>
  /** the item's minimum selling price in cents */
  minSellingPrice?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  needsRefrigeration?: InputMaybe<Scalars['Boolean']['input']>
  /** net content in milliliters or milligrams */
  netContent?: InputMaybe<Scalars['Int']['input']>
  photoPath?: InputMaybe<Scalars['String']['input']>
  /** the item's selling price in cents */
  sellingPrice?: InputMaybe<Scalars['Int']['input']>
  subcategory?: InputMaybe<Scalars['String']['input']>
  /** measurement units */
  units?: InputMaybe<Scalars['String']['input']>
}

export type GTItemKolorsMobilePlanInput = {
  name?: InputMaybe<Scalars['String']['input']>
  photoPath?: InputMaybe<Scalars['String']['input']>
  planID?: InputMaybe<Scalars['ID']['input']>
  sellingPrice?: InputMaybe<Scalars['Int']['input']>
}

export type GTItemQueryInput = {
  id: Scalars['ID']['input']
}

export const GTItemRemovalReason = {
  Accident: 'accident',
  AdministrativeError: 'administrativeError',
  Expired: 'expired',
  OperativeWaste: 'operativeWaste',
  Return: 'return'
} as const

export type GTItemRemovalReason = (typeof GTItemRemovalReason)[keyof typeof GTItemRemovalReason]
export const GTItemWasteReason = {
  Accident: 'accident',
  AdministrativeError: 'administrativeError',
  Expired: 'expired',
  OperativeWaste: 'operativeWaste',
  Return: 'return'
} as const

export type GTItemWasteReason = (typeof GTItemWasteReason)[keyof typeof GTItemWasteReason]
export type GTItemsAutocompleteInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  includeSims?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type GTItemsQueryInput = {
  category?: InputMaybe<Scalars['String']['input']>
  companyID?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  subcategory?: InputMaybe<Scalars['String']['input']>
}

export type GTItemsResponse = {
  __typename?: 'ItemsResponse'
  items: Array<GTItem>
  total: Scalars['Int']['output']
}

export type GTItineraryDifference = {
  __typename?: 'ItineraryDifference'
  new: Array<GTChangeStop>
  old: Array<GTChangeStop>
}

export type GTItineraryScheduleNote = {
  __typename?: 'ItineraryScheduleNote'
  createdAt: Scalars['Time']['output']
  text: Scalars['String']['output']
  user: GTRouteServiceUser
}

export type GTKolorsMobilePlanItem = {
  __typename?: 'KolorsMobilePlanItem'
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  photoPath: Scalars['String']['output']
  planID: Scalars['ID']['output']
  sellingPrice: Scalars['Int']['output']
  templateName: GTTemplateName
}

export type GTKolorsMobilePlanItemInput = {
  id: Scalars['ID']['input']
}

export type GTKolorsMobilePlanItemsInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
}

export type GTKolorsMobilePlanItemsResponse = {
  __typename?: 'KolorsMobilePlanItemsResponse'
  items: Array<GTKolorsMobilePlanItem>
  total: Scalars['Int']['output']
}

/**
 * The Kredit type represents a user kolors credits container,
 * including its balance.
 */
export type GTKredit = {
  __typename?: 'Kredit'
  accounts: Array<GTKreditAccount>
  /** The balance of the Kolors Credits container, represented in cents. */
  balance: Scalars['Int']['output']
  businessClient?: Maybe<GTKreditBusinessClient>
  businessCustomer?: Maybe<GTKreditBusinessCustomer>
  businessSite?: Maybe<GTKreditBusinessSite>
  /** The timestamp of when the Kolors Credits container was created. */
  createdAt: Scalars['Time']['output']
  /** The unique ID of the Kolors Credits container. */
  id: Scalars['ID']['output']
  /** The timestamp of when the Kolors Credits container was last updated. */
  updatedAt: Scalars['Time']['output']
  /** The user related to this Kolors Credits container. */
  user: GTKreditUser
}

export type GTKreditAccount = {
  __typename?: 'KreditAccount'
  balance: Scalars['Int']['output']
  type: GTKreditAccountType
}

export const GTKreditAccountType = {
  Bonus: 'BONUS',
  Corporate: 'CORPORATE',
  Refund: 'REFUND',
  TopUp: 'TOP_UP'
} as const

export type GTKreditAccountType = (typeof GTKreditAccountType)[keyof typeof GTKreditAccountType]
export type GTKreditBusinessClient = {
  __typename?: 'KreditBusinessClient'
  id: Scalars['UUID']['output']
  isKreditEnabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type GTKreditBusinessCustomer = {
  __typename?: 'KreditBusinessCustomer'
  category?: Maybe<Scalars['Int']['output']>
  id: Scalars['UUID']['output']
}

export type GTKreditBusinessSite = {
  __typename?: 'KreditBusinessSite'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTKreditCategory = {
  __typename?: 'KreditCategory'
  amount: Scalars['Int']['output']
  frequency: GTFrequency
  name: Scalars['Int']['output']
}

export type GTKreditCategoryInput = {
  amount: Scalars['Int']['input']
  frequency: GTFrequencyInput
  name: Scalars['Int']['input']
}

/** Input for querying a specific kredit by ID. */
export type GTKreditInput = {
  /** The ID of the kredit to query. */
  id: Scalars['ID']['input']
}

export type GTKreditSettingsBusinessSite = {
  __typename?: 'KreditSettingsBusinessSite'
  categories: Array<GTKreditCategory>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

/** The KreditTransaction type represents a single transaction made on a Kredit. */
export type GTKreditTransaction = {
  __typename?: 'KreditTransaction'
  accounts: Array<GTKreditAccountType>
  /** The amount of the transaction, represented in cents. */
  amount: Scalars['Int']['output']
  /**
   * The potential booking ID associated with the transaction.
   * @deprecated Use transactionSource instead.
   */
  bookingID?: Maybe<Scalars['ID']['output']>
  /** The timestamp of when the transaction was created. */
  createdAt: Scalars['Time']['output']
  /** The unique ID of the transaction. */
  id: Scalars['ID']['output']
  /**
   * The potential payment ID associated with the transaction.
   * @deprecated Use transactionSource instead.
   */
  paymentID?: Maybe<Scalars['ID']['output']>
  /** The source details of the transacion */
  source: GTTransactionSource
  /** The type of transaction made (deposit, withdrawal, transfer, etc.). */
  type: GTKreditTransactionType
}

export const GTKreditTransactionType = {
  Deposit: 'DEPOSIT',
  Refund: 'REFUND',
  Unknown: 'UNKNOWN',
  Withdrawal: 'WITHDRAWAL'
} as const

export type GTKreditTransactionType = (typeof GTKreditTransactionType)[keyof typeof GTKreditTransactionType]
export type GTKreditTransactionsInput = {
  kreditID: Scalars['ID']['input']
}

export type GTKreditTransactionsResponse = {
  __typename?: 'KreditTransactionsResponse'
  items: Array<GTKreditTransaction>
  total: Scalars['Int']['output']
}

/** The KreditUser type represents a single user in the system. */
export type GTKreditUser = {
  __typename?: 'KreditUser'
  /** The email of the user. */
  email: Scalars['String']['output']
  /** The unique ID of the user. */
  id: Scalars['ID']['output']
  /** The phone number of the user. */
  phone: Scalars['String']['output']
  /** The full name of the user. */
  userFullName: Scalars['String']['output']
}

/** Input for querying kredits by associated user details. */
export type GTKreditsInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  category?: InputMaybe<Scalars['Int']['input']>
  /** The email of the user to find associated kredits. */
  email?: InputMaybe<Scalars['String']['input']>
  /** The phone number of the user to find associated kredits. */
  phone?: InputMaybe<Scalars['String']['input']>
  /** The full name of the user to find associated kredits. */
  userFullName?: InputMaybe<Scalars['String']['input']>
}

/**
 * The response from querying for a list of kredits. Includes the list of kredits
 * and the total count of kredits.
 */
export type GTKreditsResponse = {
  __typename?: 'KreditsResponse'
  /** The specific kredits that match the query. */
  items: Array<GTKredit>
  /** The total number of kredits that match the query. */
  total: Scalars['Int']['output']
}

export type GTKreditsSettings = {
  __typename?: 'KreditsSettings'
  businessSites?: Maybe<Array<GTKreditSettingsBusinessSite>>
  categories: Array<GTKreditCategory>
}

/** language */
export const GTLanguage = {
  En: 'EN',
  Es: 'ES'
} as const

export type GTLanguage = (typeof GTLanguage)[keyof typeof GTLanguage]
export type GTLimit = {
  __typename?: 'Limit'
  /** limit key */
  key: Scalars['String']['output']
  /** limit type */
  type: GTLimitType
  /** limit value */
  value?: Maybe<Scalars['Int']['output']>
}

/** limit type */
export const GTLimitType = {
  Passenger: 'passenger'
} as const

export type GTLimitType = (typeof GTLimitType)[keyof typeof GTLimitType]
export type GTLineItemComboComponent = {
  __typename?: 'LineItemComboComponent'
  item: GTItem
  quantity: Scalars['Int']['output']
}

/** geolocation */
export type GTLocation = {
  __typename?: 'Location'
  /** latitude */
  latitude: Scalars['String']['output']
  /** longitude */
  longitude: Scalars['String']['output']
}

export type GTLocationCitiesInput = {
  salesChannelID: Scalars['UUID']['input']
  vertical?: GTPlaceVertical
}

export type GTLocationCitiesResponse = {
  __typename?: 'LocationCitiesResponse'
  locationCities: Array<GTLocationCity>
}

export type GTLocationCity = {
  __typename?: 'LocationCity'
  id: Scalars['UUID']['output']
  isDestination: Scalars['Boolean']['output']
  isOrigin: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  param: Scalars['String']['output']
  places: Array<GTLocationPlace>
}

export type GTLocationCityInput = {
  id?: InputMaybe<Scalars['UUID']['input']>
  param?: InputMaybe<Scalars['String']['input']>
  salesChannelID: Scalars['UUID']['input']
  vertical?: GTPlaceVertical
}

export type GTLocationPlace = {
  __typename?: 'LocationPlace'
  address: GTLocationPlaceAddress
  amenities: Array<GTLocationPlaceAmenity>
  directions: Scalars['String']['output']
  id: Scalars['UUID']['output']
  isDestination: Scalars['Boolean']['output']
  isOrigin: Scalars['Boolean']['output']
  location: GTLocationPlaceLocation
  name: Scalars['String']['output']
  param: Scalars['String']['output']
  type: GTLocationPlaceType
}

export type GTLocationPlaceAddress = {
  __typename?: 'LocationPlaceAddress'
  city: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  line1: Scalars['String']['output']
  line2?: Maybe<Scalars['String']['output']>
  stateCode: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export const GTLocationPlaceAmenity = {
  KolorsMobile: 'KOLORS_MOBILE',
  KolorsTicket: 'KOLORS_TICKET'
} as const

export type GTLocationPlaceAmenity = (typeof GTLocationPlaceAmenity)[keyof typeof GTLocationPlaceAmenity]
export type GTLocationPlaceLocation = {
  __typename?: 'LocationPlaceLocation'
  latitude: Scalars['String']['output']
  longitude: Scalars['String']['output']
}

export const GTLocationPlaceType = {
  Attraction: 'ATTRACTION',
  ConvenienceStore: 'CONVENIENCE_STORE',
  Hotel: 'HOTEL',
  Mall: 'MALL',
  Office: 'OFFICE',
  ParkingLot: 'PARKING_LOT',
  PointOfInterest: 'POINT_OF_INTEREST',
  ResidentialArea: 'RESIDENTIAL_AREA',
  Restaurant: 'RESTAURANT',
  School: 'SCHOOL',
  Sidewalk: 'SIDEWALK',
  Station: 'STATION',
  Supermarket: 'SUPERMARKET',
  Venue: 'VENUE'
} as const

export type GTLocationPlaceType = (typeof GTLocationPlaceType)[keyof typeof GTLocationPlaceType]
export type GTLoyaltyCampaign = {
  __typename?: 'LoyaltyCampaign'
  company: GTLoyaltyCampaignCompany
  companyLines: Array<GTLoyaltyCampaignCompanyLine>
  description?: Maybe<Scalars['String']['output']>
  endAt: Scalars['Time']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  rules: Array<GTLoyaltyCampaignRule>
  startAt: Scalars['Time']['output']
  status: Scalars['String']['output']
}

export type GTLoyaltyCampaignCompany = {
  __typename?: 'LoyaltyCampaignCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTLoyaltyCampaignCompanyLine = {
  __typename?: 'LoyaltyCampaignCompanyLine'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTLoyaltyCampaignConditions = {
  __typename?: 'LoyaltyCampaignConditions'
  citySegments?: Maybe<Array<Maybe<GTLoyaltyCampaignRuleConditionsCitySegment>>>
  routeServices?: Maybe<Array<GTLoyaltyCampaignRuleConditionsRouteService>>
  /** @deprecated use salesChannelsV2 */
  salesChannels: Array<Scalars['String']['output']>
  salesChannelsV2: Array<GTLoyaltyCampaignConditionsSalesChannel>
  totalBookingsValue?: Maybe<Scalars['Int']['output']>
}

export type GTLoyaltyCampaignConditionsSalesChannel = {
  __typename?: 'LoyaltyCampaignConditionsSalesChannel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTLoyaltyCampaignEffect = {
  __typename?: 'LoyaltyCampaignEffect'
  reward?: Maybe<GTLoyaltyCampaignReward>
}

export type GTLoyaltyCampaignInput = {
  id: Scalars['ID']['input']
}

export type GTLoyaltyCampaignPlace = {
  __typename?: 'LoyaltyCampaignPlace'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTLoyaltyCampaignReward = {
  __typename?: 'LoyaltyCampaignReward'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTLoyaltyCampaignRule = {
  __typename?: 'LoyaltyCampaignRule'
  conditions?: Maybe<GTLoyaltyCampaignConditions>
  description?: Maybe<Scalars['String']['output']>
  details: Scalars['String']['output']
  effects: Array<GTLoyaltyCampaignEffect>
  howToGet: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  validAt: Scalars['String']['output']
}

export type GTLoyaltyCampaignRuleConditionsCitySegment = {
  __typename?: 'LoyaltyCampaignRuleConditionsCitySegment'
  destination: GTLoyaltyCampaignPlace
  /** @deprecated use destination */
  destinationID: Scalars['ID']['output']
  origin: GTLoyaltyCampaignPlace
  /** @deprecated use origin */
  originID: Scalars['ID']['output']
  /** @deprecated use segments */
  segmentIDs: Array<Scalars['ID']['output']>
  segments: Array<GTLoyaltyCampaignSegment>
}

export type GTLoyaltyCampaignRuleConditionsRouteService = {
  __typename?: 'LoyaltyCampaignRuleConditionsRouteService'
  code: Scalars['String']['output']
  id: Scalars['UUID']['output']
}

export type GTLoyaltyCampaignRuleInput = {
  id: Scalars['ID']['input']
  ruleID: Scalars['ID']['input']
}

export type GTLoyaltyCampaignSegment = {
  __typename?: 'LoyaltyCampaignSegment'
  destination: GTLoyaltyCampaignPlace
  id: Scalars['ID']['output']
  origin: GTLoyaltyCampaignPlace
}

export type GTLoyaltyCampaignsInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
}

export type GTLoyaltyCampaignsResponse = {
  __typename?: 'LoyaltyCampaignsResponse'
  items: Array<GTLoyaltyCampaign>
  total: Scalars['Int']['output']
}

export type GTLoyaltyReward = {
  __typename?: 'LoyaltyReward'
  company: GTLoyaltyRewardCompany
  /** @deprecated use company.id instead */
  companyID: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  earnedCount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  redeemedCount: Scalars['Int']['output']
  type: GTLoyaltyRewardType
}

export type GTLoyaltyRewardAutocomplete = {
  __typename?: 'LoyaltyRewardAutocomplete'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTLoyaltyRewardCompany = {
  __typename?: 'LoyaltyRewardCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GTLoyaltyRewardType = {
  KolorsGoRewardMvp: 'KolorsGoRewardMVP',
  KolorsRewardMvp: 'KolorsRewardMVP'
} as const

export type GTLoyaltyRewardType = (typeof GTLoyaltyRewardType)[keyof typeof GTLoyaltyRewardType]
export type GTLoyaltyRewardsAutocompleteInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type GTLoyaltyRewardsInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
}

export type GTLoyaltyRewardsResponse = {
  __typename?: 'LoyaltyRewardsResponse'
  items: Array<GTLoyaltyReward>
  total: Scalars['Int']['output']
}

export type GTMarkVehicleOutOfServiceInput = {
  id: Scalars['UUID']['input']
}

export type GTMutation = {
  __typename?: 'Mutation'
  /** activate a booking class */
  activateBookingClass: Scalars['Boolean']['output']
  activateBusinessClient: Scalars['Boolean']['output']
  activateBusinessClientUser: Scalars['Boolean']['output']
  activateBusinessSite: Scalars['Boolean']['output']
  /** activate campaign */
  activateCampaign: Scalars['Boolean']['output']
  /** activate campaign coupon */
  activateCampaignCoupon: Scalars['Boolean']['output']
  /** activate an item */
  activateItem?: Maybe<GTToggleItemResponse>
  activatePartner: Scalars['Boolean']['output']
  /** activate a season event */
  activateSeasonEvent: Scalars['Boolean']['output']
  /** activate a route service */
  activateService?: Maybe<GTToggleServiceResponse>
  /** activate a site */
  activateSite: GTToggleSiteResponse
  /** activate correlated trips for sale */
  activateTripSale: Scalars['Boolean']['output']
  /** activate a user */
  activateUser?: Maybe<GTToggleUserResponse>
  activateVehicle: Scalars['Boolean']['output']
  /** add booking tickets insurance */
  addBookingTicketsInsurance: Scalars['Boolean']['output']
  addCampaignCouponSupportInteraction: Scalars['Boolean']['output']
  /** add campaign snack coupon */
  addCampaignSnacksCoupon: Scalars['Boolean']['output']
  /** add coupon */
  addCoupon: Scalars['Boolean']['output']
  /** add fare family fares */
  addFareFamilyFares: GTAddFareFamilyFaresResponse
  addLoyaltyCampaignRuleConditionsCitySegments: Scalars['Boolean']['output']
  addLoyaltyCampaignRuleConditionsRouteServices: Scalars['Boolean']['output']
  addLoyaltyCampaignRuleConditionsSalesChannels: Scalars['Boolean']['output']
  addPartnerDocuments: Scalars['Boolean']['output']
  addRouteServicePeaks: Scalars['Boolean']['output']
  /** add segment company line spec */
  addSegmentCompanyLineSpec: Scalars['Boolean']['output']
  addServiceFeeAddonRule: Scalars['Boolean']['output']
  addTripNote: Scalars['Boolean']['output']
  /** apply booking coupon */
  applyBookingCoupon: GTApplyBookingCouponResponse
  /** archive inventory */
  archiveInventory: Scalars['Boolean']['output']
  archiveRouteServiceItinerarySchedule: Scalars['Boolean']['output']
  /** archive a vehicle template */
  archiveVehicleTemplate: Scalars['Boolean']['output']
  assignBusinessCustomerKreditCategory: Scalars['Boolean']['output']
  assignBusinessCustomersKreditCategory: Scalars['Boolean']['output']
  /** cancel booking tickets */
  cancelBookingTickets: Scalars['Boolean']['output']
  /** cancel booking tickets insurance */
  cancelBookingTicketsInsurance: Scalars['Boolean']['output']
  /** cancel booking trip change */
  cancelBookingTripChange: Scalars['Boolean']['output']
  /** cancel booking trip change proposal */
  cancelBookingTripChangeProposal: Scalars['Boolean']['output']
  /** cancel a scheduled change */
  cancelChange: Scalars['Boolean']['output']
  /** cancel an order */
  cancelOrder: Scalars['Boolean']['output']
  /** cancel sale items */
  cancelSaleItems: Scalars['Boolean']['output']
  /**
   * cancel tickets
   * @deprecated Use cancelBookingTickets instead
   */
  cancelTickets: Scalars['Boolean']['output']
  /** cancel trip */
  cancelTrip: Scalars['Boolean']['output']
  /** cancel multiple trips */
  cancelTrips: Scalars['Boolean']['output']
  changeAncillary: Scalars['Boolean']['output']
  /** change booking passengers names */
  changeBookingPassengersNames: Scalars['Boolean']['output']
  /** change a bookings's payment details */
  changeBookingPayment: Scalars['Boolean']['output']
  /** change booking tickets ancillaries */
  changeBookingTicketsAncillaries: Scalars['Boolean']['output']
  /** change booking tripChange ticket seat */
  changeBookingTripChangeTicketSeat: Scalars['Boolean']['output']
  changeBusinessClient: Scalars['Boolean']['output']
  changeBusinessClientContacts: Scalars['Boolean']['output']
  changeBusinessClientCustomerKreditsAmount: Scalars['Boolean']['output']
  changeBusinessClientCustomersKreditsAmount: Scalars['Boolean']['output']
  changeBusinessClientKreditSettings: Scalars['Boolean']['output']
  changeBusinessClientSiteKreditSettings: Scalars['Boolean']['output']
  changeBusinessClientUser: Scalars['Boolean']['output']
  changeBusinessCustomerBusinessClient: Scalars['Boolean']['output']
  changeBusinessCustomerBusinessSite: Scalars['Boolean']['output']
  changeBusinessSite: Scalars['Boolean']['output']
  changeBusinessSiteContacts: Scalars['Boolean']['output']
  /** change a booking's buyer */
  changeBuyer: Scalars['Boolean']['output']
  /** change a cart */
  changeCart: Scalars['Boolean']['output']
  /** change a combo item */
  changeComboItem: Scalars['Boolean']['output']
  /** change company insurance settings */
  changeCompanyInsuranceSettings: Scalars['Boolean']['output']
  changeCurrencyExchangeRate: Scalars['Boolean']['output']
  changeDriverDocuments: Scalars['Boolean']['output']
  /** change fare family fares */
  changeFareFamilyFares: Scalars['Boolean']['output']
  /** change fare rule */
  changeFareRule: Scalars['Boolean']['output']
  /** change an inventory */
  changeInventory: Scalars['Boolean']['output']
  /** change inventory rule */
  changeInventoryRule: Scalars['Boolean']['output']
  /** change inventory rule cabin strategies */
  changeInventoryRuleCabinStrategies: Scalars['Boolean']['output']
  /** change an item */
  changeItem: Scalars['Boolean']['output']
  /** change a kolors mobile plan item */
  changeItemKolorsMobilePlan: Scalars['Boolean']['output']
  changeLoyaltyCampaign: Scalars['Boolean']['output']
  changeLoyaltyCampaignRule: Scalars['Boolean']['output']
  /** change an order */
  changeOrder: Scalars['Boolean']['output']
  changePartnerBankDetails: Scalars['Boolean']['output']
  changePartnerContacts: Scalars['Boolean']['output']
  changePartnerDetails: Scalars['Boolean']['output']
  changePartnerKAMUser: Scalars['Boolean']['output']
  changePartnerTaxInformation: Scalars['Boolean']['output']
  changePassengerProperty: Scalars['Boolean']['output']
  changePassengerType: Scalars['Boolean']['output']
  changePlaceV2: Scalars['Boolean']['output']
  changeRouteServiceItinerariesSchedule: Scalars['Boolean']['output']
  changeRouteServiceVehiclesSchedule: Scalars['Boolean']['output']
  changeServiceFeeAddonRule: Scalars['Boolean']['output']
  /** change a site. only the properties to change should be included. */
  changeSite: Scalars['Boolean']['output']
  changeTDriverDetails: Scalars['Boolean']['output']
  changeTRoute: Scalars['Boolean']['output']
  changeTTripDriver: Scalars['Boolean']['output']
  changeTVehicleCards: Scalars['Boolean']['output']
  changeTVehicleDetails: Scalars['Boolean']['output']
  changeTVehicleDocuments: Scalars['Boolean']['output']
  changeTVehicleDrivers: Scalars['Boolean']['output']
  changeTVehicleFeatures: Scalars['Boolean']['output']
  changeTVehicleInsurancePolicy: Scalars['Boolean']['output']
  changeTVehicleLicensePlates: Scalars['Boolean']['output']
  changeTVehicleOdometerReading: Scalars['Boolean']['output']
  changeTVehicleRegistrationDetails: Scalars['Boolean']['output']
  /** change a ticket's passenger details */
  changeTicketsPassenger: Scalars['Boolean']['output']
  /** deselect a seat and select another */
  changeTripBookingSeat: Scalars['Boolean']['output']
  changeTripConfirmationModalities: Scalars['Boolean']['output']
  /**
   * change trip itinerary departure time
   * @deprecated Use createRouteServiceItinerariesSchedule instead
   */
  changeTripItineraryDepartureTime: Scalars['Boolean']['output']
  /**
   * change trip route
   * @deprecated Use createRouteServiceItinerariesSchedule instead
   */
  changeTripRoute: Scalars['Boolean']['output']
  /** changes the crew users assigned to a trip */
  changeTripUsers: Scalars['Boolean']['output']
  /**
   * change trip vehicle
   * @deprecated Use createRouteServiceVehiclesSchedule instead
   */
  changeTripVehicle: Scalars['Boolean']['output']
  /**
   * change trips vehicle
   * @deprecated Not used anymore
   */
  changeTripsVehicle: Scalars['Boolean']['output']
  /** Change User Account Details */
  changeUserAccountDetails: Scalars['Boolean']['output']
  changeUserPassword: Scalars['Boolean']['output']
  /** confirm booking payment on site */
  confirmBookingPaymentOnSite: Scalars['Boolean']['output']
  /** confirm booking trip change proposal */
  confirmBookingTripChangeProposal: Scalars['Boolean']['output']
  /** confirm booking tripChanges */
  confirmBookingTripChanges: GTConfirmBookingTripChangesResponse
  createAncillary: Scalars['Boolean']['output']
  /** create a booking class */
  createBookingClass: Scalars['Boolean']['output']
  /** create booking trip change proposal */
  createBookingTripChangeProposal: GTCreateBookingTripChangeProposalResponse
  createBusinessClient: GTCreateBusinessClientResponse
  createBusinessClientContacts: Scalars['Boolean']['output']
  createBusinessClientUser: Scalars['Boolean']['output']
  createBusinessClientsRouteServicesSchedulePrice: Scalars['Boolean']['output']
  createBusinessCustomer: Scalars['Boolean']['output']
  createBusinessCustomersFromCSV: Scalars['Boolean']['output']
  createBusinessSite: GTCreateBusinessSiteResponse
  createBusinessSiteContacts: Scalars['Boolean']['output']
  /** create campaign */
  createCampaign: Scalars['Boolean']['output']
  /** create campaign subcampaign */
  createCampaignSubcampaign?: Maybe<GTCreateCampaignSubcampaignResponse>
  /** create cart */
  createCart: Scalars['Boolean']['output']
  /** create a city */
  createCity: Scalars['Boolean']['output']
  /**
   * create a company
   * @deprecated Use createCompanyV2 instead
   */
  createCompany: Scalars['Boolean']['output']
  /** create a company line */
  createCompanyLine: Scalars['Boolean']['output']
  createCompanyV2: Scalars['Boolean']['output']
  createCountry: Scalars['Boolean']['output']
  createCurrency: Scalars['Boolean']['output']
  createDriver: GTCreateDriverResponse
  /** create fare families */
  createFareFamilies: Scalars['Boolean']['output']
  /** create fare rule */
  createFareRule: Scalars['Boolean']['output']
  /** create inventory */
  createInventory: Scalars['Boolean']['output']
  /** create inventory rule */
  createInventoryRule: Scalars['Boolean']['output']
  /** create inventory rule cabin strategies */
  createInventoryRuleCabinStrategies: Scalars['Boolean']['output']
  createLoyaltyCampaign: Scalars['Boolean']['output']
  createLoyaltyCampaignRule: Scalars['Boolean']['output']
  createLoyaltyReward: Scalars['Boolean']['output']
  /** create order */
  createOrder: Scalars['Boolean']['output']
  createPartner: GTCreatePartnerResponse
  createPartnerContacts: Scalars['Boolean']['output']
  createPassengerProperty: Scalars['Boolean']['output']
  createPassengerType: Scalars['Boolean']['output']
  /**
   * create a place
   * @deprecated Use createPlaceV2 instead
   */
  createPlace: Scalars['Boolean']['output']
  createPlaceForBusinessCustomer: Scalars['Boolean']['output']
  createPlaceV2: Scalars['Boolean']['output']
  /** create policy company role */
  createPolicyCompanyRole: Scalars['Boolean']['output']
  /** create policy permission */
  createPolicyPermission: Scalars['Boolean']['output']
  /** create policy private role */
  createPolicyPrivateRole: Scalars['Boolean']['output']
  /** create policy role */
  createPolicyRole: Scalars['Boolean']['output']
  createPresignedUrlForFileUpload: GTCreatePresignedUrlForFileUploadOutput
  /**
   * create a route
   * @deprecated Use createTRoute instead
   */
  createRoute?: Maybe<GTCreateRouteResponse>
  createRouteServiceDriverOverride: Scalars['Boolean']['output']
  createRouteServiceItinerariesSchedule: Scalars['Boolean']['output']
  createRouteServiceItineraryOverride: Scalars['Boolean']['output']
  createRouteServicePastVehicleOverride: Scalars['Boolean']['output']
  createRouteServiceRouteOverride: Scalars['Boolean']['output']
  createRouteServiceSingleTrip: Scalars['Boolean']['output']
  createRouteServiceVehicleOverride: Scalars['Boolean']['output']
  createRouteServiceVehiclesSchedule: Scalars['Boolean']['output']
  createSalesChannel: Scalars['Boolean']['output']
  /** create a season event */
  createSeasonEvent: Scalars['Boolean']['output']
  /** create a route service */
  createService: Scalars['Boolean']['output']
  /** create a site */
  createSite: Scalars['Boolean']['output']
  createTRoute: GTCreateTRouteResponse
  createTVehicle: GTCreateTVehicleResponse
  /** create a user */
  createUser: Scalars['Boolean']['output']
  /** create vehicle template */
  createVehicleTemplate: Scalars['Boolean']['output']
  /** deactivate a booking class */
  deactivateBookingClass: Scalars['Boolean']['output']
  deactivateBusinessClient: Scalars['Boolean']['output']
  deactivateBusinessClientUser: Scalars['Boolean']['output']
  deactivateBusinessSite: Scalars['Boolean']['output']
  /** deactivate campaign */
  deactivateCampaign: Scalars['Boolean']['output']
  /** deactivate campaign coupon */
  deactivateCampaignCoupon: Scalars['Boolean']['output']
  /** deactivate an item */
  deactivateItem?: Maybe<GTToggleItemResponse>
  deactivatePartner: Scalars['Boolean']['output']
  /** deactivate a season event */
  deactivateSeasonEvent: Scalars['Boolean']['output']
  /** deactivate a route service */
  deactivateService?: Maybe<GTToggleServiceResponse>
  /** deactivate a site */
  deactivateSite: GTToggleSiteResponse
  /** deactivate correlated trips for sale */
  deactivateTripSale: Scalars['Boolean']['output']
  /** deactivate a user */
  deactivateUser?: Maybe<GTToggleUserResponse>
  deactivateVehicle: Scalars['Boolean']['output']
  deleteBusinessClientContacts: Scalars['Boolean']['output']
  deleteBusinessSiteContacts: Scalars['Boolean']['output']
  /** delete inventory rule cabin strategies */
  deleteInventoryRuleCabinStrategies: Scalars['Boolean']['output']
  deletePartnerContacts: Scalars['Boolean']['output']
  deleteRouteServiceVehiclesSchedule: Scalars['Boolean']['output']
  deleteTVehicleDocuments: Scalars['Boolean']['output']
  depositKreditCorporateFunds: Scalars['Boolean']['output']
  depositKreditsBonusFunds: Scalars['Boolean']['output']
  depositKreditsCorporateFunds: Scalars['Boolean']['output']
  disableAncillary: Scalars['Boolean']['output']
  disableBusinessCustomer: Scalars['Boolean']['output']
  disableDriver: Scalars['Boolean']['output']
  /** disable fare rule */
  disableFareRule: Scalars['Boolean']['output']
  /** disable inventory rule */
  disableInventoryRule: Scalars['Boolean']['output']
  disablePassengerProperty: Scalars['Boolean']['output']
  disablePassengerType: Scalars['Boolean']['output']
  disableServiceFeeAddonRule: Scalars['Boolean']['output']
  enableAncillary: Scalars['Boolean']['output']
  enableBusinessCustomer: Scalars['Boolean']['output']
  enableDriver: Scalars['Boolean']['output']
  /** enable fare rule */
  enableFareRule: Scalars['Boolean']['output']
  /** enable inventory rule */
  enableInventoryRule: Scalars['Boolean']['output']
  enablePassengerProperty: Scalars['Boolean']['output']
  enablePassengerType: Scalars['Boolean']['output']
  enableServiceFeeAddonRule: Scalars['Boolean']['output']
  /** Force Change User Account Password */
  forceChangeUserAccountPassword: Scalars['Boolean']['output']
  /** login */
  login: GTLoginResponse
  /** logout */
  logout: Scalars['Boolean']['output']
  markVehicleOutOfService: Scalars['Boolean']['output']
  processBusinessClientKreditsDispersion: Scalars['Boolean']['output']
  /** receive an order */
  receiveOrder: Scalars['Boolean']['output']
  /** refresh session */
  refreshSession: GTLoginResponse
  registerBookingSupportInteraction: Scalars['Boolean']['output']
  /** register booking tax invoiced */
  registerBookingTaxInvoiced: Scalars['Boolean']['output']
  /** register inventory waste */
  registerInventoryWaste: Scalars['Boolean']['output']
  /** register item */
  registerItem: Scalars['Boolean']['output']
  /** register item combo */
  registerItemCombo: Scalars['Boolean']['output']
  /** register item kolors mobile plan */
  registerItemKolorsMobilePlan: Scalars['Boolean']['output']
  /**
   * register vehicle
   * @deprecated Use createTVehicle instead
   */
  registerVehicle: Scalars['Boolean']['output']
  /** reject an order */
  rejectOrder: Scalars['Boolean']['output']
  removePartnerDocuments: Scalars['Boolean']['output']
  replacePlaceForBusinessCustomer: Scalars['Boolean']['output']
  requestCampaignSubcampaignCouponsDelivery: Scalars['Boolean']['output']
  /** Request Reset User Account Password */
  requestResetUserAccountPassword: Scalars['Boolean']['output']
  resetUserPassword: Scalars['Boolean']['output']
  /** return a cart */
  returnCart: Scalars['Boolean']['output']
  scheduleRouteServiceTrips: Scalars['Boolean']['output']
  sendEmailCoupon: Scalars['Boolean']['output']
  /** @deprecated Use sendEmailCoupon instead */
  sendEmailIncidenceCoupon: Scalars['Boolean']['output']
  sendSMSCoupon: Scalars['Boolean']['output']
  /** @deprecated Use sendSMSCoupon instead */
  sendSMSIncidenceCoupon: Scalars['Boolean']['output']
  /** send tickets */
  sendTickets: Scalars['Boolean']['output']
  /** set campaign template */
  setCampaignTemplate: Scalars['Boolean']['output']
  /** ship an order */
  shipOrder: Scalars['Boolean']['output']
  /** start booking trip change */
  startBookingTripChange: GTStartBookingTripChangeResponse
  /** start booking trip change */
  startMoveBookingTripChange: GTStartMoveBookingTripChangeResponse
  submitB2BContactForm: Scalars['Boolean']['output']
  unassignBusinessCustomerKreditCategory: Scalars['Boolean']['output']
  unassignBusinessCustomersKreditCategory: Scalars['Boolean']['output']
  /** update campaign */
  updateCampaign: Scalars['Boolean']['output']
  /** update campaign snack coupon */
  updateCampaignSnacksCoupon: Scalars['Boolean']['output']
  /** update a city */
  updateCity: Scalars['Boolean']['output']
  /** update a company */
  updateCompany: Scalars['Boolean']['output']
  /** update a company line */
  updateCompanyLine: Scalars['Boolean']['output']
  /** update coupon */
  updateCoupon: Scalars['Boolean']['output']
  /**
   * update a place
   * @deprecated Use changePlaceV2 instead
   */
  updatePlace: Scalars['Boolean']['output']
  /** update policy company role */
  updatePolicyCompanyRole: Scalars['Boolean']['output']
  /** update policy private role */
  updatePolicyPrivateRole: Scalars['Boolean']['output']
  /** update season event */
  updateSeasonEvent: Scalars['Boolean']['output']
  /** update a segment */
  updateSegment: Scalars['Boolean']['output']
  /** update service */
  updateService: Scalars['Boolean']['output']
  /**
   * update a user
   * @deprecated Use updateUserV2 instead
   */
  updateUser: Scalars['Boolean']['output']
  updateUserV2: Scalars['Boolean']['output']
  /**
   * update a vehicle
   * @deprecated Not used anymore
   */
  updateVehicle: Scalars['Boolean']['output']
  /** update a vehicle template */
  updateVehicleTemplate: Scalars['Boolean']['output']
  upsertPartnerCosts: Scalars['Boolean']['output']
  validateBusinessCustomersCSV: Scalars['Boolean']['output']
  validateCouponsCSV: Scalars['Boolean']['output']
  verifyMfaCode: GTVerifyMfaCodeResponse
  /** verify trips inventory rule */
  verifyTripsInventoryRule: Scalars['Boolean']['output']
}

export type GTMutationActivateBookingClassArgs = {
  input: GTActivateBookingClassInput
}

export type GTMutationActivateBusinessClientArgs = {
  id: Scalars['UUID']['input']
}

export type GTMutationActivateBusinessClientUserArgs = {
  input: GTActivateBusinessClientUserInput
}

export type GTMutationActivateBusinessSiteArgs = {
  id: Scalars['UUID']['input']
}

export type GTMutationActivateCampaignArgs = {
  input: GTActivateCampaignInput
}

export type GTMutationActivateCampaignCouponArgs = {
  input: GTActivateCampaignCouponInput
}

export type GTMutationActivateItemArgs = {
  input: GTActivateItemInput
}

export type GTMutationActivatePartnerArgs = {
  id: Scalars['UUID']['input']
}

export type GTMutationActivateSeasonEventArgs = {
  input: GTActivateSeasonEventInput
}

export type GTMutationActivateServiceArgs = {
  input: GTActivateServiceInput
}

export type GTMutationActivateSiteArgs = {
  input: GTActivateSiteInput
}

export type GTMutationActivateTripSaleArgs = {
  input: GTActivateTripSaleInput
}

export type GTMutationActivateUserArgs = {
  input: GTActivateUserInput
}

export type GTMutationActivateVehicleArgs = {
  id: Scalars['UUID']['input']
}

export type GTMutationAddBookingTicketsInsuranceArgs = {
  input: GTAddBookingTicketsInsuranceInput
}

export type GTMutationAddCampaignCouponSupportInteractionArgs = {
  input: GTAddCampaignCouponSupportInteractionInput
}

export type GTMutationAddCampaignSnacksCouponArgs = {
  input: GTAddCampaignSnacksCouponInput
}

export type GTMutationAddCouponArgs = {
  input: GTAddCouponInput
}

export type GTMutationAddFareFamilyFaresArgs = {
  input: GTAddFareFamilyFaresInput
}

export type GTMutationAddLoyaltyCampaignRuleConditionsCitySegmentsArgs = {
  input: GTAddLoyaltyCampaignRuleConditionsCitySegmentsInput
}

export type GTMutationAddLoyaltyCampaignRuleConditionsRouteServicesArgs = {
  input: GTAddLoyaltyCampaignRuleConditionsRouteServicesInput
}

export type GTMutationAddLoyaltyCampaignRuleConditionsSalesChannelsArgs = {
  input: GTAddLoyaltyCampaignRuleConditionsSalesChannelsInput
}

export type GTMutationAddPartnerDocumentsArgs = {
  id: Scalars['UUID']['input']
  input: GTAddPartnerDocumentsInput
}

export type GTMutationAddRouteServicePeaksArgs = {
  input: GTAddRouteServicePeaksInput
}

export type GTMutationAddSegmentCompanyLineSpecArgs = {
  input: GTAddSegmentCompanyLineSpecInput
}

export type GTMutationAddServiceFeeAddonRuleArgs = {
  input: GTAddServiceFeeAddonRuleInput
}

export type GTMutationAddTripNoteArgs = {
  input: GTAddTripNoteInput
}

export type GTMutationApplyBookingCouponArgs = {
  input: GTApplyBookingCouponInput
}

export type GTMutationArchiveInventoryArgs = {
  input: GTArchiveInventoryInput
}

export type GTMutationArchiveRouteServiceItineraryScheduleArgs = {
  input: GTArchiveRouteServiceItineraryScheduleInput
}

export type GTMutationArchiveVehicleTemplateArgs = {
  input: GTArchiveVehicleTemplateInput
}

export type GTMutationAssignBusinessCustomerKreditCategoryArgs = {
  input: GTAssignBusinessCustomerKreditCategoryInput
}

export type GTMutationAssignBusinessCustomersKreditCategoryArgs = {
  input: GTAssignBusinessCustomersKreditCategoryInput
}

export type GTMutationCancelBookingTicketsArgs = {
  input: GTCancelBookingTicketsInput
}

export type GTMutationCancelBookingTicketsInsuranceArgs = {
  input: GTCancelBookingTicketsInsuranceInput
}

export type GTMutationCancelBookingTripChangeArgs = {
  input: GTCancelBookingTripChangeInput
}

export type GTMutationCancelBookingTripChangeProposalArgs = {
  input: GTCancelBookingTripChangeProposalInput
}

export type GTMutationCancelChangeArgs = {
  input: GTCancelChangeInput
}

export type GTMutationCancelOrderArgs = {
  input: GTCancelOrderInput
}

export type GTMutationCancelSaleItemsArgs = {
  input: GTCancelSaleItemsInput
}

export type GTMutationCancelTicketsArgs = {
  input: GTCancelTicketsInput
}

export type GTMutationCancelTripArgs = {
  input: GTCancelTripInput
}

export type GTMutationCancelTripsArgs = {
  input: GTCancelTripsInput
}

export type GTMutationChangeAncillaryArgs = {
  input: GTChangeAncillaryInput
}

export type GTMutationChangeBookingPassengersNamesArgs = {
  input: GTChangeBookingPassengersNamesInput
}

export type GTMutationChangeBookingPaymentArgs = {
  input: GTChangeBookingPaymentInput
}

export type GTMutationChangeBookingTicketsAncillariesArgs = {
  input: GTChangeBookingTicketsAncillariesInput
}

export type GTMutationChangeBookingTripChangeTicketSeatArgs = {
  input: GTChangeBookingTripChangeTicketSeatInput
}

export type GTMutationChangeBusinessClientArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeBusinessClientInput
}

export type GTMutationChangeBusinessClientContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeBusinessClientContactsInput
}

export type GTMutationChangeBusinessClientCustomerKreditsAmountArgs = {
  input: GTChangeBusinessClientCustomerKreditsAmountInput
}

export type GTMutationChangeBusinessClientCustomersKreditsAmountArgs = {
  input: GTChangeBusinessClientCustomersKreditsAmountInput
}

export type GTMutationChangeBusinessClientKreditSettingsArgs = {
  input: GTChangeBusinessClientKreditSettingsInput
}

export type GTMutationChangeBusinessClientSiteKreditSettingsArgs = {
  input: GTChangeBusinessClientSiteKreditSettingsInput
}

export type GTMutationChangeBusinessClientUserArgs = {
  input: GTChangeBusinessClientUserInput
}

export type GTMutationChangeBusinessCustomerBusinessClientArgs = {
  input: GTChangeBusinessCustomerBusinessClientInput
}

export type GTMutationChangeBusinessCustomerBusinessSiteArgs = {
  input: GTChangeBusinessCustomerBusinessSiteInput
}

export type GTMutationChangeBusinessSiteArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeBusinessSiteInput
}

export type GTMutationChangeBusinessSiteContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeBusinessSiteContactsInput
}

export type GTMutationChangeBuyerArgs = {
  input: GTChangeBuyerInput
}

export type GTMutationChangeCartArgs = {
  input: GTChangeCartInput
}

export type GTMutationChangeComboItemArgs = {
  input: GTChangeComboItemInput
}

export type GTMutationChangeCompanyInsuranceSettingsArgs = {
  input: GTChangeCompanyInsuranceSettingsInput
}

export type GTMutationChangeCurrencyExchangeRateArgs = {
  input: GTChangeCurrencyExchangeRateInput
}

export type GTMutationChangeDriverDocumentsArgs = {
  input: GTChangeDriverDocumentsInput
}

export type GTMutationChangeFareFamilyFaresArgs = {
  input: GTChangeFareFamilyFaresInput
}

export type GTMutationChangeFareRuleArgs = {
  input: GTChangeFareRuleInput
}

export type GTMutationChangeInventoryArgs = {
  input: GTChangeInventoryInput
}

export type GTMutationChangeInventoryRuleArgs = {
  input: GTChangeInventoryRuleInput
}

export type GTMutationChangeInventoryRuleCabinStrategiesArgs = {
  input: GTChangeInventoryRuleCabinStrategiesInput
}

export type GTMutationChangeItemArgs = {
  input: GTChangeItemInput
}

export type GTMutationChangeItemKolorsMobilePlanArgs = {
  input: GTChangeItemKolorsMobilePlanInput
}

export type GTMutationChangeLoyaltyCampaignArgs = {
  input: GTChangeLoyaltyCampaignInput
}

export type GTMutationChangeLoyaltyCampaignRuleArgs = {
  input: GTChangeLoyaltyCampaignRuleInput
}

export type GTMutationChangeOrderArgs = {
  input: GTChangeOrderInput
}

export type GTMutationChangePartnerBankDetailsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangePartnerBankDetailsInput
}

export type GTMutationChangePartnerContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangePartnerContactsInput
}

export type GTMutationChangePartnerDetailsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangePartnerDetailsInput
}

export type GTMutationChangePartnerKamUserArgs = {
  id: Scalars['UUID']['input']
  input: GTChangePartnerKamUserInput
}

export type GTMutationChangePartnerTaxInformationArgs = {
  id: Scalars['UUID']['input']
  input: GTChangePartnerTaxInformationInput
}

export type GTMutationChangePassengerPropertyArgs = {
  input: GTChangePassengerPropertyInput
}

export type GTMutationChangePassengerTypeArgs = {
  input: GTChangePassengerTypeInput
}

export type GTMutationChangePlaceV2Args = {
  id: Scalars['UUID']['input']
  input: GTChangePlaceV2Input
}

export type GTMutationChangeRouteServiceItinerariesScheduleArgs = {
  input: GTChangeRouteServiceItinerariesScheduleInput
}

export type GTMutationChangeRouteServiceVehiclesScheduleArgs = {
  input: GTChangeRouteServiceVehiclesScheduleInput
}

export type GTMutationChangeServiceFeeAddonRuleArgs = {
  input: GTChangeServiceFeeAddonRuleInput
}

export type GTMutationChangeSiteArgs = {
  input: GTChangeSiteInput
}

export type GTMutationChangeTDriverDetailsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTDriverDetailsInput
}

export type GTMutationChangeTRouteArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTRouteInput
}

export type GTMutationChangeTTripDriverArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTTripDriverInput
}

export type GTMutationChangeTVehicleCardsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleCardsInput
}

export type GTMutationChangeTVehicleDetailsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleDetailsInput
}

export type GTMutationChangeTVehicleDocumentsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleDocumentsInput
}

export type GTMutationChangeTVehicleDriversArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleDriversInput
}

export type GTMutationChangeTVehicleFeaturesArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleFeaturesInput
}

export type GTMutationChangeTVehicleInsurancePolicyArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleInsurancePolicyInput
}

export type GTMutationChangeTVehicleLicensePlatesArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleLicensePlatesInput
}

export type GTMutationChangeTVehicleOdometerReadingArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleOdometerReadingInput
}

export type GTMutationChangeTVehicleRegistrationDetailsArgs = {
  id: Scalars['UUID']['input']
  input: GTChangeTVehicleRegistrationDetailsInput
}

export type GTMutationChangeTicketsPassengerArgs = {
  input: GTChangeTicketsPassengerInput
}

export type GTMutationChangeTripBookingSeatArgs = {
  input: GTChangeTripBookingSeatInput
}

export type GTMutationChangeTripConfirmationModalitiesArgs = {
  input: GTChangeTripConfirmationModalitiesInput
}

export type GTMutationChangeTripItineraryDepartureTimeArgs = {
  input: GTChangeTripItineraryDepartureTimeInput
}

export type GTMutationChangeTripRouteArgs = {
  input: GTChangeTripRouteInput
}

export type GTMutationChangeTripUsersArgs = {
  input: GTChangeTripUsersInput
}

export type GTMutationChangeTripVehicleArgs = {
  input: GTChangeTripVehicleInput
}

export type GTMutationChangeTripsVehicleArgs = {
  input: GTChangeTripsVehicleInput
}

export type GTMutationChangeUserAccountDetailsArgs = {
  input: GTChangeUserAccountDetailsInput
}

export type GTMutationChangeUserPasswordArgs = {
  input: GTChangeUserPasswordInput
}

export type GTMutationConfirmBookingPaymentOnSiteArgs = {
  input: GTConfirmBookingPaymentOnSiteInput
}

export type GTMutationConfirmBookingTripChangeProposalArgs = {
  input: GTConfirmBookingTripChangeProposalInput
}

export type GTMutationConfirmBookingTripChangesArgs = {
  input: GTConfirmBookingTripChangesInput
}

export type GTMutationCreateAncillaryArgs = {
  input: GTCreateAncillaryInput
}

export type GTMutationCreateBookingClassArgs = {
  input: GTCreateBookingClassInput
}

export type GTMutationCreateBookingTripChangeProposalArgs = {
  input: GTCreateBookingTripChangeProposalInput
}

export type GTMutationCreateBusinessClientArgs = {
  input: GTCreateBusinessClientInput
}

export type GTMutationCreateBusinessClientContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTCreateBusinessClientContactsInput
}

export type GTMutationCreateBusinessClientUserArgs = {
  input: GTCreateBusinessClientUserInput
}

export type GTMutationCreateBusinessClientsRouteServicesSchedulePriceArgs = {
  input: GTCreateBusinessClientsRouteServicesSchedulePriceInput
}

export type GTMutationCreateBusinessCustomerArgs = {
  input: GTCreateBusinessCustomerInput
}

export type GTMutationCreateBusinessCustomersFromCsvArgs = {
  input: GTCreateBusinessCustomersFromCsvInput
}

export type GTMutationCreateBusinessSiteArgs = {
  input: GTCreateBusinessSiteInput
}

export type GTMutationCreateBusinessSiteContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTCreateBusinessSiteContactsInput
}

export type GTMutationCreateCampaignArgs = {
  input: GTCreateCampaignInput
}

export type GTMutationCreateCampaignSubcampaignArgs = {
  input: GTCreateCampaignSubcampaignInput
}

export type GTMutationCreateCartArgs = {
  input: GTCreateCartInput
}

export type GTMutationCreateCityArgs = {
  input: GTCreateCityInput
}

export type GTMutationCreateCompanyArgs = {
  input: GTCreateCompanyInput
}

export type GTMutationCreateCompanyLineArgs = {
  input: GTCreateCompanyLineInput
}

export type GTMutationCreateCompanyV2Args = {
  input: GTCreateCompanyV2Input
}

export type GTMutationCreateCountryArgs = {
  input: GTCreateCountryInput
}

export type GTMutationCreateCurrencyArgs = {
  input: GTCreateCurrencyInput
}

export type GTMutationCreateDriverArgs = {
  input: GTCreateDriverInput
}

export type GTMutationCreateFareFamiliesArgs = {
  input: GTCreateFareFamiliesInput
}

export type GTMutationCreateFareRuleArgs = {
  input: GTCreateFareRuleInput
}

export type GTMutationCreateInventoryArgs = {
  input: GTCreateInventoryInput
}

export type GTMutationCreateInventoryRuleArgs = {
  input: GTCreateInventoryRuleInput
}

export type GTMutationCreateInventoryRuleCabinStrategiesArgs = {
  input: GTCreateInventoryRuleCabinStrategiesInput
}

export type GTMutationCreateLoyaltyCampaignArgs = {
  input: GTCreateLoyaltyCampaignInput
}

export type GTMutationCreateLoyaltyCampaignRuleArgs = {
  input: GTCreateLoyaltyCampaignRuleInput
}

export type GTMutationCreateLoyaltyRewardArgs = {
  input: GTCreateLoyaltyRewardInput
}

export type GTMutationCreateOrderArgs = {
  input: GTCreateOrderInput
}

export type GTMutationCreatePartnerArgs = {
  input: GTCreatePartnerInput
}

export type GTMutationCreatePartnerContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTCreatePartnerContactsInput
}

export type GTMutationCreatePassengerPropertyArgs = {
  input: GTCreatePassengerPropertyInput
}

export type GTMutationCreatePassengerTypeArgs = {
  input: GTCreatePassengerTypeInput
}

export type GTMutationCreatePlaceArgs = {
  input: GTCreatePlaceInput
}

export type GTMutationCreatePlaceForBusinessCustomerArgs = {
  input: GTCreatePlaceForBusinessCustomerInput
}

export type GTMutationCreatePlaceV2Args = {
  input: GTCreatePlaceV2Input
}

export type GTMutationCreatePolicyCompanyRoleArgs = {
  input: GTCreatePolicyCompanyRoleInput
}

export type GTMutationCreatePolicyPermissionArgs = {
  input: GTCreatePolicyPermissionInput
}

export type GTMutationCreatePolicyPrivateRoleArgs = {
  input: GTCreatePolicyPrivateRoleInput
}

export type GTMutationCreatePolicyRoleArgs = {
  input: GTCreatePolicyRoleInput
}

export type GTMutationCreatePresignedUrlForFileUploadArgs = {
  input: GTCreatePresignedUrlForFileUploadInput
}

export type GTMutationCreateRouteArgs = {
  input: GTCreateRouteInput
}

export type GTMutationCreateRouteServiceDriverOverrideArgs = {
  input: GTCreateRouteServiceDriverOverrideInput
}

export type GTMutationCreateRouteServiceItinerariesScheduleArgs = {
  input: GTCreateRouteServiceItinerariesScheduleInput
}

export type GTMutationCreateRouteServiceItineraryOverrideArgs = {
  input: GTCreateRouteServiceItineraryOverrideInput
}

export type GTMutationCreateRouteServicePastVehicleOverrideArgs = {
  input: GTCreateRouteServicePastVehicleOverrideInput
}

export type GTMutationCreateRouteServiceRouteOverrideArgs = {
  input: GTCreateRouteServiceRouteOverrideInput
}

export type GTMutationCreateRouteServiceSingleTripArgs = {
  input: GTCreateRouteServiceSingleTripInput
}

export type GTMutationCreateRouteServiceVehicleOverrideArgs = {
  input: GTCreateRouteServiceVehicleOverrideInput
}

export type GTMutationCreateRouteServiceVehiclesScheduleArgs = {
  input: GTCreateRouteServiceVehiclesScheduleInput
}

export type GTMutationCreateSalesChannelArgs = {
  input: GTCreateSalesChannelInput
}

export type GTMutationCreateSeasonEventArgs = {
  input: GTCreateSeasonEventInput
}

export type GTMutationCreateServiceArgs = {
  input: GTCreateServiceInput
}

export type GTMutationCreateSiteArgs = {
  input: GTCreateSiteInput
}

export type GTMutationCreateTRouteArgs = {
  input: GTCreateTRouteInput
}

export type GTMutationCreateTVehicleArgs = {
  input: GTCreateTVehicleInput
}

export type GTMutationCreateUserArgs = {
  input: GTCreateUserInput
}

export type GTMutationCreateVehicleTemplateArgs = {
  input: GTCreateVehicleTemplateInput
}

export type GTMutationDeactivateBookingClassArgs = {
  input: GTDeactivateBookingClassInput
}

export type GTMutationDeactivateBusinessClientArgs = {
  id: Scalars['UUID']['input']
}

export type GTMutationDeactivateBusinessClientUserArgs = {
  input: GTDeactivateBusinessClientUserInput
}

export type GTMutationDeactivateBusinessSiteArgs = {
  id: Scalars['UUID']['input']
}

export type GTMutationDeactivateCampaignArgs = {
  input: GTDeactivateCampaignInput
}

export type GTMutationDeactivateCampaignCouponArgs = {
  input: GTDeactivateCampaignCouponInput
}

export type GTMutationDeactivateItemArgs = {
  input: GTDeactivateItemInput
}

export type GTMutationDeactivatePartnerArgs = {
  id: Scalars['UUID']['input']
  input: GTDeactivatePartnerInput
}

export type GTMutationDeactivateSeasonEventArgs = {
  input: GTDeactivateSeasonEventInput
}

export type GTMutationDeactivateServiceArgs = {
  input: GTDeactivateServiceInput
}

export type GTMutationDeactivateSiteArgs = {
  input: GTDeactivateSiteInput
}

export type GTMutationDeactivateTripSaleArgs = {
  input: GTDeactivateTripSaleInput
}

export type GTMutationDeactivateUserArgs = {
  input: GTDeactivateUserInput
}

export type GTMutationDeactivateVehicleArgs = {
  id: Scalars['UUID']['input']
  input: GTDeactivateVehicleInput
}

export type GTMutationDeleteBusinessClientContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTDeleteBusinessClientContactsInput
}

export type GTMutationDeleteBusinessSiteContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTDeleteBusinessSiteContactsInput
}

export type GTMutationDeleteInventoryRuleCabinStrategiesArgs = {
  input: GTDeleteInventoryRuleCabinStrategiesInput
}

export type GTMutationDeletePartnerContactsArgs = {
  id: Scalars['UUID']['input']
  input: GTDeletePartnerContactsInput
}

export type GTMutationDeleteRouteServiceVehiclesScheduleArgs = {
  input: GTDeleteRouteServiceVehiclesScheduleInput
}

export type GTMutationDeleteTVehicleDocumentsArgs = {
  id: Scalars['UUID']['input']
  input: GTDeleteTVehicleDocumentsInput
}

export type GTMutationDepositKreditCorporateFundsArgs = {
  input: GTDepositKreditCorporateFundsInput
}

export type GTMutationDepositKreditsBonusFundsArgs = {
  input: GTDepositKreditsBonusFundsInput
}

export type GTMutationDepositKreditsCorporateFundsArgs = {
  input: GTDepositKreditsCorporateFundsInput
}

export type GTMutationDisableAncillaryArgs = {
  input: GTDisableAncillaryInput
}

export type GTMutationDisableBusinessCustomerArgs = {
  input: GTDisableBusinessCustomerInput
}

export type GTMutationDisableDriverArgs = {
  input: GTDisableDriverInput
}

export type GTMutationDisableFareRuleArgs = {
  input: GTDisableFareRuleInput
}

export type GTMutationDisableInventoryRuleArgs = {
  input: GTDisableInventoryRuleInput
}

export type GTMutationDisablePassengerPropertyArgs = {
  input: GTDisablePassengerPropertyInput
}

export type GTMutationDisablePassengerTypeArgs = {
  input: GTDisablePassengerTypeInput
}

export type GTMutationDisableServiceFeeAddonRuleArgs = {
  input: GTDisableServiceFeeAddonRuleInput
}

export type GTMutationEnableAncillaryArgs = {
  input: GTEnableAncillaryInput
}

export type GTMutationEnableBusinessCustomerArgs = {
  input: GTEnableBusinessCustomerInput
}

export type GTMutationEnableDriverArgs = {
  input: GTEnableDriverInput
}

export type GTMutationEnableFareRuleArgs = {
  input: GTEnableFareRuleInput
}

export type GTMutationEnableInventoryRuleArgs = {
  input: GTEnableInventoryRuleInput
}

export type GTMutationEnablePassengerPropertyArgs = {
  input: GTEnablePassengerPropertyInput
}

export type GTMutationEnablePassengerTypeArgs = {
  input: GTEnablePassengerTypeInput
}

export type GTMutationEnableServiceFeeAddonRuleArgs = {
  input: GTEnableServiceFeeAddonRuleInput
}

export type GTMutationForceChangeUserAccountPasswordArgs = {
  input: GTForceChangeUserAccountPasswordInput
}

export type GTMutationLoginArgs = {
  input: GTLoginInput
}

export type GTMutationMarkVehicleOutOfServiceArgs = {
  input: GTMarkVehicleOutOfServiceInput
}

export type GTMutationProcessBusinessClientKreditsDispersionArgs = {
  input: GTProcessBusinessClientKreditsDispersionInput
}

export type GTMutationReceiveOrderArgs = {
  input: GTReceiveOrderInput
}

export type GTMutationRefreshSessionArgs = {
  input: GTRefreshSessionInput
}

export type GTMutationRegisterBookingSupportInteractionArgs = {
  input: GTRegisterBookingSupportInteractionInput
}

export type GTMutationRegisterBookingTaxInvoicedArgs = {
  input: GTRegisterBookingTaxInvoicedInput
}

export type GTMutationRegisterInventoryWasteArgs = {
  input: GTRegisterInventoryWasteInput
}

export type GTMutationRegisterItemArgs = {
  input: GTRegisterItemInput
}

export type GTMutationRegisterItemComboArgs = {
  input: GTRegisterItemComboInput
}

export type GTMutationRegisterItemKolorsMobilePlanArgs = {
  input: GTRegisterItemKolorsMobilePlanInput
}

export type GTMutationRegisterVehicleArgs = {
  input: GTRegisterVehicleInput
}

export type GTMutationRejectOrderArgs = {
  input: GTRejectOrderInput
}

export type GTMutationRemovePartnerDocumentsArgs = {
  id: Scalars['UUID']['input']
  input: GTRemovePartnerDocumentsInput
}

export type GTMutationReplacePlaceForBusinessCustomerArgs = {
  input: GTReplacePlaceForBusinessCustomerInput
}

export type GTMutationRequestCampaignSubcampaignCouponsDeliveryArgs = {
  input: GTRequestCampaignSubcampaignCouponsDeliveryInput
}

export type GTMutationRequestResetUserAccountPasswordArgs = {
  input: GTRequestResetUserAccountPasswordInput
}

export type GTMutationResetUserPasswordArgs = {
  input: GTResetUserPasswordInput
}

export type GTMutationReturnCartArgs = {
  input: GTReturnCartInput
}

export type GTMutationScheduleRouteServiceTripsArgs = {
  input: GTScheduleRouteServiceTripsInput
}

export type GTMutationSendEmailCouponArgs = {
  input: GTSendEmailCouponInput
}

export type GTMutationSendEmailIncidenceCouponArgs = {
  input: GTSendEmailIncidenceCouponInput
}

export type GTMutationSendSmsCouponArgs = {
  input: GTSendSmsCouponInput
}

export type GTMutationSendSmsIncidenceCouponArgs = {
  input: GTSendSmsIncidenceCouponInput
}

export type GTMutationSendTicketsArgs = {
  input: GTSendTicketsInput
}

export type GTMutationSetCampaignTemplateArgs = {
  input: GTSetCampaignTemplateInput
}

export type GTMutationShipOrderArgs = {
  input: GTShipOrderInput
}

export type GTMutationStartBookingTripChangeArgs = {
  input: GTStartBookingTripChangeInput
}

export type GTMutationStartMoveBookingTripChangeArgs = {
  input: GTStartMoveBookingTripChangeInput
}

export type GTMutationSubmitB2BContactFormArgs = {
  input: GTSubmitB2BContactFormInput
}

export type GTMutationUnassignBusinessCustomerKreditCategoryArgs = {
  input: GTUnassignBusinessCustomerKreditCategoryInput
}

export type GTMutationUnassignBusinessCustomersKreditCategoryArgs = {
  input: GTUnassignBusinessCustomersKreditCategoryInput
}

export type GTMutationUpdateCampaignArgs = {
  input: GTUpdateCampaignInput
}

export type GTMutationUpdateCampaignSnacksCouponArgs = {
  input: GTUpdateSnacksCouponInput
}

export type GTMutationUpdateCityArgs = {
  input: GTUpdateCityInput
}

export type GTMutationUpdateCompanyArgs = {
  input: GTUpdateCompanyInput
}

export type GTMutationUpdateCompanyLineArgs = {
  input: GTUpdateCompanyLineInput
}

export type GTMutationUpdateCouponArgs = {
  input: GTUpdateCouponInput
}

export type GTMutationUpdatePlaceArgs = {
  input: GTUpdatePlaceInput
}

export type GTMutationUpdatePolicyCompanyRoleArgs = {
  input: GTUpdatePolicyCompanyRoleInput
}

export type GTMutationUpdatePolicyPrivateRoleArgs = {
  input: GTUpdatePolicyPrivateRoleInput
}

export type GTMutationUpdateSeasonEventArgs = {
  input: GTUpdateSeasonEventInput
}

export type GTMutationUpdateSegmentArgs = {
  input: GTUpdateSegmentInput
}

export type GTMutationUpdateServiceArgs = {
  input: GTUpdateServiceInput
}

export type GTMutationUpdateUserArgs = {
  input: GTUpdateUserInput
}

export type GTMutationUpdateUserV2Args = {
  input: GTUpdateUserInput
}

export type GTMutationUpdateVehicleArgs = {
  input: GTUpdateVehicleInput
}

export type GTMutationUpdateVehicleTemplateArgs = {
  input: GTUpdateVehicleTemplateInput
}

export type GTMutationUpsertPartnerCostsArgs = {
  input: GTUpsertPartnerCostsInput
}

export type GTMutationValidateBusinessCustomersCsvArgs = {
  input: GTValidateBusinessCustomersCsvInput
}

export type GTMutationValidateCouponsCsvArgs = {
  input: GTValidateCouponsCsvInput
}

export type GTMutationVerifyMfaCodeArgs = {
  input: GTVerifyMfaCodeInput
}

export type GTMutationVerifyTripsInventoryRuleArgs = {
  input: GTVerifyTripsInventoryRuleInput
}

/** note attached file */
export type GTNoteFile = {
  __typename?: 'NoteFile'
  /** filename */
  fileName: Scalars['String']['output']
  /** path to file */
  path: Scalars['String']['output']
}

export type GTOrder = {
  __typename?: 'Order'
  cancelledBy?: Maybe<GTOrderUser>
  changedBy?: Maybe<GTOrderUser>
  companies: Array<GTOrderCompany>
  conciliationStatus?: Maybe<GTOrderConciliationStatus>
  createdAt: Scalars['Time']['output']
  createdBy: GTOrderUser
  destination: GTOrderInventory
  id: Scalars['ID']['output']
  lineItems: Array<GTOrderLineItem>
  origin?: Maybe<GTOrderInventory>
  purchaseID?: Maybe<Scalars['String']['output']>
  receivedAt?: Maybe<Scalars['Time']['output']>
  receivedBy?: Maybe<GTOrderUser>
  receiverApp?: Maybe<GTOrderReceiverApp>
  rejectedAt?: Maybe<Scalars['Time']['output']>
  status: GTOrderStatus
  supplier?: Maybe<Scalars['String']['output']>
  type: GTOrderType
}

export type GTOrderCompany = {
  __typename?: 'OrderCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GTOrderConciliationStatus = {
  Completed: 'completed',
  Conflicting: 'conflicting'
} as const

export type GTOrderConciliationStatus = (typeof GTOrderConciliationStatus)[keyof typeof GTOrderConciliationStatus]
export type GTOrderInventory = {
  __typename?: 'OrderInventory'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  type: GTInventoryType
}

export type GTOrderLineItem = {
  __typename?: 'OrderLineItem'
  container: Scalars['String']['output']
  cost: Scalars['Int']['output']
  itemID: Scalars['ID']['output']
  name: Scalars['String']['output']
  netContent: Scalars['Int']['output']
  ordered: Scalars['Int']['output']
  received?: Maybe<Scalars['Int']['output']>
  units: Scalars['String']['output']
}

export type GTOrderLineItemInput = {
  cost?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type GTOrderQueryInput = {
  id: Scalars['ID']['input']
}

export const GTOrderReceiverApp = {
  Admin: 'admin',
  Pos: 'pos'
} as const

export type GTOrderReceiverApp = (typeof GTOrderReceiverApp)[keyof typeof GTOrderReceiverApp]
export const GTOrderStatus = {
  Cancelled: 'cancelled',
  Completed: 'completed',
  Created: 'created',
  Pending: 'pending',
  Received: 'received',
  Rejected: 'rejected'
} as const

export type GTOrderStatus = (typeof GTOrderStatus)[keyof typeof GTOrderStatus]
export const GTOrderType = {
  Purchase: 'purchase',
  Shipment: 'shipment'
} as const

export type GTOrderType = (typeof GTOrderType)[keyof typeof GTOrderType]
export type GTOrderUser = {
  __typename?: 'OrderUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type GTOrdersQueryInput = {
  conciliationStatus?: InputMaybe<GTOrderConciliationStatus>
  creationDateRange?: InputMaybe<GTDateRangeInput>
  destinationID?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  inventoryID?: InputMaybe<Scalars['ID']['input']>
  itemID?: InputMaybe<Scalars['ID']['input']>
  originID?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<GTOrderStatus>
  type?: InputMaybe<GTOrderType>
}

export type GTOrdersResponse = {
  __typename?: 'OrdersResponse'
  items: Array<GTOrder>
  total: Scalars['Int']['output']
}

/** Pagination controls for requests that return multiple items. */
export type GTPagination = {
  /** desired page, first page is 0 */
  page: Scalars['Int']['input']
  /** amount of results per page */
  size: Scalars['Int']['input']
}

export type GTPaginationV2 = {
  page: Scalars['Int']['input']
  size: Scalars['Int']['input']
}

export type GTPartner = {
  __typename?: 'Partner'
  externalID: Scalars['String']['output']
  name: Scalars['String']['output']
}

export const GTPartnerBankPaymentTypeInput = {
  InterbankTransfer: 'INTERBANK_TRANSFER',
  Spei: 'SPEI',
  TransferWithinBank: 'TRANSFER_WITHIN_BANK'
} as const

export type GTPartnerBankPaymentTypeInput =
  (typeof GTPartnerBankPaymentTypeInput)[keyof typeof GTPartnerBankPaymentTypeInput]
export const GTPartnerCostScheduleDow = {
  Friday: 'FRIDAY',
  Monday: 'MONDAY',
  Saturday: 'SATURDAY',
  Sunday: 'SUNDAY',
  Thursday: 'THURSDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY'
} as const

export type GTPartnerCostScheduleDow = (typeof GTPartnerCostScheduleDow)[keyof typeof GTPartnerCostScheduleDow]
export const GTPartnerDocumentCategoryInput = {
  AccountStatementCover: 'ACCOUNT_STATEMENT_COVER',
  ComplianceOpinion: 'COMPLIANCE_OPINION',
  ConstitutiveAct: 'CONSTITUTIVE_ACT',
  Curp: 'CURP',
  OfficialId: 'OFFICIAL_ID',
  PowerOfAttorney: 'POWER_OF_ATTORNEY',
  ProofOfAddress: 'PROOF_OF_ADDRESS',
  Repse: 'REPSE',
  Rfc: 'RFC'
} as const

export type GTPartnerDocumentCategoryInput =
  (typeof GTPartnerDocumentCategoryInput)[keyof typeof GTPartnerDocumentCategoryInput]
export type GTPartnerInput = {
  externalID?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type GTPartnerSuggestion = {
  __typename?: 'PartnerSuggestion'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTPartnerSuggestionsInput = {
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTPartnerSuggestionsResponse = {
  __typename?: 'PartnerSuggestionsResponse'
  items: Array<GTPartnerSuggestion>
  total: Scalars['Long']['output']
}

export const GTPartnerTaxInformationPersonTypeInput = {
  Individual: 'INDIVIDUAL',
  LegalEntity: 'LEGAL_ENTITY'
} as const

export type GTPartnerTaxInformationPersonTypeInput =
  (typeof GTPartnerTaxInformationPersonTypeInput)[keyof typeof GTPartnerTaxInformationPersonTypeInput]
export const GTPartnerTypeInput = {
  Fleet: 'FLEET',
  Host: 'HOST'
} as const

export type GTPartnerTypeInput = (typeof GTPartnerTypeInput)[keyof typeof GTPartnerTypeInput]
export const GTPartnerVerticalInput = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTPartnerVerticalInput = (typeof GTPartnerVerticalInput)[keyof typeof GTPartnerVerticalInput]
export type GTPassengerPropertiesAutocompleteInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  salesChannelsIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTPassengerPropertiesAutocompleteOutput = {
  __typename?: 'PassengerPropertiesAutocompleteOutput'
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type GTPassengerPropertiesInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTPassengerPropertiesOutput = {
  __typename?: 'PassengerPropertiesOutput'
  items: Array<GTPassengerProperty>
  total: Scalars['Int']['output']
}

export type GTPassengerProperty = {
  __typename?: 'PassengerProperty'
  abbreviation: Array<GTPassengerPropertyTranslation>
  countryCode: Scalars['String']['output']
  createdAt: Scalars['Time']['output']
  departureDateRanges: Array<GTPassengerPropertyDateRange>
  description: Array<GTPassengerPropertyTranslation>
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  label: Array<GTPassengerPropertyTranslation>
  name: Scalars['String']['output']
  required: Scalars['Boolean']['output']
  searchDateRanges: Array<GTPassengerPropertyDateRange>
  settings: Array<GTPassengerPropertySetting>
  updatedAt: Scalars['Time']['output']
}

export type GTPassengerPropertyDateRange = {
  __typename?: 'PassengerPropertyDateRange'
  endAt: Scalars['Time']['output']
  startAt: Scalars['Time']['output']
}

export type GTPassengerPropertyInput = {
  id: Scalars['ID']['input']
}

export type GTPassengerPropertyPassengerType = {
  __typename?: 'PassengerPropertyPassengerType'
  id: Scalars['ID']['output']
  label: Array<GTPassengerPropertyTranslation>
  name: Scalars['String']['output']
}

export type GTPassengerPropertySetting = {
  __typename?: 'PassengerPropertySetting'
  discount: Scalars['Int']['output']
  passengerTypes: Array<Maybe<GTPassengerPropertyPassengerType>>
  /** @deprecated use salesChannelsV2 instead */
  salesChannels: Array<GTSalesChannel>
  salesChannelsV2: Array<GTPassengerPropertySettingSalesChannel>
}

export type GTPassengerPropertySettingSalesChannel = {
  __typename?: 'PassengerPropertySettingSalesChannel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTPassengerPropertyTranslation = {
  __typename?: 'PassengerPropertyTranslation'
  language: GTLanguage
  text: Scalars['String']['output']
}

/** passenger types */
export const GTPassengerType = {
  Adult: 'adult',
  Child: 'child',
  Senior: 'senior',
  Student: 'student',
  Teacher: 'teacher'
} as const

export type GTPassengerType = (typeof GTPassengerType)[keyof typeof GTPassengerType]
export type GTPassengerTypeInput = {
  id: Scalars['ID']['input']
}

export type GTPassengerTypeSalesChannel = {
  __typename?: 'PassengerTypeSalesChannel'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GTPassengerTypeSalesChannelAdmin = {
  KolorsMx: 'KOLORS_MX',
  KolorsUs: 'KOLORS_US',
  Pos: 'POS',
  All: 'all',
  Offline: 'offline',
  Online: 'online'
} as const

export type GTPassengerTypeSalesChannelAdmin =
  (typeof GTPassengerTypeSalesChannelAdmin)[keyof typeof GTPassengerTypeSalesChannelAdmin]
export type GTPassengerTypeV2 = {
  __typename?: 'PassengerTypeV2'
  abbreviation: Array<GTPassengerTypeV2Translation>
  countryCode: Scalars['String']['output']
  createdAt: Scalars['Time']['output']
  description: Array<GTPassengerTypeV2Translation>
  discount: Scalars['Int']['output']
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  label: Array<GTPassengerTypeV2Translation>
  name: Scalars['String']['output']
  /** @deprecated use salesChannelsV2 */
  salesChannels: Array<GTPassengerTypeSalesChannelAdmin>
  salesChannelsV2: Array<GTPassengerTypeSalesChannel>
  updatedAt: Scalars['Time']['output']
}

export type GTPassengerTypeV2Translation = {
  __typename?: 'PassengerTypeV2Translation'
  language: GTLanguage
  plural: Scalars['Boolean']['output']
  text: Scalars['String']['output']
}

export type GTPassengerTypesAutocompleteInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  salesChannelsIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTPassengerTypesAutocompleteOutput = {
  __typename?: 'PassengerTypesAutocompleteOutput'
  countryCode: Scalars['String']['output']
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type GTPassengerTypesInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTPassengerTypesOutput = {
  __typename?: 'PassengerTypesOutput'
  items: Array<GTPassengerTypeV2>
  total: Scalars['Int']['output']
}

export const GTPaymentIncidencePaymentMethodInput = {
  Card: 'CARD',
  Cash: 'CASH'
} as const

export type GTPaymentIncidencePaymentMethodInput =
  (typeof GTPaymentIncidencePaymentMethodInput)[keyof typeof GTPaymentIncidencePaymentMethodInput]
/** payment methods */
export const GTPaymentMethod = {
  Card: 'card',
  Cash: 'cash'
} as const

export type GTPaymentMethod = (typeof GTPaymentMethod)[keyof typeof GTPaymentMethod]
/** payment processors */
export const GTPaymentProcessor = {
  Clip: 'clip',
  Conekta: 'conekta'
} as const

export type GTPaymentProcessor = (typeof GTPaymentProcessor)[keyof typeof GTPaymentProcessor]
export type GTPaymentRefundInput = {
  /** amount to refund in cents */
  amount: Scalars['Int']['input']
  /** payment id */
  paymentID: Scalars['ID']['input']
}

export type GTPaymentRefundInputV2 = {
  /** amount to refund in cents */
  amount: Scalars['Int']['input']
  /** payment ID */
  paymentID: Scalars['UUID']['input']
}

/** payment status */
export const GTPaymentStatus = {
  Paid: 'paid',
  Pending: 'pending'
} as const

export type GTPaymentStatus = (typeof GTPaymentStatus)[keyof typeof GTPaymentStatus]
/** payment ticket */
export type GTPaymentTicket = {
  __typename?: 'PaymentTicket'
  /** discount */
  discount: Scalars['Int']['output']
  /** id */
  id: Scalars['String']['output']
  /** subtotal in cents */
  subtotal: Scalars['Int']['output']
  /** vat in cents */
  vat: Scalars['Int']['output']
}

export type GTPaymentUser = {
  __typename?: 'PaymentUser'
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

/** permission */
export type GTPermission = {
  __typename?: 'Permission'
  /** actions */
  actions: Array<Scalars['String']['output']>
  /** permission id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

export type GTPermissionInput = {
  action?: Scalars['String']['input']
  aggregateName: Scalars['String']['input']
  strict?: Scalars['Boolean']['input']
}

export type GTPhoneNumber = {
  __typename?: 'PhoneNumber'
  countryCode: Scalars['String']['output']
  number: Scalars['String']['output']
}

/**
 * phone number is divided into countryCode and number, excluding the plus sign.
 * e.g. +52 55 1234 5678 => countryCode: "52", number: "5512345678"
 */
export type GTPhoneNumberInput = {
  countryCode: Scalars['String']['input']
  number: Scalars['String']['input']
}

/** place */
export type GTPlace = {
  __typename?: 'Place'
  /** address */
  address?: Maybe<GTAddress>
  /** amenities */
  amenities: Array<GTPlaceAmenity>
  /** area */
  area?: Maybe<Scalars['String']['output']>
  /** city name, only present if place is not a city */
  cityName?: Maybe<Scalars['String']['output']>
  /** code */
  code: Scalars['String']['output']
  /** countryCode */
  countryCode?: Maybe<Scalars['String']['output']>
  /** directions */
  directions?: Maybe<Scalars['String']['output']>
  /** place id */
  id: Scalars['ID']['output']
  /** geolocation */
  location: GTLocation
  /** name */
  name: Scalars['String']['output']
  /** unique human readable identifier for the place */
  param: Scalars['String']['output']
  /** parent, only present if place is not a city */
  parent?: Maybe<GTPlace>
  /** child places. only present if place is a city. */
  places: Array<GTPlace>
  /** state code in ISO_3166-2:MX format. */
  stateCode?: Maybe<Scalars['String']['output']>
  /** tags */
  tags: Array<Scalars['String']['output']>
  /** timezone */
  timezone: Scalars['String']['output']
  /** place type */
  type: GTPlaceType
}

export type GTPlaceAddressV2 = {
  __typename?: 'PlaceAddressV2'
  city: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  line1: Scalars['String']['output']
  line2?: Maybe<Scalars['String']['output']>
  stateCode: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export const GTPlaceAmenity = {
  KolorsMobile: 'KOLORS_MOBILE',
  KolorsTicket: 'KOLORS_TICKET'
} as const

export type GTPlaceAmenity = (typeof GTPlaceAmenity)[keyof typeof GTPlaceAmenity]
export type GTPlaceByParamV2Input = {
  param: Scalars['String']['input']
  salesChannelID: Scalars['UUID']['input']
  vertical?: GTPlaceVertical
}

export type GTPlaceGeoLocationInput = {
  latitude: Scalars['String']['input']
  longitude: Scalars['String']['input']
}

export type GTPlaceLocationV2 = {
  __typename?: 'PlaceLocationV2'
  latitude: Scalars['String']['output']
  longitude: Scalars['String']['output']
}

export type GTPlaceSuggestion = {
  __typename?: 'PlaceSuggestion'
  id: Scalars['UUID']['output']
  location?: Maybe<GTPlaceSuggestionLocation>
  name: Scalars['String']['output']
  stateCode?: Maybe<Scalars['String']['output']>
}

export type GTPlaceSuggestionLocation = {
  __typename?: 'PlaceSuggestionLocation'
  latitude: Scalars['String']['output']
  longitude: Scalars['String']['output']
}

export type GTPlaceSuggestionV2 = {
  __typename?: 'PlaceSuggestionV2'
  address?: Maybe<GTPlaceAddressV2>
  cityName: Scalars['String']['output']
  code: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  id: Scalars['UUID']['output']
  location?: Maybe<GTPlaceLocationV2>
  name: Scalars['String']['output']
  param: Scalars['String']['output']
  places: Array<GTPlaceSuggestionV2>
  searchScore: Scalars['Float']['output']
  stateCode: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type GTPlaceSuggestionsInput = {
  limit: Scalars['Int']['input']
  originId?: InputMaybe<Scalars['UUID']['input']>
  pattern: Scalars['String']['input']
  salesChannelID: Scalars['UUID']['input']
  vertical?: GTPlaceVertical
}

export type GTPlaceSuggestionsResponse = {
  __typename?: 'PlaceSuggestionsResponse'
  places: Array<GTPlaceSuggestionV2>
}

/** place types */
export const GTPlaceType = {
  Attraction: 'attraction',
  City: 'city',
  ConvenienceStore: 'convenience_store',
  Hotel: 'hotel',
  Mall: 'mall',
  Office: 'office',
  ParkingLot: 'parking_lot',
  PointOfInterest: 'point_of_interest',
  ResidentialArea: 'residential_area',
  Restaurant: 'restaurant',
  School: 'school',
  Sidewalk: 'sidewalk',
  Station: 'station',
  Supermarket: 'supermarket',
  Venue: 'venue'
} as const

export type GTPlaceType = (typeof GTPlaceType)[keyof typeof GTPlaceType]
export type GTPlaceV2 = {
  __typename?: 'PlaceV2'
  address?: Maybe<GTPlaceAddressV2>
  cityName?: Maybe<Scalars['String']['output']>
  code: Scalars['String']['output']
  countryCode?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  location?: Maybe<GTPlaceLocationV2>
  name: Scalars['String']['output']
  param: Scalars['String']['output']
  parent?: Maybe<GTPlaceV2>
  places: Array<GTPlaceV2>
  stateCode?: Maybe<Scalars['String']['output']>
  tags: Array<Scalars['String']['output']>
  timezone: Scalars['String']['output']
  type: Scalars['String']['output']
}

export const GTPlaceV2AmenityInput = {
  KolorsMobile: 'KOLORS_MOBILE',
  KolorsTicket: 'KOLORS_TICKET'
} as const

export type GTPlaceV2AmenityInput = (typeof GTPlaceV2AmenityInput)[keyof typeof GTPlaceV2AmenityInput]
export type GTPlaceV2Input = {
  id: Scalars['UUID']['input']
  salesChannelID: Scalars['UUID']['input']
  vertical?: GTPlaceVertical
}

export const GTPlaceV2TypeInput = {
  Attraction: 'ATTRACTION',
  ConvenienceStore: 'CONVENIENCE_STORE',
  Hotel: 'HOTEL',
  Mall: 'MALL',
  Office: 'OFFICE',
  ParkingLot: 'PARKING_LOT',
  PointOfInterest: 'POINT_OF_INTEREST',
  ResidentialArea: 'RESIDENTIAL_AREA',
  Restaurant: 'RESTAURANT',
  School: 'SCHOOL',
  Sidewalk: 'SIDEWALK',
  Station: 'STATION',
  Supermarket: 'SUPERMARKET',
  Venue: 'VENUE'
} as const

export type GTPlaceV2TypeInput = (typeof GTPlaceV2TypeInput)[keyof typeof GTPlaceV2TypeInput]
export const GTPlaceVertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTPlaceVertical = (typeof GTPlaceVertical)[keyof typeof GTPlaceVertical]
export type GTPlacesByGeoLocation = {
  __typename?: 'PlacesByGeoLocation'
  destination: GTPlacesByGeoLocationPlace
  origin: GTPlacesByGeoLocationPlace
}

export type GTPlacesByGeoLocationInput = {
  destination: GTPlaceGeoLocationInput
  distance?: Scalars['Int']['input']
  origin: GTPlaceGeoLocationInput
  salesChannelID: Scalars['UUID']['input']
  vertical?: GTPlaceVertical
}

export type GTPlacesByGeoLocationPlace = {
  __typename?: 'PlacesByGeoLocationPlace'
  id: Scalars['UUID']['output']
  location: GTPlaceLocationV2
  name: Scalars['String']['output']
  param: Scalars['String']['output']
}

export type GTPlacesByGeoLocationResponse = {
  __typename?: 'PlacesByGeoLocationResponse'
  places: Array<GTPlacesByGeoLocation>
}

/** policy types */
export const GTPolicyType = {
  CompanyRole: 'companyRole',
  Permission: 'permission',
  PrivateRole: 'privateRole',
  Role: 'role'
} as const

export type GTPolicyType = (typeof GTPolicyType)[keyof typeof GTPolicyType]
export type GTProcessBusinessClientKreditsDispersionInput = {
  id: Scalars['UUID']['input']
}

/** Application-level queries. */
export type GTQuery = {
  __typename?: 'Query'
  ancillaries: GTAncillariesResponse
  ancillary: GTAncillary
  bookingClasses: Array<GTBookingClass>
  /** get bookings */
  bookings: GTBookingsResponse
  bookingsDashboard: GTBookingsDashboardResponse
  bookingsV2: GTBookingsV2Response
  businessClient: GTBusinessClient
  businessClientSuggestions: GTBusinessClientSuggestionsResponse
  businessClientUser: GTBusinessClientUser
  businessClientUsers: GTBusinessClientUsersResponse
  businessClients: GTBusinessClientsResponse
  businessClientsAutocomplete: GTBusinessClientsAutocompleteResponse
  businessCustomer: GTBusinessCustomer
  businessCustomerSuggestions: GTBusinessCustomerSuggestionsResponse
  businessCustomers: GTBusinessCustomersResponse
  businessPartner: GTBusinessPartner
  businessPartners: GTBusinessPartnersResponse
  businessPartnersAutocomplete: GTBusinessPartnersAutocompleteResponse
  businessSite: GTBusinessSite
  businessSiteSuggestions: GTBusinessSiteSuggestionsResponse
  businessSites: GTBusinessSitesResponse
  businessSitesAutocomplete: GTBusinessSitesAutocompleteResponse
  campaign?: Maybe<GTCampaign>
  campaigns: Array<GTCampaign>
  campaignsAutocomplete: Array<GTCampaignAutocomplete>
  cart?: Maybe<GTCart>
  cartActivity: GTCartActivityResponse
  carts: GTCartsResponse
  /** get cashiers */
  cashiers: Array<GTUser>
  changes: GTChangesResponse
  /**
   * get cities. default limit is 10.
   * @deprecated Use tcities instead
   */
  cities: Array<GTPlace>
  /**
   * get city
   * @deprecated Use tcity instead
   */
  city?: Maybe<GTPlace>
  citySuggestions: GTCitySuggestionsResponse
  comboItem?: Maybe<GTComboItem>
  comboItems: GTComboItemsResponse
  comboItemsAutocomplete: Array<GTComboItemAutocomplete>
  /**
   * get companies. default limit is 10.
   * @deprecated Use companiesV2 instead
   */
  companies: Array<GTCompany>
  /** @deprecated Use companiesV2Autocomplete instead */
  companiesAutocomplete: Array<GTCompanyAutocomplete>
  companiesV2: GTCompaniesV2Response
  companiesV2Autocomplete: GTCompaniesV2AutocompleteResponse
  /**
   * get company
   * @deprecated Use companyV2 instead
   */
  company?: Maybe<GTCompany>
  companyInsuranceSettings: GTCompanyInsuranceSettings
  /** get company line */
  companyLine?: Maybe<GTCompanyLine>
  /** get company lines. default limit is 10. */
  companyLines: Array<GTCompanyLine>
  companyLinesAutocomplete: Array<GTCompanyLineAutocomplete>
  /** get company roles */
  companyRoles: GTCompanyRolesResponse
  companySuggestions: GTCompanySuggestionsResponse
  companyV2: GTCompanyV2
  countries: GTCountriesOutput
  country: GTCountry
  coupon?: Maybe<GTCoupon>
  couponsWithPagination: GTCouponsResponse
  currencies: GTCurrenciesOutput
  currency: GTCurrency
  databaseSubcampaign?: Maybe<GTDatabaseSubcampaign>
  databaseSubcampaignCoupons?: Maybe<GTDatabaseSubcampaignCouponsResponse>
  databaseSubcampaigns: GTDatabaseSubcampaignsResponse
  drawers: GTDrawersResponse
  driverSuggestions: GTDriverSuggestionsResponse
  /** get drivers */
  drivers: Array<GTUser>
  /** @deprecated Use fareFamiliesWithPagination instead */
  fareFamilies: Array<GTFareFamily>
  fareFamiliesWithPagination: GTFareFamiliesResponse
  fareRule?: Maybe<GTFareRule>
  fareRules: GTFareRulesResponse
  incidenceSubcampaign?: Maybe<GTIncidenceSubcampaign>
  incidenceSubcampaigns: GTIncidenceSubcampaignsResponse
  inventories: GTInventoriesResponse
  inventoriesAutocomplete: Array<GTInventoryAutocomplete>
  inventory?: Maybe<GTInventory>
  inventoryItems: GTInventoryItemsResponse
  inventoryRule?: Maybe<GTInventoryRule>
  inventoryRules: GTInventoryRulesResponse
  inventoryWastes: GTInventoryWastesResponse
  item?: Maybe<GTItem>
  items: GTItemsResponse
  itemsAutocomplete: Array<GTItemAutocomplete>
  kolorsMobilePlanItem?: Maybe<GTKolorsMobilePlanItem>
  kolorsMobilePlanItems: GTKolorsMobilePlanItemsResponse
  /** Fetch a specific Kolors Credits container using its ID. */
  kredit: GTKredit
  kreditTransactions: GTKreditTransactionsResponse
  /** Fetch a list of Kolors Credits containers, possibly filtered by User characteristics. */
  kredits: GTKreditsResponse
  locationCities: GTLocationCitiesResponse
  locationCity: GTLocationCity
  loyaltyCampaign: GTLoyaltyCampaign
  loyaltyCampaignRule: GTLoyaltyCampaignRule
  loyaltyCampaigns: GTLoyaltyCampaignsResponse
  loyaltyRewards: GTLoyaltyRewardsResponse
  loyaltyRewardsAutocomplete: Array<GTLoyaltyRewardAutocomplete>
  noop?: Maybe<Scalars['Boolean']['output']>
  order?: Maybe<GTOrder>
  orders: GTOrdersResponse
  partnerSuggestions: GTPartnerSuggestionsResponse
  /** @deprecated Use businessPartnersAutocomplete instead */
  partnersAutocomplete: Array<GTPartner>
  passengerProperties: GTPassengerPropertiesOutput
  passengerPropertiesAutocomplete: Array<GTPassengerPropertiesAutocompleteOutput>
  passengerProperty: GTPassengerProperty
  passengerType: GTPassengerTypeV2
  passengerTypes: GTPassengerTypesOutput
  passengerTypesAutocomplete: Array<GTPassengerTypesAutocompleteOutput>
  /** get permissions */
  permissions: Array<GTPermission>
  /**
   * get place
   * @deprecated Use tplace instead
   */
  place?: Maybe<GTPlace>
  placeByParamV2: GTPlaceV2
  placeSuggestions: GTPlaceSuggestionsResponse
  placeV2: GTPlaceV2
  /**
   * get places. default limit is 10.
   * @deprecated Use tplaces instead
   */
  places: Array<GTPlace>
  placesByGeoLocation: GTPlacesByGeoLocationResponse
  /** get role */
  role?: Maybe<GTRole>
  /** get roles */
  roles: GTRolesResponse
  /**
   * get route
   * @deprecated Use troute instead
   */
  route?: Maybe<GTRoute>
  routeServiceSuggestions: GTRouteServiceSuggestionsResponse
  routeSuggestions: GTRouteSuggestionsResponse
  routeVehicles: Array<GTVehicle>
  /**
   * get routes. default limit is 10.
   * @deprecated Use troutes instead
   */
  routes: Array<GTRoute>
  /** @deprecated Use troutes instead */
  routesWithPagination: GTRoutesResponse
  sales: GTSalesResponse
  salesChannel: GTSalesChannelV2
  salesChannels: GTSalesChannelsOutput
  seasonEvent?: Maybe<GTSeasonEvent>
  seasonEvents: Array<GTSeasonEvent>
  seatsTemplate: GTSeatsTemplateResponse
  /** get segment */
  segment?: Maybe<GTSegment>
  segmentSuggestions: GTSegmentSuggestionsResponse
  /** get segments. default limit is 10. */
  segments: Array<GTSegment>
  /** get segments between cities */
  segmentsBetweenCities: Array<GTSegment>
  segmentsWithPagination: GTSegmentsResponse
  service?: Maybe<GTService>
  serviceFeeAddonRule: GTServiceFeeAddonRule
  serviceFeeAddonRules: GTServiceFeeAddonRulesOutput
  /**
   * get route services. default limit is 10.
   * @deprecated use servicesWithPagination
   */
  services: Array<GTService>
  servicesWithPagination: GTServicesResponse
  /** get site */
  site?: Maybe<GTSite>
  /** get sites */
  sites?: Maybe<GTSitesResponse>
  sitesAutocomplete: Array<GTSiteAutocomplete>
  sitesDashboard: GTSitesDashboard
  subcampaign?: Maybe<GTSubcampaign>
  suppliersAutocomplete: Array<GTSupplierAutocomplete>
  tcities: GTTCitiesResponse
  tcitiesAutocomplete: GTTCitiesAutocompleteResponse
  tcity: GTTCity
  tdriver: GTTDriver
  tdrivers: GTTDriversResponse
  tdriversAutocomplete: GTTDriversAutocompleteResponse
  ticketsInsurance: GTTicketInsuranceResponse
  tplace: GTTPlace
  tplaceSuggestions: GTTPlaceSuggestionsResponse
  tplaces: GTTPlacesResponse
  tplacesAutocomplete: GTTPlacesAutocompleteResponse
  /** get trip */
  trip?: Maybe<GTTrip>
  tripBookings: GTTripBookings
  tripSegments: Array<GTTripSegment>
  tripTracking: GTTripTrackingResponse
  tripV2: GTTripV2
  /** get trips */
  trips: GTTripsResponse
  tripsDriverConflict: GTTripsDriverConflictResponse
  tripsV2: GTTripsV2Response
  tripsVehicleConflict: GTTripsVehicleConflictResponse
  troute: GTTRoute
  troutes: GTTRoutesResponse
  troutesAutocomplete: GTTRoutesAutocompleteResponse
  tvehicle: GTTVehicle
  tvehicles: GTTVehiclesResponse
  tvehiclesAutocomplete: GTTVehiclesAutocompleteResponse
  /** get user */
  user?: Maybe<GTUser>
  userSuggestions: GTUserSuggestionsResponse
  /** get users */
  users: GTUsersResponse
  usersAutocomplete: Array<GTUserAutocomplete>
  /**
   * get vehicle
   * @deprecated Use tvehicle instead
   */
  vehicle?: Maybe<GTVehicle>
  vehicleSuggestions: GTVehicleSuggestionsResponse
  /** get vehicle template */
  vehicleTemplate?: Maybe<GTVehicleTemplate>
  /** get vehicle templates */
  vehicleTemplates: Array<GTVehicleTemplate>
  /** @deprecated Use tvehiclesAutocomplete instead */
  vehicleTemplatesAutocomplete: Array<GTVehicleTemplateAutocomplete>
  /**
   * get vehicles
   * @deprecated Use tvehicles instead
   */
  vehicles: Array<GTVehicle>
  /** Fetch a specific Wallet using its ID. */
  wallet: GTWallet
  /** Fetch a list of Wallets, possibly filtered by User characteristics. */
  wallets: GTWalletsResponse
}

/** Application-level queries. */
export type GTQueryAncillariesArgs = {
  input?: InputMaybe<GTAncillariesInput>
  pagination: GTPagination
}

/** Application-level queries. */
export type GTQueryAncillaryArgs = {
  input?: InputMaybe<GTAncillaryInput>
}

/** Application-level queries. */
export type GTQueryBookingClassesArgs = {
  input?: InputMaybe<GTBookingClassesInput>
}

/** Application-level queries. */
export type GTQueryBookingsArgs = {
  input?: InputMaybe<GTBookingsQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryBookingsDashboardArgs = {
  input?: InputMaybe<GTBookingsDashboardInput>
}

/** Application-level queries. */
export type GTQueryBookingsV2Args = {
  input: GTBookingsV2Input
  pagination?: GTPaginationV2
  sorting?: GTSortingV2
}

/** Application-level queries. */
export type GTQueryBusinessClientArgs = {
  id: Scalars['UUID']['input']
}

/** Application-level queries. */
export type GTQueryBusinessClientSuggestionsArgs = {
  input: GTBusinessClientSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryBusinessClientUserArgs = {
  id: Scalars['UUID']['input']
}

/** Application-level queries. */
export type GTQueryBusinessClientUsersArgs = {
  input?: InputMaybe<GTBusinessClientUsersInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryBusinessClientsArgs = {
  input?: InputMaybe<GTBusinessClientsInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryBusinessClientsAutocompleteArgs = {
  input?: InputMaybe<GTBusinessClientsAutocompleteInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryBusinessCustomerArgs = {
  input: GTBusinessCustomerInput
}

/** Application-level queries. */
export type GTQueryBusinessCustomerSuggestionsArgs = {
  input: GTBusinessCustomerSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryBusinessCustomersArgs = {
  input: GTBusinessCustomersInput
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryBusinessPartnerArgs = {
  id: Scalars['UUID']['input']
}

/** Application-level queries. */
export type GTQueryBusinessPartnersArgs = {
  input?: InputMaybe<GTBusinessPartnersInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryBusinessPartnersAutocompleteArgs = {
  input?: InputMaybe<GTBusinessPartnersAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryBusinessSiteArgs = {
  id: Scalars['UUID']['input']
}

/** Application-level queries. */
export type GTQueryBusinessSiteSuggestionsArgs = {
  input: GTBusinessSiteSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryBusinessSitesArgs = {
  input?: InputMaybe<GTBusinessSitesInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryBusinessSitesAutocompleteArgs = {
  input?: InputMaybe<GTBusinessSitesAutocompleteInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryCampaignArgs = {
  input: GTCampaignQueryInput
}

/** Application-level queries. */
export type GTQueryCampaignsArgs = {
  input?: InputMaybe<GTCampaignsInput>
}

/** Application-level queries. */
export type GTQueryCampaignsAutocompleteArgs = {
  input?: InputMaybe<GTCampaignsAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryCartArgs = {
  input: GTCartQueryInput
}

/** Application-level queries. */
export type GTQueryCartActivityArgs = {
  input?: InputMaybe<GTCartActivityInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryCartsArgs = {
  input?: InputMaybe<GTCartsQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryCashiersArgs = {
  input?: InputMaybe<GTCashiersQueryInput>
}

/** Application-level queries. */
export type GTQueryChangesArgs = {
  input?: InputMaybe<GTChangesQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryCitiesArgs = {
  input?: InputMaybe<GTPlacesQueryInput>
}

/** Application-level queries. */
export type GTQueryCityArgs = {
  input: GTPlaceQueryInput
}

/** Application-level queries. */
export type GTQueryCitySuggestionsArgs = {
  input: GTCitySuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryComboItemArgs = {
  input: GTComboItemQueryInput
}

/** Application-level queries. */
export type GTQueryComboItemsArgs = {
  input?: InputMaybe<GTComboItemsQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryComboItemsAutocompleteArgs = {
  input?: InputMaybe<GTComboItemsAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryCompaniesArgs = {
  input?: InputMaybe<GTCompaniesQueryInput>
}

/** Application-level queries. */
export type GTQueryCompaniesAutocompleteArgs = {
  input?: InputMaybe<GTCompaniesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryCompaniesV2Args = {
  input?: InputMaybe<GTCompaniesV2Input>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryCompaniesV2AutocompleteArgs = {
  input?: InputMaybe<GTCompaniesV2AutocompleteInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryCompanyArgs = {
  input: GTCompanyQueryInput
}

/** Application-level queries. */
export type GTQueryCompanyInsuranceSettingsArgs = {
  input: GTCompanyInsuranceSettingsInput
}

/** Application-level queries. */
export type GTQueryCompanyLineArgs = {
  input?: InputMaybe<GTCompanyLineQueryInput>
}

/** Application-level queries. */
export type GTQueryCompanyLinesArgs = {
  input?: InputMaybe<GTCompanyLinesQueryInput>
}

/** Application-level queries. */
export type GTQueryCompanyLinesAutocompleteArgs = {
  input?: InputMaybe<GTCompanyLinesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryCompanyRolesArgs = {
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryCompanySuggestionsArgs = {
  input: GTCompanySuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryCompanyV2Args = {
  id: Scalars['UUID']['input']
}

/** Application-level queries. */
export type GTQueryCountriesArgs = {
  input?: InputMaybe<GTCountriesInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryCountryArgs = {
  input: GTCountryInput
}

/** Application-level queries. */
export type GTQueryCouponArgs = {
  input: GTCouponInput
}

/** Application-level queries. */
export type GTQueryCouponsWithPaginationArgs = {
  input?: InputMaybe<GTCouponsWithPaginationInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryCurrenciesArgs = {
  input?: InputMaybe<GTCurrenciesInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryCurrencyArgs = {
  input: GTCurrencyInput
}

/** Application-level queries. */
export type GTQueryDatabaseSubcampaignArgs = {
  input: GTDatabaseSubcampaignInput
}

/** Application-level queries. */
export type GTQueryDatabaseSubcampaignCouponsArgs = {
  input: GTDatabaseSubcampaignCouponsInput
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryDatabaseSubcampaignsArgs = {
  input?: InputMaybe<GTDatabaseSubcampaignsInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryDrawersArgs = {
  input?: InputMaybe<GTDrawersInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryDriverSuggestionsArgs = {
  input: GTDriverSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryDriversArgs = {
  input: GTDriversInput
}

/** Application-level queries. */
export type GTQueryFareFamiliesArgs = {
  input?: InputMaybe<GTFareFamiliesInput>
}

/** Application-level queries. */
export type GTQueryFareFamiliesWithPaginationArgs = {
  input?: InputMaybe<GTFareFamiliesInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryFareRuleArgs = {
  input: GTFareRuleInput
}

/** Application-level queries. */
export type GTQueryFareRulesArgs = {
  input?: InputMaybe<GTFareRulesInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryIncidenceSubcampaignArgs = {
  input: GTIncidenceSubcampaignInput
}

/** Application-level queries. */
export type GTQueryIncidenceSubcampaignsArgs = {
  input?: InputMaybe<GTIncidenceSubcampaignsInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryInventoriesArgs = {
  input?: InputMaybe<GTInventoriesQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryInventoriesAutocompleteArgs = {
  input?: InputMaybe<GTInventoriesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryInventoryArgs = {
  input: GTInventoryQueryInput
}

/** Application-level queries. */
export type GTQueryInventoryItemsArgs = {
  input: GTInventoryItemsQueryInput
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryInventoryRuleArgs = {
  input: GTInventoryRuleInput
}

/** Application-level queries. */
export type GTQueryInventoryRulesArgs = {
  input: GTInventoryRulesInput
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryInventoryWastesArgs = {
  input?: InputMaybe<GTInventoryWastesQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryItemArgs = {
  input: GTItemQueryInput
}

/** Application-level queries. */
export type GTQueryItemsArgs = {
  input?: InputMaybe<GTItemsQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryItemsAutocompleteArgs = {
  input?: InputMaybe<GTItemsAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryKolorsMobilePlanItemArgs = {
  input?: InputMaybe<GTKolorsMobilePlanItemInput>
}

/** Application-level queries. */
export type GTQueryKolorsMobilePlanItemsArgs = {
  input?: InputMaybe<GTKolorsMobilePlanItemsInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryKreditArgs = {
  input: GTKreditInput
}

/** Application-level queries. */
export type GTQueryKreditTransactionsArgs = {
  input: GTKreditTransactionsInput
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryKreditsArgs = {
  input: GTKreditsInput
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryLocationCitiesArgs = {
  input: GTLocationCitiesInput
}

/** Application-level queries. */
export type GTQueryLocationCityArgs = {
  input: GTLocationCityInput
}

/** Application-level queries. */
export type GTQueryLoyaltyCampaignArgs = {
  input: GTLoyaltyCampaignInput
}

/** Application-level queries. */
export type GTQueryLoyaltyCampaignRuleArgs = {
  input: GTLoyaltyCampaignRuleInput
}

/** Application-level queries. */
export type GTQueryLoyaltyCampaignsArgs = {
  input?: InputMaybe<GTLoyaltyCampaignsInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryLoyaltyRewardsArgs = {
  input: GTLoyaltyRewardsInput
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryLoyaltyRewardsAutocompleteArgs = {
  input?: InputMaybe<GTLoyaltyRewardsAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryOrderArgs = {
  input: GTOrderQueryInput
}

/** Application-level queries. */
export type GTQueryOrdersArgs = {
  input?: InputMaybe<GTOrdersQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryPartnerSuggestionsArgs = {
  input: GTPartnerSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryPassengerPropertiesArgs = {
  input?: InputMaybe<GTPassengerPropertiesInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryPassengerPropertiesAutocompleteArgs = {
  input?: InputMaybe<GTPassengerPropertiesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryPassengerPropertyArgs = {
  input: GTPassengerPropertyInput
}

/** Application-level queries. */
export type GTQueryPassengerTypeArgs = {
  input: GTPassengerTypeInput
}

/** Application-level queries. */
export type GTQueryPassengerTypesArgs = {
  input?: InputMaybe<GTPassengerTypesInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryPassengerTypesAutocompleteArgs = {
  input?: InputMaybe<GTPassengerTypesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryPermissionsArgs = {
  input?: InputMaybe<GTPermissionsQueryInput>
}

/** Application-level queries. */
export type GTQueryPlaceArgs = {
  input: GTPlaceQueryInput
}

/** Application-level queries. */
export type GTQueryPlaceByParamV2Args = {
  input: GTPlaceByParamV2Input
}

/** Application-level queries. */
export type GTQueryPlaceSuggestionsArgs = {
  input: GTPlaceSuggestionsInput
}

/** Application-level queries. */
export type GTQueryPlaceV2Args = {
  input: GTPlaceV2Input
}

/** Application-level queries. */
export type GTQueryPlacesArgs = {
  input?: InputMaybe<GTPlacesQueryInput>
}

/** Application-level queries. */
export type GTQueryPlacesByGeoLocationArgs = {
  input: GTPlacesByGeoLocationInput
}

/** Application-level queries. */
export type GTQueryRoleArgs = {
  input: GTRoleQueryInput
}

/** Application-level queries. */
export type GTQueryRolesArgs = {
  input?: InputMaybe<GTRolesQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryRouteArgs = {
  input?: InputMaybe<GTRouteQueryInput>
}

/** Application-level queries. */
export type GTQueryRouteServiceSuggestionsArgs = {
  input: GTRouteServiceSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryRouteSuggestionsArgs = {
  input: GTRouteSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryRouteVehiclesArgs = {
  input: GTRouteVehiclesQueryInput
}

/** Application-level queries. */
export type GTQueryRoutesArgs = {
  input?: InputMaybe<GTRoutesQueryInput>
}

/** Application-level queries. */
export type GTQueryRoutesWithPaginationArgs = {
  input?: InputMaybe<GTRoutesQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQuerySalesArgs = {
  input?: InputMaybe<GTSalesQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQuerySalesChannelArgs = {
  input: GTSalesChannelInput
}

/** Application-level queries. */
export type GTQuerySalesChannelsArgs = {
  input?: InputMaybe<GTSalesChannelsInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQuerySeasonEventArgs = {
  input: GTSeasonEventInput
}

/** Application-level queries. */
export type GTQuerySeatsTemplateArgs = {
  input: GTSeatsTemplateInput
}

/** Application-level queries. */
export type GTQuerySegmentArgs = {
  input?: InputMaybe<GTSegmentQueryInput>
}

/** Application-level queries. */
export type GTQuerySegmentSuggestionsArgs = {
  input: GTSegmentSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQuerySegmentsArgs = {
  input?: InputMaybe<GTSegmentsQueryInput>
}

/** Application-level queries. */
export type GTQuerySegmentsBetweenCitiesArgs = {
  input: GTSegmentsBetweenCitiesInput
}

/** Application-level queries. */
export type GTQuerySegmentsWithPaginationArgs = {
  input?: InputMaybe<GTSegmentsQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryServiceArgs = {
  input: GTServiceQueryInput
}

/** Application-level queries. */
export type GTQueryServiceFeeAddonRuleArgs = {
  id: Scalars['ID']['input']
}

/** Application-level queries. */
export type GTQueryServiceFeeAddonRulesArgs = {
  input: GTServiceFeeAddonRulesInput
  pagination: GTPagination
}

/** Application-level queries. */
export type GTQueryServicesArgs = {
  input?: InputMaybe<GTServicesQueryInput>
}

/** Application-level queries. */
export type GTQueryServicesWithPaginationArgs = {
  input?: InputMaybe<GTServicesQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQuerySiteArgs = {
  input: GTSiteQueryInput
}

/** Application-level queries. */
export type GTQuerySitesArgs = {
  input?: InputMaybe<GTSitesQueryInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQuerySitesAutocompleteArgs = {
  input?: InputMaybe<GTSitesAutocompleteInput>
}

/** Application-level queries. */
export type GTQuerySitesDashboardArgs = {
  input?: InputMaybe<GTSitesDashboardInput>
}

/** Application-level queries. */
export type GTQuerySubcampaignArgs = {
  input: GTSubcampaignInput
}

/** Application-level queries. */
export type GTQuerySuppliersAutocompleteArgs = {
  input?: InputMaybe<GTSuppliersAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryTcitiesArgs = {
  input?: InputMaybe<GTTCitiesInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryTcitiesAutocompleteArgs = {
  input?: InputMaybe<GTTCitiesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryTcityArgs = {
  input: GTTCityInput
}

/** Application-level queries. */
export type GTQueryTdriverArgs = {
  input: GTTDriverInput
}

/** Application-level queries. */
export type GTQueryTdriversArgs = {
  input?: InputMaybe<GTTDriversInput>
  pagination?: InputMaybe<GTTPagination>
  sorting?: InputMaybe<GTTSorting>
}

/** Application-level queries. */
export type GTQueryTdriversAutocompleteArgs = {
  input?: InputMaybe<GTTDriversAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryTicketsInsuranceArgs = {
  input?: InputMaybe<GTTicketsInsuranceInput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryTplaceArgs = {
  input: GTTPlaceInput
}

/** Application-level queries. */
export type GTQueryTplaceSuggestionsArgs = {
  input: GTTPlaceSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryTplacesArgs = {
  input?: InputMaybe<GTTPlacesInput>
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryTplacesAutocompleteArgs = {
  input?: InputMaybe<GTTPlacesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryTripArgs = {
  input: GTTripQueryInput
}

/** Application-level queries. */
export type GTQueryTripBookingsArgs = {
  input: GTTripBookingsQueryInput
}

/** Application-level queries. */
export type GTQueryTripSegmentsArgs = {
  input: GTTripSegmentsQueryInput
}

/** Application-level queries. */
export type GTQueryTripTrackingArgs = {
  input: GTTripTrackingQueryInput
}

/** Application-level queries. */
export type GTQueryTripV2Args = {
  input: GTTripV2Input
}

/** Application-level queries. */
export type GTQueryTripsArgs = {
  input: GTTripsQueryInput
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryTripsDriverConflictArgs = {
  input: GTTripsDriverConflictInput
}

/** Application-level queries. */
export type GTQueryTripsV2Args = {
  input: GTTripsV2Input
  pagination?: GTPaginationV2
  sorting?: GTSortingV2
}

/** Application-level queries. */
export type GTQueryTripsVehicleConflictArgs = {
  input: GTTripsVehicleConflictInput
}

/** Application-level queries. */
export type GTQueryTrouteArgs = {
  input: GTTRouteInput
}

/** Application-level queries. */
export type GTQueryTroutesArgs = {
  input?: InputMaybe<GTTRoutesInput>
  pagination?: InputMaybe<GTTPagination>
  sorting?: InputMaybe<GTTSorting>
}

/** Application-level queries. */
export type GTQueryTroutesAutocompleteArgs = {
  input?: InputMaybe<GTTRoutesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryTvehicleArgs = {
  input: GTTVehicleInput
}

/** Application-level queries. */
export type GTQueryTvehiclesArgs = {
  input?: InputMaybe<GTTVehiclesInput>
  pagination?: InputMaybe<GTTPagination>
  sorting?: InputMaybe<GTTSorting>
}

/** Application-level queries. */
export type GTQueryTvehiclesAutocompleteArgs = {
  input?: InputMaybe<GTTVehiclesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryUserArgs = {
  input: GTUserQueryInput
}

/** Application-level queries. */
export type GTQueryUserSuggestionsArgs = {
  input: GTUserSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryUsersArgs = {
  input?: InputMaybe<GTUsersQueryInmput>
  pagination?: InputMaybe<GTPagination>
}

/** Application-level queries. */
export type GTQueryUsersAutocompleteArgs = {
  input?: InputMaybe<GTUsersAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryVehicleArgs = {
  input: GTVehicleQueryInput
}

/** Application-level queries. */
export type GTQueryVehicleSuggestionsArgs = {
  input: GTVehicleSuggestionsInput
  pagination?: InputMaybe<GTPaginationV2>
  sorting?: InputMaybe<GTSortingV2>
}

/** Application-level queries. */
export type GTQueryVehicleTemplateArgs = {
  input: GTTemplateQueryInput
}

/** Application-level queries. */
export type GTQueryVehicleTemplatesArgs = {
  input?: InputMaybe<GTTemplatesQueryInput>
}

/** Application-level queries. */
export type GTQueryVehicleTemplatesAutocompleteArgs = {
  input?: InputMaybe<GTVehicleTemplatesAutocompleteInput>
}

/** Application-level queries. */
export type GTQueryVehiclesArgs = {
  input?: InputMaybe<GTVehiclesQueryInput>
}

/** Application-level queries. */
export type GTQueryWalletArgs = {
  input: GTWalletInput
}

/** Application-level queries. */
export type GTQueryWalletsArgs = {
  input: GTWalletsInput
  pagination?: InputMaybe<GTPagination>
}

export type GTReceiptData = {
  __typename?: 'ReceiptData'
  actualAmount?: Maybe<GTAmounts>
  expectedAmount: GTExpectedAmountByMethod
  ticketsByStatus?: Maybe<GTTicketsByStatus>
}

export type GTReceiveOrderInput = {
  id: Scalars['ID']['input']
  receivedItems: Array<GTReceivedItemInput>
}

export type GTReceivedItemInput = {
  id: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export const GTRefundDestinationInput = {
  Card: 'CARD',
  Kredit: 'KREDIT'
} as const

export type GTRefundDestinationInput = (typeof GTRefundDestinationInput)[keyof typeof GTRefundDestinationInput]
export type GTRegisterBookingSupportInteractionInput = {
  callStatus?: InputMaybe<GTCallStatus>
  id: Scalars['ID']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  notificationChannel?: InputMaybe<GTNotificationChannel>
}

export type GTRegisterBookingTaxInvoicedInput = {
  bookingID: Scalars['UUID']['input']
  taxFolio: Scalars['String']['input']
}

export type GTRegisterInventoryWasteInput = {
  id: Scalars['ID']['input']
  items: Array<GTRegisterWasteItemInput>
  purchaseID?: InputMaybe<Scalars['String']['input']>
  reason: GTItemWasteReason
  supplier?: InputMaybe<Scalars['String']['input']>
}

export type GTRegisterItemComboInput = {
  category: Scalars['String']['input']
  components: Array<GTItemComboComponentInput>
  minSellingPrice?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  photoPath?: InputMaybe<Scalars['String']['input']>
  sellingPrice: Scalars['Int']['input']
  subcategory: Scalars['String']['input']
}

export type GTRegisterItemInput = {
  /** brand */
  brand: Scalars['String']['input']
  category: Scalars['String']['input']
  container: Scalars['String']['input']
  /** the item's minimum selling price in cents */
  minSellingPrice?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  needsRefrigeration?: InputMaybe<Scalars['Boolean']['input']>
  /** net content in milliliters or milligrams */
  netContent: Scalars['Int']['input']
  photoPath?: InputMaybe<Scalars['String']['input']>
  /** the item's selling price in cents */
  sellingPrice: Scalars['Int']['input']
  subcategory?: InputMaybe<Scalars['String']['input']>
  /** template name */
  templateName?: InputMaybe<GTTemplateName>
  /** measurement units */
  units: Scalars['String']['input']
}

export type GTRegisterItemKolorsMobilePlanInput = {
  name: Scalars['String']['input']
  photoPath?: InputMaybe<Scalars['String']['input']>
  planID: Scalars['ID']['input']
  sellingPrice: Scalars['Int']['input']
  templateName: GTTemplateName
}

export type GTRegisterWasteItemInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  quantity: Scalars['Int']['input']
  unitPrice?: InputMaybe<Scalars['Int']['input']>
}

export type GTRejectOrderInput = {
  id: Scalars['ID']['input']
}

export type GTRemoveInventoryItemsInput = {
  id: Scalars['ID']['input']
  items: Array<GTRemoveItemsInput>
  purchaseID?: InputMaybe<Scalars['String']['input']>
  reason: GTItemRemovalReason
  supplier?: InputMaybe<Scalars['String']['input']>
}

export type GTRemoveItemsInput = {
  id: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
  unitPrice: Scalars['Int']['input']
}

export type GTRemovePartnerDocumentsInput = {
  documentIDs: Array<Scalars['UUID']['input']>
}

export type GTReplacePlaceForBusinessCustomerInput = {
  businessClientID: Scalars['UUID']['input']
  businessCustomerID: Scalars['UUID']['input']
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  currentPlaceID: Scalars['UUID']['input']
  name: Scalars['String']['input']
  place: GTBusinessCustomerPlaceInput
}

export type GTRequestCampaignSubcampaignCouponsDeliveryInput = {
  id: Scalars['ID']['input']
  subcampaignID: Scalars['ID']['input']
}

export type GTRequestResetUserAccountPasswordInput = {
  /** User Account Email */
  email: Scalars['String']['input']
}

export type GTResetUserPasswordInput = {
  id: Scalars['UUID']['input']
  password: Scalars['String']['input']
}

export type GTReturnCartInput = {
  id: Scalars['ID']['input']
}

/** role */
export type GTRole = {
  __typename?: 'Role'
  /** role id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** role's permissions */
  permissions: Array<GTRolePermission>
  /** users that have this role assigned */
  users: Array<GTRoleUser>
}

/** role company */
export type GTRoleCompany = {
  __typename?: 'RoleCompany'
  /** company id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** role permission */
export type GTRolePermission = {
  __typename?: 'RolePermission'
  /** permission id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** role user */
export type GTRoleUser = {
  __typename?: 'RoleUser'
  /** email */
  email: Scalars['String']['output']
  /** user id */
  id: Scalars['ID']['output']
}

/** route */
export type GTRoute = {
  __typename?: 'Route'
  /** company */
  company: GTRouteCompany
  /** company lines */
  companyLines: Array<GTRouteCompanyLine>
  /** route id */
  id: Scalars['ID']['output']
  /** indicates wether the route is active or not */
  isActive: Scalars['Boolean']['output']
  /** segments */
  segments: Array<GTRouteSegment>
  /** route services */
  services: Array<GTRouteRouteService>
  /** stops */
  stops: Array<GTRouteStop>
}

/** company */
export type GTRouteCompany = {
  __typename?: 'RouteCompany'
  /** company id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** company line */
export type GTRouteCompanyLine = {
  __typename?: 'RouteCompanyLine'
  /** company line id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** route service */
export type GTRouteRouteService = {
  __typename?: 'RouteRouteService'
  /** code */
  code?: Maybe<Scalars['String']['output']>
  /** route service id */
  id: Scalars['ID']['output']
  /** indicates wether the route service is active or not */
  isActive: Scalars['Boolean']['output']
}

/** segment */
export type GTRouteSegment = {
  __typename?: 'RouteSegment'
  /** company line specs */
  companyLineSpecs: Array<GTRouteSegmentCompanyLineSpec>
  /** destination place */
  destination: GTRouteSegmentPlace
  /** segment id */
  id: Scalars['ID']['output']
  /** origin place */
  origin: GTRouteSegmentPlace
}

/** company line spec */
export type GTRouteSegmentCompanyLineSpec = {
  __typename?: 'RouteSegmentCompanyLineSpec'
  /** distance in meters */
  distance: Scalars['Int']['output']
  /** duration in minutes */
  duration: Scalars['Int']['output']
  /** company line id */
  id: Scalars['ID']['output']
}

/** place */
export type GTRouteSegmentPlace = {
  __typename?: 'RouteSegmentPlace'
  /** place id */
  id: Scalars['ID']['output']
  /** timezone */
  timezone: Scalars['String']['output']
}

export type GTRouteServiceBusinessClient = {
  __typename?: 'RouteServiceBusinessClient'
  businessSites?: Maybe<Array<GTRouteServiceBusinessSite>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTRouteServiceBusinessSite = {
  __typename?: 'RouteServiceBusinessSite'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** company */
export type GTRouteServiceCompany = {
  __typename?: 'RouteServiceCompany'
  /** company id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** vertical */
  vertical: GTRouteServiceCompanyVertical
}

/** company line */
export type GTRouteServiceCompanyLine = {
  __typename?: 'RouteServiceCompanyLine'
  /** company */
  company: GTRouteServiceCompany
  /**
   * company id
   * @deprecated use company.id instead
   */
  companyID: Scalars['ID']['output']
  /** company line id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

export const GTRouteServiceCompanyVertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTRouteServiceCompanyVertical =
  (typeof GTRouteServiceCompanyVertical)[keyof typeof GTRouteServiceCompanyVertical]
export type GTRouteServiceConfirmationModalitiesInput = {
  facebook: Scalars['Boolean']['input']
  paid: Scalars['Boolean']['input']
  reserved: Scalars['Boolean']['input']
}

export type GTRouteServiceConfirmationModalitiesV2ExceptionInput = {
  placeIDs: Array<Scalars['ID']['input']>
}

export type GTRouteServiceConfirmationModalitiesV2Input = {
  exceptions: GTRouteServiceConfirmationModalitiesV2ExceptionInput
  name: Scalars['String']['input']
  salesChannelIDs: Array<Scalars['ID']['input']>
}

/** confirmation modalities v2 */
export type GTRouteServiceConfirmationModalityV2 = {
  __typename?: 'RouteServiceConfirmationModalityV2'
  exceptions: GTRouteServiceConfirmationModalityV2Exceptions
  name: Scalars['String']['output']
  salesChannels: Array<GTRouteServiceSalesChannelV2>
}

/** confirmation modality exception */
export type GTRouteServiceConfirmationModalityV2Exceptions = {
  __typename?: 'RouteServiceConfirmationModalityV2Exceptions'
  places?: Maybe<Array<GTRouteServiceConfirmationModalityV2Place>>
}

/** confirmation modality exception places */
export type GTRouteServiceConfirmationModalityV2Place = {
  __typename?: 'RouteServiceConfirmationModalityV2Place'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTRouteServiceItinerarySchedule = {
  __typename?: 'RouteServiceItinerarySchedule'
  dow: Array<GTWeekday>
  endAt?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  itinerary: Array<GTRouteServiceItineraryStop>
  notes: Array<GTItineraryScheduleNote>
  route: GTRouteServiceRoute
  startAt: Scalars['Date']['output']
  status: GTRouteServiceItineraryScheduleStatus
}

export const GTRouteServiceItineraryScheduleStatus = {
  Disabled: 'DISABLED',
  Enabled: 'ENABLED',
  Expired: 'EXPIRED'
} as const

export type GTRouteServiceItineraryScheduleStatus =
  (typeof GTRouteServiceItineraryScheduleStatus)[keyof typeof GTRouteServiceItineraryScheduleStatus]
/** itinerary stop */
export type GTRouteServiceItineraryStop = {
  __typename?: 'RouteServiceItineraryStop'
  /** arrival time */
  arrival?: Maybe<GTRouteServiceItineraryTime>
  /** departure time */
  departure?: Maybe<GTRouteServiceItineraryTime>
  /** place id */
  placeID: Scalars['ID']['output']
  /** timezone */
  timezone: Scalars['String']['output']
}

/** itinerary time */
export type GTRouteServiceItineraryTime = {
  __typename?: 'RouteServiceItineraryTime'
  /** an integer representing how many days after departure the stop takes place. ex. 0 = same day, 1 = next day */
  day: Scalars['Int']['output']
  /** time in hh:mm format, in the timezone of the place */
  time: Scalars['String']['output']
}

export type GTRouteServicePeak = {
  __typename?: 'RouteServicePeak'
  endAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  startAt: Scalars['Date']['output']
}

export type GTRouteServicePeakInput = {
  endAt: Scalars['Date']['input']
  startAt: Scalars['Date']['input']
}

/** route */
export type GTRouteServiceRoute = {
  __typename?: 'RouteServiceRoute'
  /** route id */
  id: Scalars['ID']['output']
  /** segments */
  segments: Array<GTRouteServiceRouteSegment>
  /** stops */
  stops: Array<GTRouteServiceRouteStop>
}

/** segment */
export type GTRouteServiceRouteSegment = {
  __typename?: 'RouteServiceRouteSegment'
  /** company line specs */
  companyLineSpecs: Array<GTRouteServiceRouteSegmentCompanyLineSpec>
  /** destination place */
  destination: GTRouteServiceRouteSegmentPlace
  /** segment id */
  id: Scalars['ID']['output']
  /** origin place */
  origin: GTRouteServiceRouteSegmentPlace
}

/** company line spec */
export type GTRouteServiceRouteSegmentCompanyLineSpec = {
  __typename?: 'RouteServiceRouteSegmentCompanyLineSpec'
  /** distance in meters */
  distance: Scalars['Int']['output']
  /** duration in minutes */
  duration: Scalars['Int']['output']
  /** company line id */
  id: Scalars['ID']['output']
}

/** place */
export type GTRouteServiceRouteSegmentPlace = {
  __typename?: 'RouteServiceRouteSegmentPlace'
  /** place code */
  code: Scalars['String']['output']
  /** place id */
  id: Scalars['ID']['output']
  /** place name */
  name: Scalars['String']['output']
  /** timezone */
  timezone: Scalars['String']['output']
}

/** route stop */
export type GTRouteServiceRouteStop = {
  __typename?: 'RouteServiceRouteStop'
  /** place code */
  code: Scalars['String']['output']
  /** place id */
  id: Scalars['ID']['output']
  /** place name */
  name: Scalars['String']['output']
  /** timezone */
  timezone: Scalars['String']['output']
}

export type GTRouteServiceSalesChannelV2 = {
  __typename?: 'RouteServiceSalesChannelV2'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTRouteServiceScheduleFrequency = {
  __typename?: 'RouteServiceScheduleFrequency'
  unit: GTRouteServiceScheduleFrequencyUnit
  value: Scalars['Int']['output']
}

export const GTRouteServiceScheduleFrequencyUnit = {
  Day: 'DAY',
  Week: 'WEEK'
} as const

export type GTRouteServiceScheduleFrequencyUnit =
  (typeof GTRouteServiceScheduleFrequencyUnit)[keyof typeof GTRouteServiceScheduleFrequencyUnit]
export const GTRouteServiceSingleTripReason = {
  Maintenance: 'MAINTENANCE',
  Operation: 'OPERATION'
} as const

export type GTRouteServiceSingleTripReason =
  (typeof GTRouteServiceSingleTripReason)[keyof typeof GTRouteServiceSingleTripReason]
export type GTRouteServiceSuggestion = {
  __typename?: 'RouteServiceSuggestion'
  code: Scalars['String']['output']
  departureTime?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
}

export type GTRouteServiceSuggestionsInput = {
  code?: InputMaybe<Scalars['String']['input']>
  companyID?: InputMaybe<Scalars['UUID']['input']>
  companyLineIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  endAt?: InputMaybe<GTDateRange>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  includeWithNoEndDate?: InputMaybe<Scalars['Boolean']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  normalizedCode?: InputMaybe<Scalars['String']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
  routeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  startAt?: InputMaybe<GTDateRange>
  verticals?: InputMaybe<Array<Scalars['String']['input']>>
}

export type GTRouteServiceSuggestionsResponse = {
  __typename?: 'RouteServiceSuggestionsResponse'
  items: Array<GTRouteServiceSuggestion>
  total: Scalars['Long']['output']
}

/** Crew User */
export type GTRouteServiceUser = {
  __typename?: 'RouteServiceUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

/** vehicle */
export type GTRouteServiceVehicle = {
  __typename?: 'RouteServiceVehicle'
  /** externalID */
  externalID?: Maybe<Scalars['String']['output']>
  /** vehicle id */
  id?: Maybe<Scalars['ID']['output']>
  /** name */
  name?: Maybe<Scalars['String']['output']>
  /** template */
  template: GTRouteServiceVehicleTemplate
}

export type GTRouteServiceVehicleSchedule = {
  __typename?: 'RouteServiceVehicleSchedule'
  dow?: Maybe<Array<GTWeekday>>
  driver?: Maybe<GTRouteServiceVehicleScheduleDriver>
  endAt?: Maybe<Scalars['Date']['output']>
  frequency?: Maybe<GTRouteServiceScheduleFrequency>
  id: Scalars['ID']['output']
  startAt: Scalars['Date']['output']
  vehicle: GTRouteServiceVehicle
}

export type GTRouteServiceVehicleScheduleDriver = {
  __typename?: 'RouteServiceVehicleScheduleDriver'
  id: Scalars['ID']['output']
  userAccount: GTRouteServiceVehicleScheduleDriverUserAccount
}

export type GTRouteServiceVehicleScheduleDriverUserAccount = {
  __typename?: 'RouteServiceVehicleScheduleDriverUserAccount'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  /** @deprecated use firstName and lastName instead */
  name?: Maybe<Scalars['String']['output']>
}

export type GTRouteServiceVehicleTemplate = {
  __typename?: 'RouteServiceVehicleTemplate'
  /** capacity */
  capacity: Scalars['Int']['output']
  /** template id */
  id: Scalars['ID']['output']
}

/** route stop */
export type GTRouteStop = {
  __typename?: 'RouteStop'
  /** place code */
  code: Scalars['String']['output']
  /** place id */
  id: Scalars['ID']['output']
  /** place name */
  name: Scalars['String']['output']
  /** timezone */
  timezone: Scalars['String']['output']
}

export type GTRouteSuggestion = {
  __typename?: 'RouteSuggestion'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTRouteSuggestionsInput = {
  businessClientIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  businessSiteIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  companyLineIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  pattern?: InputMaybe<Scalars['String']['input']>
  verticals?: InputMaybe<Array<Scalars['String']['input']>>
}

export type GTRouteSuggestionsResponse = {
  __typename?: 'RouteSuggestionsResponse'
  items: Array<GTRouteSuggestion>
  total: Scalars['Long']['output']
}

export type GTRouteVehiclesQueryInput = {
  routeID: Scalars['ID']['input']
}

export type GTRoutesResponse = {
  __typename?: 'RoutesResponse'
  /** routes */
  items: Array<GTRoute>
  /** total number of routes */
  total: Scalars['Int']['output']
}

export type GTShaAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaAncillariesHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaAncillariesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaAutocompleteProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBookingsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBusinessClientUsersHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBusinessClientUsersProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBusinessClientsHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBusinessClientsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBusinessCustomersHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBusinessCustomersProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBusinessSitesHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaBusinessSitesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaCompaniesHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaCompaniesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaCountriesHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaCountriesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaCurrenciesHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaCurrenciesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaDriversHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaDriversProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaFormsHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaInventoryRulesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaKreditsHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaKreditsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaLoyaltyCampaignsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaLoyaltyRewardsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaLoyaltyRewardsProjectorInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaPartnerCostsHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaPartnersHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaPartnersProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaPassengerPropertiesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaPassengerTypesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaPlacesAutocompleteProjectorSnitchInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaPlacesHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaPlacesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaRoutesHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaRoutesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaSalesChannelsHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaSalesChannelsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaSeasonEventsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaSessionsHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaSubcampaignsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaSubcampaignsProjectorInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaTripSegmentsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaTripsHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaTripsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaVehiclesHandlerAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaVehiclesProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTShaWalletsProjectorAdminInput = {
  value?: InputMaybe<Scalars['String']['input']>
}

export type GTSale = {
  __typename?: 'Sale'
  buyer?: Maybe<GTSaleBuyer>
  cart: GTSaleCart
  coupon?: Maybe<GTSaleCoupon>
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  lineItems: Array<GTSaleLineItem>
  payment: GTSalePayment
  seller: GTSaleUser
  status: Scalars['String']['output']
  trip?: Maybe<GTSaleTrip>
}

export type GTSaleBuyer = {
  __typename?: 'SaleBuyer'
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  seat?: Maybe<GTSaleSeat>
}

export type GTSaleCart = {
  __typename?: 'SaleCart'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GTSaleChannel = {
  Pos: 'POS',
  Online: 'online'
} as const

export type GTSaleChannel = (typeof GTSaleChannel)[keyof typeof GTSaleChannel]
export type GTSaleCoupon = {
  __typename?: 'SaleCoupon'
  /** code */
  code: Scalars['String']['output']
  /** discount type */
  discountType: GTDiscountType
  /** coupon id */
  id: Scalars['ID']['output']
  /** coupon value */
  value: Scalars['Int']['output']
}

export type GTSaleLineItem = {
  __typename?: 'SaleLineItem'
  comboComponents?: Maybe<Array<GTLineItemComboComponent>>
  container: Scalars['String']['output']
  discount?: Maybe<Scalars['Int']['output']>
  itemID: Scalars['ID']['output']
  name: Scalars['String']['output']
  netContent: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
  total: Scalars['Int']['output']
  unitPrice: Scalars['Int']['output']
  units: Scalars['String']['output']
}

export type GTSalePayment = {
  __typename?: 'SalePayment'
  amount: Scalars['Int']['output']
  last4?: Maybe<Scalars['String']['output']>
  method: Scalars['String']['output']
}

export type GTSalePlace = {
  __typename?: 'SalePlace'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTSaleSeat = {
  __typename?: 'SaleSeat'
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
}

export type GTSaleSegment = {
  __typename?: 'SaleSegment'
  destination: GTSalePlace
  id: Scalars['ID']['output']
  origin: GTSalePlace
}

export const GTSaleStatus = {
  Cancelled: 'cancelled',
  Confirmed: 'confirmed'
} as const

export type GTSaleStatus = (typeof GTSaleStatus)[keyof typeof GTSaleStatus]
export type GTSaleTrip = {
  __typename?: 'SaleTrip'
  destination: GTSalePlace
  id: Scalars['ID']['output']
  origin: GTSalePlace
  segment: GTSaleSegment
}

export type GTSaleUser = {
  __typename?: 'SaleUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

/** sales channel */
export const GTSalesChannel = {
  KolorsMx: 'KOLORS_MX',
  KolorsUs: 'KOLORS_US',
  Pos: 'POS',
  All: 'all',
  Offline: 'offline',
  Online: 'online'
} as const

export type GTSalesChannel = (typeof GTSalesChannel)[keyof typeof GTSalesChannel]
export type GTSalesChannelCountry = {
  __typename?: 'SalesChannelCountry'
  code: Scalars['String']['output']
  currency: GTSalesChannelCurrency
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTSalesChannelCurrency = {
  __typename?: 'SalesChannelCurrency'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  symbol: Scalars['String']['output']
}

export type GTSalesChannelInput = {
  id: Scalars['ID']['input']
}

/** sales channel type */
export const GTSalesChannelType = {
  Offline: 'OFFLINE',
  Online: 'ONLINE'
} as const

export type GTSalesChannelType = (typeof GTSalesChannelType)[keyof typeof GTSalesChannelType]
export const GTSalesChannelTypeV2 = {
  Offline: 'OFFLINE',
  Online: 'ONLINE'
} as const

export type GTSalesChannelTypeV2 = (typeof GTSalesChannelTypeV2)[keyof typeof GTSalesChannelTypeV2]
export type GTSalesChannelV2 = {
  __typename?: 'SalesChannelV2'
  country: GTSalesChannelCountry
  createdAt: Scalars['Time']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  type: GTSalesChannelTypeV2
  updatedAt: Scalars['Time']['output']
}

export type GTSalesChannelsInput = {
  countryID?: InputMaybe<Scalars['ID']['input']>
}

export type GTSalesChannelsOutput = {
  __typename?: 'SalesChannelsOutput'
  items: Array<GTSalesChannelV2>
  total: Scalars['Int']['output']
}

export type GTSalesDashboardDataInfo = {
  __typename?: 'SalesDashboardDataInfo'
  amount: Scalars['Int']['output']
  count: Scalars['Int']['output']
}

/** paginated sales response */
export type GTSalesResponse = {
  __typename?: 'SalesResponse'
  /** sales */
  items: Array<GTSale>
  /** total of sales */
  total: Scalars['Int']['output']
}

export type GTScheduleRouteServiceTripsInput = {
  /** route service id */
  id: Scalars['ID']['input']
  /** end at */
  scheduleEndAt?: InputMaybe<Scalars['Date']['input']>
  /** start at */
  scheduleStartAt?: InputMaybe<Scalars['Date']['input']>
}

export type GTSeasonEvent = {
  __typename?: 'SeasonEvent'
  activePeriods: Array<GTActivePeriod>
  company: GTSeasonEventCompany
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  routeServices: Array<GTSeasonEventRouteService>
  segments: Array<GTSeasonEventSegment>
  type: GTSeasonEventType
}

export type GTSeasonEventCompany = {
  __typename?: 'SeasonEventCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTSeasonEventRouteService = {
  __typename?: 'SeasonEventRouteService'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type GTSeasonEventSegment = {
  __typename?: 'SeasonEventSegment'
  destination: GTSeasonEventSegmentPlace
  id: Scalars['ID']['output']
  origin: GTSeasonEventSegmentPlace
}

export type GTSeasonEventSegmentPlace = {
  __typename?: 'SeasonEventSegmentPlace'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export const GTSeasonEventType = {
  /** exception event */
  E: 'E',
  /** high season */
  H: 'H',
  /** low season */
  L: 'L',
  /** special event */
  S: 'S'
} as const

export type GTSeasonEventType = (typeof GTSeasonEventType)[keyof typeof GTSeasonEventType]
/** season type */
export const GTSeasonType = {
  /** exception event */
  E: 'E',
  /** high season */
  H: 'H',
  /** low season */
  L: 'L',
  /** N/A */
  N: 'N',
  /** special event */
  S: 'S'
} as const

export type GTSeasonType = (typeof GTSeasonType)[keyof typeof GTSeasonType]
/** seat */
export type GTSeat = {
  __typename?: 'Seat'
  /** seat id */
  id: Scalars['ID']['output']
  /** seat label */
  label: Scalars['String']['output']
  /** seat status */
  status: GTSeatStatus
}

/** seat status */
export const GTSeatStatus = {
  Available: 'available',
  Occupied: 'occupied'
} as const

export type GTSeatStatus = (typeof GTSeatStatus)[keyof typeof GTSeatStatus]
export type GTSeatsTemplateResponse = {
  __typename?: 'SeatsTemplateResponse'
  /** seats */
  seats: Array<GTSeat>
  /** vehicle template */
  vehicleTemplate: GTVehicleTemplate
}

/** segment */
export type GTSegment = {
  __typename?: 'Segment'
  /** company */
  company: GTSegmentCompany
  /** company line specs */
  companyLineSpecs: Array<GTSegmentCompanyLineSpec>
  /** destination place */
  destination: GTSegmentPlace
  /** segment id */
  id: Scalars['ID']['output']
  /** origin place */
  origin: GTSegmentPlace
}

export type GTSegmentCity = {
  __typename?: 'SegmentCity'
  /** code */
  code: Scalars['String']['output']
  /** city id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** company */
export type GTSegmentCompany = {
  __typename?: 'SegmentCompany'
  /** company lines */
  companyLines: Array<GTSegmentCompanyCompanyLine>
  /** company id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** company line */
export type GTSegmentCompanyCompanyLine = {
  __typename?: 'SegmentCompanyCompanyLine'
  /** company line id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** company line spec */
export type GTSegmentCompanyLineSpec = {
  __typename?: 'SegmentCompanyLineSpec'
  /** company line */
  companyLine: GTSegmentCompanyLineSpecCompanyLine
  /** distance in meters */
  distance?: Maybe<Scalars['Int']['output']>
  /** duration in minutes */
  duration?: Maybe<Scalars['Int']['output']>
  /** fares */
  fares: Array<GTSegmentCompanyLineSpecFare>
  /** maximum fare in cents */
  maxFare?: Maybe<Scalars['Int']['output']>
  /** minimum fare in cents */
  minFare?: Maybe<Scalars['Int']['output']>
  /** real distance in meters */
  realDistance?: Maybe<Scalars['Int']['output']>
  /** vat rate */
  vatRate?: Maybe<Scalars['Float']['output']>
}

/** company line */
export type GTSegmentCompanyLineSpecCompanyLine = {
  __typename?: 'SegmentCompanyLineSpecCompanyLine'
  /** company line id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** fare */
export type GTSegmentCompanyLineSpecFare = {
  __typename?: 'SegmentCompanyLineSpecFare'
  /** discount rate */
  discountRate: Scalars['Float']['output']
  /** fare id */
  id: Scalars['ID']['output']
  /** passenger type this fare applies to */
  passengerType: GTPassengerType
  /** subtotal in cents */
  subtotal: Scalars['Int']['output']
  /** vat in cents */
  vat: Scalars['Int']['output']
}

/** place */
export type GTSegmentPlace = {
  __typename?: 'SegmentPlace'
  /** city */
  city: GTSegmentCity
  /** code */
  code: Scalars['String']['output']
  /** place id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

export type GTSegmentSuggestion = {
  __typename?: 'SegmentSuggestion'
  destination: GTSegmentSuggestionPlace
  /** @deprecated Use destination.name instead */
  destinationName?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  origin: GTSegmentSuggestionPlace
  /** @deprecated Use origin.name instead */
  originName?: Maybe<Scalars['String']['output']>
}

export type GTSegmentSuggestionPlace = {
  __typename?: 'SegmentSuggestionPlace'
  cityID: Scalars['UUID']['output']
  code: Scalars['String']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTSegmentSuggestionsInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  destinationCityIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  destinationIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  includeReverse?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  onlyNoFareFamily?: InputMaybe<Scalars['Boolean']['input']>
  originCityIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  originIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTSegmentSuggestionsResponse = {
  __typename?: 'SegmentSuggestionsResponse'
  items: Array<GTSegmentSuggestion>
  total: Scalars['Long']['output']
}

export type GTSegmentsDifference = {
  __typename?: 'SegmentsDifference'
  new: Array<GTChangeSegment>
  old: Array<GTChangeSegment>
}

export type GTSegmentsResponse = {
  __typename?: 'SegmentsResponse'
  /** segments */
  items: Array<GTSegment>
  /** total number of segments */
  total: Scalars['Int']['output']
}

/** sell status */
export const GTSellStatus = {
  Activated: 'activated',
  Deactivated: 'deactivated'
} as const

export type GTSellStatus = (typeof GTSellStatus)[keyof typeof GTSellStatus]
export type GTSendEmailCouponInput = {
  campaignID: Scalars['ID']['input']
  couponID: Scalars['ID']['input']
  email?: InputMaybe<Scalars['String']['input']>
  subcampaignID: Scalars['ID']['input']
}

export type GTSendEmailIncidenceCouponInput = {
  campaignID: Scalars['ID']['input']
  couponID: Scalars['ID']['input']
  email?: InputMaybe<Scalars['String']['input']>
  subcampaignID: Scalars['ID']['input']
}

export type GTSendSmsCouponInput = {
  campaignID: Scalars['ID']['input']
  couponID: Scalars['ID']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  subcampaignID: Scalars['ID']['input']
}

export type GTSendSmsIncidenceCouponInput = {
  campaignID: Scalars['ID']['input']
  couponID: Scalars['ID']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  subcampaignID: Scalars['ID']['input']
}

/** route service */
export type GTService = {
  __typename?: 'Service'
  /** active period */
  activePeriod?: Maybe<Scalars['Int']['output']>
  /** business clients */
  businessClients?: Maybe<Array<GTRouteServiceBusinessClient>>
  /** code */
  code?: Maybe<Scalars['String']['output']>
  /** company line */
  companyLine: GTRouteServiceCompanyLine
  /**
   * confirmation modalities
   * @deprecated use confirmationModalitiesV2 instead
   */
  confirmationModalities: GTConfirmationModalities
  /** confirmation modalities v2 */
  confirmationModalitiesV2: Array<GTRouteServiceConfirmationModalityV2>
  /** created at */
  createdAt: Scalars['Time']['output']
  /** created by */
  createdBy: GTRouteServiceUser
  /** crew users */
  crewUsers: Array<GTRouteServiceUser>
  /**
   * days of week the service is active
   * @deprecated use itinerariesSchedule.dow instead
   */
  dow?: Maybe<Array<GTDayOfWeek>>
  /** endAt */
  endAt?: Maybe<Scalars['String']['output']>
  /** idicates whether the route service has selectable seats or not */
  hasSelectableSeats: Scalars['Boolean']['output']
  /** has tolls */
  hasTolls: Scalars['Boolean']['output']
  /** route service id */
  id: Scalars['ID']['output']
  /** indicates whether the route service is active or not */
  isActive: Scalars['Boolean']['output']
  /** determines if the route service can be deleted */
  isDeletable: Scalars['Boolean']['output']
  /** is on guard */
  isOnGuard: Scalars['Boolean']['output']
  /** is pet friendly */
  isPetFriendly: Scalars['Boolean']['output']
  /** is private */
  isPrivate: Scalars['Boolean']['output']
  /** itineraries schedule */
  itinerariesSchedule: Array<GTRouteServiceItinerarySchedule>
  /**
   * itinerary
   * @deprecated use itinerariesSchedule.itinerary instead
   */
  itinerary?: Maybe<Array<GTRouteServiceItineraryStop>>
  /** limits */
  limits: Array<GTLimit>
  /** peaks */
  peaks?: Maybe<Array<GTRouteServicePeak>>
  /** price checked at */
  priceCheckedAt: Scalars['Time']['output']
  /** price per trip */
  pricePerTrip?: Maybe<Scalars['Int']['output']>
  /**
   * route
   * @deprecated use itinerariesSchedule.route instead
   */
  route?: Maybe<GTRouteServiceRoute>
  /**
   * sales channels
   * @deprecated use salesChannelsV2 instead
   */
  salesChannels?: Maybe<Array<GTSalesChannel>>
  /** sales channels v2 */
  salesChannelsV2?: Maybe<Array<GTRouteServiceSalesChannelV2>>
  /** startAt */
  startAt: Scalars['String']['output']
  /** updated at */
  updatedAt: Scalars['Time']['output']
  /** vehicles schedule */
  vehiclesSchedule: Array<GTRouteServiceVehicleSchedule>
}

export type GTServiceFee = {
  __typename?: 'ServiceFee'
  id: Scalars['ID']['output']
  price: Scalars['Int']['output']
}

export type GTServiceFeeAddonRule = {
  __typename?: 'ServiceFeeAddonRule'
  company: GTServiceFeeAddonRuleCompany
  companyLine: GTServiceFeeAddonRuleCompanyLine
  confirmationModalities: Array<GTConfirmationModality>
  id: Scalars['ID']['output']
  isEnabled: Scalars['Boolean']['output']
  salesChannels: Array<GTSalesChannel>
  salesChannelsV2: Array<GTServiceFeeAddonRuleSalesChannel>
  segments: Array<GTServiceFeeAddonRuleSegment>
  ticketFees: Array<GTServiceFeeAddonRuleTicketFee>
}

export type GTServiceFeeAddonRuleCompany = {
  __typename?: 'ServiceFeeAddonRuleCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTServiceFeeAddonRuleCompanyLine = {
  __typename?: 'ServiceFeeAddonRuleCompanyLine'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTServiceFeeAddonRuleFee = {
  __typename?: 'ServiceFeeAddonRuleFee'
  amount: Scalars['Int']['output']
  currencyCode: Scalars['String']['output']
}

export type GTServiceFeeAddonRulePlace = {
  __typename?: 'ServiceFeeAddonRulePlace'
  code: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  stateCode: Scalars['String']['output']
}

export type GTServiceFeeAddonRuleSalesChannel = {
  __typename?: 'ServiceFeeAddonRuleSalesChannel'
  country: GTServiceFeeAddonRuleSalesChannelCountry
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTServiceFeeAddonRuleSalesChannelCountry = {
  __typename?: 'ServiceFeeAddonRuleSalesChannelCountry'
  code: Scalars['String']['output']
  currency: GTServiceFeeAddonRuleSalesChannelCountryCurrency
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTServiceFeeAddonRuleSalesChannelCountryCurrency = {
  __typename?: 'ServiceFeeAddonRuleSalesChannelCountryCurrency'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  symbol: Scalars['String']['output']
}

export type GTServiceFeeAddonRuleSegment = {
  __typename?: 'ServiceFeeAddonRuleSegment'
  destination: GTServiceFeeAddonRulePlace
  id: Scalars['ID']['output']
  origin: GTServiceFeeAddonRulePlace
}

export type GTServiceFeeAddonRuleTicketFee = {
  __typename?: 'ServiceFeeAddonRuleTicketFee'
  fees: Array<GTServiceFeeAddonRuleFee>
  quantity: Scalars['Int']['output']
}

/** service fee addon rules input */
export type GTServiceFeeAddonRulesInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTServiceFeeAddonRulesOutput = {
  __typename?: 'ServiceFeeAddonRulesOutput'
  items: Array<GTServiceFeeAddonRule>
  total: Scalars['Int']['output']
}

export type GTServiceQueryInput = {
  id: Scalars['ID']['input']
}

/** service type */
export const GTServiceType = {
  /** Economic */
  E: 'E',
  /** Luxury */
  L: 'L',
  /** Primera */
  P: 'P'
} as const

export type GTServiceType = (typeof GTServiceType)[keyof typeof GTServiceType]
export type GTServicesResponse = {
  __typename?: 'ServicesResponse'
  /** route services */
  items: Array<GTService>
  /** total number of route services */
  total: Scalars['Int']['output']
}

export type GTSetCampaignTemplateInput = {
  confirmationModalities?: InputMaybe<GTConfirmationMoldalitiesInput>
  expirationTime: GTDurationInput
  id: Scalars['ID']['input']
  prefix?: InputMaybe<Scalars['String']['input']>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  value: Scalars['Int']['input']
}

export type GTShipOrderInput = {
  id: Scalars['ID']['input']
}

/** site */
export type GTSite = {
  __typename?: 'Site'
  /** address */
  address?: Maybe<GTAddress>
  /** company this site belongs to */
  company: GTSiteCompany
  /** site id */
  id: Scalars['ID']['output']
  /** indicates wether the site is active or not */
  isActive: Scalars['Boolean']['output']
  /** site name */
  name: Scalars['String']['output']
  /** site's settings */
  settings: GTSiteSettings
  /** site type */
  type: GTSiteType
  /** users assigned to this site */
  users: Array<GTSiteUser>
}

/** site autocomplete */
export type GTSiteAutocomplete = {
  __typename?: 'SiteAutocomplete'
  /** company ID */
  companyID: Scalars['ID']['output']
  /** site ID */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** site company */
export type GTSiteCompany = {
  __typename?: 'SiteCompany'
  /** company id */
  id: Scalars['ID']['output']
  /** company name */
  name: Scalars['String']['output']
}

/** companyu line */
export type GTSiteCompanyLine = {
  __typename?: 'SiteCompanyLine'
  /** company line id */
  id: Scalars['ID']['output']
  /** company line name */
  name: Scalars['String']['output']
}

export type GTSiteKreditsSettings = {
  __typename?: 'SiteKreditsSettings'
  canTopUp: Scalars['Boolean']['output']
}

/** a site's settings. */
export type GTSiteSettings = {
  __typename?: 'SiteSettings'
  /** defines if the ticket price can be modified by the user */
  canChangePrice: Scalars['Boolean']['output']
  /** defines if tickets can be reserved */
  canReserveTickets: Scalars['Boolean']['output']
  /** default origin place for this site. */
  defaultOrigin?: Maybe<GTDefaultOrigin>
  /** defines if a site can recive mixed payments */
  enableMixedPayments: Scalars['Boolean']['output']
  /** enabled company lines */
  enabledCompanyLines: Array<GTSiteCompanyLine>
  /** physical printer present in site. */
  hasPrinter: Scalars['Boolean']['output']
  /** defines if the site can see the transactions report */
  hideTransactionsReport: Scalars['Boolean']['output']
  /** defines if a site can top up kredits */
  kredits: GTSiteKreditsSettings
  /** passenger name required */
  passengerNameRequired: Scalars['Boolean']['output']
  /** payment methods allowed at shis site. */
  payment: Array<GTSiteSettingsPaymentMethod>
  /** minutes before departure to close sales. */
  saleClosingThreshold: Scalars['Int']['output']
  /** snacks settings */
  snacks: GTSiteSnacksSettings
}

export const GTSiteSettingsPaymentMethod = {
  Card: 'CARD',
  Cash: 'CASH'
} as const

export type GTSiteSettingsPaymentMethod = (typeof GTSiteSettingsPaymentMethod)[keyof typeof GTSiteSettingsPaymentMethod]
export type GTSiteSnacksSettings = {
  __typename?: 'SiteSnacksSettings'
  canSell: Scalars['Boolean']['output']
  showMinPrice: Scalars['Boolean']['output']
  tripRequired: Scalars['Boolean']['output']
}

export const GTSiteType = {
  Fixed: 'fixed',
  Mobile: 'mobile'
} as const

export type GTSiteType = (typeof GTSiteType)[keyof typeof GTSiteType]
/** site user */
export type GTSiteUser = {
  __typename?: 'SiteUser'
  /** user's first name */
  firstName: Scalars['String']['output']
  /** user id */
  id: Scalars['ID']['output']
  /** user's last name */
  lastName: Scalars['String']['output']
  /** user's username */
  username: Scalars['String']['output']
}

export type GTSitesAutocompleteInput = {
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type GTSitesDashboard = {
  __typename?: 'SitesDashboard'
  topAgents: Array<GTTopAgentDashboardData>
  topCompanies: Array<GTTopCompanyDashboardData>
  topServices: Array<GTTopServiceDasboardData>
  topSites: Array<GTTopSiteDashboardData>
  totalSales: GTTotalSalesDashboardData
}

export type GTSitesResponse = {
  __typename?: 'SitesResponse'
  items: Array<GTSite>
  total: Scalars['Int']['output']
}

export type GTSnackConstraintsInput = {
  limit: Scalars['Int']['input']
  minSale?: InputMaybe<Scalars['Int']['input']>
}

export type GTSortingV2 = {
  field: Scalars['String']['input']
  order: Scalars['Int']['input']
}

export const GTSource = {
  Android: 'android',
  Ios: 'ios',
  Mobileweb: 'mobileweb',
  Web: 'web'
} as const

export type GTSource = (typeof GTSource)[keyof typeof GTSource]
export type GTStartBookingTripChangeResponse = {
  __typename?: 'StartBookingTripChangeResponse'
  tripChangeID: Scalars['ID']['output']
}

export type GTStartMoveBookingTripChangeResponse = {
  __typename?: 'StartMoveBookingTripChangeResponse'
  tripChangeID: Scalars['ID']['output']
}

export type GTStatusChange = {
  __typename?: 'StatusChange'
  changedAt: Scalars['Time']['output']
  status: GTChangeStatus
}

export type GTSubCampaign = {
  __typename?: 'SubCampaign'
  authorizer?: Maybe<Scalars['String']['output']>
  campaign: GTSubCampaignCampaign
  coupons: Array<GTCoupon>
  endsAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  reason?: Maybe<Scalars['String']['output']>
  startsAt: Scalars['String']['output']
  trip?: Maybe<GTSubCampaignTrip>
}

export type GTSubCampaignCampaign = {
  __typename?: 'SubCampaignCampaign'
  area: GTCampaignArea
  company: GTSubCampaignCompany
  endsAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  startsAt: Scalars['String']['output']
  timezone: Scalars['String']['output']
  type: GTCampaignType
}

export type GTSubCampaignCompany = {
  __typename?: 'SubCampaignCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTSubCampaignTrip = {
  __typename?: 'SubCampaignTrip'
  destination: GTCampaignPlace
  id: Scalars['ID']['output']
  legSegments: Array<GTCampaignSegment>
  origin: GTCampaignPlace
}

export type GTSubcampaign = {
  __typename?: 'Subcampaign'
  campaignID: Scalars['ID']['output']
  coupons: Array<GTSubcampaignCoupon>
  id: Scalars['ID']['output']
  trip: GTSubcampaignTrip
}

export type GTSubcampaignBooking = {
  __typename?: 'SubcampaignBooking'
  buyer?: Maybe<GTBuyer>
  coupon?: Maybe<GTSubcampaignBookingCoupon>
  destination: GTSubcampaignPlace
  id: Scalars['ID']['output']
  origin: GTSubcampaignPlace
  passengersCount: Scalars['Int']['output']
  ticketsCount: Scalars['Int']['output']
}

export type GTSubcampaignBookingCoupon = {
  __typename?: 'SubcampaignBookingCoupon'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type GTSubcampaignCoupon = {
  __typename?: 'SubcampaignCoupon'
  emails: Array<GTEmail>
  id: Scalars['ID']['output']
  originalBooking: GTSubcampaignBooking
  redeemedBooking?: Maybe<GTSubcampaignBooking>
  status: GTCouponStatus
  supportInteractions: Array<GTSubcampaignSupportInteraction>
}

export const GTSubcampaignCouponEmailStatus = {
  Blocked: 'BLOCKED',
  Bounce: 'BOUNCE',
  Deferred: 'DEFERRED',
  Delivered: 'DELIVERED',
  Dropped: 'DROPPED',
  Processed: 'PROCESSED',
  Sent: 'SENT'
} as const

export type GTSubcampaignCouponEmailStatus =
  (typeof GTSubcampaignCouponEmailStatus)[keyof typeof GTSubcampaignCouponEmailStatus]
export type GTSubcampaignInput = {
  id: Scalars['ID']['input']
}

export type GTSubcampaignPlace = {
  __typename?: 'SubcampaignPlace'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTSubcampaignRouteService = {
  __typename?: 'SubcampaignRouteService'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type GTSubcampaignSegment = {
  __typename?: 'SubcampaignSegment'
  destination: GTSubcampaignPlace
  id: Scalars['ID']['output']
  origin: GTSubcampaignPlace
}

export type GTSubcampaignSupportInteraction = {
  __typename?: 'SubcampaignSupportInteraction'
  bookingID: Scalars['ID']['output']
  callStatus?: Maybe<GTCallStatus>
  createdAt: Scalars['Time']['output']
  notes?: Maybe<Scalars['String']['output']>
  notificationChannel?: Maybe<GTNotificationChannel>
  user: GTInteractionUser
}

export type GTSubcampaignTrip = {
  __typename?: 'SubcampaignTrip'
  affectedLegSegments: Array<GTSubcampaignSegment>
  departureAt: Scalars['Time']['output']
  destination: GTSubcampaignPlace
  id: Scalars['ID']['output']
  origin: GTSubcampaignPlace
  routeService: GTSubcampaignRouteService
}

export type GTSubmitB2BContactFormInput = {
  company?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  passengersNumber: Scalars['Int']['input']
  phone: Scalars['String']['input']
}

/** supplier autocomplete */
export type GTSupplierAutocomplete = {
  __typename?: 'SupplierAutocomplete'
  /** name */
  name: Scalars['String']['output']
}

export type GTSuppliersAutocompleteInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type GTSupportInteraction = {
  __typename?: 'SupportInteraction'
  callStatus?: Maybe<GTCallStatus>
  createdAt: Scalars['Time']['output']
  notes?: Maybe<Scalars['String']['output']>
  notificationChannel?: Maybe<GTNotificationChannel>
  user: GTInteractionUser
}

export type GTTCitiesAutocompleteInput = {
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTTCitiesAutocompleteResponse = {
  __typename?: 'TCitiesAutocompleteResponse'
  items: Array<GTTCityAutocomplete>
  total: Scalars['Int']['output']
}

export type GTTCitiesInput = {
  code?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
}

export type GTTCitiesResponse = {
  __typename?: 'TCitiesResponse'
  items: Array<GTTCity>
  total: Scalars['Int']['output']
}

export type GTTCity = {
  __typename?: 'TCity'
  code: Scalars['String']['output']
  countryCode?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Time']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  param: Scalars['String']['output']
  places: Array<GTTPlace>
  stateCode?: Maybe<Scalars['String']['output']>
  tags: Array<Scalars['String']['output']>
  timezone: Scalars['String']['output']
  updatedAt: Scalars['Time']['output']
}

export type GTTCityAutocomplete = {
  __typename?: 'TCityAutocomplete'
  countryCode?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  stateCode: Scalars['String']['output']
}

export type GTTCityInput = {
  code?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  param?: InputMaybe<Scalars['String']['input']>
}

export type GTTDriver = {
  __typename?: 'TDriver'
  createdAt: Scalars['Time']['output']
  documents: Array<GTTDriverDocument>
  id: Scalars['UUID']['output']
  isAssigned: Scalars['Boolean']['output']
  isEnabled: Scalars['Boolean']['output']
  partner: GTTDriverPartner
  rating: Scalars['Float']['output']
  stateCode?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Time']['output']
  userAccount: GTTDriverUserAccount
  vehicles: Array<GTTDriverVehicle>
}

export type GTTDriverAutocomplete = {
  __typename?: 'TDriverAutocomplete'
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type GTTDriverDocument = {
  __typename?: 'TDriverDocument'
  category: GTTDriverDocumentCategory
  document: GTAsset
}

export const GTTDriverDocumentCategory = {
  Application: 'APPLICATION',
  BirthCertificate: 'BIRTH_CERTIFICATE',
  Curp: 'CURP',
  Id: 'ID',
  Licence: 'LICENCE',
  Photo: 'PHOTO',
  ProofOfAddress: 'PROOF_OF_ADDRESS',
  ProofOfLastStudy: 'PROOF_OF_LAST_STUDY'
} as const

export type GTTDriverDocumentCategory = (typeof GTTDriverDocumentCategory)[keyof typeof GTTDriverDocumentCategory]
export type GTTDriverInput = {
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
}

export type GTTDriverPartner = {
  __typename?: 'TDriverPartner'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTDriverUserAccount = {
  __typename?: 'TDriverUserAccount'
  devices?: Maybe<Array<Maybe<GTDriverDevice>>>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use firstName and lastName instead */
  name?: Maybe<Scalars['String']['output']>
  /** @deprecated Use phoneNumber instead */
  phone?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<GTPhoneNumber>
}

export type GTTDriverVehicle = {
  __typename?: 'TDriverVehicle'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTDriversAutocompleteInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>
  partnerID?: InputMaybe<Scalars['UUID']['input']>
}

export type GTTDriversAutocompleteResponse = {
  __typename?: 'TDriversAutocompleteResponse'
  items: Array<GTTDriverAutocomplete>
  total: Scalars['Int']['output']
}

export type GTTDriversInput = {
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>
  partnerID?: InputMaybe<Scalars['UUID']['input']>
}

export type GTTDriversResponse = {
  __typename?: 'TDriversResponse'
  items: Array<GTTDriver>
  total: Scalars['Int']['output']
}

export type GTTPagination = {
  page: Scalars['Int']['input']
  size: Scalars['Int']['input']
}

export type GTTPlace = {
  __typename?: 'TPlace'
  address?: Maybe<GTTPlaceAddress>
  amenities: Array<GTTPlaceAmenity>
  area?: Maybe<Scalars['String']['output']>
  businessClient?: Maybe<GTTPlaceBusinessClient>
  businessCustomer?: Maybe<GTTPlaceBusinessCustomer>
  businessSite?: Maybe<GTTPlaceBusinessSite>
  code: Scalars['String']['output']
  company?: Maybe<GTTPlaceCompany>
  coordinates: GTTPlaceCoordinates
  countryCode?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Time']['output']
  directions?: Maybe<Scalars['String']['output']>
  geofenceRadius?: Maybe<Scalars['Int']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  param: Scalars['String']['output']
  parent: GTTCity
  photo?: Maybe<GTAsset>
  stateCode?: Maybe<Scalars['String']['output']>
  tags: Array<Scalars['String']['output']>
  timezone: Scalars['String']['output']
  type: GTTPlaceType
  updatedAt: Scalars['Time']['output']
}

export type GTTPlaceAddress = {
  __typename?: 'TPlaceAddress'
  cityName: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  line1: Scalars['String']['output']
  line2?: Maybe<Scalars['String']['output']>
  stateCode: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export const GTTPlaceAmenity = {
  KolorsMobile: 'KOLORS_MOBILE',
  KolorsTicket: 'KOLORS_TICKET'
} as const

export type GTTPlaceAmenity = (typeof GTTPlaceAmenity)[keyof typeof GTTPlaceAmenity]
export type GTTPlaceAutocomplete = {
  __typename?: 'TPlaceAutocomplete'
  coordinates: GTTPlaceCoordinates
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  stateCode: Scalars['String']['output']
}

export type GTTPlaceBusinessClient = {
  __typename?: 'TPlaceBusinessClient'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTPlaceBusinessCustomer = {
  __typename?: 'TPlaceBusinessCustomer'
  id: Scalars['UUID']['output']
}

export type GTTPlaceBusinessSite = {
  __typename?: 'TPlaceBusinessSite'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTPlaceCompany = {
  __typename?: 'TPlaceCompany'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  vertical: GTTPlaceVertical
}

export type GTTPlaceCoordinates = {
  __typename?: 'TPlaceCoordinates'
  latitude: Scalars['String']['output']
  longitude: Scalars['String']['output']
}

export type GTTPlaceInput = {
  code?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  param?: InputMaybe<Scalars['String']['input']>
}

export type GTTPlaceSuggestionsInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  companyID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  includeWithNoSites?: InputMaybe<Scalars['Boolean']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
  vertical?: InputMaybe<Scalars['String']['input']>
}

export type GTTPlaceSuggestionsResponse = {
  __typename?: 'TPlaceSuggestionsResponse'
  items: Array<GTPlaceSuggestion>
  total: Scalars['Long']['output']
}

export const GTTPlaceType = {
  Attraction: 'ATTRACTION',
  ConvenienceStore: 'CONVENIENCE_STORE',
  Hotel: 'HOTEL',
  Mall: 'MALL',
  Office: 'OFFICE',
  ParkingLot: 'PARKING_LOT',
  PointOfInterest: 'POINT_OF_INTEREST',
  ResidentialArea: 'RESIDENTIAL_AREA',
  Restaurant: 'RESTAURANT',
  School: 'SCHOOL',
  Sidewalk: 'SIDEWALK',
  Station: 'STATION',
  Supermarket: 'SUPERMARKET',
  Venue: 'VENUE'
} as const

export type GTTPlaceType = (typeof GTTPlaceType)[keyof typeof GTTPlaceType]
export const GTTPlaceVertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTTPlaceVertical = (typeof GTTPlaceVertical)[keyof typeof GTTPlaceVertical]
export type GTTPlacesAutocompleteInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  companyID?: InputMaybe<Scalars['UUID']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
  vertical?: InputMaybe<GTTPlaceVertical>
}

export type GTTPlacesAutocompleteResponse = {
  __typename?: 'TPlacesAutocompleteResponse'
  items: Array<GTTPlaceAutocomplete>
  total: Scalars['Int']['output']
}

export type GTTPlacesInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  cityID?: InputMaybe<Scalars['UUID']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  companyID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  vertical?: InputMaybe<GTTPlaceVertical>
}

export type GTTPlacesResponse = {
  __typename?: 'TPlacesResponse'
  items: Array<GTTPlace>
  total: Scalars['Int']['output']
}

export type GTTRoute = {
  __typename?: 'TRoute'
  businessClient?: Maybe<GTTRouteBusinessClient>
  businessSite?: Maybe<GTTRouteBusinessSite>
  company: GTTRouteCompany
  companyLines: Array<GTTRouteCompanyLine>
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  polyline?: Maybe<GTTRoutePolyline>
  segments: Array<GTTRouteSegment>
  services: Array<GTTRouteRouteService>
  stops: Array<GTTRouteStop>
}

export type GTTRouteAutocomplete = {
  __typename?: 'TRouteAutocomplete'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTRouteBusinessClient = {
  __typename?: 'TRouteBusinessClient'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTRouteBusinessSite = {
  __typename?: 'TRouteBusinessSite'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTRouteCompany = {
  __typename?: 'TRouteCompany'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  vertical: GTTRouteVertical
}

export type GTTRouteCompanyLine = {
  __typename?: 'TRouteCompanyLine'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTRouteCompanyLineSpec = {
  __typename?: 'TRouteCompanyLineSpec'
  distance?: Maybe<Scalars['Int']['output']>
  duration?: Maybe<Scalars['Int']['output']>
  id: Scalars['UUID']['output']
}

export type GTTRouteInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  stopIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type GTTRoutePlace = {
  __typename?: 'TRoutePlace'
  id: Scalars['UUID']['output']
  timezone: Scalars['String']['output']
}

export type GTTRoutePolyline = {
  __typename?: 'TRoutePolyline'
  distance: Scalars['Int']['output']
  encoded: Scalars['String']['output']
}

export type GTTRouteRouteService = {
  __typename?: 'TRouteRouteService'
  code: Scalars['String']['output']
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
}

export type GTTRouteSegment = {
  __typename?: 'TRouteSegment'
  companyLineSpecs: Array<GTTRouteCompanyLineSpec>
  destination: GTTRoutePlace
  id: Scalars['UUID']['output']
  origin: GTTRoutePlace
}

export type GTTRouteStop = {
  __typename?: 'TRouteStop'
  code: Scalars['String']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  timezone: Scalars['String']['output']
}

export const GTTRouteVertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTTRouteVertical = (typeof GTTRouteVertical)[keyof typeof GTTRouteVertical]
export type GTTRoutesAutocompleteInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTTRoutesAutocompleteResponse = {
  __typename?: 'TRoutesAutocompleteResponse'
  items: Array<GTTRouteAutocomplete>
  total: Scalars['Int']['output']
}

export type GTTRoutesInput = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  companyID?: InputMaybe<Scalars['UUID']['input']>
  destinationID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  originID?: InputMaybe<Scalars['UUID']['input']>
  routeServiceCode?: InputMaybe<Scalars['String']['input']>
}

export type GTTRoutesResponse = {
  __typename?: 'TRoutesResponse'
  items: Array<GTTRoute>
  total: Scalars['Int']['output']
}

export type GTTSorting = {
  field: Scalars['String']['input']
  order: Scalars['Int']['input']
}

export type GTTVehicle = {
  __typename?: 'TVehicle'
  cards: GTTVehicleCards
  cityName?: Maybe<Scalars['String']['output']>
  clients: Array<GTTVehicleClient>
  companies: Array<GTTVehicleCompany>
  createdAt: Scalars['Time']['output']
  documents: Array<GTTVehicleDocument>
  drivers: Array<GTTVehicleDriver>
  externalID?: Maybe<Scalars['String']['output']>
  features?: Maybe<GTTVehicleFeatures>
  id: Scalars['UUID']['output']
  inactiveReason?: Maybe<Scalars['String']['output']>
  insurancePolicy?: Maybe<GTTVehicleInsurancePolicy>
  /** @deprecated Use status instead */
  isActive?: Maybe<Scalars['Boolean']['output']>
  isAssigned: Scalars['Boolean']['output']
  /** @deprecated Use isActive instead */
  isEnabled?: Maybe<Scalars['Boolean']['output']>
  licensePlates?: Maybe<Array<GTTVehicleLicensePlate>>
  name: Scalars['String']['output']
  odometerReading?: Maybe<Scalars['Int']['output']>
  partner: GTTVehiclePartner
  registrationDetails?: Maybe<GTTVehicleRegistrationDetails>
  routeServices: Array<GTTVehicleRouteService>
  stateCode?: Maybe<Scalars['String']['output']>
  status: GTVehicleStatus
  template: GTTVehicleTemplate
  updatedAt: Scalars['Time']['output']
  vin: Scalars['String']['output']
}

export type GTTVehicleAutocomplete = {
  __typename?: 'TVehicleAutocomplete'
  capacity: Scalars['Int']['output']
  externalID?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTVehicleCards = {
  __typename?: 'TVehicleCards'
  /** @deprecated Use fuelCards instead */
  fuel?: Maybe<Scalars['String']['output']>
  fuelCards?: Maybe<Array<Scalars['String']['output']>>
  /** @deprecated Use tagCards instead */
  tag?: Maybe<Scalars['String']['output']>
  tagCards?: Maybe<Array<Scalars['String']['output']>>
}

export type GTTVehicleClient = {
  __typename?: 'TVehicleClient'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTVehicleCompany = {
  __typename?: 'TVehicleCompany'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTVehicleDocument = {
  __typename?: 'TVehicleDocument'
  category: GTTVehicleDocumentCategory
  document: GTAsset
}

export const GTTVehicleDocumentCategory = {
  Bills: 'BILLS',
  ExternalInspection: 'EXTERNAL_INSPECTION',
  InternalInspection: 'INTERNAL_INSPECTION',
  Photo: 'PHOTO',
  TuningService: 'TUNING_SERVICE',
  VerificationCertificate: 'VERIFICATION_CERTIFICATE'
} as const

export type GTTVehicleDocumentCategory = (typeof GTTVehicleDocumentCategory)[keyof typeof GTTVehicleDocumentCategory]
export type GTTVehicleDriver = {
  __typename?: 'TVehicleDriver'
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type GTTVehicleFeatures = {
  __typename?: 'TVehicleFeatures'
  amenities: Array<Scalars['String']['output']>
  brandCoverage?: Maybe<Scalars['String']['output']>
  make?: Maybe<Scalars['String']['output']>
  model?: Maybe<Scalars['String']['output']>
  year?: Maybe<Scalars['Int']['output']>
}

export type GTTVehicleInput = {
  id?: InputMaybe<Scalars['UUID']['input']>
  vin?: InputMaybe<Scalars['String']['input']>
}

export type GTTVehicleInsurancePolicy = {
  __typename?: 'TVehicleInsurancePolicy'
  document: GTAsset
  expirationDate: Scalars['Date']['output']
}

export type GTTVehicleLicensePlate = {
  __typename?: 'TVehicleLicensePlate'
  number: Scalars['String']['output']
  stateCode?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type GTTVehiclePartner = {
  __typename?: 'TVehiclePartner'
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export const GTTVehicleRegistrationCategory = {
  Federal: 'FEDERAL',
  Local: 'LOCAL'
} as const

export type GTTVehicleRegistrationCategory =
  (typeof GTTVehicleRegistrationCategory)[keyof typeof GTTVehicleRegistrationCategory]
export type GTTVehicleRegistrationDetails = {
  __typename?: 'TVehicleRegistrationDetails'
  category: GTTVehicleRegistrationCategory
  document: GTAsset
  expirationDate?: Maybe<Scalars['Date']['output']>
  isPermanent: Scalars['Boolean']['output']
  stateCode: Scalars['String']['output']
  /** @deprecated type would be removed in the future */
  type?: Maybe<GTTVehicleRegistrationType>
}

export const GTTVehicleRegistrationType = {
  A: 'A',
  A1: 'A1',
  A2: 'A2',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  E1: 'E1'
} as const

export type GTTVehicleRegistrationType = (typeof GTTVehicleRegistrationType)[keyof typeof GTTVehicleRegistrationType]
export const GTTVehicleRegistrationTypeInput = {
  A: 'A',
  A1: 'A1',
  A2: 'A2',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  E1: 'E1'
} as const

export type GTTVehicleRegistrationTypeInput =
  (typeof GTTVehicleRegistrationTypeInput)[keyof typeof GTTVehicleRegistrationTypeInput]
export type GTTVehicleRouteService = {
  __typename?: 'TVehicleRouteService'
  code: Scalars['String']['output']
  id: Scalars['UUID']['output']
}

export type GTTVehicleTemplate = {
  __typename?: 'TVehicleTemplate'
  capacity: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  type: GTTVehicleTemplateType
}

export const GTTVehicleTemplateType = {
  Bus: 'BUS',
  Car: 'CAR',
  Van: 'VAN'
} as const

export type GTTVehicleTemplateType = (typeof GTTVehicleTemplateType)[keyof typeof GTTVehicleTemplateType]
export const GTTVehicleTemplateTypeInput = {
  Bus: 'BUS',
  Car: 'CAR',
  Van: 'VAN'
} as const

export type GTTVehicleTemplateTypeInput = (typeof GTTVehicleTemplateTypeInput)[keyof typeof GTTVehicleTemplateTypeInput]
export type GTTVehiclesAutocompleteInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  partnerID?: InputMaybe<Scalars['UUID']['input']>
}

export type GTTVehiclesAutocompleteResponse = {
  __typename?: 'TVehiclesAutocompleteResponse'
  items: Array<GTTVehicleAutocomplete>
  total: Scalars['Int']['output']
}

export type GTTVehiclesInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  driverID?: InputMaybe<Scalars['UUID']['input']>
  externalID?: InputMaybe<Scalars['String']['input']>
  hasDriver?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>
  make?: InputMaybe<Scalars['String']['input']>
  partnerID?: InputMaybe<Scalars['UUID']['input']>
  plateType?: InputMaybe<Scalars['String']['input']>
  plates?: InputMaybe<Scalars['String']['input']>
  registrationType?: InputMaybe<GTTVehicleRegistrationTypeInput>
  stateCode?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<GTTVehicleTemplateTypeInput>
  vin?: InputMaybe<Scalars['String']['input']>
}

export type GTTVehiclesResponse = {
  __typename?: 'TVehiclesResponse'
  items: Array<GTTVehicle>
  total: Scalars['Int']['output']
}

export const GTTemplateName = {
  MobilePlan: 'mobilePlan',
  Sim: 'sim'
} as const

export type GTTemplateName = (typeof GTTemplateName)[keyof typeof GTTemplateName]
export type GTTicketInsurance = {
  __typename?: 'TicketInsurance'
  bookingID: Scalars['ID']['output']
  hasConflict?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  insurance: GTInsurance
  passenger: GTBookingPassenger
  trip: GTTicketInsuranceTrip
}

export type GTTicketInsuranceResponse = {
  __typename?: 'TicketInsuranceResponse'
  items: Array<GTTicketInsurance>
  total: Scalars['Int']['output']
}

export type GTTicketInsuranceTrip = {
  __typename?: 'TicketInsuranceTrip'
  id: Scalars['ID']['output']
  segment: GTTicketInsuranceTripSegment
}

export type GTTicketInsuranceTripSegment = {
  __typename?: 'TicketInsuranceTripSegment'
  departureAt: Scalars['Time']['output']
  destination: GTBookingTripPlace
  id: Scalars['ID']['output']
  origin: GTBookingTripPlace
}

/** ticket payment */
export type GTTicketPayment = {
  __typename?: 'TicketPayment'
  /** discount */
  discount: Scalars['Int']['output']
  /** status */
  status: Scalars['String']['output']
  /** subtotal */
  subtotal: Scalars['Int']['output']
  /** VAT */
  vat: Scalars['Int']['output']
}

/** ticket status */
export const GTTicketStatus = {
  Cancelled: 'cancelled',
  Confirmed: 'confirmed',
  Pending: 'pending',
  Reserved: 'reserved'
} as const

export type GTTicketStatus = (typeof GTTicketStatus)[keyof typeof GTTicketStatus]
export type GTTicketsByStatus = {
  __typename?: 'TicketsByStatus'
  cancelled: Scalars['Int']['output']
  confirmed: Scalars['Int']['output']
}

export type GTTimesRangeInput = {
  from?: InputMaybe<Scalars['Time']['input']>
  to?: InputMaybe<Scalars['Time']['input']>
}

/** toggle item response */
export type GTToggleItemResponse = {
  __typename?: 'ToggleItemResponse'
  /** item id */
  id: Scalars['ID']['output']
  /** indicates whether the item is active or not */
  isActive: Scalars['Boolean']['output']
}

/** toggle route service response */
export type GTToggleServiceResponse = {
  __typename?: 'ToggleServiceResponse'
  /** route service id */
  id: Scalars['ID']['output']
  /** indicates whether the route service is active or not */
  isActive: Scalars['Boolean']['output']
}

/** toggle site response */
export type GTToggleSiteResponse = {
  __typename?: 'ToggleSiteResponse'
  /** site id */
  id: Scalars['ID']['output']
  /** indicates wether the site is active or not */
  isActive: Scalars['Boolean']['output']
}

/** toggle user response */
export type GTToggleUserResponse = {
  __typename?: 'ToggleUserResponse'
  /** user id */
  id: Scalars['ID']['output']
  /** indicates whether the user is active or not */
  isActive: Scalars['Boolean']['output']
}

export type GTTopAgentDashboardData = {
  __typename?: 'TopAgentDashboardData'
  amount: Scalars['Int']['output']
  company: Scalars['String']['output']
  count: Scalars['Int']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

export type GTTopCompanyDashboardData = {
  __typename?: 'TopCompanyDashboardData'
  amount: Scalars['Int']['output']
  count: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type GTTopServiceDasboardData = {
  __typename?: 'TopServiceDasboardData'
  amount: Scalars['Int']['output']
  code: Scalars['String']['output']
  company: Scalars['String']['output']
  count: Scalars['Int']['output']
  destination: Scalars['String']['output']
  origin: Scalars['String']['output']
}

export type GTTopSiteDashboardData = {
  __typename?: 'TopSiteDashboardData'
  amount: Scalars['Int']['output']
  company: Scalars['String']['output']
  count: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTTotalSalesDashboardData = {
  __typename?: 'TotalSalesDashboardData'
  card: GTSalesDashboardDataInfo
  cash: GTSalesDashboardDataInfo
  total: GTSalesDashboardDataInfo
}

/**
 * The TransactionSource type represents the source and it's specific details.
 * it could only be one of the following: booking, sale (topUp), MobileLine
 */
export type GTTransactionSource =
  | GTTransactionSourceAdmin
  | GTTransactionSourceBooking
  | GTTransactionSourceCorporate
  | GTTransactionSourceMobileLine
  | GTTransactionSourceRefund
  | GTTransactionSourceSale

export type GTTransactionSourceAdmin = {
  __typename?: 'TransactionSourceAdmin'
  userID?: Maybe<Scalars['UUID']['output']>
}

export type GTTransactionSourceBooking = {
  __typename?: 'TransactionSourceBooking'
  bookingID: Scalars['ID']['output']
}

export type GTTransactionSourceCorporate = {
  __typename?: 'TransactionSourceCorporate'
  dispersionID?: Maybe<Scalars['UUID']['output']>
  userID?: Maybe<Scalars['UUID']['output']>
}

export type GTTransactionSourceMobileLine = {
  __typename?: 'TransactionSourceMobileLine'
  number: Scalars['String']['output']
}

export type GTTransactionSourceRefund = {
  __typename?: 'TransactionSourceRefund'
  refundedTransactionID: Scalars['ID']['output']
}

export type GTTransactionSourceSale = {
  __typename?: 'TransactionSourceSale'
  salesChannelID: Scalars['ID']['output']
}

/** trip */
export type GTTrip = {
  __typename?: 'Trip'
  /** arrival time */
  arrivalAt: Scalars['Time']['output']
  /** company line */
  companyLine: GTTripCompanyLine
  /** number of confirmed seats */
  confirmedSeats: Scalars['Int']['output']
  /** crew users */
  crewUsers: Array<GTTripUser>
  /** departure time */
  departureAt: Scalars['Time']['output']
  /** vehicle driver, outside of vehicle object to avoid caching issues */
  driver?: Maybe<GTTripVehicleDriver>
  /** trip id */
  id: Scalars['ID']['output']
  /** itinerary */
  itinerary: Array<GTTripItineraryStop>
  /** leg segments */
  legSegments: Array<GTTripLegSegment>
  /** notes */
  notes?: Maybe<Array<GTTripNote>>
  /** route */
  route: GTTripRoute
  /** route service */
  routeService: GTTripRouteService
  /** sale status */
  saleStatus: GTTripSaleStatus
  /** number of seats */
  seats: Scalars['Int']['output']
  /** service score */
  serviceScore?: Maybe<Scalars['Int']['output']>
  /** status */
  status: GTTripStatus
  /** timeline events */
  timeline: Array<GTTripTimelineEvent>
  /** vehicle */
  vehicle?: Maybe<GTTripVehicle>
}

export type GTTripBookings = {
  __typename?: 'TripBookings'
  arrivalAt: Scalars['Time']['output']
  departureAt: Scalars['Time']['output']
  destination: GTTripBookingsPlace
  id: Scalars['ID']['output']
  itinerary: Array<GTTripBookingsStop>
  origin: GTTripBookingsPlace
  routeServiceCode: Scalars['String']['output']
}

export type GTTripBookingsPlace = {
  __typename?: 'TripBookingsPlace'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  timezone: Scalars['String']['output']
}

export type GTTripBookingsStop = {
  __typename?: 'TripBookingsStop'
  bookings: Array<GTTripStopBooking>
  departureAt: Scalars['Time']['output']
  destination: GTTripBookingsPlace
  origin: GTTripBookingsPlace
  passengers: GTTripStopPassengers
}

/** company line */
export type GTTripCompanyLine = {
  __typename?: 'TripCompanyLine'
  /** company */
  company: GTTripCompanyLineCompany
  /** company line id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** company */
export type GTTripCompanyLineCompany = {
  __typename?: 'TripCompanyLineCompany'
  id: Scalars['ID']['output']
}

/** itinerary stop */
export type GTTripItineraryStop = {
  __typename?: 'TripItineraryStop'
  /** arrival time */
  arrival?: Maybe<GTTripItineraryStopTime>
  /** departure time */
  departure?: Maybe<GTTripItineraryStopTime>
  /** place */
  place: GTTripItineraryStopPlace
  /** real departure time */
  realDepartureTime?: Maybe<Scalars['Time']['output']>
}

/** place */
export type GTTripItineraryStopPlace = {
  __typename?: 'TripItineraryStopPlace'
  /** place id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** timezone */
  timezone: Scalars['String']['output']
}

/** change trip itinerary departure time itinerary time */
export type GTTripItineraryStopTime = {
  __typename?: 'TripItineraryStopTime'
  /** an integer representing how many days after departure the stop takes place. ex. 0 = same day, 1 = next day */
  day: Scalars['Int']['output']
  /** time in hh:mm format, in the timezone of the place */
  time: Scalars['String']['output']
}

export type GTTripLegSegment = {
  __typename?: 'TripLegSegment'
  destination: GTTripLegSegmentPlace
  id: Scalars['ID']['output']
  origin: GTTripLegSegmentPlace
}

export type GTTripLegSegmentPlace = {
  __typename?: 'TripLegSegmentPlace'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** trip note */
export type GTTripNote = {
  __typename?: 'TripNote'
  /** cash flow */
  cashFlow?: Maybe<GTCashFlow>
  /** note category */
  category: Scalars['String']['output']
  /** created at */
  createdAt: Scalars['Time']['output']
  /** attached files */
  files?: Maybe<Array<GTNoteFile>>
  /** message */
  message: Scalars['String']['output']
}

export const GTTripNoteCategoryInput = {
  Ambassador: 'AMBASSADOR',
  Cash: 'CASH',
  Driver: 'DRIVER',
  Events: 'EVENTS',
  Odometer: 'ODOMETER',
  Passengers: 'PASSENGERS',
  Service: 'SERVICE',
  Tolls: 'TOLLS',
  Vehicle: 'VEHICLE'
} as const

export type GTTripNoteCategoryInput = (typeof GTTripNoteCategoryInput)[keyof typeof GTTripNoteCategoryInput]
/** route */
export type GTTripRoute = {
  __typename?: 'TripRoute'
  /** route id */
  id: Scalars['ID']['output']
  /** route name */
  name?: Maybe<Scalars['String']['output']>
}

export type GTTripRoutePolyline = {
  __typename?: 'TripRoutePolyline'
  distance: Scalars['Int']['output']
  encoded: Scalars['String']['output']
}

/** route service */
export type GTTripRouteService = {
  __typename?: 'TripRouteService'
  /** business clients */
  businessClients: Array<GTTripRouteServiceBusinessClient>
  /** route service code */
  code: Scalars['String']['output']
  /** route service id */
  id: Scalars['ID']['output']
}

export type GTTripRouteServiceBusinessClient = {
  __typename?: 'TripRouteServiceBusinessClient'
  /** business sites */
  businessSites: Array<GTTripRouteServiceBusinessSite>
  /** business client id */
  id: Scalars['ID']['output']
  /** business client name */
  name: Scalars['String']['output']
}

export type GTTripRouteServiceBusinessSite = {
  __typename?: 'TripRouteServiceBusinessSite'
  /** business site id */
  id: Scalars['ID']['output']
  /** business site name */
  name: Scalars['String']['output']
}

/** sale status */
export const GTTripSaleStatus = {
  Activated: 'activated',
  Deactivated: 'deactivated'
} as const

export type GTTripSaleStatus = (typeof GTTripSaleStatus)[keyof typeof GTTripSaleStatus]
export type GTTripSegment = {
  __typename?: 'TripSegment'
  availableSeatsNumber: Scalars['Int']['output']
  basePrice: Scalars['Int']['output']
  duration: Scalars['Int']['output']
  hasSelectableSeats: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  itinerary: Array<GTTripSegmentItineraryStop>
  segment: GTTripSegmentSegment
  tripID: Scalars['ID']['output']
}

export type GTTripSegmentFare = {
  __typename?: 'TripSegmentFare'
  discountRate: Scalars['Float']['output']
  passengerType: GTPassengerType
  subtotal: Scalars['Int']['output']
  vat: Scalars['Int']['output']
}

export type GTTripSegmentItineraryStop = {
  __typename?: 'TripSegmentItineraryStop'
  arrivalTime?: Maybe<Scalars['String']['output']>
  day: Scalars['Int']['output']
  departureTime?: Maybe<Scalars['String']['output']>
  place: GTTripItineraryStopPlace
}

export type GTTripSegmentSegment = {
  __typename?: 'TripSegmentSegment'
  destination: GTTripSegmentSegmentPlace
  id: Scalars['ID']['output']
  origin: GTTripSegmentSegmentPlace
}

export type GTTripSegmentSegmentPlace = {
  __typename?: 'TripSegmentSegmentPlace'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export const GTTripSegmentVerticalInput = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTTripSegmentVerticalInput = (typeof GTTripSegmentVerticalInput)[keyof typeof GTTripSegmentVerticalInput]
export type GTTripSegmentsPassengerTypeInput = {
  id: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

/** trip status */
export const GTTripStatus = {
  Cancelled: 'cancelled',
  Completed: 'completed',
  Deactivated: 'deactivated',
  Delayed: 'delayed',
  InTransit: 'inTransit',
  OnTime: 'onTime'
} as const

export type GTTripStatus = (typeof GTTripStatus)[keyof typeof GTTripStatus]
export type GTTripStopBooking = {
  __typename?: 'TripStopBooking'
  buyer?: Maybe<GTTripStopBookingBuyer>
  checkInScore?: Maybe<Scalars['Float']['output']>
  destinationName: Scalars['String']['output']
  id: Scalars['ID']['output']
  status: Scalars['String']['output']
  supportInteractions: Array<GTTripStopBookingSupportInteraction>
  tickets: Array<GTTripStopBookingTicket>
  vertical: GTBookingVertical
}

export type GTTripStopBookingBuyer = {
  __typename?: 'TripStopBookingBuyer'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phone: Scalars['String']['output']
}

export type GTTripStopBookingSupportInteraction = {
  __typename?: 'TripStopBookingSupportInteraction'
  callStatus?: Maybe<GTCallStatus>
  createdAt: Scalars['Time']['output']
  notes?: Maybe<Scalars['String']['output']>
  notificationChannel?: Maybe<GTNotificationChannel>
}

export type GTTripStopBookingTicket = {
  __typename?: 'TripStopBookingTicket'
  checkedIn: Scalars['Boolean']['output']
  couponCode: Scalars['String']['output']
  id: Scalars['ID']['output']
  passenger: GTTripStopBookingTicketPassenger
  payment?: Maybe<GTTripStopBookingTicketPayment>
  seat: GTTripStopBookingTicketSeat
  status: Scalars['String']['output']
}

export type GTTripStopBookingTicketPassenger = {
  __typename?: 'TripStopBookingTicketPassenger'
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
}

export type GTTripStopBookingTicketPayment = {
  __typename?: 'TripStopBookingTicketPayment'
  discount: Scalars['Int']['output']
  status: GTPaymentStatus
  subtotal: Scalars['Int']['output']
  vat: Scalars['Int']['output']
}

export type GTTripStopBookingTicketSeat = {
  __typename?: 'TripStopBookingTicketSeat'
  label: Scalars['String']['output']
}

export type GTTripStopPassengers = {
  __typename?: 'TripStopPassengers'
  down: Scalars['Int']['output']
  total: Scalars['Int']['output']
  up: Scalars['Int']['output']
}

/** trip timeline event */
export type GTTripTimelineEvent = {
  __typename?: 'TripTimelineEvent'
  /** correlation id */
  correlationID: Scalars['String']['output']
  /** timestamp */
  createdAt: Scalars['Time']['output']
  /** placeID (could be null) */
  placeID?: Maybe<Scalars['ID']['output']>
  /** type */
  type: Scalars['String']['output']
  /** user */
  user: GTTripUser
}

export type GTTripTrackingQueryInput = {
  id: Scalars['UUID']['input']
}

export type GTTripTrackingRecord = {
  __typename?: 'TripTrackingRecord'
  fixTime: Scalars['Time']['output']
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  speed: Scalars['Float']['output']
}

export type GTTripTrackingResponse = {
  __typename?: 'TripTrackingResponse'
  records: Array<GTTripTrackingRecord>
  total: Scalars['Int']['output']
}

/** Crew User */
export type GTTripUser = {
  __typename?: 'TripUser'
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type GTTripV2 = {
  __typename?: 'TripV2'
  arrivalAt: Scalars['Time']['output']
  availableSeatsNumber: Scalars['Int']['output']
  company: GTTripV2Company
  companyLine: GTTripV2CompanyLine
  confirmationModalities: Array<GTTripV2ConfirmationModality>
  confirmedSeatsNumber: Scalars['Int']['output']
  createdAt: Scalars['Time']['output']
  crewUsers: Array<GTTripV2User>
  departureAt: Scalars['Time']['output']
  driver?: Maybe<GTTripV2Driver>
  id: Scalars['UUID']['output']
  isOnGuard: Scalars['Boolean']['output']
  isPeak: Scalars['Boolean']['output']
  itinerary: Array<GTTripV2ItineraryStop>
  legSegments: Array<GTTripV2LegSegment>
  notes?: Maybe<Array<GTTripV2Note>>
  route: GTTripV2Route
  routeService: GTTripV2RouteService
  saleStatus: GTTripV2SaleStatus
  serviceScore?: Maybe<Scalars['Int']['output']>
  status: GTTripV2Status
  timeline: Array<GTTripV2TimelineEvent>
  updatedAt: Scalars['Time']['output']
  vehicle?: Maybe<GTTripV2Vehicle>
  vertical: GTTripV2Vertical
}

export const GTTripV2CancellationReasonCategory = {
  BusinessClient: 'BUSINESS_CLIENT',
  Driver: 'DRIVER',
  ExternalConditions: 'EXTERNAL_CONDITIONS',
  Other: 'OTHER',
  Partner: 'PARTNER',
  Planning: 'PLANNING',
  Vehicle: 'VEHICLE'
} as const

export type GTTripV2CancellationReasonCategory =
  (typeof GTTripV2CancellationReasonCategory)[keyof typeof GTTripV2CancellationReasonCategory]
export const GTTripV2CancellationReasonSubcategory = {
  Absence: 'ABSENCE',
  Accident: 'ACCIDENT',
  Disability: 'DISABILITY',
  MechanicalFailure: 'MECHANICAL_FAILURE',
  Other: 'OTHER',
  Protest: 'PROTEST',
  Roadblock: 'ROADBLOCK',
  ScheduledMaintenance: 'SCHEDULED_MAINTENANCE',
  Security: 'SECURITY',
  Traffic: 'TRAFFIC',
  UnitStoppage: 'UNIT_STOPPAGE',
  Weather: 'WEATHER'
} as const

export type GTTripV2CancellationReasonSubcategory =
  (typeof GTTripV2CancellationReasonSubcategory)[keyof typeof GTTripV2CancellationReasonSubcategory]
export type GTTripV2Company = {
  __typename?: 'TripV2Company'
  id: Scalars['UUID']['output']
}

export type GTTripV2CompanyLine = {
  __typename?: 'TripV2CompanyLine'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTripV2ConfirmationModality = {
  __typename?: 'TripV2ConfirmationModality'
  exceptions: GTTripV2ConfirmationModalityExceptions
  name: GTTripV2ConfirmationModalityName
  salesChannels: Array<GTTripV2ConfirmationModalitySalesChannel>
}

export type GTTripV2ConfirmationModalityExceptions = {
  __typename?: 'TripV2ConfirmationModalityExceptions'
  places: Array<GTTripV2ConfirmationModalityExceptionsPlace>
}

export type GTTripV2ConfirmationModalityExceptionsPlace = {
  __typename?: 'TripV2ConfirmationModalityExceptionsPlace'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export const GTTripV2ConfirmationModalityName = {
  Paid: 'PAID',
  Reserved: 'RESERVED'
} as const

export type GTTripV2ConfirmationModalityName =
  (typeof GTTripV2ConfirmationModalityName)[keyof typeof GTTripV2ConfirmationModalityName]
export const GTTripV2ConfirmationModalityNameInput = {
  Paid: 'PAID',
  Reserved: 'RESERVED'
} as const

export type GTTripV2ConfirmationModalityNameInput =
  (typeof GTTripV2ConfirmationModalityNameInput)[keyof typeof GTTripV2ConfirmationModalityNameInput]
export type GTTripV2ConfirmationModalitySalesChannel = {
  __typename?: 'TripV2ConfirmationModalitySalesChannel'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTripV2Driver = {
  __typename?: 'TripV2Driver'
  RSVP?: Maybe<GTTripV2DriverRsvp>
  firstName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  lastName: Scalars['String']['output']
}

export type GTTripV2DriverRsvp = {
  __typename?: 'TripV2DriverRSVP'
  attempt: Scalars['Int']['output']
  status: GTTripV2DriverRsvpStatus
}

export const GTTripV2DriverRsvpStatus = {
  Confirmed: 'CONFIRMED',
  Declined: 'DECLINED',
  Failed: 'FAILED',
  Missed: 'MISSED',
  Sent: 'SENT'
} as const

export type GTTripV2DriverRsvpStatus = (typeof GTTripV2DriverRsvpStatus)[keyof typeof GTTripV2DriverRsvpStatus]
export type GTTripV2Input = {
  id: Scalars['UUID']['input']
}

export type GTTripV2ItineraryStop = {
  __typename?: 'TripV2ItineraryStop'
  arrival?: Maybe<GTTripV2ItineraryStopTime>
  checkedIn?: Maybe<GTTripV2ItineraryStopCheck>
  checkedOut?: Maybe<GTTripV2ItineraryStopCheck>
  departure?: Maybe<GTTripV2ItineraryStopTime>
  place: GTTripV2ItineraryStopPlace
  realDepartureTime?: Maybe<Scalars['Time']['output']>
}

export type GTTripV2ItineraryStopCheck = {
  __typename?: 'TripV2ItineraryStopCheck'
  at: Scalars['Time']['output']
  method: GTTripV2ItineraryStopCheckMethod
}

export const GTTripV2ItineraryStopCheckMethod = {
  Automatic: 'AUTOMATIC',
  Manual: 'MANUAL'
} as const

export type GTTripV2ItineraryStopCheckMethod =
  (typeof GTTripV2ItineraryStopCheckMethod)[keyof typeof GTTripV2ItineraryStopCheckMethod]
export const GTTripV2ItineraryStopCheckType = {
  CheckIn: 'CHECK_IN',
  CheckOut: 'CHECK_OUT'
} as const

export type GTTripV2ItineraryStopCheckType =
  (typeof GTTripV2ItineraryStopCheckType)[keyof typeof GTTripV2ItineraryStopCheckType]
export type GTTripV2ItineraryStopPlace = {
  __typename?: 'TripV2ItineraryStopPlace'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  timezone: Scalars['Timezone']['output']
}

export type GTTripV2ItineraryStopTime = {
  __typename?: 'TripV2ItineraryStopTime'
  day: Scalars['Int']['output']
  time: Scalars['String']['output']
}

export type GTTripV2LegSegment = {
  __typename?: 'TripV2LegSegment'
  destination: GTTripV2LegSegmentPlace
  id: Scalars['UUID']['output']
  origin: GTTripV2LegSegmentPlace
}

export type GTTripV2LegSegmentPlace = {
  __typename?: 'TripV2LegSegmentPlace'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTripV2Note = {
  __typename?: 'TripV2Note'
  cashFlow?: Maybe<GTTripV2NoteCashFlow>
  category?: Maybe<Scalars['String']['output']>
  changeType?: Maybe<GTTripV2NoteChangeType>
  createdAt: Scalars['Time']['output']
  files?: Maybe<Array<GTTripV2NoteFile>>
  message?: Maybe<Scalars['String']['output']>
  subcategory?: Maybe<Scalars['String']['output']>
  user?: Maybe<GTTripV2User>
}

export type GTTripV2NoteCashFlow = {
  __typename?: 'TripV2NoteCashFlow'
  amount: Scalars['Int']['output']
  type: Scalars['String']['output']
}

export const GTTripV2NoteCategory = {
  Ambassador: 'AMBASSADOR',
  Cash: 'CASH',
  Driver: 'DRIVER',
  Events: 'EVENTS',
  Passengers: 'PASSENGERS',
  Service: 'SERVICE',
  Tolls: 'TOLLS',
  Vehicle: 'VEHICLE'
} as const

export type GTTripV2NoteCategory = (typeof GTTripV2NoteCategory)[keyof typeof GTTripV2NoteCategory]
export const GTTripV2NoteChangeType = {
  AddNote: 'ADD_NOTE',
  AssignDriver: 'ASSIGN_DRIVER',
  CancelTrip: 'CANCEL_TRIP',
  ChangeVehicle: 'CHANGE_VEHICLE'
} as const

export type GTTripV2NoteChangeType = (typeof GTTripV2NoteChangeType)[keyof typeof GTTripV2NoteChangeType]
export type GTTripV2NoteFile = {
  __typename?: 'TripV2NoteFile'
  name: Scalars['String']['output']
  path: Scalars['String']['output']
}

export type GTTripV2Route = {
  __typename?: 'TripV2Route'
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
  polyline?: Maybe<GTTripRoutePolyline>
}

export type GTTripV2RouteService = {
  __typename?: 'TripV2RouteService'
  businessClients: Array<GTTripV2RouteServiceBusinessClient>
  code: Scalars['String']['output']
  id: Scalars['UUID']['output']
}

export type GTTripV2RouteServiceBusinessClient = {
  __typename?: 'TripV2RouteServiceBusinessClient'
  businessSites: Array<GTTripV2RouteServiceBusinessSite>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTripV2RouteServiceBusinessSite = {
  __typename?: 'TripV2RouteServiceBusinessSite'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export const GTTripV2SaleStatus = {
  Activated: 'ACTIVATED',
  Deactivated: 'DEACTIVATED'
} as const

export type GTTripV2SaleStatus = (typeof GTTripV2SaleStatus)[keyof typeof GTTripV2SaleStatus]
export const GTTripV2Status = {
  Cancelled: 'CANCELLED',
  Completed: 'COMPLETED',
  InTransit: 'IN_TRANSIT',
  OnTime: 'ON_TIME'
} as const

export type GTTripV2Status = (typeof GTTripV2Status)[keyof typeof GTTripV2Status]
export type GTTripV2TimelineEvent = {
  __typename?: 'TripV2TimelineEvent'
  correlationID: Scalars['UUID']['output']
  createdAt: Scalars['Time']['output']
  placeID?: Maybe<Scalars['UUID']['output']>
  tripCancellationReason?: Maybe<GTTripV2TimelineEventTripCancellationReason>
  type: Scalars['String']['output']
  user: GTTripV2User
}

export type GTTripV2TimelineEventTripCancellationReason = {
  __typename?: 'TripV2TimelineEventTripCancellationReason'
  category?: Maybe<GTTripV2CancellationReasonCategory>
  subcategory?: Maybe<GTTripV2CancellationReasonSubcategory>
}

export type GTTripV2User = {
  __typename?: 'TripV2User'
  firstName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type GTTripV2Vehicle = {
  __typename?: 'TripV2Vehicle'
  externalID?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  partner?: Maybe<GTTripV2VehiclePartner>
  schedule?: Maybe<GTTripV2VehicleSchedule>
  template?: Maybe<GTTripV2VehicleTemplate>
}

export type GTTripV2VehiclePartner = {
  __typename?: 'TripV2VehiclePartner'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTTripV2VehicleSchedule = {
  __typename?: 'TripV2VehicleSchedule'
  id: Scalars['UUID']['output']
  type: GTTripV2VehicleScheduleType
}

export const GTTripV2VehicleScheduleType = {
  Dow: 'DOW',
  Frequency: 'FREQUENCY'
} as const

export type GTTripV2VehicleScheduleType = (typeof GTTripV2VehicleScheduleType)[keyof typeof GTTripV2VehicleScheduleType]
export type GTTripV2VehicleTemplate = {
  __typename?: 'TripV2VehicleTemplate'
  id: Scalars['UUID']['output']
}

export const GTTripV2Vertical = {
  City: 'CITY',
  Rental: 'RENTAL',
  Team: 'TEAM',
  Travel: 'TRAVEL'
} as const

export type GTTripV2Vertical = (typeof GTTripV2Vertical)[keyof typeof GTTripV2Vertical]
/** vehicle */
export type GTTripVehicle = {
  __typename?: 'TripVehicle'
  /** external ID */
  externalID?: Maybe<Scalars['String']['output']>
  /** vehicle id */
  id: Scalars['ID']['output']
  /** vehicle name */
  name: Scalars['String']['output']
  /** partner */
  partner?: Maybe<GTTripVehiclePartner>
  /** schedule */
  schedule?: Maybe<GTTripVehicleSchedule>
  /**
   * vehicle template
   * @deprecated Use seats on Trip
   */
  template?: Maybe<GTTripVehicleVehicleTemplate>
}

export type GTTripVehicleDriver = {
  __typename?: 'TripVehicleDriver'
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  partner: GTTripVehiclePartner
  phoneNumber?: Maybe<GTPhoneNumber>
}

/** partner */
export type GTTripVehiclePartner = {
  __typename?: 'TripVehiclePartner'
  /** partner id */
  id: Scalars['ID']['output']
  /** partner name */
  name: Scalars['String']['output']
}

export type GTTripVehicleSchedule = {
  __typename?: 'TripVehicleSchedule'
  id: Scalars['ID']['output']
  type: GTTripVehicleScheduleType
}

export const GTTripVehicleScheduleType = {
  Dow: 'DOW',
  Frequency: 'FREQUENCY'
} as const

export type GTTripVehicleScheduleType = (typeof GTTripVehicleScheduleType)[keyof typeof GTTripVehicleScheduleType]
export type GTTripVehicleVehicleTemplate = {
  __typename?: 'TripVehicleVehicleTemplate'
  capacity: Scalars['Int']['output']
  id: Scalars['ID']['output']
}

export type GTTripsDriverConflictInput = {
  driverID: Scalars['UUID']['input']
  tripID: Scalars['UUID']['input']
}

export type GTTripsDriverConflictResponse = {
  __typename?: 'TripsDriverConflictResponse'
  items: Array<GTTripV2>
  total: Scalars['Int']['output']
}

/** paginated trips response */
export type GTTripsResponse = {
  __typename?: 'TripsResponse'
  /** trips. id is correlationID */
  items: Array<GTTrip>
  /** total of trips */
  total: Scalars['Int']['output']
}

export const GTTripsV2DayOfWeek = {
  Friday: 'FRIDAY',
  Monday: 'MONDAY',
  Saturday: 'SATURDAY',
  Sunday: 'SUNDAY',
  Thursday: 'THURSDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY'
} as const

export type GTTripsV2DayOfWeek = (typeof GTTripsV2DayOfWeek)[keyof typeof GTTripsV2DayOfWeek]
export type GTTripsV2HourRangeInput = {
  endHour?: InputMaybe<Scalars['String']['input']>
  startHour?: InputMaybe<Scalars['String']['input']>
}

export type GTTripsV2Input = {
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  companyID?: InputMaybe<Scalars['UUID']['input']>
  companyLineID?: InputMaybe<Scalars['UUID']['input']>
  confirmationModalityName?: InputMaybe<GTTripV2ConfirmationModalityNameInput>
  departureAtRange?: InputMaybe<GTTripsV2TimeRangeInput>
  departureDaysOfWeek?: InputMaybe<Array<GTTripsV2DayOfWeek>>
  departureHourRange?: InputMaybe<GTTripsV2HourRangeInput>
  destinationCityID?: InputMaybe<Scalars['UUID']['input']>
  destinationID?: InputMaybe<Scalars['UUID']['input']>
  driverID?: InputMaybe<Scalars['UUID']['input']>
  driverRSVPStatus?: InputMaybe<GTTripV2DriverRsvpStatus>
  hasDriver?: InputMaybe<Scalars['Boolean']['input']>
  hasIncidents?: InputMaybe<Scalars['Boolean']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  notConfirmationModalityName?: InputMaybe<GTTripV2ConfirmationModalityNameInput>
  originCityID?: InputMaybe<Scalars['UUID']['input']>
  originID?: InputMaybe<Scalars['UUID']['input']>
  partnerIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  routeIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  routeServiceIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  saleStatus?: InputMaybe<GTTripsV2SaleStatusInput>
  shortIDs?: InputMaybe<Array<Scalars['String']['input']>>
  status?: InputMaybe<GTTripsV2StatusInput>
  vehicleID?: InputMaybe<Scalars['UUID']['input']>
  vehicleIsActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTTripsV2Response = {
  __typename?: 'TripsV2Response'
  items: Array<GTTripV2>
  total: Scalars['Int']['output']
}

export const GTTripsV2SaleStatusInput = {
  Activated: 'ACTIVATED',
  Deactivated: 'DEACTIVATED'
} as const

export type GTTripsV2SaleStatusInput = (typeof GTTripsV2SaleStatusInput)[keyof typeof GTTripsV2SaleStatusInput]
export const GTTripsV2StatusInput = {
  Cancelled: 'CANCELLED',
  Completed: 'COMPLETED',
  InTransit: 'IN_TRANSIT',
  OnTime: 'ON_TIME'
} as const

export type GTTripsV2StatusInput = (typeof GTTripsV2StatusInput)[keyof typeof GTTripsV2StatusInput]
export type GTTripsV2TimeRangeInput = {
  endAt?: InputMaybe<Scalars['Time']['input']>
  startAt?: InputMaybe<Scalars['Time']['input']>
}

export type GTTripsVehicleConflictInput = {
  tripID: Scalars['UUID']['input']
  vehicleID: Scalars['UUID']['input']
}

export type GTTripsVehicleConflictResponse = {
  __typename?: 'TripsVehicleConflictResponse'
  items: Array<GTTripV2>
  total: Scalars['Int']['output']
}

export type GTUnassignBusinessCustomerKreditCategoryInput = {
  id: Scalars['UUID']['input']
}

export type GTUnassignBusinessCustomersKreditCategoryInput = {
  ids: Array<Scalars['UUID']['input']>
}

export type GTUpdateCampaignDatesInput = {
  endsAt?: InputMaybe<Scalars['String']['input']>
  startsAt?: InputMaybe<Scalars['String']['input']>
  timezone: Scalars['String']['input']
}

export type GTUpdateCampaignInput = {
  dates?: InputMaybe<GTUpdateCampaignDatesInput>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type GTUpdateCouponConstraintsInput = {
  bookingTypes?: InputMaybe<Array<GTBookingType>>
  citySegments?: InputMaybe<Array<GTCouponConstraintsCitySegmentInput>>
  confirmationModalities?: InputMaybe<GTConfirmationMoldalitiesInput>
  departureDateRanges?: InputMaybe<Array<GTDepartureDateRangeInput>>
  limit?: InputMaybe<Scalars['Int']['input']>
  minSale?: InputMaybe<Scalars['Int']['input']>
  minTicketsCount?: InputMaybe<Scalars['Int']['input']>
  onlyRegisteredUsers?: InputMaybe<Scalars['Boolean']['input']>
  onlyRoundTrips?: InputMaybe<Scalars['Boolean']['input']>
  passengerTypes?: InputMaybe<Array<GTCouponConstraintsPassengerTypeInput>>
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  userLimit?: InputMaybe<Scalars['Int']['input']>
}

export type GTUpdateCouponCouponDatesInput = {
  endsAt?: InputMaybe<Scalars['String']['input']>
  startsAt?: InputMaybe<Scalars['String']['input']>
  timezone: Scalars['String']['input']
}

export type GTUpdateCouponCouponDiscountInput = {
  discountType?: InputMaybe<GTDiscountType>
  value?: InputMaybe<Scalars['Int']['input']>
}

export type GTUpdateCouponCouponInput = {
  channels?: InputMaybe<Array<GTCouponChannel>>
  constraints?: InputMaybe<GTUpdateCouponConstraintsInput>
  dates?: InputMaybe<GTUpdateCouponCouponDatesInput>
  discount?: InputMaybe<GTUpdateCouponCouponDiscountInput>
  id: Scalars['ID']['input']
}

export type GTUpdateCouponInput = {
  campaignID: Scalars['ID']['input']
  coupon: GTUpdateCouponCouponInput
}

export type GTUpdateRouteServicePeakInput = {
  endAt: Scalars['Date']['input']
  id: Scalars['ID']['input']
  startAt: Scalars['Date']['input']
}

export type GTUpdateServiceInput = {
  activePeriod?: InputMaybe<Scalars['Int']['input']>
  businessClients?: InputMaybe<Array<GTChangeRouteServiceBusinessClientInput>>
  confirmationModalitiesV2?: InputMaybe<Array<GTRouteServiceConfirmationModalitiesV2Input>>
  executeAt?: InputMaybe<Scalars['Time']['input']>
  hasSelectableSeats?: InputMaybe<Scalars['Boolean']['input']>
  hasTolls?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  isPetFriendly?: InputMaybe<Scalars['Boolean']['input']>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  itinerary?: InputMaybe<Array<GTItineraryInput>>
  limits?: InputMaybe<Array<GTRouteServiceLimitInput>>
  peaks?: InputMaybe<Array<GTUpdateRouteServicePeakInput>>
  pricePerTrip?: InputMaybe<Scalars['Int']['input']>
  /** sales channels ids */
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  userIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  vehicleID?: InputMaybe<Scalars['ID']['input']>
}

export type GTUpdateSnacksCouponCouponConstraintsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>
  minSale?: InputMaybe<Scalars['Int']['input']>
}

export type GTUpdateSnacksCouponCouponInput = {
  constraints?: InputMaybe<GTUpdateSnacksCouponCouponConstraintsInput>
  dates?: InputMaybe<GTUpdateCouponCouponDatesInput>
  discount?: InputMaybe<GTUpdateCouponCouponDiscountInput>
  id: Scalars['ID']['input']
}

export type GTUpdateSnacksCouponInput = {
  campaignID: Scalars['ID']['input']
  coupon: GTUpdateSnacksCouponCouponInput
}

export type GTUpdateUserInput = {
  companyIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['UUID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  mfaEnabled?: InputMaybe<Scalars['Boolean']['input']>
  passwordExpirationEnabled?: InputMaybe<Scalars['Boolean']['input']>
  policyIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  position?: InputMaybe<Scalars['String']['input']>
}

/** update vehicle input */
export type GTUpdateVehicleInput = {
  /** documents */
  documents?: InputMaybe<GTDocumentsUpdateInput>
  /** driver IDs */
  driverIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** vehicle id */
  id: Scalars['ID']['input']
  /** make e.g. Ford */
  make?: InputMaybe<Scalars['String']['input']>
  /** model e.g. Focus */
  model?: InputMaybe<Scalars['String']['input']>
  /** vehicle name */
  name?: InputMaybe<Scalars['String']['input']>
  /** odometer reading */
  odometerReading?: InputMaybe<Scalars['Int']['input']>
  /** partner */
  partner?: InputMaybe<GTPartnerInput>
  photoPath?: InputMaybe<Scalars['String']['input']>
  /** registration plate */
  registrationPlate?: InputMaybe<Scalars['String']['input']>
  /** registration state code */
  registrationStateCode?: InputMaybe<Scalars['String']['input']>
  /** vehicle type */
  type?: InputMaybe<GTVehicleType>
  /** vehicle identification number */
  vin?: InputMaybe<Scalars['String']['input']>
  /** year e.g. 2015 */
  year?: InputMaybe<Scalars['Int']['input']>
}

export type GTUpsertPartnerCostScheduleInput = {
  cost: Scalars['Int']['input']
  dow: Array<GTPartnerCostScheduleDow>
  endAt?: InputMaybe<Scalars['Date']['input']>
  note: Scalars['String']['input']
  startAt: Scalars['Date']['input']
}

export type GTUpsertPartnerCostsInput = {
  routeServiceIDs: Array<Scalars['UUID']['input']>
  schedule: GTUpsertPartnerCostScheduleInput
  vehicleIDs: Array<Scalars['UUID']['input']>
}

/** user */
export type GTUser = {
  __typename?: 'User'
  /** companies */
  companies: Array<GTUserCompany>
  /**
   * company
   * @deprecated Use companies instead
   */
  company?: Maybe<GTUserCompany>
  /** email */
  email?: Maybe<Scalars['String']['output']>
  /** first name */
  firstName: Scalars['String']['output']
  /** user id */
  id: Scalars['ID']['output']
  /** indicates whether the user is active or not */
  isActive: Scalars['Boolean']['output']
  /** indicates whether the user is blocked or not */
  isBlocked: Scalars['Boolean']['output']
  /** last name */
  lastName: Scalars['String']['output']
  /** MFA enabled */
  mfaEnabled: Scalars['Boolean']['output']
  /** password expiration enabled */
  passwordExpirationEnabled: Scalars['Boolean']['output']
  /** permissions assigned to this user */
  permissions: Array<GTPermission>
  /** position */
  position?: Maybe<Scalars['String']['output']>
  /** roles assigned to this user */
  roles: Array<GTRole>
  /** site ID */
  siteID?: Maybe<Scalars['ID']['output']>
  /** username */
  username: Scalars['String']['output']
}

export const GTUserAccountGenderInput = {
  Female: 'female',
  Male: 'male',
  NotSharing: 'notSharing',
  Other: 'other'
} as const

export type GTUserAccountGenderInput = (typeof GTUserAccountGenderInput)[keyof typeof GTUserAccountGenderInput]
/** user autocomplete */
export type GTUserAutocomplete = {
  __typename?: 'UserAutocomplete'
  /** first name */
  firstName: Scalars['String']['output']
  /** user ID */
  id: Scalars['ID']['output']
  /** last name */
  lastName: Scalars['String']['output']
  /** site ID */
  siteID?: Maybe<Scalars['String']['output']>
  /** username */
  username: Scalars['String']['output']
}

export const GTUserAutocompleteType = {
  Base: 'base',
  Cashier: 'cashier',
  Crew: 'crew',
  Warehouse: 'warehouse'
} as const

export type GTUserAutocompleteType = (typeof GTUserAutocompleteType)[keyof typeof GTUserAutocompleteType]
/** company */
export type GTUserCompany = {
  __typename?: 'UserCompany'
  /** company id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
  /** policy role */
  policyRole?: Maybe<GTUserCompanyPolicyRole>
  /** vertical */
  vertical?: Maybe<GTCompanyV2Vertical>
}

export type GTUserCompanyPolicyRole = {
  __typename?: 'UserCompanyPolicyRole'
  /** policy role id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

export type GTUserSuggestion = {
  __typename?: 'UserSuggestion'
  firstName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  lastName: Scalars['String']['output']
  name: Scalars['String']['output']
  username: Scalars['String']['output']
}

export type GTUserSuggestionsInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
  siteIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  type?: InputMaybe<Scalars['String']['input']>
}

export type GTUserSuggestionsResponse = {
  __typename?: 'UserSuggestionsResponse'
  items: Array<GTUserSuggestion>
  total: Scalars['Long']['output']
}

export type GTUsersAutocompleteInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<GTUserAutocompleteType>
}

/** paginated users response */
export type GTUsersResponse = {
  __typename?: 'UsersResponse'
  /** users */
  items: Array<GTUser>
  /** total of users */
  total: Scalars['Int']['output']
}

export type GTValidateBusinessCustomersCsvInput = {
  /** The path of the CSV file */
  path: Scalars['String']['input']
}

export type GTValidateCouponsCsvInput = {
  path: Scalars['String']['input']
}

/** vehicle */
export type GTVehicle = {
  __typename?: 'Vehicle'
  /** company */
  company?: Maybe<GTVehicleCompany>
  /** documents */
  documents: Array<GTVehicleDocument>
  /** drivers */
  drivers: Array<GTVehicleDriver>
  /** vehicle id */
  id: Scalars['ID']['output']
  /** make e.g. Ford */
  make?: Maybe<Scalars['String']['output']>
  /** model e.g. Focus */
  model?: Maybe<Scalars['String']['output']>
  /** vehicle name */
  name: Scalars['String']['output']
  /** last odometer reading */
  odometerReading?: Maybe<GTVehicleOdometerReading>
  /** partner */
  partner: GTPartner
  /** photo path */
  photoPath?: Maybe<Scalars['String']['output']>
  /** registration plate */
  registrationPlate: Scalars['String']['output']
  /** registration state code */
  registrationStateCode: Scalars['String']['output']
  /** status */
  status: GTVehicleStatus
  /** vehicle template */
  template: GTVehicleVehicleTemplate
  /** vehicle type */
  type: GTVehicleType
  /** vehicle identification number */
  vin: Scalars['String']['output']
  /** year e.g. 2015 */
  year?: Maybe<Scalars['Int']['output']>
}

/** vehicle company */
export type GTVehicleCompany = {
  __typename?: 'VehicleCompany'
  /** company id */
  id: Scalars['ID']['output']
  /** company name */
  name: Scalars['String']['output']
}

export type GTVehicleDifference = {
  __typename?: 'VehicleDifference'
  new: GTChangeVehicle
  old: GTChangeVehicle
}

/** documents */
export type GTVehicleDocument = {
  __typename?: 'VehicleDocument'
  /** document category */
  category: GTDocumentCategory
  /** filename */
  filename: Scalars['String']['output']
  /** document id */
  id: Scalars['ID']['output']
  /** path */
  path: Scalars['String']['output']
}

export const GTVehicleDocumentCategoryInput = {
  Bills: 'BILLS',
  ExternalInspection: 'EXTERNAL_INSPECTION',
  InternalInspection: 'INTERNAL_INSPECTION',
  Photo: 'PHOTO',
  TuningService: 'TUNING_SERVICE',
  VerificationCertificate: 'VERIFICATION_CERTIFICATE'
} as const

export type GTVehicleDocumentCategoryInput =
  (typeof GTVehicleDocumentCategoryInput)[keyof typeof GTVehicleDocumentCategoryInput]
/** vehicle driver */
export type GTVehicleDriver = {
  __typename?: 'VehicleDriver'
  /** driver first name */
  firstName: Scalars['String']['output']
  /** driver id */
  id: Scalars['String']['output']
  /** driver last name */
  lastName: Scalars['String']['output']
  username: Scalars['String']['output']
}

/** odometer reading */
export type GTVehicleOdometerReading = {
  __typename?: 'VehicleOdometerReading'
  /** date of reading */
  readAt: Scalars['Time']['output']
  /** odometer reading */
  value: Scalars['Int']['output']
}

export const GTVehicleRegistrationCategoryInput = {
  DrivingPermit: 'DRIVING_PERMIT',
  Federal: 'FEDERAL',
  Local: 'LOCAL'
} as const

export type GTVehicleRegistrationCategoryInput =
  (typeof GTVehicleRegistrationCategoryInput)[keyof typeof GTVehicleRegistrationCategoryInput]
export const GTVehicleRegistrationTypeInput = {
  A: 'A',
  A1: 'A1',
  A2: 'A2',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  E1: 'E1'
} as const

export type GTVehicleRegistrationTypeInput =
  (typeof GTVehicleRegistrationTypeInput)[keyof typeof GTVehicleRegistrationTypeInput]
/** vehicle status */
export const GTVehicleStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  OutOfService: 'OUT_OF_SERVICE',
  Assigned: 'assigned',
  Unassigned: 'unassigned'
} as const

export type GTVehicleStatus = (typeof GTVehicleStatus)[keyof typeof GTVehicleStatus]
export type GTVehicleSuggestion = {
  __typename?: 'VehicleSuggestion'
  capacity: Scalars['Int']['output']
  externalID?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type GTVehicleSuggestionsInput = {
  companyID?: InputMaybe<Scalars['UUID']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>
  partnerID?: InputMaybe<Scalars['UUID']['input']>
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type GTVehicleSuggestionsResponse = {
  __typename?: 'VehicleSuggestionsResponse'
  items: Array<GTVehicleSuggestion>
  total: Scalars['Long']['output']
}

/** vehicle template */
export type GTVehicleTemplate = {
  __typename?: 'VehicleTemplate'
  /** capacity */
  capacity: Scalars['Int']['output']
  /** companies that use this template */
  companies: Array<GTVehicleTemplateCompany>
  /** template id */
  id: Scalars['ID']['output']
  /** template layout [floor][row][column] */
  layout: Array<Array<Array<GTVehicleTemplateCell>>>
  /** template name */
  name: Scalars['String']['output']
  /** vehicle type */
  type: GTVehicleType
  /** vehicles that use this template */
  vehicleIDs: Array<Scalars['ID']['output']>
}

export type GTVehicleTemplateAutocomplete = {
  __typename?: 'VehicleTemplateAutocomplete'
  /** vehicle template id */
  id: Scalars['ID']['output']
  /** name */
  name: Scalars['String']['output']
}

/** layout cell */
export type GTVehicleTemplateCell = {
  __typename?: 'VehicleTemplateCell'
  /** component */
  component: GTCellComponent
  /** label, used for seat number */
  label?: Maybe<Scalars['String']['output']>
  /** component properties */
  properties: Array<GTCellComponentProperty>
}

/** layout cell */
export type GTVehicleTemplateCellInput = {
  /** component */
  component: GTCellComponent
  /** label, used for seat number */
  label?: InputMaybe<Scalars['String']['input']>
  /** component properties */
  properties: Array<GTCellComponentProperty>
}

/** company */
export type GTVehicleTemplateCompany = {
  __typename?: 'VehicleTemplateCompany'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GTVehicleTemplatesAutocompleteInput = {
  capacity: Scalars['Int']['input']
  type: GTVehicleType
}

/** vehicle type */
export const GTVehicleType = {
  Bus: 'bus',
  Car: 'car',
  Van: 'van'
} as const

export type GTVehicleType = (typeof GTVehicleType)[keyof typeof GTVehicleType]
/** vehicle template */
export type GTVehicleVehicleTemplate = {
  __typename?: 'VehicleVehicleTemplate'
  /** capacity */
  capacity: Scalars['Int']['output']
  /** template id */
  id: Scalars['String']['output']
  /** template name */
  name: Scalars['String']['output']
}

export type GTVerifyMfaCodeInput = {
  code: Scalars['String']['input']
}

export type GTVerifyMfaCodeResponse = {
  __typename?: 'VerifyMfaCodeResponse'
  accessToken: Scalars['String']['output']
  passwordExpired: Scalars['Boolean']['output']
  refreshToken: Scalars['String']['output']
}

export type GTVerifyTripsInventoryRuleInput = {
  /** inventory rule id */
  id: Scalars['ID']['input']
}

/**
 * The Wallet type represents a single user's wallet,
 * including its balance and transactions.
 */
export type GTWallet = {
  __typename?: 'Wallet'
  /** The balance of the wallet, represented in cents. */
  balance: Scalars['Int']['output']
  /** The timestamp of when the wallet was created. */
  createdAt: Scalars['Time']['output']
  /** The unique ID of the wallet. */
  id: Scalars['ID']['output']
  /** The list of transactions associated with the wallet. */
  transactions: Array<GTWalletTransaction>
  /** The timestamp of when the wallet was last updated. */
  updatedAt: Scalars['Time']['output']
  /** The user related to this wallet. */
  user: GTWalletUser
}

/** Input for querying a specific wallet by ID. */
export type GTWalletInput = {
  /** The ID of the wallet to query. */
  id: Scalars['ID']['input']
}

/** The WalletTransaction type represents a single transaction made on a Wallet. */
export type GTWalletTransaction = {
  __typename?: 'WalletTransaction'
  /** The amount of the transaction, represented in cents. */
  amount: Scalars['Int']['output']
  /** The potential booking ID associated with the transaction. */
  bookingID?: Maybe<Scalars['ID']['output']>
  /** The timestamp of when the transaction was created. */
  createdAt: Scalars['Time']['output']
  /** The unique ID of the transaction. */
  id: Scalars['ID']['output']
  /** The potential payment ID associated with the transaction. */
  paymentID?: Maybe<Scalars['ID']['output']>
  /** The type of transaction made (deposit, withdrawal, transfer, etc.). */
  type: GTWalletTransactionType
}

export const GTWalletTransactionType = {
  Deposit: 'DEPOSIT',
  Unknown: 'UNKNOWN',
  Withdrawal: 'WITHDRAWAL'
} as const

export type GTWalletTransactionType = (typeof GTWalletTransactionType)[keyof typeof GTWalletTransactionType]
/** The WalletUser type represents a single user in the system. */
export type GTWalletUser = {
  __typename?: 'WalletUser'
  /** The email of the user. */
  email: Scalars['String']['output']
  /** The unique ID of the user. */
  id: Scalars['ID']['output']
  /** The phone number of the user. */
  phone: Scalars['String']['output']
  /** The full name of the user. */
  userFullName: Scalars['String']['output']
}

/** Input for querying wallets by associated user details. */
export type GTWalletsInput = {
  /** The email of the user to find associated wallets. */
  email?: InputMaybe<Scalars['String']['input']>
  /** The phone number of the user to find associated wallets. */
  phone?: InputMaybe<Scalars['String']['input']>
  /** The full name of the user to find associated wallets. */
  userFullName?: InputMaybe<Scalars['String']['input']>
}

/**
 * The response from querying for a list of wallets. Includes the list of wallets
 * and the total count of wallets.
 */
export type GTWalletsResponse = {
  __typename?: 'WalletsResponse'
  /** The specific wallets that match the query. */
  items: Array<GTWallet>
  /** The total number of wallets that match the query. */
  total: Scalars['Int']['output']
}

export const GTWeekday = {
  Friday: 'FRIDAY',
  Monday: 'MONDAY',
  Saturday: 'SATURDAY',
  Sunday: 'SUNDAY',
  Thursday: 'THURSDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY'
} as const

export type GTWeekday = (typeof GTWeekday)[keyof typeof GTWeekday]
export type GTActivateBookingClassInput = {
  id: Scalars['ID']['input']
}

/** activate item input */
export type GTActivateItemInput = {
  /** item id */
  ID: Scalars['ID']['input']
}

export type GTActivateSeasonEventInput = {
  id: Scalars['ID']['input']
}

/** activate route service input */
export type GTActivateServiceInput = {
  /** route service id */
  ID: Scalars['ID']['input']
}

/** activate site input */
export type GTActivateSiteInput = {
  /** site id */
  id: Scalars['ID']['input']
}

/** activate trip sale input */
export type GTActivateTripSaleInput = {
  /** trip id */
  id: Scalars['ID']['input']
}

/** activate user input */
export type GTActivateUserInput = {
  /** user id */
  ID: Scalars['ID']['input']
}

export type GTAddCampaignCouponSupportInteractionInput = {
  campaignID: Scalars['ID']['input']
  couponID: Scalars['ID']['input']
  interaction: GTCampaignCouponSupportInteractionInput
  subcampaignID?: InputMaybe<Scalars['ID']['input']>
}

/** company line specs input */
export type GTAddCompanyLineSpecInput = {
  /** company line id */
  companyLineID: Scalars['ID']['input']
  /** distance in meters */
  distance?: InputMaybe<Scalars['Int']['input']>
  /** duration in minutes */
  duration?: InputMaybe<Scalars['Int']['input']>
}

export type GTAddFareFamilyFaresResponse = {
  __typename?: 'addFareFamilyFaresResponse'
  fareIDs: Array<Scalars['ID']['output']>
}

export type GTAddSegmentCompanyLineSpecInput = {
  companyLineSpec: GTAddCompanyLineSpecInput
  id: Scalars['ID']['input']
}

/** address input. */
export type GTAddressInput = {
  /** city */
  city: Scalars['String']['input']
  /** country code in ISO_3166-1_alpha-2 format. */
  countryCode: Scalars['String']['input']
  /** line 1. */
  line1: Scalars['String']['input']
  /** line 2. optional. */
  line2?: InputMaybe<Scalars['String']['input']>
  /** state code in ISO_3166-2:MX format. */
  stateCode: Scalars['String']['input']
  /** zip code. */
  zipCode: Scalars['String']['input']
}

/** address update input. only the fields to change should be included. */
export type GTAddressUpdateInput = {
  /** city */
  city?: InputMaybe<Scalars['String']['input']>
  /** country code in ISO_3166-1_alpha-2 format. */
  countryCode?: InputMaybe<Scalars['String']['input']>
  /** line 1 */
  line1?: InputMaybe<Scalars['String']['input']>
  /** line 2 */
  line2?: InputMaybe<Scalars['String']['input']>
  /** state code in ISO_3166-2:MX format. */
  stateCode?: InputMaybe<Scalars['String']['input']>
  /** zipcode */
  zipCode?: InputMaybe<Scalars['String']['input']>
}

/** archive vehicle template input */
export type GTArchiveVehicleTemplateInput = {
  /** vehicle template id */
  id: Scalars['ID']['input']
}

export type GTBookingClassesInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
}

/** bookings dashboard input */
export type GTBookingsDashboardInput = {
  /** date in dd/mm/yyyy format */
  date?: InputMaybe<Scalars['String']['input']>
  /** timezone of the client (browser) */
  timezone?: InputMaybe<Scalars['String']['input']>
}

/** bookings query input */
export type GTBookingsQueryInput = {
  /** buyer phone */
  buyerPhone?: InputMaybe<Scalars['String']['input']>
  /** company Line ID */
  companyLineID?: InputMaybe<Scalars['ID']['input']>
  /** destination id */
  destination?: InputMaybe<Scalars['ID']['input']>
  /** buuyer's email */
  email?: InputMaybe<Scalars['String']['input']>
  /** email status */
  emailStatus?: InputMaybe<GTBookingEmailStatus>
  /** origin id */
  origin?: InputMaybe<Scalars['ID']['input']>
  /** purchase date range */
  purchaseDateRange?: InputMaybe<GTDateRangeInput>
  /** reservation number */
  reservation?: InputMaybe<Array<Scalars['String']['input']>>
  /** sales channel id */
  salesChannelID?: InputMaybe<Scalars['ID']['input']>
  /** sales channel type */
  salesChannelType?: InputMaybe<GTSalesChannelType>
  /** service codes */
  serviceCodes?: InputMaybe<Array<Scalars['String']['input']>>
  /** service id */
  serviceID?: InputMaybe<Scalars['ID']['input']>
  /** pos id */
  siteID?: InputMaybe<Scalars['ID']['input']>
  /** sorting date defaults to last 4 months if not provided */
  sortingDate?: InputMaybe<GTDateRangeInput>
  /** status */
  status?: InputMaybe<Array<Scalars['String']['input']>>
  /** timezone of the client (browser) */
  timezone?: InputMaybe<Scalars['String']['input']>
  /** travel date range */
  travelDateRange?: InputMaybe<GTDateRangeInput>
}

/** buyer input */
export type GTBuyerInput = {
  /** email */
  email?: InputMaybe<Scalars['String']['input']>
  /** first name */
  firstName?: InputMaybe<Scalars['String']['input']>
  /** last name */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** phone number */
  phone?: InputMaybe<Scalars['String']['input']>
}

export const GTCallStatus = {
  AttendanceConfirmed: 'attendanceConfirmed',
  BusyNumber: 'busyNumber',
  Cancellation: 'cancellation',
  HungUp: 'hungUp',
  NonExistentNumber: 'nonExistentNumber',
  ServiceChange: 'serviceChange',
  UnavailableNumber: 'unavailableNumber',
  Voicemail: 'voicemail',
  WrongNumber: 'wrongNumber'
} as const

export type GTCallStatus = (typeof GTCallStatus)[keyof typeof GTCallStatus]
/** cancel booking change input */
export type GTCancelBookingTripChangeInput = {
  /** booking id */
  id: Scalars['ID']['input']
  /** trip change id */
  tripChangeID: Scalars['ID']['input']
}

export type GTCancelChangeInput = {
  id: Scalars['ID']['input']
}

/** cancel tickets input */
export type GTCancelTicketsInput = {
  /** booking id */
  bookingID: Scalars['ID']['input']
  /** comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** payments */
  payments?: InputMaybe<Array<GTPaymentRefundInput>>
  /** cancelation reason */
  reason?: InputMaybe<GTBookingTicketCancelationReason>
  /** ticket IDs */
  ticketIDs: Array<Scalars['ID']['input']>
}

/** cancel trip input */
export type GTCancelTripInput = {
  /** trip cancellation category */
  category?: InputMaybe<GTCancellationCategory>
  /** trip cancellation comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** trip id */
  id: Scalars['ID']['input']
  /** trip cancellation subcategory */
  subcategory?: InputMaybe<GTCancellationSubcategory>
}

/** cancel multiple trips input */
export type GTCancelTripsInput = {
  /** trip cancellation category */
  category?: InputMaybe<GTCancellationCategory>
  /** trip cancellation comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** trip cancellation subcategory */
  subcategory?: InputMaybe<GTCancellationSubcategory>
  /** trip ids */
  tripIDs: Array<Scalars['ID']['input']>
}

export type GTCartActivityInput = {
  cartID: Scalars['ID']['input']
}

/** cashiers input */
export type GTCashiersQueryInput = {
  /** company id */
  companyID?: InputMaybe<Scalars['ID']['input']>
  /** if true, returns users assigned to a site. */
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>
}

/** change booking payment input */
export type GTChangeBookingPaymentInput = {
  /** booking id */
  bookingID: Scalars['ID']['input']
  /** payment */
  payment: GTChangePaymentInput
}

export type GTChangeBookingTripChangeTicketSeatInput = {
  /** booking ID */
  id: Scalars['ID']['input']
  /** seat ID */
  seatID: Scalars['ID']['input']
  /** ticket ID */
  ticketID: Scalars['ID']['input']
  /** tripChange ID */
  tripChangeID: Scalars['ID']['input']
}

/** change buyer input */
export type GTChangeBuyerInput = {
  /** booking id */
  bookingID: Scalars['ID']['input']
  /** buyer */
  buyer: GTBuyerInput
}

export type GTChangeFareFamilyFaresFareInput = {
  basePrice?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  isActive: Scalars['Boolean']['input']
  /** deprecated in favor of basePrice */
  total?: InputMaybe<Scalars['Int']['input']>
}

export type GTChangeFareFamilyFaresInput = {
  fares: Array<GTChangeFareFamilyFaresFareInput>
  /** fare family id */
  id: Scalars['ID']['input']
}

/** change payment input */
export type GTChangePaymentInput = {
  /** id */
  id: Scalars['ID']['input']
  /** last 4 */
  last4?: InputMaybe<Scalars['String']['input']>
  /** payment method */
  method: GTPaymentMethod
  /** payment processor */
  processor?: InputMaybe<GTPaymentProcessor>
}

/** change site input */
export type GTChangeSiteInput = {
  /** the address of the site. */
  address?: InputMaybe<GTAddressUpdateInput>
  /** site id. */
  id: Scalars['ID']['input']
  /** the name of the site. */
  name?: InputMaybe<Scalars['String']['input']>
  /** settings for this site. */
  settings?: InputMaybe<GTChangeSiteSettingsInput>
  /** the type of the site. */
  type?: InputMaybe<GTSiteType>
  /** users assigned to this site. */
  userIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTChangeSiteKreditsSettingsInput = {
  canTopUp?: InputMaybe<Scalars['Boolean']['input']>
}

/** change site's settings input */
export type GTChangeSiteSettingsInput = {
  /** defines if the ticket price can be modified by the user */
  canChangePrice?: InputMaybe<Scalars['Boolean']['input']>
  /** defines if the site can reserve tickets */
  canReserveTickets?: InputMaybe<Scalars['Boolean']['input']>
  /** default origin place for this site. */
  defaultOriginID?: InputMaybe<Scalars['ID']['input']>
  /** defines if a site can recive mixed payments */
  enableMixedPayments?: InputMaybe<Scalars['Boolean']['input']>
  /** enabled company line IDs */
  enabledCompanyLineIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** physical printer present in site. */
  hasPrinter?: InputMaybe<Scalars['Boolean']['input']>
  /** defines if the site can see the transactions report */
  hideTransactionsReport?: InputMaybe<Scalars['Boolean']['input']>
  /** kredits settings */
  kredits?: InputMaybe<GTChangeSiteKreditsSettingsInput>
  /** defines if the passenger name is required */
  passengerNameRequired?: InputMaybe<Scalars['Boolean']['input']>
  /** payment methods allowed at shis site. */
  payment?: InputMaybe<Array<GTSiteSettingsPaymentMethod>>
  /** minutes before departure to close sales. */
  saleClosingThreshold?: InputMaybe<Scalars['Int']['input']>
  /** snacks settings */
  snacks?: InputMaybe<GTChangeSiteSnacksSettingsInput>
}

export type GTChangeSiteSnacksSettingsInput = {
  canSell?: InputMaybe<Scalars['Boolean']['input']>
  showMinPrice?: InputMaybe<Scalars['Boolean']['input']>
  tripRequired?: InputMaybe<Scalars['Boolean']['input']>
}

/** change ticket passenger input */
export type GTChangeTicketsPassengerInput = {
  /** booking id */
  bookingID: Scalars['ID']['input']
  /** ticket id */
  tickets: Array<GTChangeTicketsPassengerTicketsInput>
}

export type GTChangeTicketsPassengerTicketsInput = {
  /** ticket id */
  id: Scalars['ID']['input']
  /** passenger */
  passenger: GTPassengerInput
}

export type GTChangeTripBookingSeatBookingInput = {
  /** booking id */
  id: Scalars['ID']['input']
  /** id of the seat to be added */
  newSeatID: Scalars['ID']['input']
  /** id of the seat to be removed */
  oldSeatID: Scalars['ID']['input']
  /** segment id */
  segmentID: Scalars['ID']['input']
}

export type GTChangeTripBookingSeatInput = {
  /** booking */
  booking: GTChangeTripBookingSeatBookingInput
  /** trip id */
  id: Scalars['ID']['input']
}

export type GTChangesQueryInput = {
  category: GTChangeCategory
  companyID?: InputMaybe<Scalars['ID']['input']>
  dateRange?: InputMaybe<GTDateRangeInput>
  id?: InputMaybe<Scalars['ID']['input']>
  serviceCode?: InputMaybe<Scalars['String']['input']>
  timezone?: InputMaybe<Scalars['String']['input']>
  types?: InputMaybe<Array<GTChangeType>>
  userID?: InputMaybe<Scalars['UUID']['input']>
}

/** city created payload */
export type GTCityCreatedPayload = {
  __typename?: 'cityCreatedPayload'
  /** place */
  place?: Maybe<GTPlace>
}

/** companies query input */
export type GTCompaniesQueryInput = {
  /** limit the number of results. defaults to 10. */
  limit?: InputMaybe<Scalars['Int']['input']>
}

/** company line query input */
export type GTCompanyLineQueryInput = {
  /** company line id */
  ID: Scalars['ID']['input']
}

/** company line specs input */
export type GTCompanyLineSpecInput = {
  /** company line id */
  companyLineID?: InputMaybe<Scalars['ID']['input']>
  /** distance in meters */
  distance?: InputMaybe<Scalars['Int']['input']>
  /** duration in minutes */
  duration?: InputMaybe<Scalars['Int']['input']>
  /** real distance in meters */
  realDistance?: InputMaybe<Scalars['Int']['input']>
}

/** company lines query input */
export type GTCompanyLinesQueryInput = {
  /** company id */
  companyID?: InputMaybe<Scalars['ID']['input']>
  /** limit the number of results. defaults to 10. */
  limit?: InputMaybe<Scalars['Int']['input']>
}

/** company query input */
export type GTCompanyQueryInput = {
  /** company id */
  id: Scalars['ID']['input']
}

/** paginated company roles response */
export type GTCompanyRolesResponse = {
  __typename?: 'companyRolesResponse'
  /** company roles */
  items: Array<GTCompanyRole>
  /** total of company roles */
  total: Scalars['Int']['output']
}

/** confirm booking payment on site input */
export type GTConfirmBookingPaymentOnSiteInput = {
  /** booking id */
  bookingID: Scalars['ID']['input']
  /** charge id to assign to the payment confirmation */
  chargeID?: InputMaybe<Scalars['String']['input']>
  /** last 4 digits of the card */
  last4?: InputMaybe<Scalars['String']['input']>
  /** payment method */
  method: GTPaymentMethod
  /** payment processor */
  processor?: InputMaybe<GTPaymentProcessor>
  /** ticket ids */
  ticketIDs: Array<Scalars['ID']['input']>
}

export type GTConfirmBookingTripChangesInput = {
  /** booking id */
  id: Scalars['ID']['input']
  /** tripChange IDs */
  tripChangeIDs: Array<Scalars['ID']['input']>
}

export type GTConfirmBookingTripChangesResponse = {
  __typename?: 'confirmBookingTripChangesResponse'
  fail: Array<Scalars['ID']['output']>
  success: Array<Scalars['ID']['output']>
}

export type GTCreateBookingClassInput = {
  companyID: Scalars['ID']['input']
  name: Scalars['String']['input']
  priority: Scalars['Int']['input']
  promo: Scalars['Boolean']['input']
}

/** create city input */
export type GTCreateCityInput = {
  /** city code */
  code: Scalars['String']['input']
  /** country code */
  countryCode?: InputMaybe<Scalars['String']['input']>
  /** name */
  name: Scalars['String']['input']
  /** unique human readable identifier for the place */
  param: Scalars['String']['input']
  /** state code in ISO_3166-2:MX format. */
  stateCode: Scalars['String']['input']
  /** tags */
  tags: Array<Scalars['String']['input']>
  /** timezone */
  timezone: Scalars['String']['input']
}

/** create company input */
export type GTCreateCompanyInput = {
  /** address */
  address: GTAddressInput
  /** comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** domains */
  domains: Array<Scalars['String']['input']>
  /** name */
  name: Scalars['String']['input']
  /** phone number */
  phone?: InputMaybe<Scalars['String']['input']>
  /** company policy role ID. optional. */
  policyRoleID?: InputMaybe<Scalars['String']['input']>
}

/** create company line inpue */
export type GTCreateCompanyLineInput = {
  /** amenities */
  amenities: Array<GTAmenity>
  /** class */
  class: GTClass
  /** id of the company this line belongs to */
  companyID: Scalars['ID']['input']
  /** logoPath */
  logoPath: Scalars['String']['input']
  /** name */
  name: Scalars['String']['input']
  /** refund rules */
  refundRules: Scalars['String']['input']
  /** type */
  type: GTCompanyLineType
}

export type GTCreateFareFamiliesInput = {
  fareFamilies: Array<GTFareFamilyInput>
}

/** create place input */
export type GTCreatePlaceInput = {
  /** address, optional */
  address?: InputMaybe<GTAddressInput>
  /** amenities */
  amenities?: InputMaybe<Array<GTPlaceAmenity>>
  /** area */
  area?: InputMaybe<Scalars['String']['input']>
  /** place code */
  code: Scalars['String']['input']
  /** directions */
  directions?: InputMaybe<Scalars['String']['input']>
  /** geolocation, opptional */
  location: GTLocationInput
  /** name */
  name: Scalars['String']['input']
  /** unique human readable identifier for the place */
  param: Scalars['String']['input']
  /** id of the city this place is in */
  parentID: Scalars['String']['input']
  /** tags */
  tags: Array<Scalars['String']['input']>
  /** place type */
  type: GTPlaceType
}

/** create policy compay role input */
export type GTCreatePolicyCompanyRoleInput = {
  /** conditions */
  conditions: Array<Scalars['String']['input']>
  /** company role name */
  name: Scalars['String']['input']
  /** policies to assign to the company role */
  policyIDs: Array<Scalars['ID']['input']>
}

/** create policy permission input */
export type GTCreatePolicyPermissionInput = {
  /** permission actions */
  actions: Array<Scalars['String']['input']>
  /** permission name */
  name: Scalars['String']['input']
}

/** create policy private role input */
export type GTCreatePolicyPrivateRoleInput = {
  /** company this private role belongs to */
  companyID: Scalars['ID']['input']
  /** provate role name */
  name: Scalars['String']['input']
  /** policies to assign to the private role */
  policyIDs: Array<Scalars['ID']['input']>
}

/** create policy role input */
export type GTCreatePolicyRoleInput = {
  /** role name */
  name: Scalars['String']['input']
  /** role's policies */
  policyIDs: Array<Scalars['ID']['input']>
}

export type GTCreateSeasonEventInput = {
  activePeriods: Array<GTActivePeriodInput>
  companyID: Scalars['ID']['input']
  name: Scalars['String']['input']
  routeServiceIDs: Array<Scalars['ID']['input']>
  segmentIDs: Array<Scalars['ID']['input']>
  type: GTSeasonEventType
}

/** create route service input */
export type GTCreateServiceInput = {
  /** active period */
  activePeriod?: InputMaybe<Scalars['Int']['input']>
  /** business clients */
  businessClients?: InputMaybe<Array<GTCreateRouteServiceBusinessClientInput>>
  /** code */
  code?: InputMaybe<Scalars['String']['input']>
  /** company line id */
  companyLineID: Scalars['ID']['input']
  /** confirmation modalitites v2 */
  confirmationModalitiesV2?: InputMaybe<Array<GTRouteServiceConfirmationModalitiesV2Input>>
  /** if present, when the trips scheduling ends. Otherwise, trips will be continuously scheduled. */
  endAt?: InputMaybe<Scalars['String']['input']>
  /** idicates whether the route service has selectable seats or not */
  hasSelectableSeats?: InputMaybe<Scalars['Boolean']['input']>
  /** has tolls */
  hasTolls?: Scalars['Boolean']['input']
  /** is on guard */
  isOnGuard?: Scalars['Boolean']['input']
  /** is pet friendly */
  isPetFriendly?: Scalars['Boolean']['input']
  /** is private */
  isPrivate?: Scalars['Boolean']['input']
  /** itineraries schedule */
  itinerariesSchedule?: InputMaybe<Array<GTCreateRouteServiceItineraryScheduleInput>>
  /** limits per passenger type */
  limits: Array<GTRouteServiceLimitInput>
  /** peaks */
  peaks?: InputMaybe<Array<GTRouteServicePeakInput>>
  /** price per trip */
  pricePerTrip?: InputMaybe<Scalars['Int']['input']>
  /** sales channels ids */
  salesChannelIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** when the trips scheduling begins */
  startAt: Scalars['String']['input']
  /** crew user ids */
  userIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** vehicles schedule */
  vehiclesSchedule?: InputMaybe<Array<GTCreateRouteServiceVehicleScheduleInput>>
}

/** create site input */
export type GTCreateSiteInput = {
  /** the address of the site. optional. */
  address?: InputMaybe<GTAddressInput>
  /** company that this site belongs to. */
  companyID: Scalars['ID']['input']
  /** the name of the site. */
  name: Scalars['String']['input']
  /**
   * settings for this site.
   * defaults to {
   * 	hasPrinter: false,
   * 	payment: [card, cash],
   * 	saleClosingThreshold: 5,
   * 	canChangePrice: false,
   * 	enabledCompanyLineIDs: [],
   * 	passengerNameRequired: true,
   * 	snacks: {
   * 		canSell: false,
   * 		showMinPrice: false,
   * 		tripRequired: false
   * 	}
   * 	canTopUpKredits: false,
   * }
   */
  settings?: InputMaybe<GTSiteSettingsInput>
  /** the type of the site. */
  type: GTSiteType
  /** users assigned to this site. */
  userIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** create user input */
export type GTCreateUserInput = {
  /** companies the user belongs to */
  companyIDs?: InputMaybe<Array<Scalars['UUID']['input']>>
  /** email */
  email?: InputMaybe<Scalars['String']['input']>
  /** first name */
  firstName: Scalars['String']['input']
  /** last name */
  lastName: Scalars['String']['input']
  /** MFA enabled */
  mfaEnabled?: InputMaybe<Scalars['Boolean']['input']>
  /** password (confirmation must be done by the client) */
  password: Scalars['String']['input']
  /** password expiration enabled */
  passwordExpirationEnabled?: InputMaybe<Scalars['Boolean']['input']>
  /** policies to assign to this user */
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** position */
  position: Scalars['String']['input']
  /** username */
  username: Scalars['String']['input']
}

/** create vehicle template input */
export type GTCreateVehicleTemplateInput = {
  /** company id */
  companyID?: InputMaybe<Scalars['ID']['input']>
  /** template layout [floor][row][column] */
  layout: Array<Array<Array<GTVehicleTemplateCellInput>>>
  /** template name */
  name: Scalars['String']['input']
  /** vehicle type */
  type: GTVehicleType
}

export type GTDateRangeInput = {
  /** date in dd/mm/yyyy format */
  from?: InputMaybe<Scalars['String']['input']>
  /** date in dd/mm/yyyy format */
  to?: InputMaybe<Scalars['String']['input']>
}

export type GTDeactivateBookingClassInput = {
  id: Scalars['ID']['input']
}

/** deactivate item input */
export type GTDeactivateItemInput = {
  /** item id */
  ID: Scalars['ID']['input']
}

export type GTDeactivateSeasonEventInput = {
  id: Scalars['ID']['input']
}

/** deactivate route service input */
export type GTDeactivateServiceInput = {
  /** route service id */
  ID: Scalars['ID']['input']
}

/** deactivate site input */
export type GTDeactivateSiteInput = {
  /** site id */
  id: Scalars['ID']['input']
}

/** deactivate trip input */
export type GTDeactivateTripInput = {
  /** trip id */
  id: Scalars['ID']['input']
  /** trip deactivation reason */
  reason?: InputMaybe<GTDeactivationReason>
}

/** deactivate trip sale input */
export type GTDeactivateTripSaleInput = {
  /** trip id */
  id: Scalars['ID']['input']
}

/** deactivate user input */
export type GTDeactivateUserInput = {
  /** user id */
  ID: Scalars['ID']['input']
}

/** change documents input */
export type GTDocumentsUpdateInput = {
  /** documents to be added */
  add?: InputMaybe<Array<GTVehicleDocumentInput>>
  /** id of documents to be removed */
  remove?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTDrawersInput = {
  /** company IDs */
  companyIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** start date in dd/mm/yyyy format */
  from?: InputMaybe<Scalars['String']['input']>
  /** site IDs */
  siteIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** status */
  status?: InputMaybe<GTDrawerStatus>
  /** client timezone */
  timezone?: InputMaybe<Scalars['String']['input']>
  /** end date in dd/mm/yyyy format */
  to?: InputMaybe<Scalars['String']['input']>
  /** user IDs */
  userIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** drivers input */
export type GTDriversInput = {
  /** company id */
  companyID?: InputMaybe<Scalars['ID']['input']>
}

export type GTFareFamiliesInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  destinationCityID?: InputMaybe<Scalars['ID']['input']>
  originCityID?: InputMaybe<Scalars['ID']['input']>
  segmentID?: InputMaybe<Scalars['ID']['input']>
}

export type GTFareFamilyInput = {
  companyID: Scalars['ID']['input']
  familyName: Scalars['String']['input']
  fares: Array<GTFareFamilyFareInput>
  segmentID: Scalars['ID']['input']
}

/** itinerary input */
export type GTItineraryInput = {
  /** arrival time */
  arrival?: InputMaybe<GTItineraryTimeInput>
  /** departure time */
  departure?: InputMaybe<GTItineraryTimeInput>
  /** place id */
  placeID: Scalars['ID']['input']
}

/** itinerary time input */
export type GTItineraryTimeInput = {
  /** an integer representing how many days after departure the stop takes place. ex. 0 = same day, 1 = next day */
  day: Scalars['Int']['input']
  /** time in hh:mm format, in the timezone of the place */
  time: Scalars['String']['input']
}

/** location input */
export type GTLocationInput = {
  /** latitude */
  latitude: Scalars['String']['input']
  /** longitude */
  longitude: Scalars['String']['input']
}

/** login input */
export type GTLoginInput = {
  /** password */
  password: Scalars['String']['input']
  /** username */
  username: Scalars['String']['input']
}

/** login response */
export type GTLoginResponse = {
  __typename?: 'loginResponse'
  /** access token */
  accessToken: Scalars['String']['output']
  /** MFARequired */
  mfaRequired: Scalars['Boolean']['output']
  /** passwordExpired */
  passwordExpired: Scalars['Boolean']['output']
  /** refresh token */
  refreshToken: Scalars['String']['output']
}

/** new fare input */
export type GTNewFareInput = {
  /** passenger type this fare applies to */
  passengerType: GTPassengerType
  /** subtotal in cents */
  subtotal: Scalars['Int']['input']
  /** VAT in cents */
  vat: Scalars['Int']['input']
}

export const GTNotificationChannel = {
  Email: 'EMAIL',
  Phone: 'PHONE',
  SocialMedia: 'SOCIAL_MEDIA',
  Whatsapp: 'WHATSAPP'
} as const

export type GTNotificationChannel = (typeof GTNotificationChannel)[keyof typeof GTNotificationChannel]
/** passenger input */
export type GTPassengerInput = {
  /** first name */
  firstName: Scalars['String']['input']
  /** last name */
  lastName: Scalars['String']['input']
  /** type id */
  typeID?: InputMaybe<Scalars['ID']['input']>
}

/** permissions query input */
export type GTPermissionsQueryInput = {
  /** filter by company */
  companyID?: InputMaybe<Scalars['ID']['input']>
}

/** place created payload */
export type GTPlaceCreatedPayload = {
  __typename?: 'placeCreatedPayload'
  /** place */
  place?: Maybe<GTPlace>
}

/** place query input */
export type GTPlaceQueryInput = {
  /** place id */
  id?: InputMaybe<Scalars['ID']['input']>
  /** unique human readable identifier for the place */
  param?: InputMaybe<Scalars['String']['input']>
}

/** places query input */
export type GTPlacesQueryInput = {
  /** limit the number of results. defaults to 10. */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** a string to filter the places against. */
  pattern?: InputMaybe<Scalars['String']['input']>
}

/** prices query input */
export type GTPricesQueryInput = {
  /** limit the number of results. defaults to 10. */
  limit?: InputMaybe<Scalars['Int']['input']>
}

/** refresh session input */
export type GTRefreshSessionInput = {
  /** refresh token */
  refreshToken: Scalars['String']['input']
}

/** register vehicle input */
export type GTRegisterVehicleInput = {
  /** company id */
  companyID?: InputMaybe<Scalars['String']['input']>
  /** documents */
  documents?: InputMaybe<Array<GTVehicleDocumentInput>>
  /** driver IDs */
  driverIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** make e.g. Ford */
  make?: InputMaybe<Scalars['String']['input']>
  /** model e.g. Focus */
  model?: InputMaybe<Scalars['String']['input']>
  /** vehicle name */
  name: Scalars['String']['input']
  /** odometer reading */
  odometerReading?: InputMaybe<Scalars['Int']['input']>
  /** partner */
  partner?: InputMaybe<GTPartnerInput>
  photoPath?: InputMaybe<Scalars['String']['input']>
  /** registration plate */
  registrationPlate: Scalars['String']['input']
  /** registration state code */
  registrationStateCode: Scalars['String']['input']
  /** template id */
  templateID: Scalars['ID']['input']
  /** vehicle type */
  type: GTVehicleType
  /** vehicle identification number */
  vin: Scalars['String']['input']
  /** year e.g. 2015 */
  year?: InputMaybe<Scalars['Int']['input']>
}

/** role query input */
export type GTRoleQueryInput = {
  /** role id */
  id: Scalars['ID']['input']
}

/** roles query input */
export type GTRolesQueryInput = {
  /** filter by company */
  companyID?: InputMaybe<Scalars['ID']['input']>
}

/** paginated roles response */
export type GTRolesResponse = {
  __typename?: 'rolesResponse'
  /** roles */
  items: Array<GTRole>
  /** total of roles */
  total: Scalars['Int']['output']
}

/** route query input */
export type GTRouteQueryInput = {
  /** route id */
  ID?: InputMaybe<Scalars['ID']['input']>
  /** company id */
  companyID?: InputMaybe<Scalars['ID']['input']>
  /** route stops */
  stopIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTRouteServiceLimitInput = {
  /** limit key */
  key: Scalars['String']['input']
  /** limit type */
  type: GTLimitType
  /** limit value */
  value?: InputMaybe<Scalars['Int']['input']>
}

export type GTRoutesQueryInput = {
  destinationID?: InputMaybe<Scalars['ID']['input']>
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>
  originID?: InputMaybe<Scalars['ID']['input']>
  routeServiceCode?: InputMaybe<Scalars['String']['input']>
}

export type GTSalesQueryInput = {
  cartID?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  purchaseDateRange?: InputMaybe<GTDateRangeInput>
  sellerID?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type GTSeasonEventInput = {
  id: Scalars['ID']['input']
}

export type GTSeatsTemplateInput = {
  segmentID: Scalars['ID']['input']
  tripID: Scalars['ID']['input']
}

/** segment query input */
export type GTSegmentQueryInput = {
  /** segment id */
  ID: Scalars['ID']['input']
}

/** segments between cities input */
export type GTSegmentsBetweenCitiesInput = {
  /** company id */
  companyID?: InputMaybe<Scalars['ID']['input']>
  /** destination city id */
  destinationID: Scalars['ID']['input']
  /** origin city id */
  originID: Scalars['ID']['input']
}

/** segmemts query input */
export type GTSegmentsQueryInput = {
  /** company id */
  companyID?: InputMaybe<Scalars['UUID']['input']>
  /** company line ids */
  companyLineIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** destination id */
  destinationID?: InputMaybe<Scalars['ID']['input']>
  /** show only segments that have duration set for all its company line specs */
  hasDuration?: InputMaybe<Scalars['Boolean']['input']>
  /** show only segments that have fares set for all its company line specs */
  hasPricing?: InputMaybe<Scalars['Boolean']['input']>
  /** limit the number of results. defaults to 10. */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** origin id */
  originID?: InputMaybe<Scalars['ID']['input']>
  /** filter by route id */
  routeID?: InputMaybe<Scalars['ID']['input']>
}

/** send tickets input */
export type GTSendTicketsInput = {
  /** booking id */
  bookingID: Scalars['ID']['input']
  /** emails to which the tickets will be sent */
  emails: Array<Scalars['String']['input']>
}

/** route services query input */
export type GTServicesQueryInput = {
  /** confirmation modalities */
  confirmationModalities?: InputMaybe<Array<Scalars['String']['input']>>
  /** filter by destination */
  destinationID?: InputMaybe<Scalars['ID']['input']>
  /** dow */
  dow?: InputMaybe<Array<GTDayOfWeek>>
  /** filter by status */
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  /** filter by origin */
  originID?: InputMaybe<Scalars['ID']['input']>
  /** filter by route */
  routeID?: InputMaybe<Scalars['ID']['input']>
  /** filter by segments ids */
  segmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** vehicle id */
  vehicleID?: InputMaybe<Scalars['ID']['input']>
}

export type GTSiteKreditsSettingsInput = {
  canTopUp: Scalars['Boolean']['input']
}

/** site input */
export type GTSiteQueryInput = {
  /** site id */
  id: Scalars['ID']['input']
}

/** site's settings input */
export type GTSiteSettingsInput = {
  /** defines if the ticket price can be modified by the user */
  canChangePrice: Scalars['Boolean']['input']
  /** defines if the site can reserve tickets */
  canReserveTickets: Scalars['Boolean']['input']
  /** default origin place for this site. */
  defaultOriginID?: InputMaybe<Scalars['ID']['input']>
  /** defines if a site can recive mixed payments */
  enableMixedPayments: Scalars['Boolean']['input']
  /** enabled company line IDs */
  enabledCompanyLineIDs: Array<Scalars['ID']['input']>
  /** physical printer present in site. */
  hasPrinter: Scalars['Boolean']['input']
  /** defines if the site can see the transactions report */
  hideTransactionsReport: Scalars['Boolean']['input']
  /** kredits settings */
  kredits: GTSiteKreditsSettingsInput
  /** defines if the passenger name is required */
  passengerNameRequired?: InputMaybe<Scalars['Boolean']['input']>
  /** payment methods allowed at shis site. */
  payment?: InputMaybe<Array<GTSiteSettingsPaymentMethod>>
  /** minutes before departure to close sales. */
  saleClosingThreshold: Scalars['Int']['input']
  /** snacks settings */
  snacks: GTSiteSnacksSettingsInput
}

export type GTSiteSnacksSettingsInput = {
  canSell: Scalars['Boolean']['input']
  showMinPrice: Scalars['Boolean']['input']
  tripRequired: Scalars['Boolean']['input']
}

export type GTSitesDashboardInput = {
  /** company IDs */
  companyIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** start date in dd/mm/yyyy format */
  from?: InputMaybe<Scalars['String']['input']>
  /** site IDs */
  siteIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** client timezone */
  timezone?: InputMaybe<Scalars['String']['input']>
  /** end date in dd/mm/yyyy format */
  to?: InputMaybe<Scalars['String']['input']>
}

/** sites query input */
export type GTSitesQueryInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<GTSiteType>
  userID?: InputMaybe<Scalars['ID']['input']>
  withoutUser?: InputMaybe<Scalars['Boolean']['input']>
}

/** start booking change input */
export type GTStartBookingTripChangeInput = {
  /** booking id */
  id: Scalars['ID']['input']
  /** trip change */
  tripChange: GTTripChangeInput
}

/** start move booking change input */
export type GTStartMoveBookingTripChangeInput = {
  /** booking id */
  id: Scalars['ID']['input']
  /** trip change */
  tripChange: GTTripChangeMoveInput
}

export const GTSubcampaignStatus = {
  Created: 'CREATED',
  Sent: 'SENT'
} as const

export type GTSubcampaignStatus = (typeof GTSubcampaignStatus)[keyof typeof GTSubcampaignStatus]
/** template query input */
export type GTTemplateQueryInput = {
  /** vehicle template id */
  id: Scalars['ID']['input']
}

/** templates query input */
export type GTTemplatesQueryInput = {
  /** capacity */
  capacity?: InputMaybe<Scalars['Int']['input']>
  /** vehicle type */
  type?: InputMaybe<GTVehicleType>
}

export type GTTicketsInsuranceInput = {
  bookingID?: InputMaybe<Scalars['ID']['input']>
  certificateIDs?: InputMaybe<Array<Scalars['String']['input']>>
  hasConflict?: InputMaybe<Scalars['Boolean']['input']>
  statusV2?: InputMaybe<Array<GTAncillaryStatusInput>>
  timezone?: InputMaybe<Scalars['String']['input']>
  travelDateRange?: InputMaybe<GTDateRangeInput>
}

export type GTTripBookingsQueryInput = {
  tripID: Scalars['ID']['input']
}

/** trip change input */
export type GTTripChangeInput = {
  /** new trip id */
  newTripID: Scalars['ID']['input']
  /** old trip id */
  oldTripID: Scalars['ID']['input']
}

/** trip change move input */
export type GTTripChangeMoveInput = {
  /** new trip */
  newTrip: GTTripChangeMoveTripInput
  /** old trip id */
  oldTripID: Scalars['ID']['input']
}

/** trip change move trip input */
export type GTTripChangeMoveTripInput = {
  /** trip id */
  id: Scalars['ID']['input']
  /** segment id */
  segmentID: Scalars['ID']['input']
}

/** trip passengers input */
export type GTTripPassengersInput = {
  /** trip id */
  id: Scalars['ID']['input']
}

/** trip query input */
export type GTTripQueryInput = {
  id: Scalars['ID']['input']
}

export type GTTripSegmentsQueryInput = {
  departureDate: Scalars['String']['input']
  destinationID: Scalars['ID']['input']
  originID: Scalars['ID']['input']
  passengerTypes: Array<GTTripSegmentsPassengerTypeInput>
  salesChannelID: Scalars['ID']['input']
  vertical?: GTTripSegmentVerticalInput
}

/** trips query input */
export type GTTripsQueryInput = {
  /** route service business client ID */
  businessClientID?: InputMaybe<Scalars['UUID']['input']>
  /** route service business client business site ID */
  businessSiteID?: InputMaybe<Scalars['UUID']['input']>
  /** company ID */
  companyID?: InputMaybe<Scalars['ID']['input']>
  /** company line ID */
  companyLineID?: InputMaybe<Scalars['ID']['input']>
  /** minimum range of departure date, in dd/mm/yyyy format */
  departureDateFrom: Scalars['String']['input']
  /** maximum range of departure date, in dd/mm/yyyy format */
  departureDateTo: Scalars['String']['input']
  /** destinationCityID */
  destinationCityID?: InputMaybe<Scalars['ID']['input']>
  /** destination ID */
  destinationID?: InputMaybe<Scalars['ID']['input']>
  /** driver ID */
  driverID?: InputMaybe<Scalars['ID']['input']>
  /** has subcampaigns */
  hasSubcampaigns?: InputMaybe<Scalars['Boolean']['input']>
  /** array of trip IDs (could be the fisrt 8 characters or the full ID) */
  ids?: InputMaybe<Array<Scalars['String']['input']>>
  /** originCityID */
  originCityID?: InputMaybe<Scalars['ID']['input']>
  /** origin ID */
  originID?: InputMaybe<Scalars['ID']['input']>
  /** array of partner ids */
  partnerIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  /** route ID */
  routeID?: InputMaybe<Scalars['UUID']['input']>
  /** route name */
  routeName?: InputMaybe<Scalars['String']['input']>
  /** sale status */
  saleStatus?: InputMaybe<GTTripSaleStatus>
  /** service codes */
  serviceCodes?: InputMaybe<Array<Scalars['String']['input']>>
  /** status */
  status?: InputMaybe<GTTripStatus>
  /** timezone of the client (browser) */
  timezone: Scalars['String']['input']
  /** vehicle ID */
  vehicleID?: InputMaybe<Scalars['ID']['input']>
}

/** update city input */
export type GTUpdateCityInput = {
  /** city id */
  id: Scalars['ID']['input']
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** unique human readable identifier for the place */
  param?: InputMaybe<Scalars['String']['input']>
  /** state code in ISO_3166-2:MX format. */
  stateCode?: InputMaybe<Scalars['String']['input']>
  /** tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  /** timezone */
  timezone?: InputMaybe<Scalars['String']['input']>
}

/** update company input. only the fields to change should be included. */
export type GTUpdateCompanyInput = {
  /** company id */
  ID: Scalars['ID']['input']
  /** address */
  address?: InputMaybe<GTAddressUpdateInput>
  /** comments */
  comments?: InputMaybe<Scalars['String']['input']>
  /** domains */
  domains?: InputMaybe<Array<Scalars['String']['input']>>
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** phone */
  phone?: InputMaybe<Scalars['String']['input']>
  /** company policy role id */
  policyRoleID?: InputMaybe<Scalars['String']['input']>
}

/** update company line input. only the fields to change should be included. */
export type GTUpdateCompanyLineInput = {
  /** amenities */
  amenities?: InputMaybe<Array<GTAmenity>>
  /** class */
  class?: InputMaybe<GTClass>
  /** company line id */
  id: Scalars['ID']['input']
  /** logoPath */
  logoPath?: InputMaybe<Scalars['String']['input']>
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** refund rules */
  refundRules?: InputMaybe<Scalars['String']['input']>
}

/** update fare input */
export type GTUpdateFareInput = {
  /** fare id */
  id: Scalars['ID']['input']
  /** subtotal in cents */
  subtotal?: InputMaybe<Scalars['Int']['input']>
  /** VAT in cents */
  vat?: InputMaybe<Scalars['Int']['input']>
}

/** update place input */
export type GTUpdatePlaceInput = {
  /** address */
  address?: InputMaybe<GTAddressUpdateInput>
  /** amenities */
  amenities?: InputMaybe<Array<GTPlaceAmenity>>
  /** area */
  area?: InputMaybe<Scalars['String']['input']>
  /** directions */
  directions?: InputMaybe<Scalars['String']['input']>
  /** place id */
  id: Scalars['ID']['input']
  /** geolocation */
  location?: InputMaybe<GTLocationInput>
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** unique human readable identifier for the place */
  param?: InputMaybe<Scalars['String']['input']>
  /** tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  /** place type */
  type?: InputMaybe<GTPlaceType>
}

/** update policy company role input */
export type GTUpdatePolicyCompanyRoleInput = {
  /** company role id */
  id: Scalars['ID']['input']
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** policies to assign to the company role */
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** update policy private role input */
export type GTUpdatePolicyPrivateRoleInput = {
  /** private role id */
  id: Scalars['ID']['input']
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** policies to assign to the private role */
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GTUpdateSeasonEventInput = {
  activePeriods?: InputMaybe<Array<GTActivePeriodInput>>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  routeServiceIDs?: InputMaybe<Array<Scalars['ID']['input']>>
  segments?: InputMaybe<Array<Scalars['ID']['input']>>
  type?: InputMaybe<GTSeasonEventType>
}

/** update segment input */
export type GTUpdateSegmentInput = {
  /** company line specs */
  companyLineSpecs: Array<GTCompanyLineSpecInput>
  /** segment id */
  id: Scalars['ID']['input']
}

/** update template input */
export type GTUpdateVehicleTemplateInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

/** user query input */
export type GTUserQueryInput = {
  /** user id */
  id: Scalars['ID']['input']
}

/** users query input */
export type GTUsersQueryInmput = {
  /** user id */
  id?: InputMaybe<Scalars['ID']['input']>
  /** filter by isActive */
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type GTVehicleDocumentInput = {
  /** document category */
  category: GTDocumentCategory
  filePath: Scalars['String']['input']
}

/** vehicle query input */
export type GTVehicleQueryInput = {
  id: Scalars['ID']['input']
}

/** vehicles query input */
export type GTVehiclesQueryInput = {
  companyID?: InputMaybe<Scalars['ID']['input']>
}
